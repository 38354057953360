import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRateAlert } from '@pretto/app/src/Sentences/contexts/RateAlertContext'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { tooltipEmail } from '@pretto/app/src/Sentences/lib/email'
import { useTracking } from '@pretto/app/src/Sentences/lib/tracking/useTracking'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import SubscribeConsumer from '@pretto/app/src/SharedContainers/Subscribe'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce'

const Disclaimer = styled.div`
  ${({ theme }) => theme.typos.caption};
`

export const WantsRateAlertPage: React.FC = () => {
  const { rateAlert, setRateAlert } = useRateAlert()

  const { goToNextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useState(0)
  const trackAction = useTracking()
  const [debouncedEmail] = useDebounce(rateAlert.email, 1200)

  const handleActiveField = (key: string) => {
    if (key === YesNo.Yes) return setActiveField(1)
  }

  const mapWantsRateAlertValues: MapToChoices<YesNo[]> = values =>
    values.map(value => ({
      key: value,
      isSelected:
        (rateAlert.wantsRateAlert === true && value === YesNo.Yes) ||
        (rateAlert.wantsRateAlert === false && value === YesNo.No),
      onClick: () => {
        setRateAlert({ wantsRateAlert: value === YesNo.Yes })
      },
      label: t(`sentences.rateAlert.wantsRateAlert.values.${value}`),
    }))

  return (
    <SubscribeConsumer>
      {subscribeContext => (
        <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'wantsRateAlert' } }}>
          <Title>{t('sentences.rateAlert.title')}</Title>
          <div>
            {t('sentences.rateAlert.sentence')}
            <br />
            {t('sentences.rateAlert.wantsRateAlert.sentences.0')}
            <Prompt onClick={() => setActiveField(0)} isFilled={!isNil(rateAlert.wantsRateAlert)}>
              {t(
                `sentences.rateAlert.wantsRateAlert.values.${
                  rateAlert.wantsRateAlert || isNil(rateAlert.wantsRateAlert) ? YesNo.Yes : YesNo.No
                }`
              )}
            </Prompt>
            {activeField === 1 && (
              <>
                {t('sentences.rateAlert.wantsRateAlert.sentences.1')}
                <TrackedResizableField
                  {...tooltipEmail(debouncedEmail || '')}
                  clearable
                  events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'emailRateAlert' } }}
                  inputProps={{
                    autoFocus: activeField === 1,
                    placeholder: 'bruce.wayne@gmail.com',
                    spellCheck: false,
                    type: 'email',
                  }}
                  onChange={value => {
                    setRateAlert({ email: value })
                  }}
                  value={rateAlert.email || ''}
                />
                {!!rateAlert.email && (
                  <Disclaimer>
                    {t('sentences.rateAlert.emailRateAlert.disclaimer.0')}{' '}
                    <Link to={'https://www.pretto.fr/cgu'}>{t('sentences.rateAlert.emailRateAlert.disclaimer.1')}</Link>
                  </Disclaimer>
                )}
              </>
            )}
          </div>
          {activeField === 0 && (
            <Choices
              choices={mapWantsRateAlertValues(Object.values(YesNo))}
              events={{
                eventName: EventName.SimulationChoiceClicked,
                eventPayload: { choice: 'rateAlertWantsRateAlert' },
              }}
              onChoiceClick={handleActiveField}
            />
          )}
          {!isNil(rateAlert.wantsRateAlert) && (!rateAlert.wantsRateAlert || rateAlert.email) && (
            <ButtonNext
              onClick={async (event: React.MouseEvent<HTMLButtonElement> & React.MouseEvent<HTMLAnchorElement>) => {
                event.preventDefault()

                trackAction({
                  eventName: EventName.SimulationRateAlertDeclared,
                  eventPayload: { wantsRateAlert: rateAlert.wantsRateAlert },
                })

                if (rateAlert.wantsRateAlert) {
                  await subscribeContext.onSignUp({
                    assignment: 'project',
                    email: rateAlert.email,
                    kind: 'sentences',
                    source: 'sentences_rate_alert',
                    subscribeRateAlert: true,
                  })

                  trackAction({ eventName: EventName.SignedUp })
                }

                goToNextRoute()
              }}
            >
              {t('sentences.next')}
            </ButtonNext>
          )}
        </TrackedView>
      )}
    </SubscribeConsumer>
  )
}
