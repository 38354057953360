import { MaturityType } from '@pretto/app/src/Sentences/types/enums'
import { Occurrence } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/occurrence'
import { AdvisorLabelsType } from '@pretto/app/src/config/advisor'
import { t } from '@pretto/app/src/lib/i18n'

import { Trans } from 'react-i18next'

type getTextsProps = { advisorLabel: AdvisorLabelsType; maturity: MaturityType; occurrence: Occurrence }

export const getTexts = ({ advisorLabel = 'expert', maturity, occurrence }: getTextsProps) => {
  const formatedAdvisorLabel = advisorLabel.charAt(0).toUpperCase() + advisorLabel.slice(1)

  if (occurrence === Occurrence.PUSHY)
    return {
      title: t('mutualizedAgenda.title.postPushy'),
      description: <Trans i18nKey="mutualizedAgenda.description.postPushy" values={{ formatedAdvisorLabel }} />,
      buttonDRI: t('mutualizedAgenda.buttonDRI'),
      buttonSkip: t('mutualizedAgenda.buttonSkip.postPushy'),
    }

  if ([MaturityType.UnderStudy, MaturityType.SearchStarted].includes(maturity))
    return {
      title: <Trans i18nKey="mutualizedAgenda.title.default" values={{ formatedAdvisorLabel }} />,
      description: <Trans i18nKey="mutualizedAgenda.description.default" />,
      buttonDRI: t('mutualizedAgenda.buttonDRI'),
      buttonSkip: t('mutualizedAgenda.buttonSkip.default'),
    }

  return {
    title: <Trans i18nKey="mutualizedAgenda.title.default" values={{ formatedAdvisorLabel }} />,
    description: <Trans i18nKey="mutualizedAgenda.description.hot" />,
    buttonDRI: t('mutualizedAgenda.buttonDRI'),
    buttonSkip: t('mutualizedAgenda.buttonSkip.default'),
  }
}
