import { GetSimulation } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import { SimulationKind } from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'

export const mapPtz: ComposableMapper = ({ project, simulation }) => {
  if (simulation.kind !== SimulationKind.Purchase && simulation.kind !== SimulationKind.Capacity) {
    return { project, simulation }
  }

  const mapBeenOwner = (simulation: GetSimulation): boolean | null | undefined => {
    if (isNil(simulation.ptzBeenOwner)) {
      return null
    }

    return !simulation.ptzBeenOwner
  }

  const mapPtzFiscalIncomeNm2 = (simulation: GetSimulation): number | null | undefined => {
    if (simulation.ptzBeenOwner !== false) {
      return null
    }

    return simulation.ptzFiscalIncomeNm2
  }

  return {
    project: {
      ...project,

      profile: {
        ...project.profile,
        first_time_buyer: mapBeenOwner(simulation),
        fiscal_income_nm2: mapPtzFiscalIncomeNm2(simulation),
      },
    },
    simulation,
  }
}
