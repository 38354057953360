import * as S from './PartnerCard.styles'

export interface PartnerCardProps {
  content: string
  picture: string | null
  link: string
  onClickRecoTrackType?: () => void
}

export const PartnerCard: React.FC<PartnerCardProps & React.HTMLAttributes<HTMLDivElement>> = ({
  content,
  picture,
  link,
  onClickRecoTrackType,
}) => {
  return (
    <S.PartnerCard>
      {picture !== null ? (
        <S.ImageContainer>
          <S.Image path={picture} />
        </S.ImageContainer>
      ) : (
        <S.ImagePlaceholder>
          <S.Icon />
        </S.ImagePlaceholder>
      )}
      <S.Container>
        <S.Content>{content}</S.Content>
        <S.PartnerLink href={link} onClick={onClickRecoTrackType}>
          En savoir plus
        </S.PartnerLink>
      </S.Container>
    </S.PartnerCard>
  )
}
