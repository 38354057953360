import { useEffect } from 'react'

import { useAuth } from '../AuthProvider'

const LogoutPage = () => {
  const { signOut } = useAuth()

  useEffect(() => {
    signOut()
  }, [])

  return null
}

export default LogoutPage
