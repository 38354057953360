import { isYearAfterToday, isYearInLessThan } from '@pretto/app/src/Sentences/Loan/DateDuration/lib/checkYear'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField2'
import { t } from '@pretto/app/src/lib/i18n'

import { isNil } from 'lodash'
import { useDebounce } from 'use-debounce'

export const DateDurationPage: React.FC = () => {
  const { simulation, setSimulation } = useSentences()
  const { activeField, refreshField, isFilled } = useActiveField(
    ({ renegotiationLoanDuration, renegotiationLoanStartYear }) => [
      isNil(renegotiationLoanStartYear),
      isNil(renegotiationLoanDuration),
    ]
  )

  const isStartYearError =
    simulation.renegotiationLoanStartYear && isYearAfterToday(simulation.renegotiationLoanStartYear)
  const endYear = (simulation.renegotiationLoanStartYear ?? 0) + (simulation.renegotiationLoanDuration ?? 0)
  const isEndYearError = endYear && isYearInLessThan(endYear, 5)

  const [debouncedIsStartYearError] = useDebounce(isStartYearError, 1200)
  const [debouncedIsEndYearError] = useDebounce(isEndYearError, 1200)

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'dateDuration' } }}>
      <Title>{t('sentences.loan.title')}</Title>

      <div>
        {t(`sentences.loan.dateDuration.sentences.0`)}
        <TrackedResizableField
          clearable
          events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'loanStartYear' } }}
          inputProps={{ autoFocus: true, format: '0000', placeholder: '2015', type: 'date' }}
          message={t('sentences.loan.dateDuration.date.error')}
          onChange={(value: string) => {
            const renegotiationLoanStartYear = toIntOrNull(value)
            setSimulation({ renegotiationLoanStartYear })
            refreshField({ renegotiationLoanStartYear })
          }}
          state={debouncedIsStartYearError ? 'error' : 'default'}
          value={simulation.renegotiationLoanStartYear?.toString() ?? ''}
        />
        {activeField > 0 && (
          <>
            {t(`sentences.loan.dateDuration.sentences.1`)}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'loanDuration' } }}
              inputProps={{ maxLength: 2, placeholder: '20', type: 'integer' }}
              message={t('sentences.loan.dateDuration.duration.error')}
              onChange={(value: string) => {
                setSimulation({ renegotiationLoanDuration: toIntOrNull(value) })
              }}
              state={debouncedIsEndYearError ? 'error' : 'default'}
              value={simulation.renegotiationLoanDuration?.toString() ?? ''}
            />
            {t(`sentences.loan.dateDuration.sentences.2`)}
          </>
        )}
        .
      </div>

      {isFilled && !isStartYearError && !isEndYearError && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
