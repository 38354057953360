import { useContribution } from '@pretto/app/src/Sentences/Contribution/Contribution/lib/useContribution'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { getMinimumContribution, MinimumContribution } from '@pretto/app/src/Sentences/lib/contribution'
import { getContributionPlaceholder, getSavingsPlaceholder } from '@pretto/app/src/Sentences/lib/placeholders'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationKind, SimulationMortgagorLodgingLocationKind } from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'
import { useEffect, useState } from 'react'

export const ContributionPage: React.FC = () => {
  const { nextLabel, tooltipComponent } = useContribution()
  const { simulation, setSimulation } = useSentences()
  const { nextRoute } = useRoutesGraph()

  const isNonResident =
    simulation.profileMortgagors[0]?.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.World
  const isPurchase = simulation.kind === SimulationKind.Purchase

  const [minimumContribution, setMinimumContribution] = useState<MinimumContribution | null>(null)

  const getDefaultActiveField = () => {
    if (isNil(simulation.contribution)) return 0
    if (isNil(simulation.savingsResidual)) return 1
    return 2
  }
  const getIsFilled = () => {
    if (!nextRoute) return false
    return !isNil(simulation.contribution) && !isNil(simulation.savingsResidual)
  }

  const [activeField, setActiveField] = useActiveField(getDefaultActiveField(), getIsFilled())

  useEffect(() => {
    if (!isNonResident || !isPurchase) {
      return
    }

    getMinimumContribution(simulation).then(setMinimumContribution)
  }, [])

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'contribution' } }}>
      <Title>{t('sentences.contribution.title')}</Title>
      <div>
        {simulation.ownPropertyForSale
          ? t(`sentences.contribution.contribution.sentences.0`)
          : t(`sentences.contribution.contribution.sentences.1`)}
        {t('sentences.contribution.contribution.sentences.2')}
        <TrackedResizableField
          clearable
          events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'contribution' } }}
          inputProps={{
            autoFocus: activeField === 0,
            placeholder: getContributionPlaceholder(simulation),
            type: 'integer',
          }}
          onChange={(value: string) => {
            setSimulation({ contribution: toIntOrNull(value) })
            setActiveField(2)
          }}
          value={simulation.contribution?.toString() || ''}
        />
        {t('sentences.contribution.contribution.sentences.3')},&nbsp;
        {activeField > 0 && (
          <>
            {t('sentences.contribution.savings.sentences.0')}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'savings' } }}
              inputProps={{
                autoFocus: activeField === 1,
                placeholder: getSavingsPlaceholder(simulation),
                type: 'integer',
              }}
              onChange={(value: string) => {
                setSimulation({ savingsResidual: toIntOrNull(value) })
              }}
              value={simulation.savingsResidual?.toString() || ''}
            />
            {t('sentences.contribution.savings.sentences.1')}
          </>
        )}
      </div>

      {tooltipComponent}

      {activeField > 0 && !isNil(simulation.savingsResidual) && isNonResident && isPurchase && minimumContribution && (
        <Message
          html={t('sentences.tips.nonResident.savings', {
            minimumSavings: minimumContribution.savings ? minimumContribution.savings.toString() : '',
          })}
        />
      )}

      <ButtonNext>{nextLabel}</ButtonNext>
    </TrackedView>
  )
}
