import BookingUnavailable from '@pretto/bricks/app/booking/components/BookingUnavailable'
import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import temporal from '@pretto/bricks/core/utility/temporal'

import { Calendar } from '@pretto/zen/dashboard/calendar/Calendar/Calendar'
import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import { formatDecodePhone } from '@pretto/app-core/lib/filters'
import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'

import { getTypeVisio } from '@pretto/app/src/Booking/Containers/BookingWidget/lib/getTypeVisio'
import { DURATION_TYPES } from '@pretto/app/src/Booking/config/duration'
import { getAvailabilitiesFromCache } from '@pretto/app/src/Booking/lib/getAvailabilitiesFromCache'
import { getOffTimezone } from '@pretto/app/src/Booking/lib/getOffTimezone'
import { populateCache } from '@pretto/app/src/Booking/lib/populateCache'
import useAvailabilities from '@pretto/app/src/Booking/lib/useAvailabilities'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { ADVISOR, BOOK_APPOINTMENT, REFERRAL_BOOK } from '@pretto/app/src/apollo'
import parseDuration from '@pretto/app/src/lib/duration'
import { getIsHotMaturity } from '@pretto/app/src/lib/getIsHotMaturity'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { cloneElement, createElement, useEffect, useRef, useState } from 'react'

import { BookingVisioModal } from '../../components/BookingVisioModal'
import { getBookingDuration } from '../../lib/getBookingDuration'

const DEFAULT_APPOINTMENT_KIND = 'r1'

const BookingWidget = ({
  advisorEmail,
  advisorPicture,
  appointmentKind,
  constraints,
  defaultOffsetSize,
  delayBeforeAppointment,
  hideNavigation,
  hideSeeMore,
  hideDuration,
  maxRows,
  offsetSizes,
  onBooked,
  onBooking,
  onUnavailable,
  typology,
  unavailableComponent,
  isHeaderRowSticky,
  ...props
}) => {
  const client = useApolloClient()

  const [getAvailabilitiesBetween, getAvailabilitiesWithinAndRepeat, getMinimumAvailabilitiesFrom, kind] =
    useAvailabilities(constraints, appointmentKind, advisorEmail)

  const offsetSize = useBreakpointToValue(defaultOffsetSize, offsetSizes)

  const [bookAppointment] = useMutation(BOOK_APPOINTMENT)
  const [bookReferAppointment] = useMutation(REFERRAL_BOOK)

  const isUnmountedRef = useRef(false)

  const [cache, setCache] = useState([])
  const [currentOffset, setCurrentOffset] = useState(0)
  const [firstAvailabilityDateTime, setFirstAvailabilityDateTime] = useState(null)
  const [isChoiceDialogOpen, setIsChoiceDialogOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [mutatingSlotValue, setMutatingSlotValue] = useState(null)

  const trackAction = useTracking()

  const { isVisioEnabled, isVisioMandatory } = useUser()

  const duration = getBookingDuration(kind).meetDuration

  useEffect(() => {
    ;(async () => {
      // Try to find availabilities withing the next 6 weeks
      // in 3 successive calls of 2 weeks batch.
      // If none, disabled loader, since the cache remains empty,
      // the controller will return a error.
      const firstAvailabilities = await getAvailabilitiesWithinAndRepeat({
        fromDateTime: temporal().add(...delayBeforeAppointment),
      })

      if (isUnmountedRef.current) {
        return
      }

      if (!firstAvailabilities.length) {
        onUnavailable()
        setIsLoading(false)
        return
      }

      const firstAvailabilityDateTime = temporal(firstAvailabilities[0].start)

      setFirstAvailabilityDateTime(firstAvailabilityDateTime)
    })()

    return () => (isUnmountedRef.current = true)
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!firstAvailabilityDateTime) {
        return
      }

      const bufferSize = Math.max(offsetSize, 3)
      const currentAvailabilities = getAvailabilitiesFromCache(cache, offsetSize)
      const projectedCurrentOffset = (currentOffset + 1) * offsetSize + bufferSize - 1

      if (currentAvailabilities.length >= projectedCurrentOffset) {
        setIsLoading(false)
        return
      }

      const fromDateTime = cache[cache.length - 1]?.value
        ? temporal(cache[cache.length - 1].value).add(1, 'day')
        : firstAvailabilityDateTime

      if (offsetSize === 1) {
        const availabilities = await getMinimumAvailabilitiesFrom({
          fromDateTime,
          minimumDays: projectedCurrentOffset - currentAvailabilities.length,
        })

        if (isUnmountedRef.current) {
          return
        }

        if (availabilities.length === 0) {
          setIsLoading(false)
          return
        }

        const toDateTime = temporal(availabilities[availabilities.length - 1].start).endOf('day')
        const populatedCache = populateCache({ availabilities, cache, fromDateTime, toDateTime })

        setCache(populatedCache)
      }

      if (offsetSize > 1) {
        const toDateTime = firstAvailabilityDateTime.add(projectedCurrentOffset, 'day').endOf('day')

        const availabilities = await getAvailabilitiesBetween(fromDateTime, toDateTime)

        if (isUnmountedRef.current) {
          return
        }

        const populatedCache = populateCache({ availabilities, cache, fromDateTime, toDateTime })

        setCache(populatedCache)
      }

      setIsLoading(false)
    })()
  }, [cache, firstAvailabilityDateTime, currentOffset, offsetSize])

  useEffect(() => {
    // For data tracking
    if (cache.length === 0 || !isLoading) {
      return
    }

    const slotsCount = cache.slice(0, offsetSize).map(({ slots, value }) => ({
      count: slots.length,
      daysSinceToday: temporal(value).diff(temporal().startOf('day'), 'day'),
    }))

    trackAction('ADVISOR_AVAILABILITIES_ACCESSED', {
      booking_appointment_duration: duration,
      booking_appointment_kind: kind,
      booking_appointment_slots_count: slotsCount,
    })
  }, [cache, isLoading])

  useEffect(() => {
    setCurrentOffset(0)
  }, [offsetSize])

  const handleNext = () => {
    setCurrentOffset(index => index + 1)
  }

  const handlePrevious = () => {
    setCurrentOffset(index => Math.max(0, index - 1))
  }

  const handleCloseChoiceDialog = () => {
    setIsChoiceDialogOpen(false)
    setMutatingSlotValue(null)
  }

  const handleSelectCallType = async isVisio => {
    setIsChoiceDialogOpen(false)

    trackAction('APPOINTMENT_POPUP_BUTTON_CLICKED', {
      appointment_popup_button_is_visio: isVisio,
      advisor_has_visio: getTypeVisio({ isVisioEnabled, isVisioMandatory }),
    })

    await select(mutatingSlotValue, isVisio)
  }

  const handleSelect = async ({ value }) => {
    setMutatingSlotValue(value)

    if (isVisioEnabled) {
      setIsChoiceDialogOpen(true)
      return
    }

    await select(value)

    setMutatingSlotValue(null)
  }

  const select = async (startTime, isVisio = false) => {
    const isForceVisio = kind === 'r3' ? true : isVisio
    onBooking(duration, startTime)

    if (typology === 'preLead') {
      await bookReferAppointment({ variables: { startTime } })
    } else {
      await bookAppointment({
        update: () => {
          invalidateCache(client)
        },
        variables: { isVisio: isForceVisio, kind, startTime },
      })
    }

    onBooked(duration, startTime, kind, availabilities)
  }

  if (isLoading) {
    return <SpinnerLegacy />
  }

  if (!cache.length) {
    return cloneElement(unavailableComponent)
  }

  const availabilities = getAvailabilitiesFromCache(cache, offsetSize)
  const days = availabilities.slice(currentOffset * offsetSize, offsetSize * (currentOffset + 1))

  const isNextDisabled = (currentOffset + 1) * offsetSize >= availabilities.length
  const isPreviousDisabled = currentOffset === 0

  const timezone = getOffTimezone()

  const calendarProps = {
    days,
    duration: !hideDuration && parseDuration(duration).toString(),
    isNextDisabled,
    isPreviousDisabled,
    isMoreButtonDisabled: hideSeeMore,
    isNavigationDisabled: hideNavigation,
    isHeaderRowSticky,
    mutatingSlotValue,
    onPrevious: handlePrevious,
    onNext: handleNext,
    onSelect: handleSelect,
    timezone,
    truncatedRows: maxRows,
  }

  const dialogProps = {
    advisorPicture,
    appointmentDate: temporal(mutatingSlotValue).format('dddd LL [à] HH[h]mm'),
    isOpen: isChoiceDialogOpen,
    isVisioMandatory,
    onClickPhone: handleSelectCallType.bind(null, false),
    onClickVisio: handleSelectCallType.bind(null, true),
    onClose: handleCloseChoiceDialog,
  }

  return (
    <>
      {isVisioEnabled && <BookingVisioModal {...dialogProps} />}
      <Calendar {...props} {...calendarProps} />
    </>
  )
}

BookingWidget.defaultProps = {
  defaultOffsetSize: 1,
  delayBeforeAppointment: [2, 'hour'],
  offsetSizes: { tablet: 7 },
  onBooking: () => {},
}

BookingWidget.propTypes = {
  advisorEmail: PropTypes.string.isRequired,
  advisorPicture: PropTypes.string,
  appointmentKind: PropTypes.oneOf(Object.keys(DURATION_TYPES)).isRequired,
  constraints: PropTypes.object.isRequired,
  defaultOffsetSize: PropTypes.number,
  /** Used as arguments from `Add` function in dayjs: https://day.js.org/docs/en/manipulate/add */
  delayBeforeAppointment: PropTypes.arrayOf(
    PropTypes.oneOfType(PropTypes.number, PropTypes.oneOf(['day', 'hour', 'minute']))
  ),
  hideNavigation: PropTypes.bool,
  hideSeeMore: PropTypes.bool,
  hideDuration: PropTypes.bool,
  isHeaderRowSticky: PropTypes.bool,
  maxRows: PropTypes.number,
  offsetSizes: PropTypes.object,
  onBooked: PropTypes.func.isRequired,
  onBooking: PropTypes.func,
  onUnavailable: PropTypes.func.isRequired,
  typology: PropTypes.string.isRequired,
  unavailableComponent: PropTypes.node,
}

const BookingWidgetController = props => {
  const {
    advisor: { email: advisorEmail, mediumPicturePath: advisorPicture, phone: advisorPhone },
    typology,
    maturity,
  } = useUser()

  const { data, loading } = useQuery(ADVISOR)

  const trackAction = useTracking()

  useEffect(() => {
    if (loading) {
      return
    }

    if (!data.advisor.availabilities || data.advisor.availabilities.length > 0) {
      return
    }

    trackUnavailable()
  }, [loading])

  const trackUnavailable = () => {
    trackAction('BOOKING_UNAVAILABLE_VIEWED')
  }

  const handleUnavailable = trackUnavailable

  if (loading) {
    return <SpinnerLegacy />
  }

  const {
    advisor: { absences, allowPeriods, availabilities },
  } = data

  const appointmentKind = data.deal?.appointmentKind ?? DEFAULT_APPOINTMENT_KIND

  const unavailableComponent = createElement(BookingUnavailable, {
    email: advisorEmail || 'hello@pretto.fr',
    phoneE164: advisorPhone,
    phoneNational: formatDecodePhone(advisorPhone),
  })

  if ((!availabilities || availabilities.length === 0) && getIsHotMaturity(maturity)) {
    return cloneElement(unavailableComponent)
  }

  return (
    <BookingWidget
      advisorPicture={advisorPicture}
      advisorEmail={advisorEmail}
      appointmentKind={appointmentKind}
      {...props}
      constraints={{ absences, allowPeriods, availabilities }}
      onUnavailable={handleUnavailable}
      typology={typology}
      unavailableComponent={unavailableComponent}
    />
  )
}

export default BookingWidgetController
