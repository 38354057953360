import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { usePrettoSearch } from '@pretto/app/src/Sentences/contexts/PrettoSearchContext'
import { Frequency } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

export const FrequencyPage: React.FC = () => {
  const { prettoSearch, setPrettoSearch } = usePrettoSearch()

  const mapFrequencyValues: MapToChoices<Frequency[]> = values => {
    return values.map(value => ({
      key: value,
      isSelected: prettoSearch.frequency === value,
      onClick: () => {
        setPrettoSearch({ frequency: value })
      },
      label: t(`sentences.propertySearch.frequency.values.${value}`),
    }))
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'frequency' } }}>
      <Title>{t('sentences.propertySearch.title')}</Title>
      <div>
        {t('sentences.propertySearch.frequency.sentence')}
        <Prompt isFilled={!!prettoSearch.frequency}>
          {t(`sentences.propertySearch.frequency.values.${prettoSearch.frequency || Frequency.Daily}`)}
        </Prompt>
        .
      </div>
      <Choices
        choices={mapFrequencyValues(Object.values(Frequency))}
        events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'propertySearchFrequency' } }}
      />
      <ButtonNext>{t('sentences.next')}</ButtonNext>
    </TrackedView>
  )
}
