import DashboardOverviewCard from '@pretto/bricks/app/dashboard/components/DashboardOverviewCard'
import Link from '@pretto/bricks/website/utility/Link'

import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import type { CardProps, Config } from '@pretto/app/src/dashboard/types/card'
import { formatDisplayNumber } from '@pretto/app/src/lib/formats'
import { getIsHotMaturity } from '@pretto/app/src/lib/getIsHotMaturity'
import { DETAILS } from '@pretto/app/src/overview/config/labels'
import { ProjectKind } from '@pretto/app/src/types/gateway/enums'

import get from 'lodash/get'

import { useOverviewCardQuery } from './overview.gateway.graphql'

type Keys = keyof typeof DETAILS

const PURCHASE_KEYS: Keys[] = [
  'project.purchase.property_price',
  'best_result.financing.contribution',
  'best_result.financing.loanAmount',
]

const RENEGOTIATION_KEYS: Keys[] = [
  'project.renegotiation.remaining_principal',
  'best_result.financing.penaltyFees',
  'best_result.financing.loanAmount',
]

const ACTION_URL = '/overview'

const Overview: React.FC<CardProps> = ({ onActionClick }) => {
  const { data, loading } = useOverviewCardQuery({ fetchPolicy: 'network-only' })

  const {
    maturity,
    user: { projectKind },
  } = useUser()

  if (loading) {
    return null
  }

  if (!data?.best_result) {
    return (
      <DashboardOverviewCard
        description={
          <>
            Il nous manque des informations ou votre projet n’est pas réalisable dans sa forme actuelle.
            <br />
            Vous pouvez <Link href="/simulation">ajuster</Link> votre projet ou contacter votre expert pour en discuter
            ensemble.
          </>
        }
        hasHighlights={false}
      />
    )
  }

  const projectValues = projectKind === ProjectKind.Purchase ? PURCHASE_KEYS : RENEGOTIATION_KEYS

  const dashboardHighlightsProps = {
    highlights: projectValues.reduce<{ title: string; value: string }[]>((previous, key) => {
      const value = get(data, key)

      if (!value) {
        return previous
      }

      return [...previous, { title: DETAILS[key], value: formatDisplayNumber(value) }]
    }, []),
  }

  const handleActionClick = () => {
    onActionClick(ACTION_URL)
  }

  return (
    <DashboardOverviewCard
      actionUrl={ACTION_URL}
      description="Consultez les détails de votre projet. Vous pouvez aussi modifier votre projet et comparer les taux."
      dashboardHighlightsProps={dashboardHighlightsProps}
      hasHighlights
      maturity={maturity}
      projectKind={projectKind}
      onActionClick={handleActionClick}
    />
  )
}

export const config: Config = {
  component: Overview,
  name: 'overview',
  condition: (data, { hasFeatureAccess, project: { project_kind: projectKind, maturity: maturity } }) =>
    projectKind === ProjectKind.Renegotiation || (getIsHotMaturity(maturity) && !hasFeatureAccess(['BUYOUT'])),
}
