import { g } from '@pretto/bricks/components/layout'

import { ChoiceProps, Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorAdditionalRevenueKind } from '@pretto/app/src/types/api/enums'
import { SimulationMortgagorAdditionalRevenue } from '@pretto/app/src/types/api/schema'
import { TimesBold } from '@pretto/picto'

import isNil from 'lodash/isNil'
import styled, { css } from 'styled-components'

const Container = styled.div<{ $isEditable: boolean }>`
  padding: ${g(2)};
  border-radius: ${g(1)};
  ${({ $isEditable, theme }) =>
    $isEditable
      ? css`
          border: 1px solid ${theme.colors.neutral2};
        `
      : css`
          background-color: ${theme.colors.neutral5};
        `}
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${g(2)};
`

const Title = styled.h1`
  ${({ theme }) => theme.typos.body4Bold}
`

const Cross = styled(TimesBold)`
  cursor: pointer;
`

interface RevenueBoxProps {
  activeField: number
  index: number
  isEditable: boolean
  onChangeAmount: (value: string) => void
  onPromptClick: () => void
  onChoiceClick: () => void
  onClick: () => void
  onRemove: () => void
  revenue: Pick<SimulationMortgagorAdditionalRevenue, 'amount' | 'kind'>
  choices: ChoiceProps[]
}

export const RevenueBox: React.FC<RevenueBoxProps> = ({
  activeField,
  onPromptClick,
  onChoiceClick,
  index,
  isEditable,
  onChangeAmount,
  onClick,
  onRemove,
  revenue,
  choices,
  ...props
}) => {
  return (
    <Container onClick={onClick} $isEditable={isEditable} {...props}>
      <Header>
        <Title>
          {t('sentences.income.revenue.subtitle')} {index + 1} :
        </Title>
        <Cross onClick={onRemove} />
      </Header>
      <Prompt isFilled={!isNil(revenue.kind)} onClick={onPromptClick}>
        {t(`sentences.income.revenue.values.${revenue.kind || SimulationMortgagorAdditionalRevenueKind.Rent}`)}
      </Prompt>
      {!isEditable && revenue.amount && (
        <>
          {t('sentences.income.revenue.sentences.0')}
          <Prompt isFilled>{revenue.amount}</Prompt>
          {t('sentences.income.revenue.sentences.1')}
        </>
      )}
      {isEditable && activeField === 1 && (
        <>
          {t('sentences.income.revenue.sentences.0')}
          <TrackedResizableField
            clearable
            events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'incomeRevenueAmount' } }}
            inputProps={{ autoFocus: activeField === 1, placeholder: '500', type: 'integer' }}
            onChange={onChangeAmount}
            value={revenue.amount?.toString() || ''}
          />
          {t('sentences.income.revenue.sentences.1')}
        </>
      )}
      {activeField === 0 && isEditable && (
        <Choices
          choices={choices}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'incomeRevenueBox' } }}
          onChoiceClick={onChoiceClick}
        />
      )}
    </Container>
  )
}
