import { g, ng } from '@pretto/bricks/components/layout'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'

import styled, { css, keyframes } from 'styled-components'

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Image = styled(ImageComponent).attrs({
  options: { height: ng(30), width: ng(30) },
})``

export const ContentContainer = styled.div`
  max-width: 350px;
`

export const Title = styled.h5`
  ${({ theme }) => theme.typos.heading5};
  margin: ${g(2)} 0 ${g(2)} 0;
`

export const List = styled.ul``

export const ListItem = styled.li<{ isActive: boolean; isPrevious: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 10px 0;
  position: relative;

  &::before {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    content: '';

    ${props =>
      props.isActive &&
      css`
        display: inline-block;
        width: 24px;
        height: 24px;
        border: 2px solid transparent;
        border-top: 2px solid rgba(0, 104, 85, 1);
        border-right: 2px solid rgba(255, 255, 255, 0);
        border-left: 2px solid rgba(255, 255, 255, 0);
        animation: ${spin} 1s linear infinite;
        border-radius: 50%;
      `}
  }

  &::after {
    content: '';
    position: absolute;
    left: 8px;
    top: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props =>
      props.isActive
        ? ({ theme }) => theme.colors.primary1
        : props.isPrevious
        ? ({ theme }) => theme.colors.neutral1
        : ({ theme }) => theme.colors.neutral2};

    ${props =>
      props.isActive &&
      css`
        display: none;
      `}
  }
`

export const Content = styled.p<{ isActive: boolean; isPrevious: boolean }>`
  padding: 0 0 0 ${g(2)};
  ${({ theme }) => theme.typos.body4};
  color: ${props =>
    props.isActive
      ? ({ theme }) => theme.colors.primary1
      : props.isPrevious
      ? ({ theme }) => theme.colors.neutral1
      : ({ theme }) => theme.colors.neutral2};
`
