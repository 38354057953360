import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorOccupationDuration } from '@pretto/app/src/types/api/enums'

export const mapDuration: MapToChoices<SimulationMortgagorOccupationDuration[]> = (values, context) => {
  if (!context) return []
  const { simulation, setSimulationComortgagor } = context

  return values.map(value => ({
    key: value,
    isSelected: simulation.profileMortgagors[1]?.occupationDuration === value,
    onClick: () => {
      setSimulationComortgagor({ occupationDuration: value })
    },
    label: t(`sentences.situation.contractType.values.${value}`),
  }))
}
