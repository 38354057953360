import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import { SimulationKind } from '@pretto/app/src/types/api/enums'

export const mapContribution: ComposableMapper = ({ project, simulation }) => {
  if (
    simulation.kind !== SimulationKind.Buyout &&
    simulation.kind !== SimulationKind.Capacity &&
    simulation.kind !== SimulationKind.Purchase
  ) {
    return { project, simulation }
  }

  return {
    project: {
      ...project,

      contribution: simulation.contribution,
    },
    simulation,
  }
}
