import { checkSeniorityYearIsValid } from '@pretto/app/src/Sentences/Occupation/Sector/lib/checkSeniorityYearIsValid'
import {
  SimulationMortgagorOccupationDuration,
  SimulationMortgagorOccupationKind,
  SimulationMortgagorOccupationOtherStatus,
  SimulationMortgagorOccupationPublicStatus,
} from '@pretto/app/src/types/api/enums'
import { SimulationMortgagor } from '@pretto/app/src/types/api/schema'

export const checkSeniorityYearIsFilled = (mortgagor: SimulationMortgagor): boolean => {
  switch (mortgagor.occupationKind) {
    case SimulationMortgagorOccupationKind.Private:
      if (mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.ShortTerm) {
        return checkSeniorityYearIsValid(mortgagor.occupationStartYear)
      }
      return true

    case SimulationMortgagorOccupationKind.Public:
      if (
        mortgagor.occupationPublicStatus === SimulationMortgagorOccupationPublicStatus.Contractor &&
        mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.ShortTerm
      ) {
        return checkSeniorityYearIsValid(mortgagor.occupationStartYear)
      }
      return true

    case SimulationMortgagorOccupationKind.Other:
      if (mortgagor.occupationOtherStatus !== SimulationMortgagorOccupationOtherStatus.Retired) {
        return checkSeniorityYearIsValid(mortgagor.occupationStartYear)
      }
      return true

    case SimulationMortgagorOccupationKind.Medical:
      return checkSeniorityYearIsValid(mortgagor.occupationStartYear)

    default:
      return true
  }
}
