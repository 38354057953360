import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationPropertyKind } from '@pretto/app/src/types/api/enums'

export const mapPropertyTypeValues: MapToChoices<SimulationPropertyKind[]> = values => {
  const { simulation, setSimulation } = useSentences()

  return values.map(value => ({
    key: value,
    isSelected: simulation.propertyKind === value,
    onClick: () => {
      setSimulation({ propertyKind: value })
    },
    label: t(`sentences.introduction.propertyType.values.${value}`),
  }))
}
