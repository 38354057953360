import * as Types from '@pretto/app/src/types/api/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectKickoffQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProjectKickoffQuery = { __typename?: 'RootQuery', projectKickoff: { __typename?: 'ProjectKickoff', id: string, nextSteps: Array<{ __typename?: 'ProjectKickoffStep', kind: Types.ProjectKickoffStepKind }>, completedSteps: Array<{ __typename?: 'ProjectKickoffStep', kind: Types.ProjectKickoffStepKind }> } };

export type CompleteProjectKickoffStepMutationVariables = Types.Exact<{
  input: Types.CompleteProjectKickoffStepInput;
}>;


export type CompleteProjectKickoffStepMutation = { __typename?: 'RootMutation', completeProjectKickoffStep?: { __typename?: 'ProjectKickoff', completedSteps: Array<{ __typename?: 'ProjectKickoffStep', kind: Types.ProjectKickoffStepKind, completedAt?: any | null }> } | null };


export const ProjectKickoffDocument = gql`
    query ProjectKickoff {
  projectKickoff {
    id
    nextSteps {
      kind
    }
    completedSteps {
      kind
    }
  }
}
    `;

/**
 * __useProjectKickoffQuery__
 *
 * To run a query within a React component, call `useProjectKickoffQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectKickoffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectKickoffQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectKickoffQuery(baseOptions?: Apollo.QueryHookOptions<ProjectKickoffQuery, ProjectKickoffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectKickoffQuery, ProjectKickoffQueryVariables>(ProjectKickoffDocument, options);
      }
export function useProjectKickoffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectKickoffQuery, ProjectKickoffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectKickoffQuery, ProjectKickoffQueryVariables>(ProjectKickoffDocument, options);
        }
export type ProjectKickoffQueryHookResult = ReturnType<typeof useProjectKickoffQuery>;
export type ProjectKickoffLazyQueryHookResult = ReturnType<typeof useProjectKickoffLazyQuery>;
export type ProjectKickoffQueryResult = Apollo.QueryResult<ProjectKickoffQuery, ProjectKickoffQueryVariables>;
export const CompleteProjectKickoffStepDocument = gql`
    mutation CompleteProjectKickoffStep($input: CompleteProjectKickoffStepInput!) {
  completeProjectKickoffStep(input: $input) {
    completedSteps {
      kind
      completedAt
    }
  }
}
    `;
export type CompleteProjectKickoffStepMutationFn = Apollo.MutationFunction<CompleteProjectKickoffStepMutation, CompleteProjectKickoffStepMutationVariables>;

/**
 * __useCompleteProjectKickoffStepMutation__
 *
 * To run a mutation, you first call `useCompleteProjectKickoffStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteProjectKickoffStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeProjectKickoffStepMutation, { data, loading, error }] = useCompleteProjectKickoffStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteProjectKickoffStepMutation(baseOptions?: Apollo.MutationHookOptions<CompleteProjectKickoffStepMutation, CompleteProjectKickoffStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteProjectKickoffStepMutation, CompleteProjectKickoffStepMutationVariables>(CompleteProjectKickoffStepDocument, options);
      }
export type CompleteProjectKickoffStepMutationHookResult = ReturnType<typeof useCompleteProjectKickoffStepMutation>;
export type CompleteProjectKickoffStepMutationResult = Apollo.MutationResult<CompleteProjectKickoffStepMutation>;
export type CompleteProjectKickoffStepMutationOptions = Apollo.BaseMutationOptions<CompleteProjectKickoffStepMutation, CompleteProjectKickoffStepMutationVariables>;