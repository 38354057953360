import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { OthersChoices } from '@pretto/app/src/Sentences/Occupation/Sector/Containers/SectorPage/Others/choices'
import { OthersSentence } from '@pretto/app/src/Sentences/Occupation/Sector/Containers/SectorPage/Others/sentence'
import { checkSeniorityYearIsFilled } from '@pretto/app/src/Sentences/Occupation/Sector/lib/checkSeniorityYearIsFilled'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { usePrettoSearch } from '@pretto/app/src/Sentences/contexts/PrettoSearchContext'
import { useRateAlert } from '@pretto/app/src/Sentences/contexts/RateAlertContext'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import {
  isCDD as isMortgagorCSS,
  isEarlyBusinessOwner as isMortgagorEarlyBusinessOwner,
  isOnTrial as isMortgagorOnTrial,
} from '@pretto/app/src/Sentences/lib/contract'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { isOccupationFilled } from '@pretto/app/src/Sentences/lib/validators/context'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorOccupationKind } from '@pretto/app/src/types/api/enums'

import { PrivateChoices } from './Private/choices'
import { PrivateSentence } from './Private/sentence'
import { PublicChoices } from './Public/choices'
import { PublicSentence } from './Public/sentence'

export const SectorPage: React.FC = () => {
  const { isLoggedIn } = useAuth()
  const { prettoSearch } = usePrettoSearch()
  const { rateAlert } = useRateAlert()

  const { simulation, setSimulationMortgagor } = useSentences()
  const mainMortgagor = simulation.profileMortgagors[0] ?? {}
  const { nextRoute } = useRoutesGraph()

  const isOnTrial = isMortgagorOnTrial(mainMortgagor)
  const isCDD = isMortgagorCSS(mainMortgagor)
  const isEarlyBusinessOwner = isMortgagorEarlyBusinessOwner(mainMortgagor)

  const [activeField, setActiveField] = useActiveField(
    0,
    Boolean(nextRoute && isOccupationFilled({ isLoggedIn, prettoSearch, rateAlert, simulation }))
  )

  const mapSectorValues: MapToChoices<SimulationMortgagorOccupationKind[]> = values => {
    return values.map(value => ({
      key: value,
      isSelected: mainMortgagor.occupationKind === value,
      onClick: () => {
        setSimulationMortgagor({ occupationKind: value })
        setActiveField(1)
      },
      label: t(`sentences.situation.sector.values.${value}`),
    }))
  }

  const handleActiveField = (field: number) => {
    setActiveField(field)
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'sector' } }}>
      <Title>{t('sentences.situation.title')}</Title>
      <div>
        {t('sentences.situation.sector.sentence')}
        <Prompt
          onClick={() => {
            setActiveField(0)
          }}
          isFilled={!!mainMortgagor.occupationKind}
        >
          {t(
            `sentences.situation.sector.values.${
              mainMortgagor.occupationKind || SimulationMortgagorOccupationKind.Private
            }`
          )}
        </Prompt>
        {activeField > 0 && mainMortgagor.occupationKind === SimulationMortgagorOccupationKind.Private && (
          <PrivateSentence activeField={activeField} onSetActive={handleActiveField} />
        )}
        {activeField > 0 && mainMortgagor.occupationKind === SimulationMortgagorOccupationKind.Public && (
          <PublicSentence activeField={activeField} onSetActive={handleActiveField} />
        )}
        {activeField > 0 &&
          (mainMortgagor.occupationKind === SimulationMortgagorOccupationKind.Other ||
            mainMortgagor.occupationKind === SimulationMortgagorOccupationKind.Medical) && (
            <OthersSentence activeField={activeField} onSetActive={handleActiveField} />
          )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapSectorValues(
            Object.values([
              SimulationMortgagorOccupationKind.Private,
              SimulationMortgagorOccupationKind.Public,
              SimulationMortgagorOccupationKind.Other,
              SimulationMortgagorOccupationKind.Medical,
            ])
          )}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'occupationType' } }}
        />
      )}
      {activeField > 0 && mainMortgagor.occupationKind === SimulationMortgagorOccupationKind.Private && (
        <PrivateChoices activeField={activeField} onSetActive={handleActiveField} />
      )}
      {activeField > 0 && mainMortgagor.occupationKind === SimulationMortgagorOccupationKind.Public && (
        <PublicChoices activeField={activeField} onSetActive={handleActiveField} />
      )}
      {activeField > 0 &&
        (mainMortgagor.occupationKind === SimulationMortgagorOccupationKind.Other ||
          mainMortgagor.occupationKind === SimulationMortgagorOccupationKind.Medical) && (
          <OthersChoices activeField={activeField} onSetActive={handleActiveField} />
        )}
      {isOnTrial && <Message>{t('sentences.tips.occupation.trialPeriod.mortgagor')}</Message>}
      {isCDD && <Message>{t('sentences.tips.occupation.shortTerm.mortgagor')}</Message>}
      {isEarlyBusinessOwner && <Message>{t('sentences.tips.occupation.earlyBusinessOwner.mortgagor')}</Message>}
      {isOccupationFilled({ isLoggedIn, prettoSearch, rateAlert, simulation }) &&
        checkSeniorityYearIsFilled(mainMortgagor) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
