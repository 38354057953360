import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const ChildSupportPage: React.FC = () => {
  const { simulation, setSimulationMortgagor } = useSentences()
  const { goToNextRoute, nextRoute } = useRoutesGraph()

  const getIsFilled = () => {
    if (!nextRoute) return false
    if (isNil(simulation.profileMortgagors[0]?.chargesPaysChildSupport)) return false
    if (
      simulation.profileMortgagors[0]?.chargesPaysChildSupport &&
      isNil(simulation.profileMortgagors[0]?.chargesPaysChildSupport)
    )
      return false
    return true
  }

  const [activeField, setActiveField] = useActiveField(
    simulation.profileMortgagors[0]?.chargesPaysChildSupport ? 1 : 0,
    getIsFilled()
  )

  if (!simulation.profileMortgagors?.length) {
    return null
  }

  const paysChildSupport = simulation.profileMortgagors[0]?.chargesPaysChildSupport

  const mapHasChildSupport = (values: YesNo[]) => {
    return values.map(value => {
      return {
        key: value,
        value,
        isSelected:
          (value === YesNo.Yes && paysChildSupport === true) || (value === YesNo.No && paysChildSupport === false),
        onClick: () => {
          setSimulationMortgagor({ chargesPaysChildSupport: value === YesNo.Yes })
          setActiveField(value === YesNo.Yes ? 1 : 0)

          if (value === YesNo.No) goToNextRoute()
        },
        label: t(`sentences.charge.childSupport.values.${value}`),
      }
    })
  }

  const saveAmount = (value: string) => {
    setSimulationMortgagor({ chargesChildSupport: toIntOrNull(value) })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'childSupport' } }}>
      <Title>{t('sentences.charge.title')}</Title>
      <div>
        {t('sentences.charge.childSupport.sentences.0')}
        <Prompt isFilled={!isNil(paysChildSupport)} onClick={() => setActiveField(0)}>
          {t(
            `sentences.charge.childSupport.values.${paysChildSupport || isNil(paysChildSupport) ? YesNo.Yes : YesNo.No}`
          )}
        </Prompt>
        {t('sentences.charge.childSupport.sentences.1')}
        {activeField > 0 && paysChildSupport && (
          <>
            {t('sentences.charge.childSupport.sentences.2')}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'mortgagorChildSupport' } }}
              inputProps={{ autoFocus: activeField === 1, placeholder: '300', type: 'integer' }}
              onChange={saveAmount}
              value={simulation.profileMortgagors[0]?.chargesChildSupport?.toString() || ''}
            />
            {t('sentences.charge.childSupport.sentences.3')}
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapHasChildSupport(Object.values(YesNo))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'childSupport' } }}
        />
      )}
      <ButtonNext>{t('sentences.next')}</ButtonNext>
    </TrackedView>
  )
}
