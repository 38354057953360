import Responsive from '@pretto/bricks/components/utility/Responsive'

import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useTracking } from '@pretto/app/src/Sentences/lib/tracking/useTracking'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import abTest from '@pretto/app/src/lib/abtest'
import { isFrenchBrowser, t } from '@pretto/app/src/lib/i18n'
import { useTrustpilotData } from '@pretto/app/src/lib/useTrustpilotData'
import { SimulationKind, SimulationMaturityStatus } from '@pretto/app/src/types/api/enums'
import { Bulb, FileCheck, House, HouseMagnifier, MagnifyingGlassLeft, Picto, ShieldCheck } from '@pretto/picto'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'

import { IndexPage as IndexPageView } from '../../views/IndexPage/IndexPage'

const PICTOS: Record<SimulationMaturityStatus, Picto> = {
  [SimulationMaturityStatus.UnderStudy]: Bulb,
  [SimulationMaturityStatus.SearchStarted]: MagnifyingGlassLeft,
  [SimulationMaturityStatus.OfferMade]: HouseMagnifier,
  [SimulationMaturityStatus.OfferAccepted]: ShieldCheck,
  [SimulationMaturityStatus.SaleAgreementSigned]: FileCheck,
}

export const IndexPage: React.FC = () => {
  const [isLanguageSwitchDialogOpen, setIsLanguageSwitchDialogOpen] = useState(false)

  const { i18n } = useTranslation()
  const { search } = useLocation()
  const { goToNextRoute, goToRoute } = useRoutesGraph()
  const { setSimulation } = useSentences()
  const trackAction = useTracking()
  const trustpilotData = useTrustpilotData()
  const { isEnglishUser } = useUser()
  const history = useHistory()

  useEffect(() => {
    // User is configured as French but browser is not in French
    if (!isFrenchBrowser() && !isEnglishUser) {
      setIsLanguageSwitchDialogOpen(true)
    }
  }, [isEnglishUser])

  const handleClickMaturity = (type: SimulationMaturityStatus) => {
    trackAction({ eventName: EventName.MaturityDeclared, eventPayload: { value: type, choice: 'maturity' } })
    setSimulation({ maturityStatus: type })
    if (type !== SimulationMaturityStatus.SearchStarted) {
      setSimulation({
        kind: type === SimulationMaturityStatus.UnderStudy ? SimulationKind.Capacity : SimulationKind.Purchase,
      })
    }
    goToNextRoute()
  }

  const handleConfirmLanguageSwitch = () => {
    const newLanguageCode = 'en'
    i18n.changeLanguage(newLanguageCode)

    const queryParams = new URLSearchParams(search)

    if (queryParams.has('lang')) {
      queryParams.set('lang', newLanguageCode)
    } else {
      queryParams.append('lang', newLanguageCode)
    }

    history.replace({ search: queryParams.toString() })

    setIsLanguageSwitchDialogOpen(false)

    // 2024-05 We have to do a full reload because we're not
    // using useTranslation() for all t() calls, so the i18n language is not
    // updated properly for some translations. If all calls to t() are
    // refactored to use the useTranslation() hook we can remove this reload.
    window.location.reload()
  }

  const handleCloseLanguageSwitchDialog = () => {
    setIsLanguageSwitchDialogOpen(false)
  }

  const mapMaturityValues = (values: SimulationMaturityStatus[]) =>
    values.map(value => ({
      description: t(`sentences.maturity.index.values.${value}.description`),
      key: value,
      onClick() {
        handleClickMaturity(value)
      },
      picto: PICTOS[value],
      title: t(`sentences.maturity.index.values.${value}.title`),
    }))

  const variant = abTest('aiguillage_renegotiation_wording_270325') || 'a'

  const alternativeChoices = [
    {
      get description() {
        return t(`sentences.maturity.index.others.description.${variant}`)
      },
      key: 'alternativeChoices',
      onClick() {
        goToRoute('/project/introduction/others')
      },
      picto: House,
      get title() {
        return t(`sentences.maturity.index.others.title.${variant}`)
      },
    },
  ]

  const mergedChoices = [
    ...mapMaturityValues([
      SimulationMaturityStatus.UnderStudy,
      SimulationMaturityStatus.SearchStarted,
      SimulationMaturityStatus.OfferMade,
      SimulationMaturityStatus.OfferAccepted,
      SimulationMaturityStatus.SaleAgreementSigned,
    ]),
    ...alternativeChoices,
  ]

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'maturityIndex' } }}>
      <Responsive max="tablet">
        <Header mode="compact" navigationItemsList={[NAV_ITEMS.faq]} backgroundColor="neutral4" />
      </Responsive>
      <Responsive min="tablet">
        <Header navigationItemsList={[NAV_ITEMS.faq]} backgroundColor="neutral4" />
      </Responsive>
      <IndexPageView
        purchaseChoices={mergedChoices}
        trustpilotRateProps={trustpilotData}
        isLanguageSwitchDialogOpen={isLanguageSwitchDialogOpen}
        onConfirmLanguageSwitch={handleConfirmLanguageSwitch}
        onCloseLanguageSwitchDialog={handleCloseLanguageSwitchDialog}
      />
    </TrackedView>
  )
}
