import { ArticleCard, ArticleCardProps } from '../ArticleCard/ArticleCard'
import { PartnerCard, PartnerCardProps } from '../PartnerCard/PartnerCard'
import { RecommendationCard, RecommendationCardProps } from '../RecommendationCard/RecommendationCard'

import * as S from './SolutionItem.styles'

export interface SolutionItemProps {
  recommendation: RecommendationCardProps
  article: ArticleCardProps | null
  partner: PartnerCardProps | null
}

export const SolutionItem: React.FC<SolutionItemProps & React.HTMLAttributes<HTMLDivElement>> = ({
  recommendation,
  article,
  partner,
}) => {
  const noArticleAndPartner = !article && !partner

  return (
    <S.Container>
      <RecommendationCard {...recommendation} />
      {noArticleAndPartner ? null : (
        <>
          <S.Divider />
          <S.Section>
            <S.Title>Pour vous aider :</S.Title>
            <S.ArticleAndPartner>
              {article ? <ArticleCard {...article} /> : null}
              {partner ? <PartnerCard {...partner} /> : null}
            </S.ArticleAndPartner>
          </S.Section>
        </>
      )}
    </S.Container>
  )
}
