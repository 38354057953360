import { GetSimulation } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import { SimulationKind, SimulationMaturityStatus } from '@pretto/app/src/types/api/enums'
import { Maturity } from '@pretto/app/src/types/gateway/enums'

export const mapMaturity: ComposableMapper = ({ project, simulation }) => {
  if (simulation.kind !== SimulationKind.Purchase && simulation.kind !== SimulationKind.Capacity) {
    return { project, simulation }
  }

  const mapMaturityAgreementSignedDate = (simulation: GetSimulation) => {
    if (simulation.maturityStatus === SimulationMaturityStatus.SaleAgreementSigned) {
      return simulation.maturityAgreementSignedDate
    }

    return null
  }

  const mapMaturityStatus = (simulation: GetSimulation): Maturity | null => {
    switch (simulation.maturityStatus) {
      case SimulationMaturityStatus.OfferAccepted:
        return Maturity.OfferAccepted

      case SimulationMaturityStatus.SaleAgreementSigned:
        return Maturity.SaleAgreementSigned

      case SimulationMaturityStatus.OfferMade:
        return Maturity.OfferMade

      case SimulationMaturityStatus.SearchStarted:
        return Maturity.SearchStarted

      case SimulationMaturityStatus.UnderStudy:
        return Maturity.UnderStudy

      default:
        return null
    }
  }

  return {
    project: {
      ...project,

      purchase: {
        ...project.purchase,

        agreement_sign_date: mapMaturityAgreementSignedDate(simulation),
        maturity: mapMaturityStatus(simulation),
        specific_amounts: project.purchase?.specific_amounts ?? [],
      },
    },
    simulation,
  }
}
