import { GetSimulation, useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { client as clientAPI } from '@pretto/app/src/api/api'
import { SimulationKind } from '@pretto/app/src/types/api/enums'

import { createContext, PropsWithChildren, useContext } from 'react'

import { SimulationResultsQuery, useSimulationResultsQuery } from './simulation_result.api.graphql'

interface SimulationResultsContextInterface {
  data: SimulationResultsQuery | undefined
  loading: boolean
  error: Error | undefined
  simulation: GetSimulation
}

export const SimulationResultsContext = createContext<SimulationResultsContextInterface>(
  {} as SimulationResultsContextInterface
)

export const SimulationResultsProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { simulation } = useSentences()
  const { projectID } = useUser() as { projectID: string }
  const { loading, data, error } = useSimulationResultsQuery({
    variables: {
      input: {
        simulationId: simulation.id as string,
        projectId: projectID,
        simulationKind: simulation.kind as SimulationKind,
        projectOverride: undefined,
      },
    },
    fetchPolicy: 'network-only',
    client: clientAPI,
    skip: simulation.id === undefined || simulation.kind === undefined,
  })

  return (
    <SimulationResultsContext.Provider
      value={{
        data,
        loading,
        error,
        simulation,
      }}
    >
      {children}
    </SimulationResultsContext.Provider>
  )
}

export const useSimulationResults = () => useContext(SimulationResultsContext)
