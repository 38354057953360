import { ResizableField } from '@pretto/app/src/Sentences/components/ResizableField/ResizableField'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { Result as CityResult, useCityField } from '@pretto/app/src/Sentences/lib/useCityField'
import { Result as CountryResult, useCountryField } from '@pretto/app/src/Sentences/lib/useCountryField'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorLodgingLocationKind, SimulationPropertyUsage } from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'
import { useState } from 'react'

export const AddressPage: React.FC = () => {
  const { simulation, setSimulationComortgagor } = useSentences()

  const { nextRoute } = useRoutesGraph()
  const defaultValue =
    simulation.profileMortgagors[1]?.lodgingLocation?.city && simulation.profileMortgagors[1]?.lodgingLocation?.zipcode
      ? `${simulation.profileMortgagors[1]?.lodgingLocation?.city} (${simulation.profileMortgagors[1]?.lodgingLocation?.zipcode})`
      : ''
  const { fieldProps: cityFieldProps, results: cityResults } = useCityField(defaultValue)
  const { fieldProps: countryFieldProps, results: countryResults } = useCountryField(
    simulation.profileMortgagors[1]?.lodgingLocation?.countryCode ?? ''
  )
  const [visibleCities, setVisibleCities] = useState(false)
  const [visibleCountries, setVisibleCountries] = useState(false)
  const livesInFrance =
    simulation.profileMortgagors[1]?.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.FranceMetropolitan
  const isRentalInvestment = simulation.propertyUsage === SimulationPropertyUsage.RentalInvestment

  const getDefaultActiveField = () => {
    if (isNil(simulation.profileMortgagors[1]?.lodgingLocation?.countryCode)) {
      return 0
    }
    if (livesInFrance) {
      return 1
    }
    return 2
  }

  const getIsFilled = () => {
    if (!nextRoute) return false
    if (isNil(simulation.profileMortgagors[1]?.lodgingLocation?.countryCode)) return false
    if (livesInFrance && !simulation.profileMortgagors[1]?.lodgingLocation?.city) return false
    if (!livesInFrance && !simulation.profileMortgagors[1]?.lodgingLocation?.countryCode) return false
    return true
  }

  const [activeField, setActiveField] = useActiveField(getDefaultActiveField(), getIsFilled())

  const handleClickOnCity = (value: CityResult['value']) => {
    cityFieldProps.onChange(`${value.city} (${value.zipcode})`)
    setVisibleCities(false)
    setSimulationComortgagor({
      lodgingLocation: {
        address: null,
        city: value.city,
        zipcode: value.zipcode,
        countryCode: 'fr',
      },
    })
  }

  const handleCountryOnClick = (value: CountryResult) => {
    const label = value.label.replace(/\s\(.*\)/, '')
    countryFieldProps.onChange(label)
    setVisibleCountries(false)
    setSimulationComortgagor({
      lodgingLocation: {
        address: null,
        city: null,
        zipcode: null,
        countryCode: value.value,
      },
    })
  }

  const mapResidentValues: MapToChoices<SimulationMortgagorLodgingLocationKind[]> = values =>
    values.map(value => ({
      key: value,
      isSelected:
        (value === SimulationMortgagorLodgingLocationKind.FranceMetropolitan &&
          simulation.profileMortgagors[1]?.lodgingLocationKind ===
            SimulationMortgagorLodgingLocationKind.FranceMetropolitan) ||
        (value === SimulationMortgagorLodgingLocationKind.World &&
          simulation.profileMortgagors[1]?.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.World),
      onClick: () => {
        setVisibleCities(true)
        setSimulationComortgagor({ lodgingLocationKind: value })
        setActiveField(value === SimulationMortgagorLodgingLocationKind.FranceMetropolitan ? 1 : 2)
      },
      label: t(`sentences.lodging.address.values.${value}`),
    }))

  const mapCityValues: MapToChoices<CityResult[]> = values =>
    values.map(({ label, value }) => ({
      key: value.zipcode,
      isSelected:
        simulation.profileMortgagors[1]?.lodgingLocation?.city === value.city &&
        simulation.profileMortgagors[1]?.lodgingLocation?.zipcode === value.zipcode,
      onClick: () => handleClickOnCity(value),
      label,
    }))

  const mapCountryValues: MapToChoices<CountryResult[]> = values =>
    values.map(({ label, value }) => ({
      key: value,
      isSelected: simulation.profileMortgagors[1]?.lodgingLocation?.countryCode === value,
      onClick: () => handleCountryOnClick({ label, value }),
      label,
    }))

  const fields = [
    {
      name: 'resident',
      value: simulation.profileMortgagors[1]?.lodgingLocationKind,
      values: mapResidentValues(
        Object.values([
          SimulationMortgagorLodgingLocationKind.FranceMetropolitan,
          SimulationMortgagorLodgingLocationKind.World,
        ])
      ),
    },
    {
      name: 'city',
      value: simulation.profileMortgagors[1]?.lodgingLocation?.city,
      values: mapCityValues(cityResults),
    },
    {
      name: 'country',
      value: simulation.profileMortgagors[1]?.lodgingLocation?.countryCode,
      values: mapCountryValues(countryResults),
    },
  ]

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'address-comortgagor' } }}>
      <Title>{t('sentences.lodging.comortgagor.title')}</Title>
      <div>
        {t('sentences.lodging.comortgagor.address.sentence')}
        <Prompt
          onClick={() => {
            setActiveField(0)
          }}
          isFilled={!!simulation.profileMortgagors[1]?.lodgingLocationKind}
        >
          {t(
            `sentences.lodging.address.values.${
              !simulation.profileMortgagors[1]?.lodgingLocationKind || livesInFrance
                ? SimulationMortgagorLodgingLocationKind.FranceMetropolitan
                : SimulationMortgagorLodgingLocationKind.World
            }`
          )}
        </Prompt>
        {activeField >= 1 && (
          <>
            {t(`sentences.lodging.address.sentence.${livesInFrance ? '1' : '2'}`)}
            {livesInFrance ? (
              <ResizableField
                {...cityFieldProps}
                clearable
                inputProps={{ autoFocus: activeField === 1, placeholder: 'Paris' }}
                onClick={() => {
                  setVisibleCities(true)
                  setActiveField(1)
                }}
              />
            ) : (
              <ResizableField
                {...countryFieldProps}
                clearable
                inputProps={{ autoFocus: activeField === 1, placeholder: t('sentences.lodging.countryPlaceholder') }}
                onClick={() => {
                  setVisibleCountries(true)
                  setActiveField(2)
                }}
              />
            )}
          </>
        )}
      </div>
      {(activeField === 0 || (visibleCities && activeField === 1) || (visibleCountries && activeField === 2)) && (
        <Choices
          choices={fields[activeField].values}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'comortgagorLodgingAddress' },
          }}
        />
      )}
      {!isNil(simulation.profileMortgagors[1]?.lodgingLocation?.countryCode) && !livesInFrance && (
        <Message>{t('sentences.tips.nonResident.currency')}</Message>
      )}
      {!isNil(simulation.profileMortgagors[1]?.lodgingLocation?.countryCode) &&
        !livesInFrance &&
        isRentalInvestment && <Message>{t('sentences.tips.nonResident.rentalInvestment')}</Message>}
      {((activeField >= 1 && livesInFrance && simulation.profileMortgagors[1]?.lodgingLocation?.city) ||
        (activeField >= 2 && !livesInFrance && simulation.profileMortgagors[1]?.lodgingLocation?.countryCode)) && (
        <ButtonNext>{t('sentences.next')}</ButtonNext>
      )}
    </TrackedView>
  )
}
