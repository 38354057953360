import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationRenegotiationLoanInsuranceKind } from '@pretto/app/src/types/api/enums'

export const PaymentPage: React.FC = () => {
  const { simulation, setSimulation } = useSentences()
  const isGroupInsuranceFilled =
    !!simulation.renegotiationLoanPayment &&
    simulation.renegotiationLoanInsuranceKind === SimulationRenegotiationLoanInsuranceKind.Group
  const isDelegatedInsuranceFilled =
    !!simulation.renegotiationLoanInsurancePayment &&
    simulation.renegotiationLoanInsuranceKind === SimulationRenegotiationLoanInsuranceKind.Delegate

  const isFilled = isGroupInsuranceFilled || isDelegatedInsuranceFilled

  const [activeField, setActiveField] = useActiveField(
    simulation.renegotiationLoanPayment || simulation.renegotiationLoanInsurancePayment ? 1 : 0,
    isFilled
  )

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'payment' } }}>
      <Title>{t('sentences.loan.title')}</Title>
      <div>
        {t(`sentences.loan.payment.sentences.${simulation.renegotiationLoanInsuranceKind}.0`)}
        <TrackedResizableField
          clearable
          events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'renegotiationLoanPayment' } }}
          inputProps={{ autoFocus: activeField === 0, placeholder: '800', type: 'number' }}
          onChange={value => {
            setSimulation({ renegotiationLoanPayment: toIntOrNull(value) })
            setActiveField(1)
          }}
          onClick={() => {
            setActiveField(0)
          }}
          value={simulation.renegotiationLoanPayment?.toString() || ''}
        />
        {t(`sentences.loan.payment.sentences.${simulation.renegotiationLoanInsuranceKind}.1`)}
        {simulation.renegotiationLoanInsuranceKind === SimulationRenegotiationLoanInsuranceKind.Delegate &&
          activeField > 0 && (
            <>
              {t(`sentences.loan.payment.sentences.${SimulationRenegotiationLoanInsuranceKind.Delegate}.2`)}
              <TrackedResizableField
                clearable
                events={{
                  eventName: EventName.SimulationFieldChanged,
                  eventPayload: { field: 'renegotiationInsurancePayment' },
                }}
                inputProps={{ placeholder: '50', type: 'integer' }}
                onChange={value => {
                  setSimulation({ renegotiationLoanInsurancePayment: toIntOrNull(value) })
                }}
                onClick={() => {
                  setActiveField(1)
                }}
                value={simulation.renegotiationLoanInsurancePayment?.toString() || ''}
              />
              {t(`sentences.loan.payment.sentences.${SimulationRenegotiationLoanInsuranceKind.Delegate}.3`)}
            </>
          )}
        .
      </div>
      <Message>{t(`sentences.tips.renegotiationLoan`)}</Message>
      {activeField > 0 && isFilled && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
