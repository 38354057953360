import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { mainGraph } from '@pretto/app/src/Sentences/config/routes/mainGraph'
import type { GraphContext } from '@pretto/app/src/Sentences/types/graph'
import { createRoutesGraphContext } from '@pretto/app/src/lib/graph/RoutesGraphContext'

import { usePrettoSearch } from './PrettoSearchContext'
import { useRateAlert } from './RateAlertContext'
import { useSentences } from './SentencesContext'

const { Provider, useRoutesGraph } = createRoutesGraphContext<GraphContext>(mainGraph)

export { useRoutesGraph }

export const RoutesGraphContextProvider: React.FC = ({ children }) => {
  const { isLoggedIn } = useAuth()

  const { simulation, waitForOngoingOperations: waitSimulationForOngoingOperations } = useSentences()
  const { prettoSearch, waitForOngoingOperations: waitPrettoSearchForOngoingOperations } = usePrettoSearch()
  const { rateAlert, waitForOngoingOperations: waitRateAlertForOngoingOperations } = useRateAlert()

  const context = { isLoggedIn, prettoSearch, simulation, rateAlert }

  const asyncContext = async () => {
    const simulation = await waitSimulationForOngoingOperations()
    const prettoSearch = await waitPrettoSearchForOngoingOperations()
    const rateAlert = await waitRateAlertForOngoingOperations()

    return { isLoggedIn, prettoSearch, rateAlert, simulation }
  }

  return (
    <Provider context={context} wait={asyncContext}>
      {children}
    </Provider>
  )
}
