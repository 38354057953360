import { type Day } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/day.types'
import { type Slot } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/slot.types'

import { useState } from 'react'

import * as S from './Slots.styles'

interface SlotsProps extends Day {
  onBooked: (slot?: Slot) => void
}

export const Slots = ({ slots, onBooked, ...props }: SlotsProps) => {
  const [slotLoading, setSlotLoading] = useState<Slot | undefined>()

  return (
    <S.Slots {...props}>
      {slots.map(slot => {
        const slotsLabel = slot.label

        const handleBook = () => {
          setSlotLoading(slot)
          onBooked(slot)
        }

        return (
          <S.Slot onClick={handleBook} key={slotsLabel} isLoading={slotLoading?.label === slotsLabel}>
            {slotsLabel}
            {slot.advisor.isVisio && <S.SlotDot />}
          </S.Slot>
        )
      })}
    </S.Slots>
  )
}
