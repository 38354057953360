const IFRAMES_PATH = {
  simulationStart: 'adrenalead-simulation-start',
  newLead: 'adrenalead-new-lead',
}

type AdrenaleadIframeProps = {
  isClient?: boolean
  type: keyof typeof IFRAMES_PATH
}

export const AdrenaleadIframe: React.FC<AdrenaleadIframeProps> = ({ isClient, type }) => {
  return (
    <iframe
      id={`${IFRAMES_PATH[type]}`}
      src={`https://www.pretto.fr/${IFRAMES_PATH[type]}/?is-client=${!!isClient}`}
      width="1"
      height="1"
      frameBorder="0"
      scrolling="no"
      style={{ display: 'none' }}
    />
  )
}
