import { g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const Content = styled.div`
  width: 100%;
  height: calc(100vh - 88px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export const Title = styled.h1`
  ${({ theme }) => theme.typos.heading4};
  margin: ${g(4)} 0;
  max-width: 350px;
  text-align: center;
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
