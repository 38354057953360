import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const mapLiveTogetherValues: MapToChoices<YesNo[]> = values => {
  const { simulation, setSimulation } = useSentences()
  if (!simulation) return []

  return values.map(value => ({
    key: value,
    isSelected:
      !isNil(simulation.profileLiveTogether) &&
      ((simulation.profileLiveTogether && value === YesNo.Yes) ||
        (!simulation.profileLiveTogether && value === YesNo.No)),
    onClick: () => {
      setSimulation({ profileLiveTogether: value === YesNo.Yes })
    },
    label: t(`sentences.lodging.liveTogether.values.${value}`),
  }))
}
