import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import translation from '@pretto/app/src/Sentences/config/translations/fr'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const WorksPage: React.FC = () => {
  const { simulation, setSimulation } = useSentences()
  const propertyHasWorks = simulation.propertyHasWorks
  const propertyWorksPrice = simulation.propertyWorksPrice
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(
    propertyHasWorks ? 1 : 0,
    Boolean(nextRoute && !isNil(propertyHasWorks) && (!propertyHasWorks || (propertyHasWorks && propertyWorksPrice)))
  )

  const mapHasWorksValues = (values: [key: string, value: string][]) => {
    return values.map(([key, value]) => {
      return {
        key,
        value,
        isSelected:
          (key === YesNo.Yes && propertyHasWorks === true) || (key === YesNo.No && propertyHasWorks === false),
        onClick: () => {
          setSimulation({ propertyHasWorks: key === YesNo.Yes })
          setActiveField(key === YesNo.Yes ? 1 : 0)
          if (key === YesNo.No) goToNextRoute()
        },
        label: t(`sentences.property.works.values.${key}`),
      }
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'works' } }}>
      <Title>{t('sentences.property.title')}</Title>
      <div>
        {t('sentences.property.works.sentences.0')}
        <Prompt isFilled={!isNil(propertyHasWorks)} onClick={() => setActiveField(0)}>
          {t(`sentences.property.works.values.${propertyHasWorks || isNil(propertyHasWorks) ? YesNo.Yes : YesNo.No}`)}
        </Prompt>
        {t('sentences.property.works.sentences.1')}
        {activeField > 0 && propertyHasWorks && (
          <>
            {t('sentences.property.works.sentences.2')}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'propertyWorksPrice' } }}
              inputProps={{ autoFocus: activeField === 1, placeholder: '20 000', type: 'integer' }}
              onChange={(value: string) => {
                setSimulation({ propertyWorksPrice: toIntOrNull(value) })
              }}
              value={propertyWorksPrice?.toString() || ''}
            />
            {t('sentences.property.works.sentences.3')}
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapHasWorksValues(Object.entries(translation.sentences.property.works.values))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'propertyWorks' } }}
        />
      )}
      {propertyHasWorks && <Message>{t('sentences.tips.hasWorks')}</Message>}
      {((propertyHasWorks && propertyWorksPrice) || (!isNil(propertyHasWorks) && !propertyHasWorks)) && (
        <ButtonNext>{t('sentences.next')}</ButtonNext>
      )}
    </TrackedView>
  )
}
