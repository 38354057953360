import { ERROR_TYPES, getAuthErrorMessage } from '@pretto/bricks/core/utility/errors'
import temporal from '@pretto/bricks/core/utility/temporal'

import useEmailField from '@pretto/app/src/Form/lib/useEmailField'
import { type TimelineProps } from '@pretto/app/src/SharedComponents/Timeline/Timeline'
import { MutualizedAgenda as MutualizedAgendaComponent } from '@pretto/app/src/SharedContainers/MutualizedAgenda/components/MutualizedAgenda/MutualizedAgenda'
import { orderSlotsByDate } from '@pretto/app/src/SharedContainers/MutualizedAgenda/lib/orderSlotsByDate'
import { formatLongDateEN, formatLongDateFR } from '@pretto/app/src/SharedContainers/MutualizedAgenda/lib/timeFormatter'
import type { BookingInfosParams } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/bookingInfosParams'
import type { Occurrence } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/occurrence'
import type { Slot } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/slot'
import { Steps } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/steps'
import { LOCAL_AGENDA_VISIBLE_NAME } from '@pretto/app/src/Simulation/config/agenda'
import { email } from '@pretto/app/src/Simulation/config/fields'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { ADVISOR, CALL_BACK_NOW, MUTUALIZED_AGENDA, REASSIGN_WITH_APPOINTMENT } from '@pretto/app/src/apollo'
import { DefaultAlertMessage } from '@pretto/app/src/components/DefaultAlertMessage/DefaultAlertMessage'
import { ADVISOR_LABELS, AdvisorTypes } from '@pretto/app/src/config/advisor'
import { removeItem } from '@pretto/app/src/config/itemStorage'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useMutation, useQuery } from '@apollo/client'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

interface MutualizedAgendaProps {
  isDriAllowed?: boolean
  isOpen: boolean
  occurrence: Occurrence
  onBookingInfos?: (bookingInfos: BookingInfosParams) => void
  onClose: () => void
  onContinue: () => void
  timelineProps?: TimelineProps
}

export const MutualizedAgenda: React.FC<MutualizedAgendaProps> = ({
  isDriAllowed,
  isOpen,
  occurrence,
  onBookingInfos,
  onClose,
  onContinue,
  timelineProps,
}) => {
  const [step, setStep] = useState<Steps>(Steps.AGENDA)
  const [selectedSlot, setSelectedSlot] = useState<Slot>()
  const { data, loading } = useQuery(MUTUALIZED_AGENDA)
  const [isSubmissionLoading, setIsSubmissionLoading] = useState<boolean>(false)

  const trackAction = useTracking()
  const hasAlreadyBeenViewed = useRef(false)
  const { advisor, isEnglishUser, maturity, typology, user } = useUser()

  const [alert, setAlert] = useState<ReactNode>('')
  const [isShown, setIsShown] = useState<boolean>(!!alert)

  const [bookCallBackNow] = useMutation(CALL_BACK_NOW)

  const [reassignWithAppointment] = useMutation(REASSIGN_WITH_APPOINTMENT, {
    refetchQueries: [{ query: ADVISOR }, 'Advisor'],
  })

  const { invalidate } = useEmailField({
    defaultValue: user?.email ?? '',
    ...email(typology),
    placeholderAsLabel: typology !== 'prospect',
    validators: [
      (value: string, isCheckRequested: boolean, inputProps: unknown, reason: string) => ({
        condition: ERROR_TYPES.includes(reason),
        message: getAuthErrorMessage(reason, <Link to="/login">ici</Link>),
        state: 'warning',
      }),
    ],
  })

  const days = orderSlotsByDate(data?.mutualizedAgenda ?? [])

  useEffect(() => {
    if (alert) {
      setIsShown(true)
      const timeout = setTimeout(() => {
        setIsShown(false)
      }, 5000)

      return () => clearTimeout(timeout)
    }
  }, [alert])

  useEffect(() => {
    if (data?.mutualizedAgenda && !hasAlreadyBeenViewed.current) {
      hasAlreadyBeenViewed.current = true
      trackAction('APPOINTMENT_MUTUALIZED_AGENDA_VIEWED', {
        agenda_location: occurrence,
        slotsNumber: data?.mutualizedAgenda.length,
      })
    }
  }, [data])

  const handleConfirmAppointment = (slot?: Slot) => {
    if (!slot) {
      return
    }

    const { utcDate, ...slotProps } = slot
    const selected = {
      ...slotProps,
      utcDate,
      label: isEnglishUser ? formatLongDateEN(utcDate) : formatLongDateFR(utcDate),
    }

    trackAction('APPOINTMENT_MUTUALIZED_AGENDA_CHOSEN', { agenda_location: occurrence })
    setSelectedSlot(selected)
    setStep(Steps.SUBSCRIBE)
  }

  const handleTrackNext = () => {
    trackAction('APPOINTMENT_MUTUALIZED_AGENDA_NEXT_DAYS_CLICKED', { agenda_location: occurrence })
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    }

    removeItem(LOCAL_AGENDA_VISIBLE_NAME)
    trackAction('APPOINTMENT_MUTUALIZED_AGENDA_CLOSED', { agenda_location: occurrence })
  }

  const handleIsLoading = (value: boolean) => {
    setIsSubmissionLoading(value)
  }

  const handleCallMeBack = async () => {
    await bookCallBackNow()
    trackAction('BOOKING_MUTUALIZED_CALL_BACK_NOW', { agenda_location: occurrence })
    onContinue()
  }

  const handleAlert = (alert: ReactNode) => {
    setAlert(alert)
  }

  const handleSubmit = async (withVisio: boolean) => {
    if (selectedSlot) {
      handleIsLoading(true)

      try {
        await reassignWithAppointment({
          variables: {
            advisorId: selectedSlot.advisor.id,
            startTime: selectedSlot.utcDate,
            isVisio: withVisio,
            kind: selectedSlot.kind,
          },
        })

        trackAction('BOOKING_MUTUALIZED_APPOINTMENT_BOOKED', { agenda_location: occurrence })
        removeItem(LOCAL_AGENDA_VISIBLE_NAME)

        if (onBookingInfos) {
          onBookingInfos({
            isLoading: false,
            name: `${selectedSlot?.advisor.firstName} ${selectedSlot?.advisor.lastName}`,
            start: temporal(selectedSlot?.utcDate).format('dddd LL [à] HH[h]mm'),
          })
        }

        onContinue()
      } catch (error) {
        invalidate(error)
        handleAlert(DefaultAlertMessage())
      } finally {
        handleIsLoading(false)
      }
    }
  }

  const handleGoBack = () => {
    setSelectedSlot(undefined)
    setStep(Steps.AGENDA)
  }

  const mutualizedAgendaProps = {
    advisorLabel: advisor?.role && ADVISOR_LABELS[advisor.role as AdvisorTypes],
    alert,
    handleCallMeBack,
    handleClose,
    handleConfirmAppointment,
    handleGoBack,
    handleSubmit,
    handleTrackNext,
    isDriAllowed,
    isOpen,
    isShown,
    isLoading: loading || isSubmissionLoading,
    maturity,
    occurrence,
    selectedSlot,
    step,
    timelineProps,
    days,
  }

  return <MutualizedAgendaComponent {...mutualizedAgendaProps} />
}
