import { Month } from '@pretto/app/src/Sentences/types/enums'

export const monthToIntOrNull = (month: Month): number | null => {
  switch (month) {
    case Month.January:
      return 0

    case Month.February:
      return 1

    case Month.March:
      return 2

    case Month.April:
      return 3

    case Month.May:
      return 4

    case Month.June:
      return 5

    case Month.July:
      return 6

    case Month.August:
      return 7

    case Month.September:
      return 8

    case Month.October:
      return 9

    case Month.November:
      return 10

    case Month.December:
      return 11

    default:
      return null
  }
}
