import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'

import { ChevronLeftBold, ChevronRightBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

interface SlotsProps {
  $isDisabled: boolean
}

export const Calendar = styled.div<SlotsProps>`
  flex: 0 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(1)};
  position: relative;
  grid-template-rows: 1fr auto auto;
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: pointer;
      pointer-events: none;
    `}
`

export const Previous = styled(ChevronLeftBold)`
  position: absolute;
  top: ${g(2.5)};
  left: ${g(1)};
  cursor: pointer;
`

export const Next = styled(ChevronRightBold)`
  position: absolute;
  top: ${g(2.5)};
  right: ${g(1)};
  cursor: pointer;
`

export const ThreeColumns = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column(3)};
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`

export const HeroContainer = styled(ThreeColumns)`
  background-color: ${({ theme }) => theme.colors.neutral5};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral3};
`

export const SlotsContainer = styled(ThreeColumns)``

export const Hero = styled.div`
  ${typo.bodyBold16};
  text-align: center;
  padding: ${g(2)} ${g(3)};
  display: flex;
  align-items: center;
  justify-content: center;
`
