import { temporal } from '@pretto/bricks/core/utility/temporal'

import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { handleCreditDateState } from '@pretto/app/src/Sentences/lib/credits'
import { fromFormattedToShortISO, inputDateFormat, placeholeDateFormat } from '@pretto/app/src/Sentences/lib/date'
import { sanitizeFloat } from '@pretto/app/src/Sentences/lib/sanitizeFloat'
import { toFloatOrNull } from '@pretto/app/src/Sentences/lib/toFloatOrNull'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'

import { useState } from 'react'
import { useDebounce } from 'use-debounce'

export const RemainingMortgagePage: React.FC = () => {
  const { simulation, setSimulation } = useSentences()
  const { nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute))
  const [endDate, setEndDate] = useState<string>(
    simulation.ownPropertyEndDate ? temporal(simulation.ownPropertyEndDate).format(inputDateFormat) : ''
  )
  const [displayedRate, setDisplayedRate] = useState<string>(simulation.ownPropertyRate?.toString() || '')
  const [debouncedDate] = useDebounce(simulation.ownPropertyEndDate, 1200)

  const { state, message } = handleCreditDateState(debouncedDate)

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'remainingMortgage' } }}>
      <Title>{t('sentences.sale.title')}</Title>
      <div>
        {t('sentences.sale.remainingMortgage.sentences.0')}
        <TrackedResizableField
          clearable
          events={{
            eventName: EventName.SimulationFieldChanged,
            eventPayload: { field: 'ownPropertyRemainingPrincipal' },
          }}
          inputProps={{ placeholder: '500 000', type: 'integer' }}
          onChange={(value: string) => {
            setActiveField(1)
            setSimulation({ ownPropertyRemainingPrincipal: toIntOrNull(value) })
          }}
          onClick={() => {
            setActiveField(0)
          }}
          value={simulation.ownPropertyRemainingPrincipal?.toString() || ''}
        />
        {t('sentences.sale.remainingMortgage.sentences.1')}
        {activeField > 0 && (
          <>
            {t('sentences.sale.remainingMortgage.sentences.2')}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'ownPropertyRate' } }}
              inputProps={{ placeholder: '2,4', type: 'number' }}
              onChange={(value: string) => {
                setActiveField(2)
                const rate = sanitizeFloat(value)
                setDisplayedRate(rate)
                setSimulation({ ownPropertyRate: toFloatOrNull(rate) })
              }}
              onClick={() => {
                setActiveField(1)
              }}
              value={displayedRate}
            />
            {t('sentences.sale.remainingMortgage.sentences.3')}
          </>
        )}
        {activeField > 1 && (
          <>
            {t('sentences.sale.remainingMortgage.sentences.4')}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'ownPropertyEndDate' } }}
              inputProps={{ placeholder: placeholeDateFormat, type: 'date' }}
              message={!!message && t(`sentences.${message}`)}
              onChange={(value: string) => {
                setEndDate(value)
                setSimulation({ ownPropertyEndDate: fromFormattedToShortISO(value) })
              }}
              onClick={() => {
                setActiveField(2)
              }}
              state={state}
              value={endDate}
            />
          </>
        )}
        .
      </div>
      <ButtonNext>{t('sentences.next')}</ButtonNext>
    </TrackedView>
  )
}
