import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { isAgeLegit } from '@pretto/app/src/Sentences/lib/age'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationStructureKind } from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'
import { useDebounce } from 'use-debounce'

type ChildIndex = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10'

export const AgeChildrenPage: React.FC = () => {
  const { simulation, setSimulation, setSimulationComortgagor, setSimulationMortgagor } = useSentences()

  const [debouncedMortgagors] = useDebounce(simulation.profileMortgagors, 1000)

  const isBoomer =
    Boolean(simulation.profileMortgagors[0]?.age && simulation.profileMortgagors[0].age > 55) ||
    Boolean(simulation.profileMortgagors[1]?.age && simulation.profileMortgagors[1].age > 55)

  const { goToNextRoute, nextRoute } = useRoutesGraph()

  const getDefaultActiveField = () => {
    if (!simulation.profileMortgagors[0]?.age) return 0
    if (simulation.structureKind !== SimulationStructureKind.Solo && !simulation.profileMortgagors[1]?.age) return 1
    return 2
  }

  const getIsFilled = () => {
    if (!nextRoute) return false
    if (!simulation.profileMortgagors[0]?.age) return false
    if (simulation.structureKind !== SimulationStructureKind.Solo && !simulation.profileMortgagors[1]?.age) return false
    if (isNil(simulation.profileChildren)) return false
    return true
  }

  const [activeField, setActiveField] = useActiveField(getDefaultActiveField(), getIsFilled())

  const saveAge = (value: string) => {
    const age = toIntOrNull(value)

    setSimulationMortgagor({ age })

    if (typeof age === 'number' && isAgeLegit(age)) {
      setActiveField(simulation.structureKind !== SimulationStructureKind.Solo ? 1 : 2)
    }
  }

  const saveComortgagorAge = (value: string) => {
    const age = toIntOrNull(value)

    setSimulationComortgagor({ age })

    if (
      typeof simulation.profileMortgagors[0]?.age === 'number' &&
      typeof age === 'number' &&
      isAgeLegit(simulation.profileMortgagors[0].age) &&
      isAgeLegit(age)
    ) {
      setActiveField(2)
    }
  }

  const ChoicesChildren = Array(11)
    .fill('')
    .map((value, index) => ({
      key: index.toString(),
      isSelected: simulation.profileChildren === index,
      onClick: () => {
        setSimulation({ profileChildren: index })
      },
      // TODO: use better keys, ts cannot infer index from Array(n).fill
      label: t(`sentences.lodging.children.values.${index.toString() as ChildIndex}`),
    }))

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'ageChildren' } }}>
      <Title>{t('sentences.lodging.title')}</Title>
      <div>
        {t('sentences.lodging.age.sentences.0')}
        <TrackedResizableField
          clearable
          events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'lodgingAgeMortgagor' } }}
          inputProps={{ autoFocus: activeField === 0, maxLength: 2, placeholder: '35', type: 'integer' }}
          message={t('sentences.lodging.age.error')}
          onChange={saveAge}
          state={
            debouncedMortgagors && debouncedMortgagors[0]?.age && !isAgeLegit(debouncedMortgagors[0].age)
              ? 'error'
              : 'default'
          }
          value={simulation.profileMortgagors[0]?.age?.toString() || ''}
        />
        {t('sentences.lodging.age.sentences.1')}
        {activeField > 0 && simulation.structureKind !== SimulationStructureKind.Solo && (
          <>
            {t('sentences.lodging.comortgagor.age.sentences.0')}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'lodgingAgeComortgagor' } }}
              inputProps={{ maxLength: 2, placeholder: '35', type: 'integer' }}
              message={t('sentences.lodging.comortgagor.age.error')}
              onChange={saveComortgagorAge}
              state={
                debouncedMortgagors && debouncedMortgagors[1]?.age && !isAgeLegit(debouncedMortgagors[1].age)
                  ? 'error'
                  : 'default'
              }
              value={simulation.profileMortgagors[1]?.age?.toString() || ''}
            />
            {t('sentences.lodging.comortgagor.age.sentences.1')}
          </>
        )}
        {activeField >= 2 && (
          <>
            {t('sentences.lodging.children.sentences.0')}
            <Prompt
              onClick={() => {
                setActiveField(2)
              }}
              isFilled={!isNil(simulation.profileChildren)}
            >
              {t(`sentences.lodging.children.values.${(simulation.profileChildren?.toString() as ChildIndex) || '0'}`)}
            </Prompt>
            {t('sentences.lodging.children.sentences.1')}
          </>
        )}
        .
      </div>
      {activeField === 2 && (
        <Choices
          choices={ChoicesChildren}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'lodgingAgeChildren' } }}
          onChoiceClick={() => {
            goToNextRoute()
          }}
        />
      )}
      {isBoomer && <Message>{t('sentences.tips.boomer')}</Message>}
      {!!simulation.profileMortgagors[0]?.age &&
        (simulation.structureKind === SimulationStructureKind.Solo || !!simulation.profileMortgagors[1]?.age) &&
        !isNil(simulation.profileChildren) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
