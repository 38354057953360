import CapacityErrorPage from '@pretto/bricks/app/capacity/pages/CapacityErrorPage'

import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { getNonFinancingData } from '@pretto/app/src/Capacity/Containers/lib/getNonFinancingData'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { t } from '@pretto/app/src/lib/i18n'
import { MortgageProject } from '@pretto/app/src/types/Capacity'

import { useApolloClient } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import Header from '../../../SharedContainers/Header'
import { useUser } from '../../../User/Containers/UserProvider'
import { useTracking } from '../../../lib/tracking'

const FLOW = 'capacity'

const ErrorPage: React.FC = () => {
  const client = useApolloClient()

  const { typology, isEnglishUser } = useUser()

  const { push } = useHistory()

  const [data, setData] = useState<MortgageProject | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useLoading(isLoading)

  const trackAction = useTracking()

  useEffect(() => {
    compute()
  }, [])

  useEffect(() => {
    if (!data) {
      return
    }

    const trackingName = 'CAPACITY_ERROR_PAGE_VIEWED'
    const trackingOptions = {
      capacity_error_regroup_credits_shown: data.regroupCredits,
      simulation_error_flags: data.malus,
      simulation_kind: 'capacity',
    }

    trackAction(trackingName, trackingOptions)
  }, [data])

  const compute = async () => {
    const data = await getNonFinancingData(client, isEnglishUser)

    setData(data)
    setIsLoading(false)
  }

  if (isLoading) {
    return null
  }

  const editLabel =
    typology === 'client'
      ? t('reduceAmbitionPage.optionsPage.editFolder')
      : t('reduceAmbitionPage.optionsPage.editProfile')
  const editPath = typology === 'client' ? '/application' : `/project/${FLOW}/introduction`

  const handleEditProfile = () => push(editPath)
  const handleEditClick = () => {
    trackAction('CAPACITY_ERROR_EDIT_BUTTON_CLICKED')
    push(editPath)
  }
  const handleReview = () => {
    trackAction('CAPACITY_ERROR_REVIEW_BUTTON_CLICKED')
  }

  const onMarketingCardClick = (slug: string) =>
    trackAction('CAPACITY_MARKETING_CARD_CLICKED', {
      capacity_marketing_card_origin: 'error',
      capacity_marketing_card_slug: slug,
    })

  const handleFaqClick = () => onMarketingCardClick('faq')

  const handleGuideClick = () => onMarketingCardClick('guide')

  const handleRegroupCreditsClick = () => trackAction('CAPACITY_ERROR_REGROUP_CREDITS_BUTTON_CLICKED')


  const feedbackList: { [key: string]: string } = {
    'POSITIVE': 'positive',
    'NEUTRAL': 'neutral',
    'WRONG': 'wrong'
  }

  const handleFeebackClick = (value: 'POSITIVE' | 'NEUTRAL' | 'WRONG') => {
    const feedback = feedbackList[value];
  
    if (feedback) {
      trackAction('SIMULATION_ERROR_FEEDBACK_CLICKED', {
        feedback: feedback,
        kind: 'capacity'
      });
    }
  } 

  const props = {
    ...data,
    actions: [
      {
        icon: 'pen',
        label: editLabel,
        onClick: handleEditProfile,
      },
    ],
    editLabel,
    introductionPath: `/project/${FLOW}/introduction`,
    onEditClick: handleEditClick,
    onFaqClick: handleFaqClick,
    onGuideClick: handleGuideClick,
    onRegroupCreditsClick: handleRegroupCreditsClick,
    onReview: handleReview,
    showSidebar: typology !== 'client',
    onFeedbackClick: handleFeebackClick
  }

  return (
    <>
      <Header navigationItemsList={[NAV_ITEMS.faq]} />
      <CapacityErrorPage {...props} />
    </>
  )
}

export default ErrorPage
