import { ReactNode, useEffect } from 'react'
import ReactDOM from 'react-dom'

import * as S from './Modal.styles'

export type ModalProps = {
  children: ReactNode
  onClose: () => void
  isOpen: boolean
}

export const Modal = ({ children, onClose, isOpen, ...props }: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', handleEscape)
    } else {
      window.removeEventListener('keydown', handleEscape)
    }

    return () => {
      window.removeEventListener('keydown', handleEscape)
    }
  }, [isOpen])

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose()
    }
  }

  return ReactDOM.createPortal(
    <>
      <S.GlobalStyle isOpen={isOpen} />
      <S.Overlay
        $isOpen={isOpen}
        aria-labelledby="mutualized-agenda-modal"
        onClick={() => onClose()}
        role="dialog"
        {...props}
      >
        <S.StyledModal onClick={e => e.stopPropagation()} $isOpen={isOpen}>
          {children}
        </S.StyledModal>
      </S.Overlay>
    </>,
    document.body
  )
}
