import { useBreakpoint } from '@pretto/bricks/assets/utility'
import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import { PartnerBlock } from '@pretto/zen/dashboard/uploadModal/PartnerBlock/PartnerBlock'

import { FileItemState } from '@pretto/app-core/application/components/FileItem/FileItem'
import { UploadDialog, UploadDialogFile } from '@pretto/app-core/application/components/UploadDialog/UploadDialog'
import { ALLOWED_EXTENSIONS, ALLOWED_MIME_TYPES, MAX_SIZE } from '@pretto/app-core/application/config/constants'
import { convertStatusSlug } from '@pretto/app-core/application/lib/convertStatusSlug'
import { getFileExtension } from '@pretto/app-core/application/lib/getFileExtension'
import { getFiles } from '@pretto/app-core/application/lib/getFiles'
import { getMortgagorFullname } from '@pretto/app-core/application/lib/getMortgagorFullname'
import { UploadFileStatus } from '@pretto/app-core/application/lib/useUpload'

import { useApplication } from '@pretto/app/src/Application/Containers/ApplicationContext'
import { useApplicationUpload } from '@pretto/app/src/Application/Containers/applicationUploadContext'
import { getPartnerProps } from '@pretto/app/src/Application/lib/getPartnerProps'
import Front from '@pretto/app/src/Helpers/frontchat'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'
import BANKS from '@pretto/config/banks.json'
import DOCS from '@pretto/config/docs.json'

import { useEffect, useMemo, useState } from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import Markdown from 'react-markdown'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'

interface ApplicationDocumentUploadDialogProps {
  getPreviousDocumentHref: (slug: string) => string | null
  getNextDocumentHref: (slug: string) => string | null
  onClose: () => void
}

export const ApplicationDocumentUploadDialog: React.FC<ApplicationDocumentUploadDialogProps> = ({
  getPreviousDocumentHref,
  getNextDocumentHref,
  onClose,
}) => {
  const { data, introductionUrl, isIntroductionDone } = useApplication()

  const { files, onDelete, onUpload } = useApplicationUpload()

  const { isMobile } = useBreakpoint()

  const { document } = useParams<{ document: string }>()

  const [documentSlug, setDocumentSlug] = useState(document)

  const trackAction = useTracking()

  const handleDrop = (droppedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => {
    if (!currentDocument) {
      return
    }

    const existingFiles = files.filter(({ slug }) => slug === currentDocument.slug)

    const signedFiles = getFiles(droppedFiles, existingFiles)

    onUpload(currentDocument, signedFiles)

    signedFiles.forEach(signedFile => {
      trackAction('DOCUMENTS_FILEDROP_TRACKED', {
        documents_filedrop_category: DOCS[kind].category,
        documents_filedrop_id: id,
        documents_filedrop_kind: kind,
        documents_filedrop_method: event.type === 'drop' ? event.type : 'browse',
        documents_filedrop_mime_type: signedFile.file.type,
        documents_filedrop_month: meta?.month,
        documents_filedrop_mortgagor: meta?.mortgagor,
        documents_filedrop_size: signedFile.file.size,
        documents_filedrop_year: meta?.year,
      })
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ALLOWED_MIME_TYPES,
    maxSize: MAX_SIZE,
    noClick: true,
    onDrop: handleDrop,
  })

  useEffect(() => {
    if (!document) {
      return
    }

    trackAction('DOCUMENTS_FILEDROP_MODAL_OPENED', {
      document,
    })

    setDocumentSlug(document)
  }, [document])

  useEffect(() => {
    Front(isMobile ? 'hide' : 'show')
  }, [isMobile])

  const { accounts } = useUser()

  if (!documentSlug) {
    return null
  }

  const currentDocument = data.docs?.find(document => document.slug === documentSlug)

  if (!currentDocument) {
    return <Redirect to="/404" />
  }

  if (!isIntroductionDone) {
    return <Redirect to={introductionUrl} />
  }

  const handleClickSeeMore = () => {
    trackAction('DOCUMENTS_DOCUMENT_TOOLTIP_LINK_CLICKED')
  }

  const { title: docTitle, document_status, error_message, id, meta } = currentDocument

  // TODO type currentDocument: slug should not be null
  const slug = currentDocument.slug as string
  const kind = currentDocument.kind as keyof typeof DOCS

  const docDescription = currentDocument.docDescription ? <Markdown>{currentDocument.docDescription}</Markdown> : null
  const docExplanation = kind !== 'other' ? <Markdown>{DOCS[kind].explanation}</Markdown> : null

  const errorMessage = convertStatusSlug(document_status) === 'invalid' ? error_message : null

  const titleBank = BANKS.find(({ value }) => value === meta?.bank)?.label ?? null
  const titleKind = kind === 'other' && slug !== 'other' ? docTitle : DOCS[kind].name
  const title = [titleKind, titleBank].filter(Boolean).join(' - ')

  const processedFiles = files.reduce<UploadDialogFile[]>((previous, file) => {
    if (file.slug !== slug || previous.some(({ id }) => file?.remoteId === id)) {
      return previous
    }

    const base = {
      extension: getFileExtension(file.fileName),
      fileName: file.fileName,
      id: file.localId,
    }

    switch (file.status) {
      case UploadFileStatus.Error:
        return [
          ...previous,
          {
            ...base,
            onRetry: file.retry,
            state: FileItemState.Error,
          },
        ]

      case UploadFileStatus.Removing:
        return [
          ...previous,
          {
            ...base,
            date: file.uploadedAt,
            state: FileItemState.Removing,
          },
        ]

      case UploadFileStatus.Stale:
        return [
          ...previous,
          {
            ...base,
            date: file.uploadedAt,
            helpUrl: 'https://faq.pretto.fr/fr/articles/837250',
            onHelp: handleClickSeeMore,
            state: FileItemState.Stale,
          },
        ]

      case UploadFileStatus.Uploaded:
        return [
          ...previous,
          {
            ...base,
            date: file.uploadedAt,
            onDelete() {
              onDelete(currentDocument, file)
            },
            state: FileItemState.Uploaded,
          },
        ]

      case UploadFileStatus.Uploading:
        return [
          ...previous,
          {
            ...base,
            progress: file.progress * 100,
            state: FileItemState.Uploading,
          },
        ]

      default:
        return previous
    }
  }, [])

  const footerComponent = useMemo(() => {
    const partnerProps = getPartnerProps(title)

    if (!partnerProps) {
      return null
    }

    const handlePartnerCTAClick = () => {
      trackAction('PARTNER_LINK_CLICKED', {
        origin: 'DOCUMENTS_FILEDROP_MODAL',
        name: partnerProps?.name,
        hrefDestination: partnerProps?.cta?.href,
      })
    }

    return <PartnerBlock {...partnerProps} onCTAClick={handlePartnerCTAClick} />
  }, [title])

  const dialogProps = {
    description: docDescription,
    errorMessage,
    explanation: docExplanation,
    footerComponent,
    files: processedFiles,
    fileOwnershipLabelProps: {
      names: getMortgagorFullname(meta, accounts),
    },
    isDragging: isDragActive,
    nextHref: getNextDocumentHref(slug),
    onClose,
    previousHref: getPreviousDocumentHref(slug),
    title,
    uploadAreaProps: {
      allowedExtensions: ALLOWED_EXTENSIONS,
      inputProps: getInputProps({ style: undefined, tabIndex: undefined }),
      maxSize: `${MAX_SIZE / 1e6} Mo`,
    },
  }

  return (
    <ThemeProvider designSystem="reveal">
      <div {...getRootProps()}>
        <UploadDialog key={slug} {...dialogProps} />
      </div>
    </ThemeProvider>
  )
}
