import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import SpinnerContent from '@pretto/bricks/shared/components/SpinnerContent'

import SpinnerOverlay from '@pretto/app/src/SharedContainers/SpinnerOverlay'
import LoaderLLM from '@pretto/app/src/Simulation/components/LoaderLLM/LoaderLLM'

interface LoaderProps {
  type?: LoaderType
}

export enum LoaderType {
  Default = 'default',
  Simulation = 'simulation',
  Subscribe = 'subscribe',
  Llm = 'llm',
}

export const Loader: React.FC<LoaderProps> = ({ type } = { type: LoaderType.Default }) => {
  switch (type) {
    case LoaderType.Simulation:
      return <SpinnerOverlay />

    case LoaderType.Llm:
      return (
        <LoaderLLM
          title="Recherche de financement en cours"
          illustration="v1739189143/app/illustrations/handscale.svg"
          listItem={[
            { title: 'Compilation de vos informations…' },
            { title: 'Analyse des critères bancaires…' },
            { title: 'Recherche d’optimisations…' },
            { title: 'Tests de scénarios alternatifs…' },
            { title: 'Identification de solutions…' },
          ]}
          timing={2500}
        />
      )

    case LoaderType.Subscribe:
      return (
        <SpinnerLegacy overlay>
          <SpinnerContent slug="subscribe" />
        </SpinnerLegacy>
      )

    case LoaderType.Default:
    default:
      return <SpinnerLegacy overlay />
  }
}
