import { g, breakpoints } from '@pretto/bricks/components/layout'

import { DividerHorizontal } from '@pretto/zen/reveal/atoms/dividers/dividerHorizontal/DividerHorizontal'

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin: 0 0 ${g(3)} 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral3};
  padding-bottom: ${g(3)};

  &:last-of-type {
    margin: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
    border-radius: ${g(1)};
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom: none;
  }
`

export const Divider = styled(DividerHorizontal)`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    margin: ${g(3)} 0;
  }
`

export const Section = styled.div`
  margin: ${g(2)} 0 0 0;
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0;
    display: flex;
    flex-direction: column;
  }
`

export const ArticleAndPartner = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Title = styled.h6`
  ${({ theme }) => theme.typos.heading6};
  font-weight: italic;
  margin: 0 0 ${g(2)} 0;
`

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
