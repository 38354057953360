import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { OthersChoices } from '@pretto/app/src/Sentences/ComortgagorOccupation/Sector/Containers/SectorPage/Others/choices'
import { OthersSentence } from '@pretto/app/src/Sentences/ComortgagorOccupation/Sector/Containers/SectorPage/Others/sentence'
import { checkSeniorityYearIsFilled } from '@pretto/app/src/Sentences/Occupation/Sector/lib/checkSeniorityYearIsFilled'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { usePrettoSearch } from '@pretto/app/src/Sentences/contexts/PrettoSearchContext'
import { useRateAlert } from '@pretto/app/src/Sentences/contexts/RateAlertContext'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import {
  isCDD as isMortgagorCDD,
  isEarlyBusinessOwner as isMortgagorEarlyBusinessOwner,
  isOnTrial as isMortgagorOnTrial,
} from '@pretto/app/src/Sentences/lib/contract'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { isCoMortgagorOccupationFilled } from '@pretto/app/src/Sentences/lib/validators/context'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorOccupationKind } from '@pretto/app/src/types/api/enums'

import { PrivateChoices } from './Private/choices'
import { PrivateSentence } from './Private/sentence'
import { PublicChoices } from './Public/choices'
import { PublicSentence } from './Public/sentence'

export const SectorPage: React.FC = () => {
  const { isLoggedIn } = useAuth()
  const { prettoSearch } = usePrettoSearch()
  const { rateAlert } = useRateAlert()

  const { simulation, setSimulationComortgagor } = useSentences()

  const isOnTrial = !!simulation.profileMortgagors[1] && isMortgagorOnTrial(simulation.profileMortgagors[1])
  const isCDD = !!simulation.profileMortgagors[1] && isMortgagorCDD(simulation.profileMortgagors[1])
  const isEarlyBusinessOwner =
    !!simulation.profileMortgagors[1] && isMortgagorEarlyBusinessOwner(simulation.profileMortgagors[1])

  const { nextRoute } = useRoutesGraph()

  const [activeField, setActiveField] = useActiveField(
    0,
    Boolean(nextRoute && isCoMortgagorOccupationFilled({ isLoggedIn, prettoSearch, rateAlert, simulation }))
  )

  const mapSectorValues: MapToChoices<SimulationMortgagorOccupationKind[]> = values => {
    return values.map(value => ({
      key: value,
      isSelected: simulation.profileMortgagors[1]?.occupationKind === value,
      onClick: () => {
        setSimulationComortgagor({ occupationKind: value })
        setActiveField(1)
      },
      label: t(`sentences.situation.sector.values.${value}`),
    }))
  }

  const handleActiveField = (field: number) => {
    setActiveField(field)
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'sector-comortgagor' } }}>
      <Title>{t('sentences.situation.comortgagor.title')}</Title>
      <div>
        {t('sentences.situation.comortgagor.sector.sentence')}
        <Prompt
          onClick={() => {
            setActiveField(0)
          }}
          isFilled={!!simulation.profileMortgagors[1]?.occupationKind}
        >
          {t(
            `sentences.situation.sector.values.${
              simulation.profileMortgagors[1]?.occupationKind || SimulationMortgagorOccupationKind.Private
            }`
          )}
        </Prompt>
        {activeField > 0 &&
          simulation.profileMortgagors[1]?.occupationKind === SimulationMortgagorOccupationKind.Private && (
            <PrivateSentence activeField={activeField} onSetActive={handleActiveField} />
          )}
        {activeField > 0 &&
          simulation.profileMortgagors[1]?.occupationKind === SimulationMortgagorOccupationKind.Public && (
            <PublicSentence activeField={activeField} onSetActive={handleActiveField} />
          )}
        {activeField > 0 &&
          (simulation.profileMortgagors[1]?.occupationKind === SimulationMortgagorOccupationKind.Other ||
            simulation.profileMortgagors[1]?.occupationKind === SimulationMortgagorOccupationKind.Medical) && (
            <OthersSentence activeField={activeField} onSetActive={handleActiveField} />
          )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapSectorValues(
            Object.values([
              SimulationMortgagorOccupationKind.Private,
              SimulationMortgagorOccupationKind.Public,
              SimulationMortgagorOccupationKind.Other,
              SimulationMortgagorOccupationKind.Medical,
            ])
          )}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationType' },
          }}
        />
      )}
      {activeField > 0 &&
        simulation.profileMortgagors[1]?.occupationKind === SimulationMortgagorOccupationKind.Private && (
          <PrivateChoices activeField={activeField} onSetActive={handleActiveField} />
        )}
      {activeField > 0 &&
        simulation.profileMortgagors[1]?.occupationKind === SimulationMortgagorOccupationKind.Public && (
          <PublicChoices activeField={activeField} onSetActive={handleActiveField} />
        )}
      {activeField > 0 &&
        (simulation.profileMortgagors[1]?.occupationKind === SimulationMortgagorOccupationKind.Other ||
          simulation.profileMortgagors[1]?.occupationKind === SimulationMortgagorOccupationKind.Medical) && (
          <OthersChoices activeField={activeField} onSetActive={handleActiveField} />
        )}
      {isOnTrial && <Message>{t('sentences.tips.occupation.trialPeriod.comortgagor')}</Message>}
      {isCDD && <Message>{t('sentences.tips.occupation.shortTerm.comortgagor')}</Message>}
      {isEarlyBusinessOwner && <Message>{t('sentences.tips.occupation.earlyBusinessOwner.comortgagor')}</Message>}
      {simulation.profileMortgagors[1] &&
        isCoMortgagorOccupationFilled({ isLoggedIn, prettoSearch, rateAlert, simulation }) &&
        checkSeniorityYearIsFilled(simulation.profileMortgagors[1]) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
