import { useFieldFormat } from '@pretto/zen/reveal/lib/useFieldFormat'
import type { FieldProps, FieldState } from '@pretto/zen/reveal/types/Field'

import {
  CheckBold,
  ExclamationMarkCircleBold,
  ExclamationMarkCircleLine,
  ExclamationMarkTriangleBold,
  ExclamationMarkTriangleLine,
  Picto as PictoType,
  TimesBold,
} from '@pretto/picto'

import { forwardRef, useImperativeHandle, useRef } from 'react'

import * as S from './ResizableField.styles'

export interface ResizableFieldProps extends FieldProps {
  clearable?: boolean
  onClear?: (event: React.MouseEvent<HTMLButtonElement>) => void
  submittable?: boolean
}

export interface ResizableFieldRef {
  blur: () => void
  focus: () => void
}

const pictos: Record<Exclude<FieldState, 'default'>, [PictoType, PictoType]> = {
  error: [ExclamationMarkCircleBold, ExclamationMarkCircleLine],
  warning: [ExclamationMarkTriangleBold, ExclamationMarkTriangleLine],
}

export const ResizableField = forwardRef<
  ResizableFieldRef,
  ResizableFieldProps & Omit<React.HTMLAttributes<HTMLSpanElement>, 'onChange'>
>(
  (
    {
      clearable = false,
      inputProps = {},
      message,
      onChange,
      onClear,
      onClick,
      state = 'default',
      submittable = false,
      value,
      ...props
    },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null)
    const submitButtonRef = useRef<HTMLButtonElement>(null)

    const fieldFormatProps = useFieldFormat({ inputProps, onChange, value }, innerRef)

    useImperativeHandle(
      ref,
      () => ({
        blur() {
          setTimeout(() => {
            innerRef.current?.blur()
            submitButtonRef.current?.blur()
          }, 0)
        },
        focus() {
          innerRef.current?.focus()
        },
      }),
      []
    )

    const stringValue = fieldFormatProps.value
    const isEmpty = stringValue === ''

    const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
      onChange('')
      if (onClear) onClear(event)

      if (fieldFormatProps.ref && 'current' in fieldFormatProps.ref) {
        fieldFormatProps.ref.current?.focus()
      }
    }

    return (
      <S.Field $isSubmittable={submittable} $state={state} onClick={onClick} {...props}>
        <S.Interactive $isEmpty={isEmpty}>
          <S.Placeholder>{isEmpty ? inputProps.placeholder : stringValue}</S.Placeholder>
          <S.Input {...inputProps} {...fieldFormatProps} />
        </S.Interactive>

        {state !== 'default' && (
          <S.FollowTooltip $state={state} message={message}>
            <S.Picto>
              {(['error', 'warning'] as const).map(fieldState => (
                <>
                  <S.PictoBlur $isIn={state === fieldState} $state={fieldState} as={pictos[fieldState][0]} />
                  <S.PictoFocus $isIn={state === fieldState} $state={fieldState} as={pictos[fieldState][1]} />
                </>
              ))}
            </S.Picto>
          </S.FollowTooltip>
        )}

        {clearable && state === 'default' && !isEmpty && (
          <S.ClearButton onClick={handleClear} type="button">
            <TimesBold />
          </S.ClearButton>
        )}

        {submittable && (
          <S.SubmitButtonContainer>
            <S.SubmitButton aria-label="Valider" disabled={isEmpty} ref={submitButtonRef}>
              <CheckBold />
            </S.SubmitButton>
          </S.SubmitButtonContainer>
        )}
      </S.Field>
    )
  }
)

ResizableField.displayName = 'ResizableField'
