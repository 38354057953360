import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { ErrorMessage } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationStructureSciAssociation } from '@pretto/app/src/types/api/enums'

export const SciAssociationPage: React.FC = () => {
  const { simulation, setSimulation } = useSentences()
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && simulation.structureSciAssociation))

  const saveSciAssociation = (value: SimulationStructureSciAssociation) => {
    setSimulation({ structureSciAssociation: value })
    if (value !== SimulationStructureSciAssociation.Complex) return goToNextRoute()
  }

  const mapSciAssociationValues: MapToChoices<SimulationStructureSciAssociation[]> = values => {
    return values.map(value => ({
      key: value,
      isSelected: simulation.structureSciAssociation === value,
      onClick: () => saveSciAssociation(value),
      label: t(`sentences.introduction.sciAssociation.values.${value}`),
      value,
    }))
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'sciAssociation' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t('sentences.introduction.sciAssociation.sentence.0')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!!simulation.structureSciAssociation}>
          {t(
            `sentences.introduction.sciAssociation.values.${
              simulation.structureSciAssociation || SimulationStructureSciAssociation.Simple
            }`
          )}
        </Prompt>
        {t('sentences.introduction.sciAssociation.sentence.1')}
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapSciAssociationValues(
            Object.values([SimulationStructureSciAssociation.Simple, SimulationStructureSciAssociation.Complex])
          )}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'sciAssociation' } }}
        />
      )}
      {simulation.structureSciAssociation === SimulationStructureSciAssociation.Complex && (
        <ErrorMessage>{t(`sentences.introduction.sci.error.sciAssociation`)}</ErrorMessage>
      )}
      {simulation.structureSciAssociation === SimulationStructureSciAssociation.Simple && (
        <ButtonNext>{t('sentences.next')}</ButtonNext>
      )}
    </TrackedView>
  )
}
