import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import type { SimulationMortgagorIncomePeriod } from '@pretto/app/src/types/api/enums'

export const mapIncomePeriodValues: MapToChoices<SimulationMortgagorIncomePeriod[]> = values => {
  const { simulation, setSimulationMortgagor } = useSentences()

  return values.map(value => ({
    key: value,
    isSelected: simulation.profileMortgagors[0]?.incomeSalaryPeriod === value,
    onClick: () => {
      setSimulationMortgagor({ incomeSalaryPeriod: value })
    },
    label: t(`sentences.income.period.${value}`),
  }))
}
