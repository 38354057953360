import DashboardPage from '@pretto/bricks/app/dashboard/pages/DashboardPage'
import LayoutStickyFooter from '@pretto/bricks/app/layout/LayoutStickyFooter'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import DialogTemplate from '@pretto/bricks/shared/templates/DialogTemplate'

import Header from '@pretto/app/src/SharedContainers/Header'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { NewProjectModal } from '@pretto/app/src/dashboard/components/NewProjectModal/NewProjectModal'
import { PrettoSearchModal } from '@pretto/app/src/dashboard/components/PrettoSearchModal/PrettoSearchModal'
import { ContactController } from '@pretto/app/src/dashboard/containers/contactController'
import { ProjectModalContext } from '@pretto/app/src/dashboard/contexts/ProjectModalContext'
import { getNavigationItemsList } from '@pretto/app/src/dashboard/lib/getNavigationItemsList'
import { TimesBold } from '@pretto/picto'

import pick from 'lodash/pick'
import PropTypes from 'prop-types'
import { createElement, useContext } from 'react'

import { AdrenaleadIframe } from '../../components/AdrenaleadIframe/AdrenaleadIframe'

export const Dashboard = ({
  dialogOptions,
  hasConnectionError,
  isConnecting,
  isDialogOpen,
  isPriceSubmitted,
  onCheckEmail,
  onCloseDialog,
  pageProps,
}) => {
  const { hasFeatureAccess, status, typology, user } = useUser()
  const { tablet, desktop } = getNavigationItemsList({ hasFeatureAccess, status, typology, isPriceSubmitted })
  const { setToDefault, propertySearchData } = useContext(ProjectModalContext)

  const prettoSearchModalData = pick(propertySearchData, [
    'id',
    'localisations',
    'property_kind',
    'area_min',
    'area_max',
    'rooms',
    'property_condition',
    'price_min',
    'price_max',
  ])

  return (
    <>
      <Responsive max="laptop">
        <Header navigationItemsList={tablet} />
      </Responsive>

      <Responsive min="laptop">
        <Header navigationItemsList={desktop} />
      </Responsive>

      <LayoutStickyFooter>
        <DashboardPage {...pageProps} />

        <NewProjectModal onClose={setToDefault} />

        {Object.keys(prettoSearchModalData).length !== 0 && (
          <PrettoSearchModal onClose={setToDefault} data={prettoSearchModalData} />
        )}

        <ContactController />
      </LayoutStickyFooter>

      {dialogOptions && (
        <Dialog isOpen={isDialogOpen} onRequestClose={onCloseDialog}>
          <DialogTemplate
            navbarProps={{
              iconLeft: TimesBold,
              iconLeftAction: onCloseDialog,
              title: dialogOptions.title,
            }}
          >
            {createElement(dialogOptions.component, {
              email: user.email,
              hasError: hasConnectionError,
              isLoading: isConnecting,
              onSubmit: onCheckEmail,
            })}
          </DialogTemplate>
        </Dialog>
      )}

      <AdrenaleadIframe type="newLead" isClient={typology === 'client'} />
    </>
  )
}

Dashboard.propTypes = {
  dialogOptions: PropTypes.object,
  hasConnectionError: PropTypes.bool.isRequired,
  isConnecting: PropTypes.bool.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isPriceSubmitted: PropTypes.bool.isRequired,
  onCheckEmail: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired,
}
