import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { InfoMessage } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const ExpatriatePage: React.FC = () => {
  const { simulation, setSimulationComortgagor } = useSentences()

  const { nextRoute } = useRoutesGraph()

  const [activeField, setActiveField] = useActiveField(
    0,
    Boolean(nextRoute && !isNil(simulation.profileMortgagors[1]?.occupationExpatriation))
  )

  if (!simulation.profileMortgagors[1]) {
    return null
  }

  const mapExpatriateValues = (values: YesNo[]) => {
    return values.map(value => {
      return {
        key: value,
        value,
        isSelected:
          (simulation.profileMortgagors[1]?.occupationExpatriation === true && value === YesNo.Yes) ||
          (simulation.profileMortgagors[1]?.occupationExpatriation === false && value === YesNo.No),
        onClick: () => {
          setSimulationComortgagor({ occupationExpatriation: value === YesNo.Yes })
        },
        label: t(`sentences.situation.comortgagor.expatriate.values.${value}`),
      }
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'expatriate-comortgagor' } }}>
      <Title>{t('sentences.situation.comortgagor.title')}</Title>
      <div>
        {t('sentences.situation.comortgagor.expatriate.sentences.0')}
        <Prompt
          onClick={() => setActiveField(0)}
          isFilled={!isNil(simulation.profileMortgagors[1]?.occupationExpatriation)}
        >
          {t(
            `sentences.situation.comortgagor.expatriate.values.${
              simulation.profileMortgagors[1]?.occupationExpatriation ||
              isNil(simulation.profileMortgagors[1]?.occupationExpatriation)
                ? YesNo.Yes
                : YesNo.No
            }`
          )}
        </Prompt>
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapExpatriateValues(Object.values(YesNo))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'expatriateComortgagor' } }}
        />
      )}

      {simulation.profileMortgagors[1]?.occupationExpatriation && (
        <InfoMessage>{t('sentences.situation.comortgagor.expatriate.info')} </InfoMessage>
      )}

      <ButtonNext>{t('sentences.next')}</ButtonNext>
    </TrackedView>
  )
}
