import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'
import Link from '@pretto/bricks/website/utility/Link'

import { ArrowUpRightCircle } from '@pretto/picto'

import styled from 'styled-components'

export const Icon = styled(ArrowUpRightCircle)`
  width: ${g(7)};
  height: ${g(7)};
  fill: ${({ theme }) => theme.colors.neutral2};
`

export const PartnerCard = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.neutral4};
  padding: ${g(2)};
  margin: ${g(2)} 0 0 0;
  border-radius: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 0 0 ${g(4)};
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Content = styled.p`
  ${({ theme }) => theme.typos.body4Bold};
  margin: 0 0 ${g(1)} 0;
`

export const ImageContainer = styled.div`
  margin: 0 ${g(2)} 0 0;
`
export const Image = styled(ImageComponent).attrs({
  options: { height: ng(13), width: ng(13) },
})`
  display: flex;
`

export const ImagePlaceholder = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(0.5)};
  width: ${g(13)}px;
  height: ${g(13)}px;
  min-width: ${g(13)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${g(2)} 0 0;
`

export const PartnerLink = styled(Link)`
  text-decoration: underline;
`
