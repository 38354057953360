import type { PrimaryButtonFixProps } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'

import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'

import { ButtonNext as ButtonNextComponent } from '@pretto/app/src/Sentences/components/ButtonNext/ButtonNext'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useTracking } from '@pretto/app/src/Sentences/lib/tracking/useTracking'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { client as clientAPI } from '@pretto/app/src/api/api'
import type { DistributiveOmit } from '@pretto/app/src/lib/ts/DistributiveOmit'

import { useState } from 'react'
import { useHistory } from 'react-router-dom'

export const ButtonNext: React.FC<DistributiveOmit<PrimaryButtonFixProps, 'href'>> = ({ onClick, ...props }) => {
  const { push } = useHistory()

  const { graph, nextRoute } = useRoutesGraph()

  const { simulation, persistSimulation } = useSentences()

  const [isLoading, setIsLoading] = useState(false)

  const trackAction = useTracking()

  const { typology } = useUser()

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement> & React.MouseEvent<HTMLAnchorElement>) => {
    // why use event.currentTarget.getAttribute('href') instead of
    // just event.currentTarget.href, because href will be the full url resolved
    // by the browser (absolute) whereas getAttribute('href') will return
    // the value given in the html (relative)
    const href = event.currentTarget.getAttribute('href')

    if (graph.endsBy(nextRoute)) {
      event.preventDefault()

      setIsLoading(true)

      trackAction({
        eventName: EventName.SimulationCompleted,
        eventPayload: { simulationType: simulation.kind },
      })

      await persistSimulation()

      if (typology === 'preLead') {
        await invalidateCache(clientAPI)
      }
    }

    onClick?.(event)

    if (!onClick && href) {
      push(href)
    }
  }

  if (!nextRoute) {
    return null
  }

  return (
    <ButtonNextComponent
      {...props}
      href={nextRoute.path}
      loading={isLoading}
      disabled={isLoading}
      onClick={handleClick}
    />
  )
}
