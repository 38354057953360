import * as S from './RecommendationCard.styles'

export interface RecommendationCardProps {
  title: string
  content: string
  cta?: {
    title: string
    onCtaClick: () => void
  } | null
}

export const RecommendationCard: React.FC<RecommendationCardProps & React.HTMLAttributes<HTMLDivElement>> = ({
  title,
  content,
  cta,
}) => {
  return (
    <S.RecommendationCard>
      <S.Container>
        <S.Title>{title}</S.Title>
        <S.Content>{content}</S.Content>
      </S.Container>
      {cta ? <S.Cta onClick={cta.onCtaClick}>{cta.title}</S.Cta> : null}
    </S.RecommendationCard>
  )
}
