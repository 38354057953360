import * as S from './ArticleCard.styles'

export interface ArticleCardProps {
  content: string
  picture: string | null
  link: string
  onClickRecoTrackType?: () => void
}

export const ArticleCard: React.FC<ArticleCardProps & React.HTMLAttributes<HTMLDivElement>> = ({
  content,
  picture,
  link,
  onClickRecoTrackType,
}) => {
  return (
    <S.ArticleCard>
      {picture !== null ? (
        <S.ImageContainer>
          <S.Image path={picture} />
        </S.ImageContainer>
      ) : (
        <S.ImagePlaceholder>
          <S.Icon />
        </S.ImagePlaceholder>
      )}
      <S.Container>
        <S.Content>{content}</S.Content>
        <S.ArticleLink href={link} onClick={onClickRecoTrackType}>
          Lire l&apos;article
        </S.ArticleLink>
      </S.Container>
    </S.ArticleCard>
  )
}
