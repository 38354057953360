import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import {
  SimulationKind,
  SimulationMortgagorLodgingSituationKind,
  SimulationPropertyUsage,
} from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'

export const SituationPage: React.FC = () => {
  const { simulation, setSimulation, setSimulationMortgagor } = useSentences()
  const situation = simulation.profileMortgagors[0]?.lodgingSituationKind
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const hasRentToPay =
    situation === SimulationMortgagorLodgingSituationKind.Tenant ||
    situation === SimulationMortgagorLodgingSituationKind.StaffHoused
  const maySaleProperty =
    (simulation.kind === SimulationKind.Capacity || simulation.kind === SimulationKind.Purchase) &&
    simulation.propertyUsage === SimulationPropertyUsage.PrimaryResidence &&
    situation === SimulationMortgagorLodgingSituationKind.Owner
  const staffHousedRentIsZero =
    situation === SimulationMortgagorLodgingSituationKind.StaffHoused &&
    simulation.profileMortgagors[0]?.lodgingRent === 0

  const getDefaultActiveField = () =>
    (hasRentToPay && !!simulation.profileMortgagors[0].lodgingRent) ||
    (maySaleProperty && !isNil(simulation.ownPropertyForSale)) ||
    situation === SimulationMortgagorLodgingSituationKind.Sheltered
      ? 1
      : 0

  const getIsFilled = () => {
    if (!nextRoute) return false
    if (!simulation.profileMortgagors[0]?.lodgingSituationKind) return false
    if (hasRentToPay && !simulation.profileMortgagors[0]?.lodgingRent && !staffHousedRentIsZero) return false
    if (maySaleProperty && isNil(simulation.ownPropertyForSale)) return false
    return true
  }

  const [activeField, setActiveField] = useActiveField(getDefaultActiveField(), getIsFilled())

  const mapSituationValues: MapToChoices<SimulationMortgagorLodgingSituationKind[]> = values =>
    values.map(value => ({
      key: value,
      isSelected: situation === value,
      onClick: () => {
        setSimulationMortgagor({ lodgingSituationKind: value })
        const shouldGoToNextPage =
          (value === SimulationMortgagorLodgingSituationKind.Owner &&
            simulation.propertyUsage !== SimulationPropertyUsage.PrimaryResidence) ||
          (value === SimulationMortgagorLodgingSituationKind.Owner && simulation.kind === SimulationKind.Buyout) ||
          value === SimulationMortgagorLodgingSituationKind.Sheltered

        if (shouldGoToNextPage) {
          goToNextRoute()
          return
        }
        setActiveField(1)
      },
      label: t(`sentences.lodging.situation.values.${value}`),
    }))

  const forSaleChoices = [
    {
      key: YesNo.Yes,
      isSelected: !!simulation.ownPropertyForSale,
      onClick: () => {
        setSimulation({ ownPropertyForSale: true })
      },
      label: t(`sentences.lodging.forSale.values.${YesNo.Yes}`),
    },
    {
      key: YesNo.No,
      isSelected: isNil(simulation.ownPropertyForSale) ? false : !simulation.ownPropertyForSale,
      onClick: () => {
        setSimulation({ ownPropertyForSale: false })
      },
      label: t(`sentences.lodging.forSale.values.${YesNo.No}`),
    },
  ]

  const saveRent = (value: string) => {
    setSimulationMortgagor({ lodgingRent: toIntOrNull(value) })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'situation' } }}>
      <Title>{t('sentences.lodging.title')}</Title>
      <div>
        {t('sentences.lodging.situation.sentence')}
        <Prompt isFilled={!!situation} onClick={() => setActiveField(0)}>
          {t(`sentences.lodging.situation.values.${situation || SimulationMortgagorLodgingSituationKind.Tenant}`)}
        </Prompt>
        {activeField > 0 && hasRentToPay && (
          <>
            {t('sentences.lodging.rent.sentences.0')}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'lodgingSituationRent' } }}
              inputProps={{ autoFocus: activeField === 1, placeholder: '700', type: 'integer' }}
              onChange={saveRent}
              value={simulation.profileMortgagors[0]?.lodgingRent?.toString() || ''}
            />
            {t('sentences.lodging.rent.sentences.1')}
          </>
        )}
        {activeField > 0 && maySaleProperty && (
          <>
            {t('sentences.lodging.forSale.sentences.0')}
            <Prompt isFilled={!isNil(simulation.ownPropertyForSale)} onClick={() => setActiveField(1)}>
              {t(
                `sentences.lodging.forSale.values.${
                  simulation.ownPropertyForSale || isNil(simulation.ownPropertyForSale) ? YesNo.Yes : YesNo.No
                }`
              )}
            </Prompt>
            {t('sentences.lodging.forSale.sentences.1')}
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapSituationValues(
            Object.values([
              SimulationMortgagorLodgingSituationKind.Tenant,
              SimulationMortgagorLodgingSituationKind.Owner,
              SimulationMortgagorLodgingSituationKind.Sheltered,
              SimulationMortgagorLodgingSituationKind.StaffHoused,
            ])
          )}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'lodgingSituationType' } }}
        />
      )}
      {activeField === 1 && maySaleProperty && (
        <Choices
          choices={forSaleChoices}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'lodgingForSale' } }}
          onChoiceClick={() => {
            goToNextRoute()
          }}
        />
      )}
      {getIsFilled() && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
