import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import { SimulationKind } from '@pretto/app/src/types/api/enums'
import { SpecificAmount } from '@pretto/app/src/types/gateway/schema'

import isNil from 'lodash/isNil'

export const mapBuyout: ComposableMapper = ({ project, simulation }) => {
  if (simulation.kind !== SimulationKind.Buyout) {
    return { project, simulation }
  }

  return {
    project: {
      ...project,

      purchase: {
        ...project.purchase,

        get specific_amounts() {
          if (isNil(simulation.buyoutAmount)) {
            return []
          }

          const specificAmounts = [
            {
              label: 'Montant de la soulte',
              amount: simulation.buyoutAmount,
            } satisfies SpecificAmount,
          ]

          if (!simulation.buyoutHasCredit || isNil(simulation.buyoutCreditRemainingPrincipal)) {
            return specificAmounts
          }

          return [
            ...specificAmounts,
            ...[
              {
                label: 'Montant du CRD soulte',
                amount: simulation.buyoutCreditRemainingPrincipal,
              } satisfies SpecificAmount,
            ],
          ]
        },
      },
    },
    simulation,
  }
}
