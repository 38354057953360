import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorIncomeTaxes } from '@pretto/app/src/types/api/enums'

export const mapBonusTypeValues: MapToChoices<SimulationMortgagorIncomeTaxes[]> = values => {
  const { simulation, setSimulationMortgagor } = useSentences()

  return values.map(value => ({
    key: value,
    isSelected: simulation.profileMortgagors[0].incomeBonusTaxes === value,
    onClick: () => {
      setSimulationMortgagor({ incomeBonusTaxes: value })
    },
    label: t(`sentences.income.bonus.type.${value}`),
  }))
}
