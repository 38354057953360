import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const ComortgagorChildSupportPage: React.FC = () => {
  const { simulation, setSimulationComortgagor } = useSentences()
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const comortgagor = simulation?.profileMortgagors?.[1]

  const getIsFilled = () => {
    if (!nextRoute) return false
    if (isNil(comortgagor.chargesPaysChildSupport)) return false
    if (comortgagor.chargesPaysChildSupport && isNil(comortgagor.chargesPaysChildSupport)) return false
    return true
  }

  const [activeField, setActiveField] = useActiveField(comortgagor.chargesPaysChildSupport ? 1 : 0, getIsFilled())

  if (!simulation.profileMortgagors?.length) {
    return null
  }

  const paysComortgagorChildSupport = comortgagor.chargesPaysChildSupport

  const mapHasComortgagorChildSupport = (values: YesNo[]) => {
    return values.map(value => {
      return {
        key: value,
        value,
        isSelected:
          (value === YesNo.Yes && paysComortgagorChildSupport === true) ||
          (value === YesNo.No && paysComortgagorChildSupport === false),
        onClick: () => {
          simulation.profileMortgagors[1].chargesPaysChildSupport = value === YesNo.Yes
          setSimulationComortgagor({ chargesPaysChildSupport: value === YesNo.Yes })
          setActiveField(value === YesNo.Yes ? 1 : 0)
          if (value === YesNo.No) goToNextRoute()
        },
        label: t(`sentences.charge.childSupport.comortgagor.values.${value}`),
      }
    })
  }

  const saveAmount = (value: string) => {
    setSimulationComortgagor({ chargesChildSupport: toIntOrNull(value) })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'comortgagorChildSupport' } }}>
      <Title>{t('sentences.charge.title')}</Title>
      <div>
        {t('sentences.charge.childSupport.comortgagor.sentence')}
        <Prompt isFilled={!isNil(paysComortgagorChildSupport)} onClick={() => setActiveField(0)}>
          {t(
            `sentences.charge.childSupport.comortgagor.values.${
              paysComortgagorChildSupport || isNil(paysComortgagorChildSupport) ? YesNo.Yes : YesNo.No
            }`
          )}
        </Prompt>
        {t('sentences.charge.childSupport.sentences.1')}
        {activeField > 0 && paysComortgagorChildSupport && (
          <>
            {t('sentences.charge.childSupport.sentences.2')}
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'coMortgagorChildSupport' },
              }}
              inputProps={{ autoFocus: activeField === 1, placeholder: '300', type: 'integer' }}
              onChange={saveAmount}
              value={simulation.profileMortgagors?.[1].chargesChildSupport?.toString() || ''}
            />
            {t('sentences.charge.childSupport.sentences.3')}
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapHasComortgagorChildSupport(Object.values(YesNo))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'coMortgagorChildSupport' } }}
        />
      )}
      <ButtonNext>{t('sentences.next')}</ButtonNext>
    </TrackedView>
  )
}
