import { g, breakpoints } from '@pretto/bricks/components/layout'

import { ButtonInline } from '@pretto/zen/reveal/atoms/buttons/buttonInline/ButtonInline'

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.neutral5};
  padding: ${g(3)} ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: ${({ theme }) => theme.colors.neutral4};
    padding: ${g(4)};
    border-radius: ${g(1)};
  }
`
export const Title = styled.h4`
  text-align: center;
  ${({ theme }) => theme.typos.heading6};
  margin: 0 0 ${g(2)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    text-align: left;
    ${({ theme }) => theme.typos.heading4};
    margin: 0 0 ${g(3)} 0;
  }
`

export const ButtonSimu = styled(ButtonInline)`
  margin: ${g(2)} 0 0 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: ${g(3)} 0 0 0;
  }
`
