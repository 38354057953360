import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'

const httpLink = createHttpLink({
  credentials: 'include',
  uri: process.env.API_PRETTO_GRAPHQL_URL,
})

const middlewareLink = setContext((request, { headers }) => ({
  headers: {
    ...headers,
  },
}))

const link = createPersistedQueryLink({ sha256 }).concat(middlewareLink).concat(httpLink)

const cache = new InMemoryCache({
  typePolicies: {
    ObjectDeleted: {
      merge(_, incoming, { cache, readField }) {
        cache.evict({
          id: cache.identify({
            __typename: readField<string>('objectTypename', incoming),
            id: readField<string>('id', incoming),
          }),
        })

        cache.gc()

        return incoming
      },
    },
  },
})

export const client = new ApolloClient({
  cache,
  link,
})
