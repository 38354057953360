/* eslint-disable max-lines */
import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { mapSimulationToProject } from '@pretto/app/src/Sentences/lib/mappers/mapSimulationToProject/mapSimulationToProject'
import { client as clientAPI } from '@pretto/app/src/api/api'
import { client as gatewayAPI } from '@pretto/app/src/api/gateway'
import { useStateOperations } from '@pretto/app/src/lib/state/useStateOperations'
import type {
  Simulation,
  SimulationCredit,
  SimulationMortgagor,
  SimulationMortgagorAdditionalRevenue,
} from '@pretto/app/src/types/api/schema'

import { createContext, PropsWithChildren, useContext, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useReplaceProjectMutation } from './replaceProject.gateway.graphql'
import {
  useAddSimulationCreditMutation,
  useAddSimulationMortgagorAdditionalRevenueMutation,
  useAddSimulationMortgagorMutation,
  useAddSimulationMutation,
  useDeleteSimulationCreditMutation,
  useDeleteSimulationMortgagorAdditionalRevenueMutation,
  useDeleteSimulationMortgagorMutation,
  useSimulationQuery,
  useUpdateSimulationCreditMutation,
  useUpdateSimulationMortgagorAdditionalRevenueMutation,
  useUpdateSimulationMortgagorMutation,
  useUpdateSimulationMutation,
} from './simulation.api.graphql'

// Override the default Simulation type which enforce the id to be set. Though locally,
// we don't have the id until the API gives it to us.
export type GetSimulation = Omit<Simulation, 'id'> & {
  id?: string | null
}

// When calling setSimulation within page controllers, you should not be able
// to set the id. The id must be given by the API.
// chargesCredits and profileMortgagors have to set through their dedicated methods.
export type SetSimulation = Omit<Simulation, 'id' | 'chargesCredits' | 'profileMortgagors'>

// Same logic for child objects
export type SetSimulationMortgagor = Omit<SimulationMortgagor, 'id' | 'incomeAdditionalRevenues'>

// Combine both for the complete context type
export interface SentencesContextInterface {
  addSimulationComortgagorIncomeAdditionalRevenue: () => string
  addSimulationCredit: () => string
  addSimulationMortgagorIncomeAdditionalRevenue: () => string
  deleteSimulationComortgagor: () => void
  deleteSimulationComortgagorIncomeAdditionalRevenue: (id: string) => void
  deleteSimulationCredit: (id: string) => void
  deleteSimulationMortgagorIncomeAdditionalRevenue: (id: string) => void
  isUpdate: boolean
  persistSimulation: () => Promise<void>
  simulation: GetSimulation
  setSimulation: React.Dispatch<React.SetStateAction<SetSimulation>>
  setSimulationComortgagor: React.Dispatch<React.SetStateAction<SetSimulationMortgagor>>
  setSimulationComortgagorIncomeAdditionalRevenue: React.Dispatch<
    React.SetStateAction<SimulationMortgagorAdditionalRevenue>
  >
  setSimulationCredit: React.Dispatch<React.SetStateAction<SimulationCredit>>
  setSimulationMortgagor: React.Dispatch<React.SetStateAction<SetSimulationMortgagor>>
  setSimulationMortgagorIncomeAdditionalRevenue: React.Dispatch<
    React.SetStateAction<SimulationMortgagorAdditionalRevenue>
  >
  waitForOngoingOperations: () => Promise<GetSimulation>
}

export const SentencesContext = createContext<SentencesContextInterface>({} as SentencesContextInterface)

const getInitialSimulation = (simulation?: Simulation): GetSimulation => {
  if (simulation) {
    return simulation
  }

  return {
    chargesCredits: [],
    profileMortgagors: [],
  }
}
// createProjectContext({
//   ...getSentencesContextFromLocation(),
// })

export const SentencesContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { data, loading } = useSimulationQuery({ fetchPolicy: 'network-only', client: clientAPI })

  const [addSimulationMutation] = useAddSimulationMutation({ client: clientAPI })

  const [addSimulationMortgagorAdditionalRevenueMutation] = useAddSimulationMortgagorAdditionalRevenueMutation({
    client: clientAPI,
  })

  const [addSimulationMortgagorMutation] = useAddSimulationMortgagorMutation({ client: clientAPI })

  const [deleteSimulationMortgagorAdditionalRevenueMutation] = useDeleteSimulationMortgagorAdditionalRevenueMutation({
    client: clientAPI,
  })

  const [deleteSimulationMortgagorMutation] = useDeleteSimulationMortgagorMutation({ client: clientAPI })

  const [addSimulationCreditMutation] = useAddSimulationCreditMutation({ client: clientAPI })
  const [deleteSimulationCreditMutation] = useDeleteSimulationCreditMutation({ client: clientAPI })
  const [updateSimulationCreditMutation] = useUpdateSimulationCreditMutation({ client: clientAPI })

  const [updateSimulationMutation] = useUpdateSimulationMutation({ client: clientAPI })

  const [updateSimulationMortgagorAdditionalRevenueMutation] = useUpdateSimulationMortgagorAdditionalRevenueMutation({
    client: clientAPI,
  })

  const [updateSimulationMortgagorMutation] = useUpdateSimulationMortgagorMutation({ client: clientAPI })

  const [replaceProjectMutation] = useReplaceProjectMutation({ client: gatewayAPI })

  const [simulation, setPartialSimulation, waitForOngoingOperations] = useStateOperations<GetSimulation>(
    getInitialSimulation()
  )

  useEffect(() => {
    if (loading) {
      return
    }

    setPartialSimulation(getInitialSimulation(data?.simulations[0]))
  }, [loading, data])

  const isLoading = loading || !simulation

  useLoading(isLoading)

  if (isLoading) {
    return null
  }

  const addSimulationComortgagorIncomeAdditionalRevenue = () => {
    const id = uuidv4()

    setPartialSimulation(previousContext => ({
      ...previousContext,
      profileMortgagors: [
        previousContext.profileMortgagors[0],
        {
          ...previousContext.profileMortgagors[1],
          incomeAdditionalRevenues: [...(previousContext.profileMortgagors[1]?.incomeAdditionalRevenues ?? []), { id }],
        },
        ...previousContext.profileMortgagors.slice(2),
      ],
    }))

    return id
  }

  const addSimulationCredit = () => {
    const id = uuidv4()

    setPartialSimulation(previousContext => ({
      ...previousContext,
      chargesCredits: [...previousContext.chargesCredits, { id }],
    }))

    return id
  }

  const addSimulationMortgagorIncomeAdditionalRevenue = () => {
    const id = uuidv4()

    setPartialSimulation(previousContext => ({
      ...previousContext,
      profileMortgagors: [
        {
          ...previousContext.profileMortgagors[0],
          incomeAdditionalRevenues: [...(previousContext.profileMortgagors[0]?.incomeAdditionalRevenues ?? []), { id }],
        },
        ...previousContext.profileMortgagors.slice(1),
      ],
    }))

    return id
  }

  const deleteSimulationComortgagor = () => {
    setPartialSimulation(previousContext => ({
      ...previousContext,
      profileMortgagors: [previousContext.profileMortgagors[0], ...previousContext.profileMortgagors.slice(2)],
    }))
  }

  const deleteSimulationComortgagorIncomeAdditionalRevenue = (id: string) => {
    setPartialSimulation(previousContext => ({
      ...previousContext,
      profileMortgagors: [
        previousContext.profileMortgagors[0],
        {
          ...previousContext.profileMortgagors[1],
          incomeAdditionalRevenues:
            previousContext.profileMortgagors[1]?.incomeAdditionalRevenues.filter(
              incomeAdditionalRevenue => incomeAdditionalRevenue.id !== id
            ) ?? [],
        },
        ...previousContext.profileMortgagors.slice(2),
      ],
    }))
  }

  const deleteSimulationCredit = (id: string) => {
    setPartialSimulation(previousContext => ({
      ...previousContext,
      chargesCredits: previousContext.chargesCredits.filter(chargesCredit => chargesCredit.id !== id),
    }))
  }

  const deleteSimulationMortgagorIncomeAdditionalRevenue = (id: string) => {
    setPartialSimulation(previousContext => ({
      ...previousContext,
      profileMortgagors: [
        {
          ...previousContext.profileMortgagors[0],
          incomeAdditionalRevenues:
            previousContext.profileMortgagors[0]?.incomeAdditionalRevenues.filter(
              incomeAdditionalRevenue => incomeAdditionalRevenue.id !== id
            ) ?? [],
        },
        ...previousContext.profileMortgagors.slice(1),
      ],
    }))
  }

  const isUpdate = (data?.simulations ?? []).length > 0

  const persistSimulation = async () => {
    const { __typename, chargesCredits, id, profileMortgagors, ...simulationPayload } = simulation

    if (typeof id === 'string') {
      await updateSimulationMutation({ variables: { input: { id, ...simulationPayload } } })
      await persistSimulationCredits(id, chargesCredits)
      await persistSimulationMortgagors(id, profileMortgagors)
    } else {
      const response = await addSimulationMutation({ variables: { input: simulationPayload } })

      if (response.errors || !response.data) {
        // TODO better handling
        throw new Error()
      }

      const simulationId = response.data.addSimulation.id

      await persistSimulationCredits(simulationId, chargesCredits)
      await persistSimulationMortgagors(simulationId, profileMortgagors)
    }

    await replaceProjectMutation({
      variables: { project: JSON.stringify(mapSimulationToProject({ project: {}, simulation })) },
    })
  }

  const persistSimulationCredits = async (simulationId: string, chargesCredits: SimulationCredit[]) => {
    const initialChargesCredits = data?.simulations[0]?.chargesCredits ?? []

    for (const initialChargesCredit of initialChargesCredits) {
      if (chargesCredits.every(({ id }) => id !== initialChargesCredit.id)) {
        await deleteSimulationCreditMutation({ variables: { input: { id: initialChargesCredit.id, simulationId } } })
      }
    }

    for (const { __typename, id, ...chargesCredit } of chargesCredits) {
      if (initialChargesCredits.some(initialChargesCredit => initialChargesCredit.id === id)) {
        await updateSimulationCreditMutation({ variables: { input: { ...chargesCredit, id, simulationId } } })
      } else {
        await addSimulationCreditMutation({ variables: { input: { ...chargesCredit, simulationId } } })
      }
    }
  }

  const persistSimulationMortgagors = async (simulationId: string, profileMortgagors: SimulationMortgagor[]) => {
    const initialProfileMortgagors = data?.simulations[0]?.profileMortgagors ?? []

    for (const initialProfileMortgagor of initialProfileMortgagors) {
      for (const initialIncomeAdditionalRevenu of initialProfileMortgagor.incomeAdditionalRevenues) {
        if (
          profileMortgagors.every(({ id }) => id !== initialProfileMortgagor.id) ||
          profileMortgagors
            .find(({ id }) => id === initialProfileMortgagor.id)
            ?.incomeAdditionalRevenues.every(({ id }) => id !== initialIncomeAdditionalRevenu.id)
        ) {
          await deleteSimulationMortgagorAdditionalRevenueMutation({
            variables: {
              input: {
                id: initialIncomeAdditionalRevenu.id,
                simulationId,
                mortgagorId: initialProfileMortgagor.id,
              },
            },
          })
        }
      }

      if (profileMortgagors.every(({ id }) => id !== initialProfileMortgagor.id)) {
        await deleteSimulationMortgagorMutation({
          variables: { input: { id: initialProfileMortgagor.id, simulationId } },
        })
      }
    }

    for (const { __typename, id, incomeAdditionalRevenues, ...profileMortgagor } of profileMortgagors) {
      if (typeof id === 'string') {
        await updateSimulationMortgagorMutation({ variables: { input: { ...profileMortgagor, id, simulationId } } })
        await persistSimulationMortgagorIncomeAdditionalRevenues(simulationId, id, incomeAdditionalRevenues)
      } else {
        const response = await addSimulationMortgagorMutation({
          variables: { input: { ...profileMortgagor, simulationId } },
        })

        if (response.errors || !response.data) {
          // TODO better handling
          throw new Error()
        }

        const mortgagorId = response.data.addSimulationMortgagor.id

        await persistSimulationMortgagorIncomeAdditionalRevenues(simulationId, mortgagorId, incomeAdditionalRevenues)
      }
    }
  }

  const persistSimulationMortgagorIncomeAdditionalRevenues = async (
    simulationId: string,
    mortgagorId: string,
    incomeAdditionalRevenues: SimulationMortgagorAdditionalRevenue[]
  ) => {
    const initialIncomeAdditionalRevenues =
      data?.simulations[0]?.profileMortgagors.find(({ id }) => id === mortgagorId)?.incomeAdditionalRevenues ?? []

    for (const { __typename, id, ...incomeAdditionalRevenue } of incomeAdditionalRevenues) {
      if (
        initialIncomeAdditionalRevenues.some(initialIncomeAdditionalRevenue => initialIncomeAdditionalRevenue.id === id)
      ) {
        await updateSimulationMortgagorAdditionalRevenueMutation({
          variables: {
            input: { ...incomeAdditionalRevenue, id, simulationId, mortgagorId },
          },
        })
      } else {
        await addSimulationMortgagorAdditionalRevenueMutation({
          variables: {
            input: { ...incomeAdditionalRevenue, simulationId, mortgagorId },
          },
        })
      }
    }
  }

  const setSimulation: React.Dispatch<React.SetStateAction<SetSimulation>> = state => {
    setPartialSimulation(previousContext => ({
      ...previousContext,
      ...(typeof state === 'function' ? state(previousContext) : state),
    }))
  }

  const setSimulationComortgagor: React.Dispatch<React.SetStateAction<SetSimulationMortgagor>> = state => {
    setPartialSimulation(previousContext => ({
      ...previousContext,
      profileMortgagors: [
        previousContext.profileMortgagors[0],
        {
          ...previousContext.profileMortgagors[1],
          incomeAdditionalRevenues: previousContext.profileMortgagors[1]?.incomeAdditionalRevenues ?? [],
          ...(typeof state === 'function' ? state(previousContext.profileMortgagors[1]) : state),
        },
        ...previousContext.profileMortgagors.slice(2),
      ],
    }))
  }

  const setSimulationMortgagor: React.Dispatch<React.SetStateAction<SetSimulationMortgagor>> = state => {
    setPartialSimulation(previousContext => ({
      ...previousContext,
      profileMortgagors: [
        {
          ...previousContext.profileMortgagors[0],
          incomeAdditionalRevenues: previousContext.profileMortgagors[0]?.incomeAdditionalRevenues ?? [],
          ...(typeof state === 'function' ? state(previousContext.profileMortgagors[0]) : state),
        },
        ...previousContext.profileMortgagors.slice(1),
      ],
    }))
  }

  const setSimulationCredit: React.Dispatch<React.SetStateAction<SimulationCredit>> = state => {
    setPartialSimulation(previousContext => ({
      ...previousContext,
      chargesCredits: previousContext.chargesCredits.map(chargesCredit => {
        const chargesCreditState = typeof state === 'function' ? state(chargesCredit) : state

        if (chargesCredit.id === chargesCreditState.id) {
          return {
            ...chargesCredit,
            ...chargesCreditState,
          }
        }

        return chargesCredit
      }),
    }))
  }

  const setSimulationComortgagorIncomeAdditionalRevenue: React.Dispatch<
    React.SetStateAction<SimulationMortgagorAdditionalRevenue>
  > = state => {
    setPartialSimulation(previousContext => ({
      ...previousContext,
      profileMortgagors: [
        previousContext.profileMortgagors[0],
        {
          ...previousContext.profileMortgagors[1],
          incomeAdditionalRevenues: previousContext.profileMortgagors[1].incomeAdditionalRevenues.map(
            incomeAdditionalRevenue => {
              const incomeAdditionalRevenueState = typeof state === 'function' ? state(incomeAdditionalRevenue) : state

              if (incomeAdditionalRevenue.id === incomeAdditionalRevenueState.id) {
                return {
                  ...incomeAdditionalRevenue,
                  ...incomeAdditionalRevenueState,
                }
              }

              return incomeAdditionalRevenue
            }
          ),
        },
        ...previousContext.profileMortgagors.slice(2),
      ],
    }))
  }

  const setSimulationMortgagorIncomeAdditionalRevenue: React.Dispatch<
    React.SetStateAction<SimulationMortgagorAdditionalRevenue>
  > = state => {
    setPartialSimulation(previousContext => ({
      ...previousContext,
      profileMortgagors: [
        {
          ...previousContext.profileMortgagors[0],
          incomeAdditionalRevenues: previousContext.profileMortgagors[0].incomeAdditionalRevenues.map(
            incomeAdditionalRevenue => {
              const incomeAdditionalRevenueState = typeof state === 'function' ? state(incomeAdditionalRevenue) : state

              if (incomeAdditionalRevenue.id === incomeAdditionalRevenueState.id) {
                return {
                  ...incomeAdditionalRevenue,
                  ...incomeAdditionalRevenueState,
                }
              }

              return incomeAdditionalRevenue
            }
          ),
        },
        ...previousContext.profileMortgagors.slice(1),
      ],
    }))
  }

  return (
    <SentencesContext.Provider
      value={{
        addSimulationComortgagorIncomeAdditionalRevenue,
        addSimulationCredit,
        addSimulationMortgagorIncomeAdditionalRevenue,
        deleteSimulationComortgagor,
        deleteSimulationComortgagorIncomeAdditionalRevenue,
        deleteSimulationCredit,
        deleteSimulationMortgagorIncomeAdditionalRevenue,
        isUpdate,
        persistSimulation,
        setSimulation,
        setSimulationComortgagor,
        setSimulationComortgagorIncomeAdditionalRevenue,
        setSimulationCredit,
        setSimulationMortgagor,
        setSimulationMortgagorIncomeAdditionalRevenue,
        simulation,
        waitForOngoingOperations,
      }}
    >
      {children}
    </SentencesContext.Provider>
  )
}

export const useSentences = () => useContext(SentencesContext)
