import * as v from '@pretto/app/src/Sentences/lib/validators/context'
import type { GraphContext } from '@pretto/app/src/Sentences/types/graph'
import { Edge, Graph, Route } from '@pretto/app/src/lib/graph/Graph'

import { allPass, anyPass } from 'ramda'

// Introduction
const structureRoute = new Route<GraphContext>('structure', '/project/renegotiation/introduction/structure')

const propertyUsageRoute = new Route<GraphContext>('propertyUsage', '/project/renegotiation/introduction/propertyUsage')

export const renegotiationGraph = new Graph<GraphContext>('renegotiationGraph', structureRoute)

renegotiationGraph.addRoute(propertyUsageRoute)

renegotiationGraph.addEdge(new Edge<GraphContext>(structureRoute, propertyUsageRoute, anyPass([v.isSolo, v.isDuo])))

// Lodging
const ageChildrenRoute = new Route<GraphContext>('ageChildren', '/project/renegotiation/lodging/ageChildren')
const liveTogetherRoute = new Route<GraphContext>('liveTogether', '/project/renegotiation/lodging/liveTogether')
const addressRoute = new Route<GraphContext>('address', '/project/renegotiation/lodging/address')
const situationRoute = new Route<GraphContext>('situation', '/project/renegotiation/lodging/situation')

renegotiationGraph.addRoute(ageChildrenRoute)
renegotiationGraph.addRoute(liveTogetherRoute)
renegotiationGraph.addRoute(addressRoute)
renegotiationGraph.addRoute(situationRoute)

renegotiationGraph.addEdge(new Edge<GraphContext>(propertyUsageRoute, ageChildrenRoute, v.hasPropertyUsage))

renegotiationGraph.addEdge(
  new Edge<GraphContext>(
    ageChildrenRoute,
    liveTogetherRoute,
    allPass([v.isNotSolo, v.hasAge, v.hasCoMortgagorAge, v.hasChildrenNumber])
  )
)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(
    ageChildrenRoute,
    addressRoute,
    allPass([v.hasAge, v.hasChildrenNumber, v.isSolo, v.isNotPrimaryUsage])
  )
)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(liveTogetherRoute, addressRoute, allPass([v.hasLiveTogetherCompleted, v.isNotPrimaryUsage]))
)

renegotiationGraph.addEdge(new Edge<GraphContext>(addressRoute, situationRoute, v.hasAddress))

// Loan
const bankRoute = new Route<GraphContext>('bank', '/project/renegotiation/loan/bank')
const paymentRoute = new Route<GraphContext>('payment', '/project/renegotiation/loan/payment')
const rateRoute = new Route<GraphContext>('rate', '/project/renegotiation/loan/rate')
const dateDurationRoute = new Route<GraphContext>('dateDuration', '/project/renegotiation/loan/dateDuration')

renegotiationGraph.addRoute(bankRoute)
renegotiationGraph.addRoute(paymentRoute)
renegotiationGraph.addRoute(rateRoute)
renegotiationGraph.addRoute(dateDurationRoute)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(situationRoute, bankRoute, allPass([v.hasSituationType, v.hasRent, v.hasForSale]))
)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(
    ageChildrenRoute,
    bankRoute,
    allPass([v.isSolo, v.hasAge, v.hasChildrenNumber, v.isPrimaryUsage])
  )
)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(liveTogetherRoute, bankRoute, allPass([v.hasLiveTogetherCompleted, v.isPrimaryUsage]))
)

renegotiationGraph.addEdge(new Edge<GraphContext>(bankRoute, paymentRoute, allPass([v.hasBank, v.hasInsuranceType])))

renegotiationGraph.addEdge(
  new Edge<GraphContext>(
    paymentRoute,
    rateRoute,
    allPass([
      v.hasRenegotiationPayment,
      anyPass([
        allPass([v.isBankGroupInsurance]),
        allPass([v.isDelegatedInsurance, v.hasRenegotiationInsurancePayment]),
      ]),
    ])
  )
)

renegotiationGraph.addEdge(new Edge<GraphContext>(rateRoute, dateDurationRoute, v.hasRate))

// Occupation
const sectorRoute = new Route<GraphContext>('sector', '/project/renegotiation/occupation/sector')

renegotiationGraph.addRoute(sectorRoute)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(
    dateDurationRoute,
    sectorRoute,
    allPass([v.hasRenegotiationStartYear, v.hasRenegotiationDuration])
  )
)

// Income
const salaryRoute = new Route<GraphContext>('salary', '/project/renegotiation/income/salary')
const bonusRoute = new Route<GraphContext>('bonus', '/project/renegotiation/income/bonus')
const revenueRoute = new Route<GraphContext>('revenue', '/project/renegotiation/income/revenue')

renegotiationGraph.addRoute(salaryRoute)
renegotiationGraph.addRoute(bonusRoute)
renegotiationGraph.addRoute(revenueRoute)

renegotiationGraph.addEdge(new Edge<GraphContext>(sectorRoute, salaryRoute, v.isOccupationFilled))

renegotiationGraph.addEdge(
  new Edge<GraphContext>(salaryRoute, bonusRoute, allPass([v.isNotOthersSector, v.isSalaryFilled]))
)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(salaryRoute, revenueRoute, allPass([v.isSalaryFilled, v.isOthersSector]))
)

renegotiationGraph.addEdge(new Edge<GraphContext>(bonusRoute, revenueRoute, v.isBonusFilled))

// Comortgagor Lodging
const comortgagorAddressRoute = new Route<GraphContext>(
  'comortgagorAddress',
  '/project/renegotiation/lodging-comortgagor/address'
)

const comortgagorSituationRoute = new Route<GraphContext>(
  'comortgagorSituation',
  '/project/renegotiation/lodging-comortgagor/situation'
)

renegotiationGraph.addRoute(comortgagorAddressRoute)
renegotiationGraph.addRoute(comortgagorSituationRoute)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(
    revenueRoute,
    comortgagorAddressRoute,
    allPass([v.isRevenueFilled, v.isNotSolo, v.doesNotLiveTogether])
  )
)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(comortgagorAddressRoute, comortgagorSituationRoute, v.hasComortgagorAddress)
)

// Comortgagor Occupation
const comortgagorSectorRoute = new Route<GraphContext>(
  'comortgagorSector',
  '/project/renegotiation/occupation-comortgagor/sector'
)

renegotiationGraph.addRoute(comortgagorSectorRoute)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(
    revenueRoute,
    comortgagorSectorRoute,
    allPass([v.isRevenueFilled, v.isNotSolo, v.doesLiveTogether])
  )
)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(
    comortgagorSituationRoute,
    comortgagorSectorRoute,
    allPass([v.hasComortgagorSituationType, v.hasComortgagorRent, v.hasComortgagorForSale])
  )
)

// Comortgagor Income
const comortgagorSalaryRoute = new Route<GraphContext>(
  'comortgagorSalary',
  '/project/renegotiation/income-comortgagor/salary'
)

const comortgagorBonusRoute = new Route<GraphContext>(
  'comortgagorBonus',
  '/project/renegotiation/income-comortgagor/bonus'
)

const comortgagorRevenueRoute = new Route<GraphContext>(
  'comortgagorRevenue',
  '/project/renegotiation/income-comortgagor/revenue'
)

renegotiationGraph.addRoute(comortgagorSalaryRoute)
renegotiationGraph.addRoute(comortgagorBonusRoute)
renegotiationGraph.addRoute(comortgagorRevenueRoute)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(comortgagorSectorRoute, comortgagorSalaryRoute, v.isCoMortgagorOccupationFilled)
)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(
    comortgagorSalaryRoute,
    comortgagorBonusRoute,
    allPass([v.isCoMortgagorNotOthersSector, v.isCoMortgagorSalaryFilled])
  )
)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(
    comortgagorSalaryRoute,
    comortgagorRevenueRoute,
    allPass([v.isCoMortgagorSalaryFilled, v.isCoMortgagorOthersSector])
  )
)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(comortgagorBonusRoute, comortgagorRevenueRoute, v.isCoMortgagorBonusFilled)
)

// Charges
const creditsRoute = new Route<GraphContext>('credits', '/project/renegotiation/credits/credits')
const childSupportRoute = new Route<GraphContext>('childSupport', '/project/renegotiation/credits/childSupport')

const comortgagorChildSupportRoute = new Route<GraphContext>(
  'comortgagorChildSupport',
  '/project/renegotiation/credits/comortgagorChildSupport'
)

renegotiationGraph.addRoute(creditsRoute)
renegotiationGraph.addRoute(childSupportRoute)
renegotiationGraph.addRoute(comortgagorChildSupportRoute)

renegotiationGraph.addEdge(new Edge<GraphContext>(comortgagorRevenueRoute, creditsRoute, v.isCoMortgagorRevenueFilled))
renegotiationGraph.addEdge(new Edge<GraphContext>(revenueRoute, creditsRoute, allPass([v.isRevenueFilled, v.isSolo])))
renegotiationGraph.addEdge(new Edge<GraphContext>(creditsRoute, childSupportRoute, v.isCreditsFilled))

renegotiationGraph.addEdge(
  new Edge<GraphContext>(
    childSupportRoute,
    comortgagorChildSupportRoute,
    allPass([v.isNotSolo, v.isChildSupportFilled])
  )
)

// Renegotiation
const estimatedPriceRoute = new Route<GraphContext>(
  'renegotiation',
  '/project/renegotiation/property-renegotiation/estimatedPrice'
)

renegotiationGraph.addRoute(estimatedPriceRoute)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(childSupportRoute, estimatedPriceRoute, allPass([v.isSolo, v.isChildSupportFilled]))
)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(comortgagorChildSupportRoute, estimatedPriceRoute, v.isCoMortgagorChildSupportFilled)
)

// Rate alert
const wantsRateAlertRoute = new Route<GraphContext>(
  'wantsRateAlert',
  '/project/renegotiation/rate-alert/wantsRateAlert'
)

renegotiationGraph.addRoute(wantsRateAlertRoute)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(
    estimatedPriceRoute,
    wantsRateAlertRoute,
    allPass([v.hasRenegotiationPropertyEstimatedPrice, v.isVisibleRateAlert])
  )
)

// Simulation
const simulationRoute = new Route<GraphContext>('simulation', '/simulation')

renegotiationGraph.addRoute(simulationRoute)

renegotiationGraph.addEdge(
  new Edge<GraphContext>(
    estimatedPriceRoute,
    simulationRoute,
    allPass([v.hasRenegotiationPropertyEstimatedPrice, v.isNotVisibleRateAlert])
  )
)

renegotiationGraph.addEdge(new Edge<GraphContext>(wantsRateAlertRoute, simulationRoute, v.isRateAlertFilled))
