import LayoutStickyFooter from '@pretto/bricks/app/layout/LayoutStickyFooter'
import { LanguageInfo } from '@pretto/bricks/app/simulator/components/LanguageInfo/LanguageInfo'
import { useBreakpoint } from '@pretto/bricks/assets/utility'
import PreviousNextNavBar from '@pretto/bricks/components/bars/PreviousNextNavBar'
import { Grid } from '@pretto/bricks/components/layout/Grid'
import Dialog from '@pretto/bricks/components/overlays/Dialog'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import { temporal } from '@pretto/bricks/core/utility/temporal'

import { getAttributionCookieFirstClickValue } from '@pretto/app-core/lib/attributionCookie'
import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { Content } from '@pretto/app/src/Capacity/Containers/CapacityPage/containers/Content/Content'
import { Sidebar } from '@pretto/app/src/Capacity/Containers/CapacityPage/containers/Sidebar/Sidebar/Sidebar'
import { DialogSave } from '@pretto/app/src/Capacity/Containers/CapacityPage/containers/modals/DialogSave'
import { ModalModify } from '@pretto/app/src/Capacity/Containers/CapacityPage/containers/modals/ModalModify'
import { ControlsProvider } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/ControlsContext'
import { DataContext, DataProvider } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/DataContext'
import { SaveProvider } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/SaveContext'
import ErrorPage from '@pretto/app/src/Capacity/Containers/ErrorPage'
import { getScore } from '@pretto/app/src/Capacity/Containers/lib/getScore'
import { ResultPage } from '@pretto/app/src/Capacity/Containers/resultPage'
import Header from '@pretto/app/src/SharedContainers/Header'
import { getIsPriceSubmitted } from '@pretto/app/src/SharedContainers/Header/lib/getIsPriceSubmitted'
import { getNavigationItemsList } from '@pretto/app/src/SharedContainers/Header/lib/simulatorResultsNav'
import { MutualizedAgenda } from '@pretto/app/src/SharedContainers/MutualizedAgenda/MutualizedAgenda'
import type { BookingInfosParams } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/bookingInfosParams'
import { Occurrence } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/occurrence'
import Subscribe from '@pretto/app/src/SharedContainers/Subscribe'
import { Pushy } from '@pretto/app/src/Simulation/Containers/pushy/Pushy'
import { LOCAL_AGENDA_VISIBLE_NAME } from '@pretto/app/src/Simulation/config/agenda'
import * as types from '@pretto/app/src/Simulation/lib/types'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { ADVISOR, BOOKING, PROJECT } from '@pretto/app/src/apollo'
import { AdrenaleadIframe } from '@pretto/app/src/components/AdrenaleadIframe/AdrenaleadIframe'
import { getItem, removeItem, setItem } from '@pretto/app/src/config/itemStorage'
import { LoaderType } from '@pretto/app/src/config/loader'
import { MINIMUM_LOADING_DURATION } from '@pretto/app/src/config/simulation'
import abTest from '@pretto/app/src/lib/abtest'
import useLanguageInfo from '@pretto/app/src/lib/i18n/useLanguageInfoDialog'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { CapacityData } from '@pretto/app/src/types/Capacity'

import { ApolloClient, useApolloClient, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { Fragment, memo, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

const isVersionClassic = abTest('capacity_result_202311') === 'classic'
const isVersionSuggestions = !isVersionClassic

const CapacityPage: React.FC = () => {
  const client = useApolloClient()

  const history = useHistory()

  const {
    hasFeatureAccess,
    isOnboardingDone = false,
    // isProjectAvailable,
    isProjectEditable,
    status,
    typology,
    next_booking,
    scoreZeroMinute,
  } = useUser()

  const [bookingInfos, setBookingInfos] = useState({ isLoading: true })
  const [loading, setLoading] = useState<boolean>(true)
  const [minimumLoading, setMinimumLoading] = useState<boolean>(true)
  const [stateType, setStateType] = useState<string | null>(getItem(types.SIMULATION_STATE_TYPE))
  const [shouldRefetchBooking] = useState(false)
  const [isMutualizedAgendaOpen, setIsMutualizedAgendaOpen] = useState<boolean>(
    Boolean(getItem(LOCAL_AGENDA_VISIBLE_NAME) && !next_booking)
  )

  const timeouts = useRef([])

  const { isLoggedIn } = useAuth()

  const trackAction = useTracking()

  const { data, payment, error, capacityMedium, initCapacity } = useContext(DataContext)

  const isPageLoading = useLoading(loading || minimumLoading, LoaderType.Simulation)

  const projectQuery = useQuery(PROJECT)
  const isPriceSubmitted = getIsPriceSubmitted(projectQuery)

  let loadingTimeout: ReturnType<typeof setTimeout> | undefined

  const { breakpoint } = useBreakpoint()
  const isLarge = ['laptop', 'desktop'].includes(breakpoint || '')
  const navigationItemsObject = getNavigationItemsList({
    hasFeatureAccess,
    isOnboardingDone,
    isPriceSubmitted,
    projectKind: 'capacity',
    status,
    typology,
  })
  const navItems = isLarge ? navigationItemsObject.desktop : navigationItemsObject.tablet

  const isSuggestionsHidden = abTest('capacity_result_202311') === 'suggestions_hidden' && isOnboardingDone === false

  const { isLanguageInfoOpen, closeLanguageInfo } = useLanguageInfo()

  useEffect(() => {
    launchLoader()

    if (typology === 'client' && !isProjectEditable) {
      history.replace('/')
      return
    }

    // if (typology === 'preLead' && !isProjectAvailable) {
    //   history.replace('/project/capacity/introduction')
    //   return
    // }

    initialize()

    return () => {
      timeouts.current.forEach(timeout => {
        clearTimeout(timeout)
      })
      if (loadingTimeout) {
        clearTimeout(loadingTimeout as unknown as number)
      }
    }
  }, [])

  useEffect(() => {
    if (!error && data) {
      trackCapacity()
    }
  }, [data, error])

  useEffect(() => {
    if (typology === 'client' || shouldRefetchBooking) {
      getHasBooking(client).then(result => setBookingInfos(result))
    }
  }, [typology, shouldRefetchBooking])

  const initialize = async () => {
    try {
      trackAction('CAPACITY_RESULTS_REQUESTED', {
        capacity_results_attribution: getAttributionCookieFirstClickValue(),
        capacity_results_medium: capacityMedium,
      })
      const newData = (await initCapacity()) as CapacityData

      if (stateType !== types.SIMULATION && stateType !== types.REDUCE_AMBITION && typology !== 'client') {
        const pushy = await getScore({ data: newData, trackAction })

        if (pushy) {
          setStateType(types.INTERSTITIAL_SUBSCRIBE)
        }
      }
      setLoading(false)
    } catch (error) {
      const path = typology === 'preLead' ? '/project/capacity/introduction' : '/project/choice'
      history.replace(path)
    }
  }

  const trackCapacity = () => {
    const capacity = data?.capacity
    const project = data?.project
    const eventName = 'CAPACITY_RESULTS_PAGE_VIEWED'
    const trackingOptions = {
      capacity_results_cards: capacity?.map(
        ({
          project: {
            purchase: { property_price },
          },
          financing: { loanAmount, loanDuration, mainLoansRate },
        }) => ({
          good_amount: property_price,
          loan_amount: loanAmount,
          loan_duration: loanDuration,
          rate: mainLoansRate,
        })
      ),
      capacity_results_contribution_amount: project?.contribution,
      capacity_results_medium: capacityMedium,
      capacity_results_monthly_payment: payment,
      capacity_results_version: typology,
    }
    if (typology !== 'client') {
      trackAction(eventName, trackingOptions)
    } else {
      getHasBooking(client).then(({ start }) => {
        if (!start) {
          trackAction(eventName, { ...trackingOptions, capacity_results_version: 'client_with_appointment' })
        } else {
          trackAction(eventName, { ...trackingOptions, capacity_results_version: 'client_without_appointment' })
        }
      })
    }
  }

  const launchLoader = () => {
    setLoading(true)
    setMinimumLoading(true)
    loadingTimeout = setTimeout(() => {
      setMinimumLoading(false)
    }, MINIMUM_LOADING_DURATION)
  }

  useEffect(() => {
    setItem(types.SIMULATION_STATE_TYPE, stateType)
  }, [stateType])

  if (error) {
    return <ErrorPage />
  }

  if (isPageLoading) {
    return null
  }

  if (stateType === types.INTERSTITIAL_SUBSCRIBE) {
    const goToResults = () => {
      setStateType(types.SIMULATION)
    }

    const {
      data: {
        project: {
          purchase: { maturity },
        },
      },
    } = projectQuery

    return <Pushy flow="capacity" goToResults={goToResults} maturity={maturity} />
  }

  const handleOpenMutualizedAgenda = () => {
    trackAction('APPOINTMENT_MUTUALIZED_AGENDA_CLICKED')
    setIsMutualizedAgendaOpen(true)
  }

  const handleCloseMutualizedAgenda = () => {
    removeItem(LOCAL_AGENDA_VISIBLE_NAME)
    setIsMutualizedAgendaOpen(false)
  }

  const handleNextClick = () => {
    if (typology !== 'prospect') {
      history.push('/simulation/subscribe')
      return
    }

    history.push('/simulation/subscribe')
  }

  const handleContinue = (event: React.SyntheticEvent<HTMLAnchorElement>, source?: string) => {
    trackAction('CAPACITY_RESULT_CTA_CLICKED', { capacity_result_cta_source: source ?? null })
  }

  const handlePreviousClick = () => {
    const url = isLoggedIn ? '/project/capacity/contribution' : '/project/capacity/property-search/frequency'
    history.push(url)
  }

  const handleBookingInfos = (bookingInfos: BookingInfosParams) => {
    setBookingInfos(bookingInfos)
  }

  const footerProps = isOnboardingDone
    ? null
    : { onNextClick: handleNextClick, onPreviousClick: typology !== 'client' ? handlePreviousClick : null }

  const headerProps = {
    accountVariant: typology === 'preLead' ? 'onlyInitial' : null,
    isMobileHidden: isVersionSuggestions ? typology !== 'client' : typology === 'prospect',
  }

  // ? Sorry for that, it's not elegant but it's a quick fix..
  const continueHref = (withClassicBubble?: boolean) => {
    if (typology === 'client') {
      return '/'
    }

    return withClassicBubble ? '/simulation/subscribe?classic=true' : '/simulation/subscribe'
  }

  return (
    <Fragment>
      <Header {...headerProps} navigationItemsList={navItems} />

      {isVersionClassic && (
        <LayoutStickyFooter>
          <Grid>
            <Responsive min="laptop">
              <Sidebar />
            </Responsive>
            <Content
              bookingInfos={bookingInfos}
              onOpenMutualizedAgenda={handleOpenMutualizedAgenda}
              onNextClick={handleNextClick}
              project={projectQuery}
            />
            <ModalModify />
          </Grid>
          {!!footerProps && <PreviousNextNavBar {...footerProps} />}
        </LayoutStickyFooter>
      )}

      <DialogSave />

      <MutualizedAgenda
        isDriAllowed={scoreZeroMinute?.callBackNowAllowed}
        isOpen={isMutualizedAgendaOpen}
        occurrence={Occurrence.PUSHY}
        onBookingInfos={handleBookingInfos}
        onClose={handleCloseMutualizedAgenda}
        onContinue={handleCloseMutualizedAgenda}
      />

      {isVersionSuggestions && (
        <ResultPage continueHref={continueHref} onContinue={handleContinue} isSuggestionsHidden={isSuggestionsHidden} />
      )}

      <Dialog isOpen={isLanguageInfoOpen} onRequestClose={closeLanguageInfo}>
        <LanguageInfo onClose={closeLanguageInfo} onConfirm={closeLanguageInfo} />
      </Dialog>

      <AdrenaleadIframe type="newLead" isClient={typology === 'client'} />
    </Fragment>
  )
}

CapacityPage.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired,
  onSignUp: PropTypes.func,
  onSubscribe: PropTypes.func,
}

const CapacityContext = (props: Record<string, unknown>) => (
  <Subscribe>
    {context => (
      <DataProvider>
        <SaveProvider {...context}>
          <ControlsProvider>
            <CapacityPage {...props} />
          </ControlsProvider>
        </SaveProvider>
      </DataProvider>
    )}
  </Subscribe>
)

export default memo(CapacityContext)

const getHasBooking = async (client: ApolloClient<object>) => {
  const { data } = await client.query({ query: BOOKING })
  const hasBooking = Boolean(data.next_booking)

  if (!hasBooking) {
    return { isLoading: false }
  }

  const { data: advisorData } = await client.query({ query: ADVISOR })
  const date = temporal(data.next_booking.start).format('dddd LL [à] HH[h]mm')

  return { isLoading: false, name: advisorData.advisor.name, start: date }
}
