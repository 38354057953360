import { ResizableField } from '@pretto/app/src/Sentences/components/ResizableField/ResizableField'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { Result as CityResult, useCityField } from '@pretto/app/src/Sentences/lib/useCityField'
import { Result as CountryResult, useCountryField } from '@pretto/app/src/Sentences/lib/useCountryField'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorLodgingLocationKind, SimulationPropertyUsage } from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'
import { useState } from 'react'

export const AddressPage: React.FC = () => {
  const { simulation, setSimulationMortgagor } = useSentences()
  const mainMortgagor = simulation.profileMortgagors[0]

  const { nextRoute } = useRoutesGraph()
  const defaultValue =
    mainMortgagor.lodgingLocation?.city && mainMortgagor.lodgingLocation.zipcode
      ? `${mainMortgagor.lodgingLocation.city} (${mainMortgagor.lodgingLocation.zipcode})`
      : ''
  const { fieldProps: cityFieldProps, results: cityResults } = useCityField(defaultValue)
  const { fieldProps: countryFieldProps, results: countryResults } = useCountryField(
    mainMortgagor.lodgingLocation?.countryCode
  )
  const [visibleCities, setVisibleCities] = useState(false)
  const [visibleCountries, setVisibleCountries] = useState(false)
  const livesInFrance = mainMortgagor.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.FranceMetropolitan
  const isRentalInvestment = simulation.propertyUsage === SimulationPropertyUsage.RentalInvestment

  const getDefaultActiveField = () => {
    if (isNil(mainMortgagor.lodgingLocation?.countryCode)) {
      return 0
    }
    if (livesInFrance) {
      return 1
    }
    return 2
  }

  const getIsFilled = () => {
    if (!nextRoute) return false
    if (isNil(mainMortgagor.lodgingLocation?.countryCode)) return false
    if (livesInFrance && !mainMortgagor.lodgingLocation?.city) return false
    if (!livesInFrance && !mainMortgagor.lodgingLocation?.countryCode) return false
    return true
  }

  const [activeField, setActiveField] = useActiveField(getDefaultActiveField(), getIsFilled())

  const handleClickOnCity = (value: CityResult['value']) => {
    cityFieldProps.onChange(`${value.city} (${value.zipcode})`)
    setVisibleCities(false)
    setSimulationMortgagor({
      lodgingLocation: { address: null, city: value.city, zipcode: value.zipcode, countryCode: 'fr' },
    })
  }

  const handleCountryOnClick = (value: CountryResult) => {
    const label = value.label.replace(/\s\(.*\)/, '')
    countryFieldProps.onChange(label)
    setVisibleCountries(false)
    setSimulationMortgagor({ lodgingLocation: { address: null, city: null, zipcode: null, countryCode: value.value } })
  }

  const mapResidentValues: MapToChoices<SimulationMortgagorLodgingLocationKind[]> = values =>
    values.map(value => ({
      key: value,
      isSelected: value === mainMortgagor.lodgingLocationKind,
      onClick: () => {
        setVisibleCities(true)
        setActiveField(value === SimulationMortgagorLodgingLocationKind.FranceMetropolitan ? 1 : 2)
        setSimulationMortgagor({ lodgingLocationKind: value })
      },
      label: t(`sentences.lodging.address.values.${value}`),
    }))

  const mapCityValues: MapToChoices<CityResult[]> = values =>
    values.map(({ label, value }) => ({
      key: value.zipcode,
      isSelected:
        mainMortgagor.lodgingLocation?.city === value.city && mainMortgagor.lodgingLocation?.zipcode === value.zipcode,
      onClick: () => handleClickOnCity(value),
      label,
    }))

  const mapCountryValues: MapToChoices<CountryResult[]> = values =>
    values.map(({ label, value }) => ({
      key: value,
      isSelected: mainMortgagor.lodgingLocation?.countryCode === value,
      onClick: () => handleCountryOnClick({ label, value }),
      label,
    }))

  const fields = [
    {
      name: 'resident',
      value: mainMortgagor.lodgingLocation?.countryCode,
      values: mapResidentValues(
        Object.values([
          SimulationMortgagorLodgingLocationKind.FranceMetropolitan,
          SimulationMortgagorLodgingLocationKind.World,
        ])
      ),
    },
    {
      name: 'city',
      value: mainMortgagor.lodgingLocation?.city,
      values: mapCityValues(cityResults),
    },
    {
      name: 'country',
      value: mainMortgagor.lodgingLocation?.countryCode,
      values: mapCountryValues(countryResults),
    },
  ]

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'address' } }}>
      <Title>{t('sentences.lodging.title')}</Title>
      <div>
        {t('sentences.lodging.address.sentence.0')}
        <Prompt
          onClick={() => {
            setActiveField(0)
          }}
          isFilled={!!mainMortgagor.lodgingLocationKind}
        >
          {t(
            `sentences.lodging.address.values.${
              !mainMortgagor.lodgingLocationKind || livesInFrance
                ? SimulationMortgagorLodgingLocationKind.FranceMetropolitan
                : SimulationMortgagorLodgingLocationKind.World
            }`
          )}
        </Prompt>
        {activeField >= 1 && (
          <>
            {t(`sentences.lodging.address.sentence.${livesInFrance ? '1' : '2'}`)}
            {livesInFrance ? (
              <ResizableField
                {...cityFieldProps}
                clearable
                inputProps={{ autoFocus: activeField === 1, placeholder: 'Paris' }}
                onClick={() => {
                  setVisibleCities(true)
                  setActiveField(1)
                }}
              />
            ) : (
              <ResizableField
                {...countryFieldProps}
                clearable
                inputProps={{ autoFocus: activeField === 1, placeholder: t('sentences.lodging.countryPlaceholder') }}
                onClick={() => {
                  setVisibleCountries(true)
                  setActiveField(2)
                }}
              />
            )}
          </>
        )}
        .
      </div>
      {(activeField === 0 || (visibleCities && activeField === 1) || (visibleCountries && activeField === 2)) && (
        <Choices
          choices={fields[activeField].values}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'lodgingAddress' } }}
        />
      )}
      {!isNil(mainMortgagor.lodgingLocation?.countryCode) && !livesInFrance && (
        <Message>{t('sentences.tips.nonResident.currency')}</Message>
      )}
      {!isNil(mainMortgagor.lodgingLocation?.countryCode) && !livesInFrance && isRentalInvestment && (
        <Message>{t('sentences.tips.nonResident.rentalInvestment')}</Message>
      )}
      {((activeField >= 1 && livesInFrance && mainMortgagor.lodgingLocation?.city) ||
        (activeField >= 2 && !livesInFrance && mainMortgagor.lodgingLocation?.countryCode)) && (
        <ButtonNext>{t('sentences.next')}</ButtonNext>
      )}
    </TrackedView>
  )
}
