import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorOccupationPrivateRole } from '@pretto/app/src/types/api/enums'

export const mapPrivateRole: MapToChoices<SimulationMortgagorOccupationPrivateRole[]> = (values, context) => {
  if (!context) return []
  const { simulation, setSimulationComortgagor } = context

  return values.map(value => ({
    key: value,
    isSelected: simulation.profileMortgagors[1].occupationPrivateRole === value,
    onClick: () => {
      setSimulationComortgagor({ occupationPrivateRole: value })
    },
    label: t(`sentences.situation.private.longTerm.executive.values.${value}`),
  }))
}
