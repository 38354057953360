import isFunction from 'lodash/isFunction'
import { useEffect, useRef, useState } from 'react'

/**
 * This useState wrapper exposes a function that returns a promise that resolves
 * when all setState operations are finished.
 * It is useful when you want to perform a setState operation followed by another operation.
 *
 * Exemple:
 * const [value, setValue, waitForOngoingOperations] = useState(2)
 *
 * ...
 * setValue(3)
 * const newValue = await waitForOngoingOperations()
 */
export const useStateOperations = <C>(
  initialState: C
): [C, React.Dispatch<React.SetStateAction<C>>, () => Promise<C>] => {
  interface Operation {
    lastUpdate: number
    state: C
  }

  const isOperationInProgress = useRef(false)
  const nextOperation = useRef<((value: C) => void) | null>(null)

  const [operation, setOperation] = useState<Operation>({ lastUpdate: Date.now(), state: initialState })

  useEffect(() => {
    isOperationInProgress.current = false
    nextOperation.current?.(operation.state)
    nextOperation.current = null
  }, [operation.lastUpdate])

  const operatingSetState: React.Dispatch<React.SetStateAction<C>> = state => {
    isOperationInProgress.current = true

    setOperation(previousOperation => {
      return {
        lastUpdate: Date.now(),
        state: isFunction(state) ? state(previousOperation.state) : state,
      }
    })
  }

  const waitForOngoingOperations = () => {
    if (!isOperationInProgress.current) {
      return Promise.resolve(operation.state)
    }

    return new Promise<C>(resolve => {
      nextOperation.current = resolve
    })
  }

  return [operation.state, operatingSetState, waitForOngoingOperations]
}
