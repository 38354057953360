import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import { SimulationKind } from '@pretto/app/src/types/api/enums'
import type { SolicitedBank } from '@pretto/app/src/types/gateway/schema'

import { mapBank } from './lib/bank'

export const mapCompetition: ComposableMapper = ({ project, simulation }) => {
  if (simulation.kind !== SimulationKind.Purchase || !simulation.competitionHasOffer) {
    return { project, simulation }
  }

  const solicitedBank = {
    get bank_slug() {
      if (!simulation.competitionBank) {
        return null
      }

      return mapBank(simulation.competitionBank)
    },

    duration: simulation.competitionDuration,
    rate: simulation.competitionRate,
  } satisfies SolicitedBank

  return {
    project: {
      ...project,

      solicited_banks: [solicitedBank],
    },
    simulation,
  }
}
