import { temporal } from '@pretto/bricks/core/utility/temporal'

import type { FieldState } from '@pretto/zen/reveal/types/Field'

import { isDateValid, isLessThan, isMoreThan } from '@pretto/app/src/Sentences/lib/date'

export const handleCreditDateState = (
  date?: string | null
): { state: FieldState; message?: 'creditTooLong' | 'invalidDate' | 'paidCredits' } => {
  if (!date) {
    return {
      state: 'default',
    }
  }

  if (!isDateValid(temporal(date).toDate())) {
    return {
      state: 'error',
      message: 'invalidDate',
    }
  }

  if (isMoreThan(temporal(date).toDate(), temporal().add(100, 'year').toDate())) {
    return {
      state: 'warning',
      message: 'creditTooLong',
    }
  }

  if (isLessThan(temporal(date).toDate(), new Date())) {
    return {
      state: 'warning',
      message: 'paidCredits',
    }
  }

  return {
    state: 'default',
  }
}
