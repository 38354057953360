import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorOccupationPublicStatus } from '@pretto/app/src/types/api/enums'

export const mapPublicStatus: MapToChoices<SimulationMortgagorOccupationPublicStatus[]> = (
  values,
  context,
  onSetActive
) => {
  if (!context) return []
  const { simulation, setSimulationComortgagor } = context

  return values.map(value => ({
    key: value,
    isSelected: simulation.profileMortgagors[1].occupationPublicStatus === value,
    onClick: () => {
      setSimulationComortgagor({ occupationPublicStatus: value })
      if (value === SimulationMortgagorOccupationPublicStatus.Contractor && onSetActive) {
        onSetActive(2)
      }
    },
    label: t(`sentences.situation.public.values.${value}`),
  }))
}
