import { ChevronDownBold, ChevronUpBold } from '@pretto/picto'

import { useState } from 'react'

import * as S from './Accordion.styles'

export type AccordionDataType = { title: string; content: string }

export interface AccordionProps {
  data: AccordionDataType[]
  picto?: React.ReactNode
  withPicto?: boolean
  onClickTrack?: (item: AccordionDataType) => void
}

export const Accordion: React.FC<AccordionProps & React.HTMLAttributes<HTMLDivElement>> = ({
  data,
  picto,
  withPicto,
  onClickTrack,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean[]>(data.map(() => false))

  const handleButtonClick = (index: number) => {
    setIsExpanded(prev => prev.map((value, i) => (i === index ? !value : value)))
  }

  return (
    <S.AccordionContainer>
      {data.map((item, index) => (
        <div key={index} onClick={onClickTrack?.bind(null, item)}>
          <S.AccordionButton onClick={() => handleButtonClick(index)}>
            <S.AccordionButtonContent>
              {withPicto ? <S.AccordionPictogram>{picto}</S.AccordionPictogram> : null}
              <S.AccordionTitle>{item.title}</S.AccordionTitle>
            </S.AccordionButtonContent>
            <S.AccordionIcon>{isExpanded[index] ? <ChevronUpBold /> : <ChevronDownBold />}</S.AccordionIcon>
          </S.AccordionButton>

          {isExpanded[index] ? (
            <S.AccordionPanel>
              <S.AccordionPanelText>{item.content}</S.AccordionPanelText>
            </S.AccordionPanel>
          ) : null}
        </div>
      ))}
    </S.AccordionContainer>
  )
}
