import { g } from '@pretto/bricks/components/layout'

import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationCreditKind } from '@pretto/app/src/types/api/enums'

import styled from 'styled-components'

import { CreditBoxSummary } from './CreditBoxSummary'
import { Container, Title } from './Styled'

const RenegoTitle = styled(Title)`
  margin-bottom: ${g(1)};
`

const MortgageCredit = styled(Prompt)`
  margin: 0;
  padding: 0;
`

export const RenegotiationCredit: React.FC = () => {
  const { simulation } = useSentences()

  if (!simulation.renegotiationLoanPayment) {
    return null
  }

  return (
    <Container $isEditable={false}>
      <RenegoTitle>{t('sentences.charge.credits.subtitle.renegotiation')}</RenegoTitle>
      <MortgageCredit isFilled>
        {' '}
        {t(`sentences.charge.credits.values.${SimulationCreditKind.Mortgage}`)}
      </MortgageCredit>{' '}
      <CreditBoxSummary
        payment={Math.round(simulation.renegotiationLoanPayment)}
        kind={SimulationCreditKind.Mortgage}
      />
    </Container>
  )
}
