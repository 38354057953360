import type { FieldProps } from '@pretto/zen/reveal/types/Field'

import { Bank } from '@pretto/app/src/types/api/enums'
import BANKS from '@pretto/config/banks.json'

import fuzzysort from 'fuzzysort'
import latinize from 'latinize'
import { useState } from 'react'

type Label = (typeof BANKS)[number]['label']

export type Result<L extends Label = Label> = Extract<(typeof BANKS)[number], { label: L }> extends infer B
  ? B extends { value: infer V }
    ? {
        label: L
        value: Uppercase<V & string> extends `${Bank}` ? Extract<Bank, Uppercase<V & string>> : Bank.Autre
      }
    : never
  : never

type UseBankField = (defaultBank?: Bank) => {
  fieldProps: FieldProps
  results: Result[]
}

const normalizedLatinize = (str: string) => latinize(str.replace(/’/g, "'"))

const targets = BANKS.map(({ label }) => fuzzysort.prepare(normalizedLatinize(label)))

export const useBankField: UseBankField = defaultBank => {
  const defaultValue = BANKS.find(({ value }) => value === defaultBank?.toLowerCase())?.label ?? ''

  const [searchValue, setSearchValue] = useState(defaultValue)

  const handleChange = (value: string) => {
    setSearchValue(value)
  }

  const results =
    searchValue === ''
      ? []
      : fuzzysort.go(normalizedLatinize(searchValue), targets).reduce((previous, { target }) => {
          const bankConfig = BANKS.find(({ label }) => {
            return normalizedLatinize(label) === target
          })

          if (!bankConfig) {
            return previous
          }

          const label = bankConfig.label

          const value =
            Object.entries(Bank).find(([, bank]) => bank === bankConfig.value.toUpperCase())?.[1] ?? Bank.Autre

          return [...previous, { label, value } as Result]
        }, [] as Result[])

  return {
    fieldProps: {
      onChange: handleChange,
      value: searchValue,
    },
    results,
  }
}
