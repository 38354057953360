import LinkComponent from '@pretto/bricks/website/utility/Link'

import { ResizableField } from '@pretto/app/src/Sentences/components/ResizableField/ResizableField'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { InfoMessage } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { Result as CityResult, useCityField } from '@pretto/app/src/Sentences/lib/useCityField'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationKind, SimulationPropertyUsage } from '@pretto/app/src/types/api/enums'

import { useState } from 'react'
import styled from 'styled-components'

export const Link = styled(LinkComponent)`
  text-decoration: underline;
`

export const PropertyUsagePage: React.FC = () => {
  const { simulation, setSimulation } = useSentences()

  const { nextRoute } = useRoutesGraph()

  const defaultValue =
    simulation.propertyLocation?.city && simulation.propertyLocation?.zipcode
      ? `${simulation.propertyLocation.city} (${simulation.propertyLocation.zipcode})`
      : ''

  const { fieldProps: cityFieldProps, results: cityResults } = useCityField(defaultValue)

  const [visibleCities, setVisibleCities] = useState(false)

  const [activeField, setActiveField] = useActiveField(
    simulation.propertyLocation?.city && simulation.propertyLocation?.zipcode ? 1 : 0,
    Boolean(
      nextRoute && simulation.propertyUsage && simulation.propertyLocation?.city && simulation.propertyLocation?.zipcode
    )
  )

  const handleClickOnCity = (value: CityResult['value']) => {
    cityFieldProps.onChange(`${value.city} (${value.zipcode})`)
    setVisibleCities(false)
    setSimulation({
      propertyLocation: {
        address: null,
        city: value.city,
        countryCode: 'fr',
        zipcode: value.zipcode,
      },
    })
  }

  const mapUsageValues: MapToChoices<SimulationPropertyUsage[]> = values => {
    return values.map(propertyUsage => ({
      key: propertyUsage,
      isSelected: simulation.propertyUsage === propertyUsage,
      onClick: () => {
        setSimulation({ propertyUsage })

        if (
          propertyUsage === SimulationPropertyUsage.PrimaryResidence ||
          propertyUsage === SimulationPropertyUsage.SecondaryResidence ||
          propertyUsage === SimulationPropertyUsage.RentalInvestment
        ) {
          setActiveField(1)
          setVisibleCities(true)
        }
      },
      label: t(`sentences.introduction.propertyUsage.values.${propertyUsage}`),
    }))
  }

  const mapCityValues: MapToChoices<CityResult[]> = values =>
    values.map(({ label, value }) => ({
      key: value.zipcode,
      isSelected:
        simulation.propertyLocation?.city === value.city && simulation.propertyLocation?.zipcode === value.zipcode,
      onClick: () => handleClickOnCity(value),
      label,
    }))

  const propertyUsageSentenceType =
    simulation.kind &&
    [SimulationKind.Buyout, SimulationKind.Purchase, SimulationKind.Renegotiation].includes(simulation.kind)
      ? simulation.kind
      : SimulationKind.Purchase

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'propertyUsage' } }}>
      <Title>{t('sentences.introduction.title')}</Title>

      <div>
        {t(`sentences.introduction.propertyUsage.sentence.${propertyUsageSentenceType}`)}
        <Prompt isFilled={!!simulation.propertyUsage} onClick={() => setActiveField(0)}>
          {t(
            `sentences.introduction.propertyUsage.values.${
              simulation.propertyUsage || SimulationPropertyUsage.PrimaryResidence
            }`
          )}
        </Prompt>
        {activeField > 0 && (
          <>
            {t('sentences.introduction.propertyLocalisation.sentence')}
            <ResizableField
              {...cityFieldProps}
              clearable
              inputProps={{ autoFocus: activeField === 1, placeholder: 'Paris' }}
              onClick={() => {
                setVisibleCities(true)
                setActiveField(1)
              }}
            />
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <>
          <Choices
            choices={mapUsageValues(
              Object.values(
                simulation.kind === SimulationKind.Buyout || simulation.kind === SimulationKind.Renegotiation
                  ? [
                      SimulationPropertyUsage.PrimaryResidence,
                      SimulationPropertyUsage.SecondaryResidence,
                      SimulationPropertyUsage.RentalInvestment,
                    ]
                  : [
                      SimulationPropertyUsage.PrimaryResidence,
                      SimulationPropertyUsage.SecondaryResidence,
                      SimulationPropertyUsage.RentalInvestment,
                      SimulationPropertyUsage.ProfessionalCommercialProject,
                      SimulationPropertyUsage.MixedUseProject,
                    ]
              )
            )}
            events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'usage' } }}
          />

          {simulation.propertyUsage && [SimulationPropertyUsage.MixedUseProject].includes(simulation.propertyUsage) && (
            <InfoMessage>
              {t(`sentences.introduction.propertyUsage.info.${SimulationPropertyUsage.MixedUseProject}`)}{' '}
              <Link href="https://courtier.pretto.fr/projet-credit-pro">
                {t('sentences.introduction.propertyUsage.form')}
              </Link>
            </InfoMessage>
          )}

          {simulation.propertyUsage &&
            [SimulationPropertyUsage.ProfessionalCommercialProject].includes(simulation.propertyUsage) && (
              <InfoMessage>
                {t(
                  `sentences.introduction.propertyUsage.info.${SimulationPropertyUsage.ProfessionalCommercialProject}`
                )}{' '}
                <Link href="https://courtier.pretto.fr/projet-credit-pro">
                  {t('sentences.introduction.propertyUsage.form')}
                </Link>
              </InfoMessage>
            )}
        </>
      )}

      {visibleCities && activeField === 1 && (
        <Choices
          choices={mapCityValues(cityResults)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'propertyCity' } }}
        />
      )}

      {activeField >= 1 && simulation.propertyUsage && simulation.propertyLocation?.city && (
        <ButtonNext>{t('sentences.next')}</ButtonNext>
      )}
    </TrackedView>
  )
}
