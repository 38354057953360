/** The basic AccountBankGroup type */
export enum AccountBankGroup {
  AllianzBanque = 'allianz_banque',
  Autre = 'autre',
  AxaBanque = 'axa_banque',
  BanqueCourtois = 'banque_courtois',
  BanqueFiducial = 'banque_fiducial',
  BanqueKolb = 'banque_kolb',
  BanqueLaydernier = 'banque_laydernier',
  BanqueMilleis = 'banque_milleis',
  BanqueNuger = 'banque_nuger',
  BanquePopulaireAlsace = 'banque_populaire_alsace',
  BanquePopulaireAquitaine = 'banque_populaire_aquitaine',
  BanquePopulaireAuvergne = 'banque_populaire_auvergne',
  BanquePopulaireBourgogne = 'banque_populaire_bourgogne',
  BanquePopulaireCasden = 'banque_populaire_casden',
  BanquePopulaireGrandOuest = 'banque_populaire_grand_ouest',
  BanquePopulaireMediterranee = 'banque_populaire_mediterranee',
  BanquePopulaireNord = 'banque_populaire_nord',
  BanquePopulaireOccitane = 'banque_populaire_occitane',
  BanquePopulaireRivesParis = 'banque_populaire_rives_paris',
  BanquePopulaireSud = 'banque_populaire_sud',
  BanquePopulaireValDeFrance = 'banque_populaire_val_de_france',
  BanqueRhonesalpes = 'banque_rhonesalpes',
  BanqueSavoie = 'banque_savoie',
  BanqueTarneaud = 'banque_tarneaud',
  BanqueTransatlantique = 'banque_transatlantique',
  Barclays = 'barclays',
  Bcp = 'bcp',
  Bfm = 'bfm',
  Bforbank = 'bforbank',
  BnpParibas = 'bnp_paribas',
  Boursorama = 'boursorama',
  Bred = 'bred',
  CaisseDEpargneAquitainePoitoucharentes = 'caisse_d_epargne_aquitaine_poitoucharentes',
  CaisseDEpargneAuvergneLimousin = 'caisse_d_epargne_auvergne_limousin',
  CaisseDEpargneBourgogneFranchecomte = 'caisse_d_epargne_bourgogne_franchecomte',
  CaisseDEpargneBretagnePaysdeloire = 'caisse_d_epargne_bretagne_paysdeloire',
  CaisseDEpargneCepac = 'caisse_d_epargne_cepac',
  CaisseDEpargneCotedazur = 'caisse_d_epargne_cotedazur',
  CaisseDEpargneGrandestEurope = 'caisse_d_epargne_grandest_europe',
  CaisseDEpargneHautsdefrance = 'caisse_d_epargne_hautsdefrance',
  CaisseDEpargneIledefrance = 'caisse_d_epargne_iledefrance',
  CaisseDEpargneLanguedocRoussillon = 'caisse_d_epargne_languedoc_roussillon',
  CaisseDEpargneLoireDromeArdeche = 'caisse_d_epargne_loire_drome_ardeche',
  CaisseDEpargneLoirecentre = 'caisse_d_epargne_loirecentre',
  CaisseDEpargneMidipyrenees = 'caisse_d_epargne_midipyrenees',
  CaisseDEpargneNormandie = 'caisse_d_epargne_normandie',
  CaisseDEpargneRhonealpes = 'caisse_d_epargne_rhonealpes',
  Cetelem = 'cetelem',
  Cgd = 'cgd',
  Chaabi = 'chaabi',
  Cic = 'cic',
  CreditAgricoleAlpesprovence = 'credit_agricole_alpesprovence',
  CreditAgricoleAlsacevosges = 'credit_agricole_alsacevosges',
  CreditAgricoleAnjoumaine = 'credit_agricole_anjoumaine',
  CreditAgricoleAquitaine = 'credit_agricole_aquitaine',
  CreditAgricoleAtlantiqueVendee = 'credit_agricole_atlantique_vendee',
  CreditAgricoleBriepicardie = 'credit_agricole_briepicardie',
  CreditAgricoleCentreest = 'credit_agricole_centreest',
  CreditAgricoleCentrefrance = 'credit_agricole_centrefrance',
  CreditAgricoleCentreloire = 'credit_agricole_centreloire',
  CreditAgricoleCentreouest = 'credit_agricole_centreouest',
  CreditAgricoleChampagneBourgogne = 'credit_agricole_champagne_bourgogne',
  CreditAgricoleCharentemaritimeDeuxsevres = 'credit_agricole_charentemaritime_deuxsevres',
  CreditAgricoleCharenteperigord = 'credit_agricole_charenteperigord',
  CreditAgricoleCorse = 'credit_agricole_corse',
  CreditAgricoleCotesdarmor = 'credit_agricole_cotesdarmor',
  CreditAgricoleFinistere = 'credit_agricole_finistere',
  CreditAgricoleFranchecomte = 'credit_agricole_franchecomte',
  CreditAgricoleGuadeloupe = 'credit_agricole_guadeloupe',
  CreditAgricoleIledefrance = 'credit_agricole_iledefrance',
  CreditAgricoleIleetvilaine = 'credit_agricole_ileetvilaine',
  CreditAgricoleLanguedoc = 'credit_agricole_languedoc',
  CreditAgricoleLareunion = 'credit_agricole_lareunion',
  CreditAgricoleLoireHauteloire = 'credit_agricole_loire_hauteloire',
  CreditAgricoleLorraine = 'credit_agricole_lorraine',
  CreditAgricoleMartiniqueGuyane = 'credit_agricole_martinique_guyane',
  CreditAgricoleMorbihan = 'credit_agricole_morbihan',
  CreditAgricoleNordMidipyrenees = 'credit_agricole_nord_midipyrenees',
  CreditAgricoleNordest = 'credit_agricole_nordest',
  CreditAgricoleNordfrance = 'credit_agricole_nordfrance',
  CreditAgricoleNormandie = 'credit_agricole_normandie',
  CreditAgricoleNormandieseine = 'credit_agricole_normandieseine',
  CreditAgricoleProvenceCotedazur = 'credit_agricole_provence_cotedazur',
  CreditAgricolePyreneesGascogne = 'credit_agricole_pyrenees_gascogne',
  CreditAgricoleSavoie = 'credit_agricole_savoie',
  CreditAgricoleSudmediterranee = 'credit_agricole_sudmediterranee',
  CreditAgricoleSudrhonealpes = 'credit_agricole_sudrhonealpes',
  CreditAgricoleToulouse31 = 'credit_agricole_toulouse31',
  CreditAgricoleTourainepoitou = 'credit_agricole_tourainepoitou',
  CreditAgricoleValdefrance = 'credit_agricole_valdefrance',
  CreditCooperatif = 'credit_cooperatif',
  CreditDuNord = 'credit_du_nord',
  CreditFoncier = 'credit_foncier',
  CreditMutuelAnjou = 'credit_mutuel_anjou',
  CreditMutuelBretagne = 'credit_mutuel_bretagne',
  CreditMutuelCentre = 'credit_mutuel_centre',
  CreditMutuelCentreesteurope = 'credit_mutuel_centreesteurope',
  CreditMutuelDauphinevivarais = 'credit_mutuel_dauphinevivarais',
  CreditMutuelIledefrance = 'credit_mutuel_iledefrance',
  CreditMutuelLoireatlantiqueCentreouest = 'credit_mutuel_loireatlantique_centreouest',
  CreditMutuelMassifcentral = 'credit_mutuel_massifcentral',
  CreditMutuelMediterraneen = 'credit_mutuel_mediterraneen',
  CreditMutuelMidiatlantique = 'credit_mutuel_midiatlantique',
  CreditMutuelNordeurope = 'credit_mutuel_nordeurope',
  CreditMutuelNormandie = 'credit_mutuel_normandie',
  CreditMutuelOcean = 'credit_mutuel_ocean',
  CreditMutuelSavoiemontblanc = 'credit_mutuel_savoiemontblanc',
  CreditMutuelSudest = 'credit_mutuel_sudest',
  CreditMutuelSudouest = 'credit_mutuel_sudouest',
  Czam = 'czam',
  Fortuneo = 'fortuneo',
  HelloBank = 'hello_bank',
  Hsbc = 'hsbc',
  IngDirect = 'ing_direct',
  JpMorgan = 'jp_morgan',
  LaBanquePostale = 'la_banque_postale',
  Lcl = 'lcl',
  Mafrenchbank = 'mafrenchbank',
  Monabanq = 'monabanq',
  Montepaschi = 'montepaschi',
  N26 = 'n26',
  Neuflize = 'neuflize',
  Nickel = 'nickel',
  OrangeBank = 'orange_bank',
  Palatine = 'palatine',
  Qonto = 'qonto',
  Revolut = 'revolut',
  Shine = 'shine',
  Smc = 'smc',
  SocieteGenerale = 'societe_generale',
  Socredo = 'socredo',
  Socrif = 'socrif'
}

/** The possible roles of an advisor */
export enum AdvisorRole {
  Expert = 'expert',
  Sdr = 'sdr'
}

/** The basic AgreementEnum type */
export enum AgreementEnum {
  Approved = 'approved',
  Pending = 'pending',
  Promoted = 'promoted'
}

export enum BankSlug {
  AllianzBanque = 'allianz_banque',
  Autre = 'autre',
  AxaBanque = 'axa_banque',
  BankOfAmerica = 'bank_of_america',
  BankOfChina = 'bank_of_china',
  BanqueCasden = 'banque_casden',
  BanqueChalus = 'banque_chalus',
  BanqueCourtois = 'banque_courtois',
  BanqueFiducial = 'banque_fiducial',
  BanqueKolb = 'banque_kolb',
  BanqueLaydernier = 'banque_laydernier',
  BanqueMilleis = 'banque_milleis',
  BanqueNuger = 'banque_nuger',
  BanquePopulaireAlsace = 'banque_populaire_alsace',
  BanquePopulaireAquitaine = 'banque_populaire_aquitaine',
  BanquePopulaireAuvergne = 'banque_populaire_auvergne',
  BanquePopulaireBourgogne = 'banque_populaire_bourgogne',
  BanquePopulaireCasden = 'banque_populaire_casden',
  BanquePopulaireGrandOuest = 'banque_populaire_grand_ouest',
  BanquePopulaireMediterranee = 'banque_populaire_mediterranee',
  BanquePopulaireMediterraneeNonResident = 'banque_populaire_mediterranee_non_resident',
  BanquePopulaireNord = 'banque_populaire_nord',
  BanquePopulaireOccitane = 'banque_populaire_occitane',
  BanquePopulaireRivesParis = 'banque_populaire_rives_paris',
  BanquePopulaireSud = 'banque_populaire_sud',
  BanquePopulaireValDeFrance = 'banque_populaire_val_de_france',
  BanqueRhonesalpes = 'banque_rhonesalpes',
  BanqueSavoie = 'banque_savoie',
  BanqueTarneaud = 'banque_tarneaud',
  BanqueTransatlantique = 'banque_transatlantique',
  Barclays = 'barclays',
  Bcp = 'bcp',
  Bfm = 'bfm',
  Bforbank = 'bforbank',
  BnpParibas = 'bnp_paribas',
  Boursorama = 'boursorama',
  Bred = 'bred',
  CaisseDEpargneAquitainePoitoucharentes = 'caisse_d_epargne_aquitaine_poitoucharentes',
  CaisseDEpargneAuvergneLimousin = 'caisse_d_epargne_auvergne_limousin',
  CaisseDEpargneBourgogneFranchecomte = 'caisse_d_epargne_bourgogne_franchecomte',
  CaisseDEpargneBretagnePaysdeloire = 'caisse_d_epargne_bretagne_paysdeloire',
  CaisseDEpargneCepac = 'caisse_d_epargne_cepac',
  CaisseDEpargneCotedazur = 'caisse_d_epargne_cotedazur',
  CaisseDEpargneGrandestEurope = 'caisse_d_epargne_grandest_europe',
  CaisseDEpargneHautsdefrance = 'caisse_d_epargne_hautsdefrance',
  CaisseDEpargneIledefrance = 'caisse_d_epargne_iledefrance',
  CaisseDEpargneLanguedocRoussillon = 'caisse_d_epargne_languedoc_roussillon',
  CaisseDEpargneLoireDromeArdeche = 'caisse_d_epargne_loire_drome_ardeche',
  CaisseDEpargneLoirecentre = 'caisse_d_epargne_loirecentre',
  CaisseDEpargneMidipyrenees = 'caisse_d_epargne_midipyrenees',
  CaisseDEpargneNormandie = 'caisse_d_epargne_normandie',
  CaisseDEpargneRhonealpes = 'caisse_d_epargne_rhonealpes',
  Ccf = 'ccf',
  CcfNonResident = 'ccf_non_resident',
  Cetelem = 'cetelem',
  Cgd = 'cgd',
  Chaabi = 'chaabi',
  Cic = 'cic',
  CityBank = 'city_bank',
  CreditAgricoleAlpesprovence = 'credit_agricole_alpesprovence',
  CreditAgricoleAlsacevosges = 'credit_agricole_alsacevosges',
  CreditAgricoleAnjoumaine = 'credit_agricole_anjoumaine',
  CreditAgricoleAquitaine = 'credit_agricole_aquitaine',
  CreditAgricoleAtlantiqueVendee = 'credit_agricole_atlantique_vendee',
  CreditAgricoleBriepicardie = 'credit_agricole_briepicardie',
  CreditAgricoleCentreest = 'credit_agricole_centreest',
  CreditAgricoleCentrefrance = 'credit_agricole_centrefrance',
  CreditAgricoleCentreloire = 'credit_agricole_centreloire',
  CreditAgricoleCentreouest = 'credit_agricole_centreouest',
  CreditAgricoleChampagneBourgogne = 'credit_agricole_champagne_bourgogne',
  CreditAgricoleCharentemaritimeDeuxsevres = 'credit_agricole_charentemaritime_deuxsevres',
  CreditAgricoleCharenteperigord = 'credit_agricole_charenteperigord',
  CreditAgricoleConsumerfinance = 'credit_agricole_consumerfinance',
  CreditAgricoleCorse = 'credit_agricole_corse',
  CreditAgricoleCotesdarmor = 'credit_agricole_cotesdarmor',
  CreditAgricoleFinistere = 'credit_agricole_finistere',
  CreditAgricoleFranchecomte = 'credit_agricole_franchecomte',
  CreditAgricoleGuadeloupe = 'credit_agricole_guadeloupe',
  CreditAgricoleIledefrance = 'credit_agricole_iledefrance',
  CreditAgricoleIleetvilaine = 'credit_agricole_ileetvilaine',
  CreditAgricoleLanguedoc = 'credit_agricole_languedoc',
  CreditAgricoleLareunion = 'credit_agricole_lareunion',
  CreditAgricoleLoireHauteloire = 'credit_agricole_loire_hauteloire',
  CreditAgricoleLorraine = 'credit_agricole_lorraine',
  CreditAgricoleMartiniqueGuyane = 'credit_agricole_martinique_guyane',
  CreditAgricoleMorbihan = 'credit_agricole_morbihan',
  CreditAgricoleNordMidipyrenees = 'credit_agricole_nord_midipyrenees',
  CreditAgricoleNordest = 'credit_agricole_nordest',
  CreditAgricoleNordfrance = 'credit_agricole_nordfrance',
  CreditAgricoleNormandie = 'credit_agricole_normandie',
  CreditAgricoleNormandieseine = 'credit_agricole_normandieseine',
  CreditAgricoleProvenceCotedazur = 'credit_agricole_provence_cotedazur',
  CreditAgricolePyreneesGascogne = 'credit_agricole_pyrenees_gascogne',
  CreditAgricoleSavoie = 'credit_agricole_savoie',
  CreditAgricoleSudmediterranee = 'credit_agricole_sudmediterranee',
  CreditAgricoleSudrhonealpes = 'credit_agricole_sudrhonealpes',
  CreditAgricoleToulouse31 = 'credit_agricole_toulouse31',
  CreditAgricoleTourainepoitou = 'credit_agricole_tourainepoitou',
  CreditAgricoleValdefrance = 'credit_agricole_valdefrance',
  CreditCooperatif = 'credit_cooperatif',
  CreditDuNord = 'credit_du_nord',
  CreditFoncier = 'credit_foncier',
  CreditFoncierCommunalAlsaceLorraine = 'credit_foncier_communal_alsace_lorraine',
  CreditMunicipalToulon = 'credit_municipal_toulon',
  CreditMutuelAnjou = 'credit_mutuel_anjou',
  CreditMutuelAntillesGuyane = 'credit_mutuel_antilles_guyane',
  CreditMutuelBretagne = 'credit_mutuel_bretagne',
  CreditMutuelCentre = 'credit_mutuel_centre',
  CreditMutuelCentreesteurope = 'credit_mutuel_centreesteurope',
  CreditMutuelDauphinevivarais = 'credit_mutuel_dauphinevivarais',
  CreditMutuelEnseignant = 'credit_mutuel_enseignant',
  CreditMutuelIledefrance = 'credit_mutuel_iledefrance',
  CreditMutuelLoireatlantiqueCentreouest = 'credit_mutuel_loireatlantique_centreouest',
  CreditMutuelMassifcentral = 'credit_mutuel_massifcentral',
  CreditMutuelMediterraneen = 'credit_mutuel_mediterraneen',
  CreditMutuelMidiatlantique = 'credit_mutuel_midiatlantique',
  CreditMutuelNordeurope = 'credit_mutuel_nordeurope',
  CreditMutuelNormandie = 'credit_mutuel_normandie',
  CreditMutuelOcean = 'credit_mutuel_ocean',
  CreditMutuelSavoiemontblanc = 'credit_mutuel_savoiemontblanc',
  CreditMutuelSudest = 'credit_mutuel_sudest',
  CreditMutuelSudouest = 'credit_mutuel_sudouest',
  Fortuneo = 'fortuneo',
  HelloBank = 'hello_bank',
  Hsbc = 'hsbc',
  IngDirect = 'ing_direct',
  JpMorgan = 'jp_morgan',
  LaBanquePostale = 'la_banque_postale',
  Lcl = 'lcl',
  LouvreBanquePrivee = 'louvre_banque_privee',
  Mafrenchbank = 'mafrenchbank',
  MemoBank = 'memo_bank',
  Monabanq = 'monabanq',
  Montepaschi = 'montepaschi',
  N26 = 'n26',
  Neuflize = 'neuflize',
  Nickel = 'nickel',
  OrangeBank = 'orange_bank',
  Palatine = 'palatine',
  Qonto = 'qonto',
  Revolut = 'revolut',
  Sbe = 'sbe',
  Shine = 'shine',
  Smc = 'smc',
  SocieteGenerale = 'societe_generale',
  Socredo = 'socredo',
  Socrif = 'socrif'
}

/** The basic BankingKind type */
export enum BankingKind {
  EnLigne = 'en_ligne',
  Nationale = 'nationale',
  Regionale = 'regionale',
  Specialiste = 'specialiste'
}

/** The basic Becoming type */
export enum Becoming {
  Ignored = 'ignored',
  Kept = 'kept',
  Paid = 'paid',
  Repurchased = 'repurchased',
  Unsmoothed = 'unsmoothed'
}

/** The basic BrokerList type */
export enum BrokerList {
  Abc = 'abc',
  Ace = 'ace',
  Actionlogement = 'actionlogement',
  Artemis = 'artemis',
  Boursedescredits = 'boursedescredits',
  Cafpi = 'cafpi',
  Centraledefinancement = 'centraledefinancement',
  Creditadvisor = 'creditadvisor',
  Credixia = 'credixia',
  Csf = 'csf',
  Cyberpret = 'cyberpret',
  Empruntis = 'empruntis',
  Hellopret = 'hellopret',
  Immopret = 'immopret',
  Lesfurets = 'lesfurets',
  Lowtaux = 'lowtaux',
  Meilleurtaux = 'meilleurtaux',
  Meilleurtauxcom = 'meilleurtauxcom',
  Monemprunt = 'monemprunt',
  Other = 'other',
  Vousfinancer = 'vousfinancer'
}

/** The basic Contract type */
export enum Contract {
  BusinessOwner = 'business_owner',
  CasualShowBusinessWorker = 'casual_show_business_worker',
  Childminder = 'childminder',
  ContractuelCdd = 'contractuel_cdd',
  ContractuelCdi = 'contractuel_cdi',
  Craftsman = 'craftsman',
  Doctor = 'doctor',
  Farmer = 'farmer',
  FixedTerm = 'fixed_term',
  Interim = 'interim',
  Internship = 'internship',
  Jobless = 'jobless',
  Jobseeker = 'jobseeker',
  Lawyer = 'lawyer',
  Liberal = 'liberal',
  Military = 'military',
  ParentalLeave = 'parental_leave',
  PermanentExecutive = 'permanent_executive',
  PermanentExecutiveTrial = 'permanent_executive_trial',
  PermanentNonExecutive = 'permanent_non_executive',
  PermanentNonExecutiveTrial = 'permanent_non_executive_trial',
  PermanentUnspecified = 'permanent_unspecified',
  PortageSalarial = 'portage_salarial',
  Retired = 'retired',
  SalariedDirector = 'salaried_director',
  SelfEmployed = 'self_employed',
  Student = 'student',
  Tenure = 'tenure',
  Trader = 'trader'
}

/** The basic CreditBankGroup type */
export enum CreditBankGroup {
  AllianzBanque = 'allianz_banque',
  Autre = 'autre',
  AxaBanque = 'axa_banque',
  BanqueCourtois = 'banque_courtois',
  BanqueFiducial = 'banque_fiducial',
  BanqueKolb = 'banque_kolb',
  BanqueLaydernier = 'banque_laydernier',
  BanqueMilleis = 'banque_milleis',
  BanqueNuger = 'banque_nuger',
  BanquePopulaireAlsace = 'banque_populaire_alsace',
  BanquePopulaireAquitaine = 'banque_populaire_aquitaine',
  BanquePopulaireAuvergne = 'banque_populaire_auvergne',
  BanquePopulaireBourgogne = 'banque_populaire_bourgogne',
  BanquePopulaireCasden = 'banque_populaire_casden',
  BanquePopulaireGrandOuest = 'banque_populaire_grand_ouest',
  BanquePopulaireMediterranee = 'banque_populaire_mediterranee',
  BanquePopulaireNord = 'banque_populaire_nord',
  BanquePopulaireOccitane = 'banque_populaire_occitane',
  BanquePopulaireRivesParis = 'banque_populaire_rives_paris',
  BanquePopulaireSud = 'banque_populaire_sud',
  BanquePopulaireValDeFrance = 'banque_populaire_val_de_france',
  BanqueRhonesalpes = 'banque_rhonesalpes',
  BanqueSavoie = 'banque_savoie',
  BanqueTarneaud = 'banque_tarneaud',
  BanqueTransatlantique = 'banque_transatlantique',
  Barclays = 'barclays',
  Bcp = 'bcp',
  Bfm = 'bfm',
  Bforbank = 'bforbank',
  BnpParibas = 'bnp_paribas',
  Boursorama = 'boursorama',
  Bred = 'bred',
  CaisseDEpargneAquitainePoitoucharentes = 'caisse_d_epargne_aquitaine_poitoucharentes',
  CaisseDEpargneAuvergneLimousin = 'caisse_d_epargne_auvergne_limousin',
  CaisseDEpargneBourgogneFranchecomte = 'caisse_d_epargne_bourgogne_franchecomte',
  CaisseDEpargneBretagnePaysdeloire = 'caisse_d_epargne_bretagne_paysdeloire',
  CaisseDEpargneCepac = 'caisse_d_epargne_cepac',
  CaisseDEpargneCotedazur = 'caisse_d_epargne_cotedazur',
  CaisseDEpargneGrandestEurope = 'caisse_d_epargne_grandest_europe',
  CaisseDEpargneHautsdefrance = 'caisse_d_epargne_hautsdefrance',
  CaisseDEpargneIledefrance = 'caisse_d_epargne_iledefrance',
  CaisseDEpargneLanguedocRoussillon = 'caisse_d_epargne_languedoc_roussillon',
  CaisseDEpargneLoireDromeArdeche = 'caisse_d_epargne_loire_drome_ardeche',
  CaisseDEpargneLoirecentre = 'caisse_d_epargne_loirecentre',
  CaisseDEpargneMidipyrenees = 'caisse_d_epargne_midipyrenees',
  CaisseDEpargneNormandie = 'caisse_d_epargne_normandie',
  CaisseDEpargneRhonealpes = 'caisse_d_epargne_rhonealpes',
  Cetelem = 'cetelem',
  Cgd = 'cgd',
  Chaabi = 'chaabi',
  Cic = 'cic',
  CreditAgricoleAlpesprovence = 'credit_agricole_alpesprovence',
  CreditAgricoleAlsacevosges = 'credit_agricole_alsacevosges',
  CreditAgricoleAnjoumaine = 'credit_agricole_anjoumaine',
  CreditAgricoleAquitaine = 'credit_agricole_aquitaine',
  CreditAgricoleAtlantiqueVendee = 'credit_agricole_atlantique_vendee',
  CreditAgricoleBriepicardie = 'credit_agricole_briepicardie',
  CreditAgricoleCentreest = 'credit_agricole_centreest',
  CreditAgricoleCentrefrance = 'credit_agricole_centrefrance',
  CreditAgricoleCentreloire = 'credit_agricole_centreloire',
  CreditAgricoleCentreouest = 'credit_agricole_centreouest',
  CreditAgricoleChampagneBourgogne = 'credit_agricole_champagne_bourgogne',
  CreditAgricoleCharentemaritimeDeuxsevres = 'credit_agricole_charentemaritime_deuxsevres',
  CreditAgricoleCharenteperigord = 'credit_agricole_charenteperigord',
  CreditAgricoleCorse = 'credit_agricole_corse',
  CreditAgricoleCotesdarmor = 'credit_agricole_cotesdarmor',
  CreditAgricoleFinistere = 'credit_agricole_finistere',
  CreditAgricoleFranchecomte = 'credit_agricole_franchecomte',
  CreditAgricoleGuadeloupe = 'credit_agricole_guadeloupe',
  CreditAgricoleIledefrance = 'credit_agricole_iledefrance',
  CreditAgricoleIleetvilaine = 'credit_agricole_ileetvilaine',
  CreditAgricoleLanguedoc = 'credit_agricole_languedoc',
  CreditAgricoleLareunion = 'credit_agricole_lareunion',
  CreditAgricoleLoireHauteloire = 'credit_agricole_loire_hauteloire',
  CreditAgricoleLorraine = 'credit_agricole_lorraine',
  CreditAgricoleMartiniqueGuyane = 'credit_agricole_martinique_guyane',
  CreditAgricoleMorbihan = 'credit_agricole_morbihan',
  CreditAgricoleNordMidipyrenees = 'credit_agricole_nord_midipyrenees',
  CreditAgricoleNordest = 'credit_agricole_nordest',
  CreditAgricoleNordfrance = 'credit_agricole_nordfrance',
  CreditAgricoleNormandie = 'credit_agricole_normandie',
  CreditAgricoleNormandieseine = 'credit_agricole_normandieseine',
  CreditAgricoleProvenceCotedazur = 'credit_agricole_provence_cotedazur',
  CreditAgricolePyreneesGascogne = 'credit_agricole_pyrenees_gascogne',
  CreditAgricoleSavoie = 'credit_agricole_savoie',
  CreditAgricoleSudmediterranee = 'credit_agricole_sudmediterranee',
  CreditAgricoleSudrhonealpes = 'credit_agricole_sudrhonealpes',
  CreditAgricoleToulouse31 = 'credit_agricole_toulouse31',
  CreditAgricoleTourainepoitou = 'credit_agricole_tourainepoitou',
  CreditAgricoleValdefrance = 'credit_agricole_valdefrance',
  CreditCooperatif = 'credit_cooperatif',
  CreditDuNord = 'credit_du_nord',
  CreditFoncier = 'credit_foncier',
  CreditMutuelAnjou = 'credit_mutuel_anjou',
  CreditMutuelBretagne = 'credit_mutuel_bretagne',
  CreditMutuelCentre = 'credit_mutuel_centre',
  CreditMutuelCentreesteurope = 'credit_mutuel_centreesteurope',
  CreditMutuelDauphinevivarais = 'credit_mutuel_dauphinevivarais',
  CreditMutuelIledefrance = 'credit_mutuel_iledefrance',
  CreditMutuelLoireatlantiqueCentreouest = 'credit_mutuel_loireatlantique_centreouest',
  CreditMutuelMassifcentral = 'credit_mutuel_massifcentral',
  CreditMutuelMediterraneen = 'credit_mutuel_mediterraneen',
  CreditMutuelMidiatlantique = 'credit_mutuel_midiatlantique',
  CreditMutuelNordeurope = 'credit_mutuel_nordeurope',
  CreditMutuelNormandie = 'credit_mutuel_normandie',
  CreditMutuelOcean = 'credit_mutuel_ocean',
  CreditMutuelSavoiemontblanc = 'credit_mutuel_savoiemontblanc',
  CreditMutuelSudest = 'credit_mutuel_sudest',
  CreditMutuelSudouest = 'credit_mutuel_sudouest',
  Czam = 'czam',
  Fortuneo = 'fortuneo',
  HelloBank = 'hello_bank',
  Hsbc = 'hsbc',
  IngDirect = 'ing_direct',
  JpMorgan = 'jp_morgan',
  LaBanquePostale = 'la_banque_postale',
  Lcl = 'lcl',
  Mafrenchbank = 'mafrenchbank',
  Monabanq = 'monabanq',
  Montepaschi = 'montepaschi',
  N26 = 'n26',
  Neuflize = 'neuflize',
  Nickel = 'nickel',
  OrangeBank = 'orange_bank',
  Palatine = 'palatine',
  Qonto = 'qonto',
  Revolut = 'revolut',
  Shine = 'shine',
  Smc = 'smc',
  SocieteGenerale = 'societe_generale',
  Socredo = 'socredo',
  Socrif = 'socrif',
  Virgil = 'virgil'
}

/** The basic CreditKind type */
export enum CreditKind {
  Loa = 'LOA',
  Car = 'car',
  Consumer = 'consumer',
  Mortgage = 'mortgage',
  Other = 'other',
  Student = 'student'
}

/** Diagnostic de performance énergétique */
export enum Dpe {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  Na = 'NA'
}

/** The basic ExtraLoanKind type */
export enum ExtraLoanKind {
  Cel = 'cel',
  Employer = 'employer',
  Family = 'family',
  Other = 'other',
  Pel = 'pel'
}

/** The basic FranchiseKind type */
export enum FranchiseKind {
  Partial = 'partial',
  Total = 'total'
}

/** The basic FullCivilStatus type */
export enum FullCivilStatus {
  Pacs = 'PACS',
  CommonLawUnion = 'common_law_union',
  Divorced = 'divorced',
  MarriedAcquiredProperty = 'married_acquired_property',
  MarriedNoContract = 'married_no_contract',
  MarriedSeparated = 'married_separated',
  MarriedUniversal = 'married_universal',
  NA = 'n_a',
  Single = 'single',
  Widower = 'widower'
}

/** The basic GuarantyKind type */
export enum GuarantyKind {
  Bfm = 'bfm',
  BredHabitat = 'bred_habitat',
  Camca = 'camca',
  Casden = 'casden',
  CnpAxa = 'cnp_axa',
  CreditLogement = 'credit_logement',
  CreditLogementInitio = 'credit_logement_initio',
  GarantieHabitat = 'garantie_habitat',
  Ippd = 'ippd',
  Mortgage = 'mortgage',
  Saccef = 'saccef',
  Xmines = 'xmines'
}

/** The basic Housing type */
export enum Housing {
  Owner = 'owner',
  Sheltered = 'sheltered',
  StaffHoused = 'staff_housed',
  Tenant = 'tenant',
  Unspecified = 'unspecified'
}

/** The basic IncomeKind type */
export enum IncomeKind {
  ChildSupport = 'child_support',
  CommercialRent = 'commercial_rent',
  CompensatoryAllowances = 'compensatory_allowances',
  Dividend = 'dividend',
  ExpatriationBonus = 'expatriation_bonus',
  FamilyAllowances = 'family_allowances',
  HousingAllowances = 'housing_allowances',
  InvalidityPension = 'invalidity_pension',
  MilitaryPension = 'military_pension',
  ProfessionalBenefits = 'professional_benefits',
  PropertyInvestmentCompany = 'property_investment_company',
  PropertyManagementCompany = 'property_management_company',
  Rent = 'rent',
  SeasonalRent = 'seasonal_rent',
  Unspecified = 'unspecified'
}

/** The basic IncomesCalculation type */
export enum IncomesCalculation {
  AvisImpotsMoy_3a = 'avis_impots_moy_3a',
  BilansMoy_3a = 'bilans_moy_3a',
  BilansMoy_5a = 'bilans_moy_5a',
  BsDernierMois = 'bs_dernier_mois',
  ContratTravail = 'contrat_travail',
  NetImposableDerniereAnnee = 'net_imposable_derniere_annee'
}

/** The basic InsuranceGuarantees type */
export enum InsuranceGuarantees {
  Dcptia = 'DCPTIA',
  DcptiaIpt = 'DCPTIA_IPT',
  DcptiaIptItt = 'DCPTIA_IPT_ITT',
  DcptiaIptIttIpp = 'DCPTIA_IPT_ITT_IPP',
  DcptiaIptIttIppDospsy = 'DCPTIA_IPT_ITT_IPP_DOSPSY'
}

/** The basic kind type */
export enum Kind {
  Expert = 'expert',
  Pretto = 'pretto'
}

/** The basic LostReasonEnum type */
export enum LostReasonEnum {
  AdvisorRefusedProject = 'advisorRefusedProject',
  BankCompetitor = 'bankCompetitor',
  BankRefusal = 'bankRefusal',
  BrokerCompetitor = 'brokerCompetitor',
  ClientNeverAnsweredAfterFiveAttempts = 'clientNeverAnsweredAfterFiveAttempts',
  ClientNotInterested = 'clientNotInterested',
  ClientStoppedAnswering = 'clientStoppedAnswering',
  ClientStoppedAnsweringAfterFiveAttempts = 'clientStoppedAnsweringAfterFiveAttempts',
  CompetitorLoanFoundCrm = 'competitorLoanFoundCrm',
  CreditProjectStopped = 'creditProjectStopped',
  Duplicate = 'duplicate',
  GdprRequest = 'gdprRequest',
  InsuranceCompetitor = 'insuranceCompetitor',
  InsuranceProjectStopped = 'insuranceProjectStopped',
  Mepra = 'mepra',
  NoEquivalenceOfGuarantees = 'noEquivalenceOfGuarantees',
  NonAttractiveBuyback = 'nonAttractiveBuyback',
  NonAttractiveInsuranceChange = 'nonAttractiveInsuranceChange',
  NonFundable = 'nonFundable',
  PrettoLoan = 'prettoLoan',
  ProjectHalted = 'projectHalted',
  ProjectHaltedNoActiveVisits = 'projectHaltedNoActiveVisits',
  Test = 'test',
  TooManyBankCompetitors = 'tooManyBankCompetitors',
  Unspecified = 'unspecified',
  VeryDifficultClient = 'veryDifficultClient',
  WearRate = 'wearRate',
  WrongContactDetails = 'wrongContactDetails'
}

/** The basic MandateEnum type */
export enum MandateEnum {
  Approved = 'approved',
  PendingApproval = 'pending_approval',
  Signed = 'signed'
}

/** The basic Maturity type */
export enum Maturity {
  OfferAccepted = 'offer_accepted',
  OfferMade = 'offer_made',
  SaleAgreementSigned = 'sale_agreement_signed',
  SearchStarted = 'search_started',
  UnderStudy = 'under_study',
  Unspecified = 'unspecified'
}

/** The basic MortgageArrangement type */
export enum MortgageArrangement {
  Sarl = 'SARL',
  JointOwnership = 'joint_ownership',
  Other = 'other',
  SciIr = 'sci_ir',
  SciIs = 'sci_is',
  Tontine = 'tontine'
}

export enum New_DealStatus {
  Agreement = 'agreement',
  Analysis = 'analysis',
  ApplicationReady = 'application_ready',
  ApplicationSent = 'application_sent',
  Appointment = 'appointment',
  Confirmation = 'confirmation',
  Constitution = 'constitution',
  End = 'end',
  Lost = 'lost',
  Negotiation = 'negotiation',
  Search = 'search',
  Signature = 'signature',
  Signed = 'signed',
  Unrecognized = 'unrecognized',
  Validation = 'validation'
}

export enum New_RefereeStatus {
  Joining = 'joining',
  Lost = 'lost',
  Ongoing = 'ongoing',
  ToContact = 'toContact',
  Won = 'won'
}

export enum NegotiationStateStatus {
  Failed = 'failed',
  InProgress = 'inProgress'
}

export enum NegotiationStateType {
  Adp = 'adp',
  CheckBank = 'checkBank',
  CheckGaranty = 'checkGaranty',
  ComplianceCheckSelf = 'complianceCheckSelf',
  Negotiation = 'negotiation',
  Sent = 'sent'
}

/** The basic NoCallReason type */
export enum NoCallReasonType {
  ApplicationFirst = 'application_first',
  NoPretto = 'no_pretto',
  NoTime = 'no_time',
  NotMatureEnough = 'not_mature_enough',
  PrefersWritting = 'prefers_writting'
}

/** The basic NonFundableReason type */
export enum NonFundableReason {
  Contract = 'contract',
  Seniority = 'seniority',
  Unspecified = 'unspecified'
}

/** The basic Owner type */
export enum Owner {
  Both = 'both',
  Comortgagor = 'comortgagor',
  Mortgagor = 'mortgagor'
}

/** The basic ProjectKind type */
export enum ProjectKind {
  Capacity = 'capacity',
  Purchase = 'purchase',
  Renegotiation = 'renegotiation'
}

/** The basic PropertyKind type */
export enum PropertyKind {
  Ccmi = 'CCMI',
  Scpi = 'SCPI',
  Vefa = 'VEFA',
  Construction = 'construction',
  Land = 'land',
  LandConstruction = 'land_construction',
  New = 'new',
  Old = 'old'
}

/** The basic PropertyLeadingSourceEnum type */
export enum PropertyLeadingSourceEnum {
  Facebook = 'facebook',
  Gensdeconfiance = 'gensdeconfiance',
  Leboncoin = 'leboncoin',
  Other = 'other',
  Pap = 'pap',
  RealEstate = 'real_estate',
  Seloger = 'seloger',
  WordOfMouth = 'word_of_mouth'
}

/** The basic RecognitionSourceEnum type */
export enum RecognitionSourceEnum {
  Brand = 'brand',
  BrandAd = 'brand_ad',
  FacebookAd = 'facebook_ad',
  GoogleAd = 'google_ad',
  Other = 'other',
  Partner = 'partner',
  Referral = 'referral',
  Seo = 'seo',
  WordOfMouth = 'word_of_mouth'
}

/** The basic Recurrence type */
export enum Recurrence {
  Monthly = 'monthly',
  Yearly = 'yearly'
}

/** The basic RefereeStatus type */
export enum RefereeStatus {
  Joining = 'joining',
  Lost = 'lost',
  Ongoing = 'ongoing',
  ToContact = 'to_contact',
  Won = 'won'
}

/** The basic SavingKind type */
export enum SavingKind {
  Elp = 'ELP',
  CurrentAccount = 'current_account',
  Donation = 'donation',
  EmployeeSavings = 'employee_savings',
  LifeInsurance = 'life_insurance',
  Other = 'other',
  Stock = 'stock'
}

/** The basic SeverityEnum type */
export enum SeverityEnum {
  Block = 'block',
  Warn = 'warn'
}

/** The basic Sex type */
export enum Sex {
  Female = 'female',
  Male = 'male',
  Unspecified = 'unspecified'
}

/** The basic SignupErrorEnum type */
export enum SignupErrorEnum {
  AccountAlreadyExists = 'account_already_exists',
  InvalidEmail = 'invalid_email',
  RessourceNotFound = 'ressource_not_found',
  UnknownServerError = 'unknown_server_error'
}

/** The basic SolicitedBankRelationshipLevel type */
export enum SolicitedBankRelationshipLevel {
  Employee = 'employee',
  Family = 'family',
  Main = 'main',
  None = 'none',
  Pro = 'pro',
  RelativeEmployee = 'relative_employee',
  Secondary = 'secondary',
  Sleeping = 'sleeping'
}

/** The basic SolicitedBankStatus type */
export enum SolicitedBankStatus {
  AlreadyPresented = 'already_presented',
  AppointmentDone = 'appointment_done',
  AppointmentScheduled = 'appointment_scheduled',
  AppointmentToCancel = 'appointment_to_cancel',
  Contacted = 'contacted',
  NoAnswer = 'no_answer',
  None = 'none',
  OfferMade = 'offer_made',
  ProjectRefused = 'project_refused',
  SimulationDone = 'simulation_done'
}

/** The basic SolicitedBrokerStatus type */
export enum SolicitedBrokerStatus {
  AppointmentDone = 'appointment_done',
  MandateDenounced = 'mandate_denounced',
  MandateSigned = 'mandate_signed',
  NoAnswer = 'no_answer',
  ProjectRefused = 'project_refused',
  SimulationDone = 'simulation_done'
}

/** The basic StatusEnum type */
export enum StatusEnum {
  Agreement = 'agreement',
  Analysis = 'analysis',
  ApplicationReady = 'application_ready',
  ApplicationSent = 'application_sent',
  Appointment = 'appointment',
  Confirmation = 'confirmation',
  Constitution = 'constitution',
  End = 'end',
  Lost = 'lost',
  LostDoubleChoice = 'lost_double_choice',
  LostHardClient = 'lost_hard_client',
  LostOneChoice = 'lost_one_choice',
  Negotiation = 'negotiation',
  Search = 'search',
  Signature = 'signature',
  Signed = 'signed',
  Validation = 'validation'
}

/** The basic SuggestionType type */
export enum SuggestionType {
  BumpContribution = 'bump_contribution',
  BuyNew = 'buy_new',
  BuyWithBroker = 'buy_with_broker',
  CoInvestment = 'co_investment',
  Negotiate = 'negotiate',
  RepayDebts = 'repay_debts'
}

/** The basic Taxes type */
export enum Taxes {
  After = 'after',
  Before = 'before'
}

export enum Typology {
  /** user has applied and given his number */
  Client = 'client',
  /** user has given his email */
  PreLead = 'pre_lead'
}

/** The basic Usage type */
export enum Usage {
  PrimaryResidence = 'primary_residence',
  RentalInvestment = 'rental_investment',
  SecondaryResidence = 'secondary_residence'
}

/** The basic Visio type */
export enum Visio {
  VisioDisabled = 'VISIO_DISABLED',
  VisioEnabled = 'VISIO_ENABLED',
  VisioForced = 'VISIO_FORCED'
}

/** The basic appointment_kind type */
export enum Appointment_Kind {
  Adp = 'adp',
  Certificate = 'certificate',
  R0 = 'r0',
  R1 = 'r1',
  R3 = 'r3',
  Referral = 'referral',
  Suivi = 'suivi'
}

/** The basic waiting_step type */
export enum Waiting_Step {
  Adp = 'adp',
  Lost = 'lost',
  NegotiationDone = 'negotiation_done',
  NegotiationInProgress = 'negotiation_in_progress',
  Preparation = 'preparation',
  TargetOk = 'target_ok'
}
