import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import styled from 'styled-components'

export const MainContent = styled.main`
  ${grid()}
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(5)};
  }
`
export const MainContentWrapper = styled.div`
  gap: ${g(5)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${grid(10, { isLeftAligned: true, isRightAligned: true })};
    ${column([3, 10])};
  }
`

export const SectionTop = styled.div`
  ${column([2, 8])};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-direction: column-reverse;
  padding: ${g(3)} ${g(2.5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    padding: 0;
  }
`
export const Section = styled.section`
  ${column([2, 8])};
`
export const Heading = styled.h3`
  ${({ theme }) => theme.typos.heading4};
  max-width: 287px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 456px;
    ${({ theme }) => theme.typos.heading3};
  }
`
export const Date = styled.p`
  color: ${({ theme }) => theme.colors.neutral2};
  ${({ theme }) => theme.typos.body4}
  display: flex;
  white-space: nowrap;
  align-self: flex-start;
  padding: ${g(1)} ${g(2)};
  background-color: ${({ theme }) => theme.colors.neutral5};
  border-radius: ${g(0.5)};
  margin: 0 0 ${g(3)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0;
  }
`
export const Content = styled.p`
  padding: 0 ${g(2.5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0;
  }
`

export const AccordionContainer = styled.div`
  padding: ${g(2)} ${g(2.5)} ${g(3)} ${g(2.5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(2)} 0 0 0;
  }
`

export const FeedbackCardContainer = styled.div`
  ${column([2, 8])};
  padding: ${g(5)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0;
  }
`

export const CoachingCardContainer = styled.div`
  ${column([2, 8])};
  padding: 0 ${g(2.5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0;
  }
`

export const Caption = styled.p`
  text-align: center;
  ${({ theme }) => theme.typos.caption};
  color: ${({ theme }) => theme.colors.neutral2};
  padding: ${g(3)} ${g(2.5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0;
  }
`
