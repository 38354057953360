import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { Snackbar } from '@pretto/zen/main/banners/Snackbar/Snackbar'
import { Content as SnackbarContent } from '@pretto/zen/main/banners/Snackbar/Snackbar.styles'

import { Timeline as TimelineComponent } from '@pretto/app/src/SharedComponents/Timeline/Timeline'
import { StepAgenda } from '@pretto/app/src/SharedContainers/MutualizedAgenda/components/MutualizedAgenda/steps/StepAgenda/StepAgenda'
import { StepSubscribe } from '@pretto/app/src/SharedContainers/MutualizedAgenda/components/MutualizedAgenda/steps/StepSubscribe/StepSubscribe'

import styled from 'styled-components'

export const Grid = styled.div`
  ${grid(4, { isLeftAligned: true, isRightAligned: true })};
  height: 100%;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${grid(8, { isLeftAligned: true, isRightAligned: true })};
  }
  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${grid(12, { isLeftAligned: true, isRightAligned: true })};
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    ${grid(10, { isLeftAligned: true, isRightAligned: true })};
  }
`

export const ContentContainer = styled.div`
  ${column(4)}

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column(8)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column(12)};
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    ${column(10)};
  }
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto 0;
`

export const Alert = styled(Snackbar).attrs({ status: 'alert' })`
  width: 100%;
  margin: 0;
  margin-bottom: ${g(2)};
  padding: 0;

  & ${SnackbarContent} {
    justify-content: flex-start;
  }
`

export const Timeline = styled(TimelineComponent)`
  background-color: ${({ theme }) => theme.colors.neutral5};
  padding: ${g(2)} ${g(2, 4)} ${g(2)} ${g(2, 4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(4)} ${g(11)} ${g(3)} ${g(11)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(4)} ${g(10)} ${g(3)} ${g(10)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${g(4)} ${g(10)} ${g(3)} ${g(10)};
  }
`

export const Agenda = styled(StepAgenda)`
  margin: ${g(3)} ${g(2, 4)} ${g(4)} ${g(2, 4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: ${g(4)} ${g(11)} ${g(9)} ${g(11)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin: ${g(4)} ${g(10)} ${g(4)} ${g(10)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: ${g(4)} ${g(10)} ${g(4)} ${g(10)};
  }
`

export const Subscribe = styled(StepSubscribe)`
  margin: ${g(3)} ${g(2, 4)} ${g(4)} ${g(2, 4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: ${g(4)} ${g(9)} ${g(9)} ${g(9)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin: ${g(4)} ${g(21)} ${g(4)} ${g(21)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: ${g(4)} ${g(21)} ${g(4)} ${g(21)};
  }
`
