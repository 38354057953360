import temporal from '@pretto/bricks/core/utility/temporal'

import { type Slot } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/slot.types'
import { ADVISOR_LABELS } from '@pretto/app/src/config/advisor'
import { t } from '@pretto/app/src/lib/i18n'
import { Pen } from '@pretto/picto'

import { Trans } from 'react-i18next'

import * as S from './StepSubscribe.styles'

const DEFAULT_PICTURE = 'v1717493883/logo/BigFavicon.svg'

interface StepSubscribeProps {
  handleGoBack: () => void
  handleSubmit: (withVisio: boolean) => void
  selectedSlot: Slot
}

export const StepSubscribe: React.FC<StepSubscribeProps> = ({ handleGoBack, handleSubmit, selectedSlot, ...props }) => {
  const advisorName = [selectedSlot.advisor.firstName, selectedSlot.advisor.lastName].join(' ')
  const advisorLabel = ADVISOR_LABELS[selectedSlot.advisor.role]

  const handleSubmitWithVisio = () => {
    handleSubmit(true)
  }

  const handleSubmitWithoutVisio = () => {
    handleSubmit(false)
  }

  const appointmentDay = temporal(selectedSlot.utcDate).format('dddd')
  const appointmentDate = temporal(selectedSlot.utcDate).format('LL')
  const appointmentTime = temporal(selectedSlot.utcDate).format('HH[h]mm')

  return (
    <S.StepSubscribeContainer {...props}>
      <S.Tag>{t('mutualizedAgenda.tag')}</S.Tag>

      <S.Title>{t('mutualizedAgenda.tag')} :</S.Title>

      <S.AppointmentDetails>
        <S.AdvisorPicture path={selectedSlot.advisor.pictureLink || DEFAULT_PICTURE} />

        <div>
          <Trans
            i18nKey="mutualizedAgenda.subscribe.confirmation"
            values={{ appointmentDay, appointmentDate, appointmentTime, advisorLabel, advisorName }}
          />
          <S.Back onClick={handleGoBack}>
            <S.Picto as={Pen} />
            {t('mutualizedAgenda.subscribe.edit')}
          </S.Back>
        </div>
      </S.AppointmentDetails>

      {selectedSlot.advisor.isVisio && (
        <S.TextChooseVisio>
          <Trans i18nKey="mutualizedAgenda.subscribe.textChooseVisio" values={{ advisorLabel, advisorName }} />
        </S.TextChooseVisio>
      )}

      <S.OtherOptionsSection>
        {selectedSlot.advisor.isVisio && (
          <S.Button onClick={handleSubmitWithVisio}> {t('mutualizedAgenda.subscribe.chooseVisio')}</S.Button>
        )}
        <S.Button onClick={handleSubmitWithoutVisio}>
          {selectedSlot.advisor.isVisio
            ? t('mutualizedAgenda.subscribe.choosePhone')
            : t('mutualizedAgenda.subscribe.confirm')}
        </S.Button>
      </S.OtherOptionsSection>
    </S.StepSubscribeContainer>
  )
}
