import { ResizableField } from '@pretto/app/src/Sentences/components/ResizableField/ResizableField'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { Result, useBankField } from '@pretto/app/src/Sentences/lib/useBankField'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationRenegotiationLoanInsuranceKind } from '@pretto/app/src/types/api/enums'

export const BankPage: React.FC = () => {
  const { simulation, setSimulation } = useSentences()

  const { goToNextRoute } = useRoutesGraph()

  const { fieldProps: bankFieldProps, results: bankResults } = useBankField(
    simulation.renegotiationLoanBank ?? undefined
  )

  const [activeField, setActiveField] = useActiveField(
    simulation.renegotiationLoanBank ? 1 : 0,
    !!simulation.renegotiationLoanInsuranceKind
  )

  const mapBankValues: MapToChoices<Result[]> = values =>
    values.map(({ label, value }) => ({
      key: value,
      isSelected: simulation.renegotiationLoanBank === value,
      onClick: () => {
        bankFieldProps.onChange(label)
        setSimulation({ renegotiationLoanBank: value })
      },
      label,
    }))

  const mapInsuranceTypeValues: MapToChoices<SimulationRenegotiationLoanInsuranceKind[]> = values => {
    return values.map(value => ({
      key: value,
      isSelected: simulation.renegotiationLoanInsuranceKind === value,
      onClick: () => {
        setSimulation({ renegotiationLoanInsuranceKind: value })
      },
      label: t(`sentences.loan.bank.values.${value}`),
    }))
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'bank' } }}>
      <Title>{t('sentences.loan.title')}</Title>
      <div>
        {t('sentences.loan.bank.sentences.0')}
        <ResizableField
          {...bankFieldProps}
          clearable
          inputProps={{ autoFocus: true, placeholder: 'Une banque', spellCheck: false }}
          onClick={() => {
            setActiveField(0)
          }}
        />
        {activeField > 0 && (
          <>
            {t('sentences.loan.bank.sentences.1')}
            <Prompt isFilled={!!simulation.renegotiationLoanInsuranceKind} onClick={() => setActiveField(1)}>
              {t(
                `sentences.loan.bank.values.${
                  simulation.renegotiationLoanInsuranceKind || SimulationRenegotiationLoanInsuranceKind.Group
                }`
              )}
            </Prompt>
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapBankValues(bankResults)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'bank' } }}
          onChoiceClick={() => setActiveField(1)}
        />
      )}
      {activeField === 1 && (
        <Choices
          choices={mapInsuranceTypeValues(
            Object.values([
              SimulationRenegotiationLoanInsuranceKind.Group,
              SimulationRenegotiationLoanInsuranceKind.Delegate,
            ])
          )}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'insuranceType' } }}
          onChoiceClick={() => {
            goToNextRoute()
          }}
        />
      )}
      {activeField > 1 && simulation.renegotiationLoanInsuranceKind && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
