import { States } from '@pretto/zen/reveal/atoms/message/Message'

import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { getMinimumContribution, MinimumContribution } from '@pretto/app/src/Sentences/lib/contribution'
import { calcMonthlySalaryAfterTaxes } from '@pretto/app/src/Sentences/lib/salary'
import abTest from '@pretto/app/src/lib/abtest'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationKind } from '@pretto/app/src/types/api/enums'

import { isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { getI18nContributionKey } from './helpers'

type UseContribution = () => {
  nextLabel: string
  tooltipComponent: React.ReactElement | null
}

export const useContribution: UseContribution = () => {
  const { simulation } = useSentences()

  const [debouncedContribution] = useDebounce(simulation.contribution, 1000)

  const [minContribution, setMinimumContribution] = useState<MinimumContribution | null>(null)

  useEffect(() => {
    getMinimumContribution(simulation).then(setMinimumContribution)
  }, [])

  if (simulation.kind === SimulationKind.Buyout || !minContribution) {
    return {
      nextLabel: t('sentences.next'),
      tooltipComponent: null,
    }
  }

  const i18nContributionKey = getI18nContributionKey(simulation, minContribution?.contribution)

  if (abTest('contribution_wording_202310') === 'simple') {
    return {
      nextLabel: t('sentences.next'),
      tooltipComponent: (
        <Message
          state={States.Info}
          html={t(`sentences.tips.contribution.${i18nContributionKey}`, {
            minContribution: minContribution?.contribution?.toString() ?? '',
          })}
        />
      ),
    }
  }

  if (debouncedContribution === 0) {
    return {
      nextLabel: t('sentences.nextWithoutContribution'),
      tooltipComponent: (
        <Message
          state={States.Message}
          html={`${t('sentences.tips.contribution.withoutContribution.0')} ${t(
            `sentences.tips.contribution.${i18nContributionKey}`,
            {
              minContribution: minContribution?.contribution?.toString() ?? '',
            }
          )} ${t('sentences.tips.contribution.withoutContribution.1')}`}
        />
      ),
    }
  }

  if (
    !isNil(debouncedContribution) &&
    ((!isNil(minContribution) && debouncedContribution < minContribution?.contribution) ||
      (simulation.kind === SimulationKind.Capacity &&
        debouncedContribution < calcMonthlySalaryAfterTaxes(simulation) * 4))
  ) {
    return {
      nextLabel: t('sentences.nextWithSmallContribution'),
      tooltipComponent: (
        <Message
          state={States.Message}
          html={`${t('sentences.tips.contribution.smallContribution')} ${t(
            `sentences.tips.contribution.${i18nContributionKey}`,
            {
              minContribution: minContribution?.contribution?.toString() ?? '',
            }
          )}`}
        />
      ),
    }
  }

  return {
    nextLabel: t('sentences.next'),
    tooltipComponent: (
      <Message
        state={States.Info}
        html={t(`sentences.tips.contribution.${i18nContributionKey}`, {
          minContribution: minContribution?.contribution?.toString() ?? '',
        })}
      />
    ),
  }
}
