import { breakpoints, g } from '@pretto/bricks/components/layout'

import { StepStatus } from '@pretto/app/src/SharedComponents/Timeline/Timeline'

import styled from 'styled-components'

type StepProps = {
  status: StepStatus
}

type SeparatorProps = {
  isSolid: boolean
}

const STATUS_COLORS = {
  done: { bgColor: 'white', pictoColor: 'neutral1', fontColor: 'neutral1' },
  current: { bgColor: 'primary2', pictoColor: 'white', fontColor: 'neutral1' },
  todo: { bgColor: 'white', pictoColor: 'neutral3', fontColor: 'neutral2' },
}

export const Timeline = styled.div`
  display: flex;
`

export const Step = styled.div<StepProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${g(1)};
  width: 100%;
`

export const PictoContainer = styled.div<StepProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${g(3)};
  height: ${g(3)};
  border-radius: 100%;
  background-color: ${({ theme, status }) => theme.colors[STATUS_COLORS[status].bgColor]};
  flex-shrink: 0;
  z-index: 2;

  @media screen and (min-width: ${breakpoints.laptop}) {
    width: ${g(4)};
    height: ${g(4)};
  }
`

export const Picto = styled.svg<StepProps>`
  color: ${({ theme, status }) => theme.colors[STATUS_COLORS[status].pictoColor]};
  height: ${g(2)};
  width: ${g(2)};
`

export const StepName = styled.div<StepProps>`
  ${({ theme }) => theme.typos.caption};
  color: ${({ theme, status }) => theme.colors[STATUS_COLORS[status].fontColor]};
`

export const Separator = styled.div<SeparatorProps>`
  position: absolute;
  top: ${g(1, 4)};
  left: calc(-50% + ${g(2, 4)});
  width: calc(100% - ${g(5)});
  border-top: 2px ${({ isSolid }) => (isSolid ? 'solid' : 'dashed')}
    ${({ isSolid, theme }) => theme.colors[isSolid ? 'neutral3' : 'neutral4']};
  z-index: 1;

  @media screen and (min-width: ${breakpoints.laptop}) {
    top: ${g(2)};
    left: calc(-50% + ${g(3)});
    width: calc(100% - ${g(6)});
  }
`
