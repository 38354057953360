export enum Frequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Never = 'NEVER',
}

export enum Month {
  January = 'JANUARY',
  February = 'FEBRUARY',
  March = 'MARCH',
  April = 'APRIL',
  May = 'MAY',
  June = 'JUNE',
  July = 'JULY',
  August = 'AUGUST',
  September = 'SEPTEMBER',
  October = 'OCTOBER',
  November = 'NOVEMBER',
  December = 'DECEMBER',
}

export enum Rooms {
  One = 'ONE',
  Two = 'TWO',
  Three = 'THREE',
  Four = 'FOUR',
  Five = 'FIVE',
}

export enum YesNo {
  Yes = 'YES',
  No = 'NO',
}

export enum MaturityType {
  UnderStudy = 'underStudy',
  SearchStarted = 'searchStarted',
  OfferMade = 'offerMade',
  OfferAccepted = 'offerAccepted',
  SaleAgreementSigned = 'saleAgreementSigned',
}
