import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const mapPaidOffValues: MapToChoices<YesNo[]> = values => {
  const { simulation, setSimulation } = useSentences()

  return values.map(value => ({
    key: value,
    isSelected:
      !isNil(simulation.ownPropertyPaidOff) &&
      ((simulation.ownPropertyPaidOff && value === YesNo.Yes) ||
        (!simulation.ownPropertyPaidOff && value === YesNo.No)),
    onClick: () => {
      setSimulation({ ownPropertyPaidOff: value === YesNo.Yes })
    },
    label: t(`sentences.ptz.beenOwner.values.${value}`),
  }))
}
