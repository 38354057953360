import { g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const AccordionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const AccordionPictogram = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.error2};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${g(2)} 0 0;
`

export const AccordionTitle = styled.h3`
  ${({ theme }) => theme.typos.body4};
  font-weight: bold;
`

export const AccordionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AccordionButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 ${g(2)} 0;
  cursor: pointer;
`

export const AccordionButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const AccordionPanel = styled.div`
  padding: ${g(3)} 0;
  background: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-top: 1px solid ${({ theme }) => theme.colors.neutral3};
  margin: 0 0 ${g(2)} 0;
`
export const AccordionPanelText = styled.p`
  ${({ theme }) => theme.typos.body4};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral1};
  margin: 0 0 ${g(2)} 0;
`
