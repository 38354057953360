/* globals analytics */
import { getAttributionCookieLastClickValue, handleAttributionCookie } from '@pretto/app-core/lib/attributionCookie'
import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'
import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import ApplicationProvider from '@pretto/app/src/Application/Containers/ApplicationContext'
import { ApplicationUploadProvider } from '@pretto/app/src/Application/Containers/applicationUploadContext'
import * as helpers from '@pretto/app/src/Auth/lib/helpers'
import Front from '@pretto/app/src/Helpers/frontchat'
import AdvisorDialogProvider from '@pretto/app/src/SharedContainers/AdvisorDialog'
import { UserProvider } from '@pretto/app/src/User/Containers/UserProvider'
import {
  useCompleteProjectKickoffStepMutation,
  useProjectKickoffQuery,
} from '@pretto/app/src/User/Containers/UserProviderClient/queries/projectKickOffSteps.api.graphql'
import { analyticsDefaultPayload } from '@pretto/app/src/User/config/config'
import { isEnglishUser } from '@pretto/app/src/User/lib/i18n'
import { getNetlifyAB } from '@pretto/app/src/User/lib/netlify'
import { USER_CLIENT } from '@pretto/app/src/User/queries/queries'
import { client as clientAPI } from '@pretto/app/src/api/api'
import { SCORE_FLAGS } from '@pretto/app/src/apollo'
import { ADVISOR_LABELS } from '@pretto/app/src/config/advisor'
import { getIsHotMaturity } from '@pretto/app/src/lib/getIsHotMaturity'

import { useApolloClient, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const TYPOLOGY = 'client'

export const UserProviderClient = ({ children }) => {
  const {
    data: dataUser,
    loading: loadingUser,
    error: errorUser,
    refetch: refetchUser,
  } = useQuery(USER_CLIENT, { fetchPolicy: 'network-only' })

  const {
    data: dataScoreZeroMinute,
    loading: loadingScoreZeroMinute,
    error: errorScoreZeroMinute,
  } = useQuery(SCORE_FLAGS, {
    variables: { attributionCookieLastClick: getAttributionCookieLastClickValue() },
  })

  const {
    data: dataProjectKickoff,
    loading: loadingProjectKickoff,
    error: errorProjectKickoff,
    refetch: refetchProjectKickoff,
  } = useProjectKickoffQuery({ client: clientAPI })

  const [completeProjectKickoffStepMutation] = useCompleteProjectKickoffStepMutation({ client: clientAPI })

  const areQueriesLoading = loadingUser || loadingProjectKickoff || loadingScoreZeroMinute
  const areQueriesInError = errorUser || errorProjectKickoff || errorScoreZeroMinute

  const { i18n } = useTranslation()

  const oldMaturity = useRef('')
  const [isAdvisorUnlocked, setIsAdvisorUnlocked] = useState(false)
  const client = useApolloClient()

  useLoading(areQueriesLoading)

  useEffect(() => {
    handleAttributionCookie({ isClient: true })
  }, [])

  useEffect(() => {
    const newMaturity = dataUser?.project?.purchase?.maturity || ''

    if (!getIsHotMaturity(oldMaturity?.current) && oldMaturity?.current !== '' && getIsHotMaturity(newMaturity)) {
      const refetch = async () => {
        await invalidateCache(client)
        setIsAdvisorUnlocked(true)
      }

      refetch()
    }

    oldMaturity.current = newMaturity
  }, [dataUser?.project?.purchase?.maturity])

  useEffect(() => {
    if (loadingUser) {
      return
    }

    const { account, advisor, project_id } = dataUser

    const payload = {
      ...analyticsDefaultPayload,
      advisor_email: advisor.email,
      connected: true,
      displayLang: isEnglishUser(i18n.language) ? 'en' : 'fr',
      project_id,
      typology: TYPOLOGY,
    }

    const accountId = helpers.getAccountId()

    analytics.alias(accountId)
    analytics.identify(accountId, payload)

    const netlifyAB = getNetlifyAB()

    Sentry.setUser({
      ...netlifyAB,
      id: helpers.getAccountId(),
      email: helpers.getUserEmail(),
      segment: 'client',
    })

    Front('update', {
      customFields: { ...netlifyAB, project_id },
      email: helpers.getUserEmail(),
      name: `${account.firstName} ${account.lastName}`,
      userHash: helpers.getFrontHMAC(),
    })
  }, [loadingUser])

  if (areQueriesInError) {
    throw {
      projectKickoff: errorProjectKickoff,
      scoreFlags: errorScoreZeroMinute,
      userClient: errorUser,
    }
  }

  if (areQueriesLoading) {
    return null
  }

  const {
    accounts,
    advisor,
    deal: {
      agreement,
      application,
      status,
      onboardingDone: isOnboardingDone,
      isLowPotential,
      lostReason,
      mandateStatus,
    },
    next_booking,
    project,
    project_id,
    referrer,
  } = dataUser

  const {
    project_kind,
    profile: { mortgagors },
    purchase: { build_price, property_price, maturity },
  } = project

  // eslint-disable-next-line no-unused-vars
  const { isActive, isMine, ...currentAccount } = accounts.find(({ isMine }) => isMine)
  const currentAccountIndex = accounts.findIndex(({ isMine }) => isMine)

  const orderedAccounts = mortgagors.map(
    mortgagor => accounts.find(({ mortgagorId }) => mortgagorId === mortgagor.id) || {}
  )

  const allAccountsActive = accounts.every(({ isActive }) => isActive) && mortgagors.length === accounts.length
  const hasComortgagor = mortgagors.length > 1
  const isProjectEditable = ['search', 'constitution', 'validation'].includes(status)
  const isPropertyFound = property_price !== null || build_price !== null

  const comortgagorInvited = hasComortgagor && mortgagors.length === accounts.length
  const comortgagorDeclared =
    comortgagorInvited && !!(orderedAccounts[1].firstName && orderedAccounts[1].lastName && orderedAccounts[1].phone)

  const hasFeatureAccess = featuresName => {
    return featuresName.some(name => (currentAccount.featureAccess ?? []).includes(name))
  }

  const user = {
    ...currentAccount,
    activeIndex: currentAccountIndex,
    isPropertyFound,
    projectKind: project_kind,
  }

  const value = {
    accounts: orderedAccounts,
    advisor: {
      ...(advisor && { label: ADVISOR_LABELS[advisor.role] }),
      ...advisor,
    },
    agreement,
    allAccountsActive,
    application,
    comortgagorDeclared,
    comortgagorInvited,
    hasComortgagor,
    hasFeatureAccess,
    handleCloseAdvisorUnlocked: () => setIsAdvisorUnlocked(false),
    isEnglishUser: isEnglishUser(i18n.language),
    isLowPotential,
    isOnboardingDone,
    isProjectEditable,
    isReferred: !!referrer?.id,
    isVisioEnabled: advisor.visio === 'VISIO_ENABLED' || advisor.visio === 'VISIO_FORCED',
    isVisioMandatory: advisor.visio === 'VISIO_FORCED' || helpers.getR3(),
    lostReason,
    mandateStatus,
    maturity,
    mortgagors,
    next_booking,
    project,
    projectID: project_id,
    projectKickoff: {
      nextSteps: dataProjectKickoff.projectKickoff.nextSteps,
      completeProjectKickoffStep: async kind => {
        await completeProjectKickoffStepMutation({
          variables: { input: { kind } },
          onCompleted: () => refetchProjectKickoff(),
        })
      },
    },
    refetchUser,
    status,
    scoreZeroMinute: dataScoreZeroMinute.scoreFlags,
    typology: TYPOLOGY,
    user,
    isAdvisorUnlocked,
  }

  if (hasComortgagor) {
    Object.assign(value, { comortgagor: { id: mortgagors[1].id } })
  }

  return (
    <UserProvider value={value}>
      <AdvisorDialogProvider>
        <ApplicationProvider>
          <ApplicationUploadProvider>{children}</ApplicationUploadProvider>
        </ApplicationProvider>
      </AdvisorDialogProvider>
    </UserProvider>
  )
}

UserProviderClient.propTypes = {
  children: PropTypes.node.isRequired,
}
