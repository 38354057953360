import { isLessThan } from '@pretto/app/src/Sentences/lib/date'
import {
  SimulationMortgagorOccupationDuration,
  SimulationMortgagorOccupationKind,
  SimulationMortgagorOccupationOtherStatus,
} from '@pretto/app/src/types/api/enums'
import { SimulationMortgagor } from '@pretto/app/src/types/api/schema'

export const isOnTrial = (mortgagor: SimulationMortgagor): boolean => mortgagor.occupationTrialPeriod === true

export const isCDD = (mortgagor: SimulationMortgagor): boolean =>
  mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.ShortTerm &&
  mortgagor.occupationKind === SimulationMortgagorOccupationKind.Private

const getThreeYearsAgoDate = (): Date => {
  const threeYearsAgo = new Date()
  threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3)
  return threeYearsAgo
}

export const isEarlyBusinessOwner = (mortgagor: SimulationMortgagor): boolean =>
  mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
  mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.BusinessOwner &&
  !!mortgagor.occupationStartMonth &&
  !!mortgagor.occupationStartYear &&
  isLessThan(getThreeYearsAgoDate(), new Date(mortgagor.occupationStartYear, mortgagor.occupationStartMonth))
