import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import { Owner, SavingKind } from '@pretto/app/src/types/gateway/enums'
import { Saving } from '@pretto/app/src/types/gateway/schema'

import isNil from 'lodash/isNil'

export const mapSavings: ComposableMapper = ({ project, simulation }) => ({
  project: {
    ...project,

    profile: {
      ...project.profile,

      get savings() {
        if (isNil(simulation.contribution) && isNil(simulation.savingsResidual)) {
          return []
        }

        const amount = (simulation.contribution ?? 0) + (simulation.savingsResidual ?? 0)

        return [
          {
            amount,
            kind: SavingKind.CurrentAccount,
            owner: Owner.Both,
          } satisfies Saving,
        ]
      },
    },
  },
  simulation,
})
