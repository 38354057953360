import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorIncomeTaxes } from '@pretto/app/src/types/api/enums'

export const mapIncomeTypeValues: MapToChoices<SimulationMortgagorIncomeTaxes[]> = (values, context) => {
  if (!context) return []
  const { simulation, setSimulationComortgagor } = context

  return values.map(value => ({
    key: value,
    isSelected: simulation.profileMortgagors[1]?.incomeSalaryTaxes === value,
    onClick: () => {
      setSimulationComortgagor({ incomeSalaryTaxes: value })
    },
    label: t(`sentences.income.type.${value}`),
  }))
}
