export const sanitizeFloat = (value: string): string => {
  // go through each character one by one
  return value.replace(/./g, (match, offset, value) => {
    // if digit
    if (/^[\d]$/.test(match)) {
      return match
    }

    // if first dot
    if (/^\.$/.test(match) && offset === value.indexOf('.')) {
      return match
    }

    return ''
  })
}
