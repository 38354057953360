import { Frequency, Rooms } from '@pretto/app/src/Sentences/types/enums'
import { useStateOperations } from '@pretto/app/src/lib/state/useStateOperations'
import { Scalars } from '@pretto/app/src/types/api/schema'

import { createContext, PropsWithChildren, useContext, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useSentences } from './SentencesContext'

export type Location = Scalars['Location'] & { id: string }

interface Values {
  frequency?: Lowercase<Frequency> | null
  area_min?: number | null
  area_max?: number | null
  rooms?: number | null
  localisations: Array<Pick<Location, 'city' | 'zipcode'>>
  email?: string | null
}

export type GetPrettoSearch = {
  areaMax?: number | null
  areaMin?: number | null
  email?: string | null
  frequency?: Frequency | null
  isVisible: boolean
  locations: Location[]
  numberOfRooms?: Rooms | null
}

type SetPrettoSearch = Omit<GetPrettoSearch, 'isVisible' | 'locations'>

export const getIsPrettoSearchEmbed = () => {
  const urlParams = new URLSearchParams(window.location.search)

  return urlParams.get('utm_source') === 'prettosearch'
}

export interface PrettoSearchContextInterface {
  addLocation: () => string
  deleteLocation: (id: string) => void
  getValues: () => Values
  prettoSearch: GetPrettoSearch
  setLocation: React.Dispatch<React.SetStateAction<Location>>
  setPrettoSearch: React.Dispatch<React.SetStateAction<SetPrettoSearch>>
  waitForOngoingOperations: () => Promise<GetPrettoSearch>
}

const PrettoSearchContext = createContext<PrettoSearchContextInterface>({} as PrettoSearchContextInterface)

export const PrettoSearchContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { isUpdate, simulation } = useSentences()

  const [prettoSearch, setPartialPrettoSearch, waitForOngoingOperations] = useStateOperations<GetPrettoSearch>({
    isVisible: !isUpdate && !getIsPrettoSearchEmbed(),
    locations: [],
  })

  useEffect(() => {
    const { propertyLocation } = simulation

    if (!propertyLocation) {
      return
    }

    setPartialPrettoSearch(previousContext => ({
      ...previousContext,
      locations: [{ ...propertyLocation, id: uuidv4() }],
    }))
  }, [simulation])

  const addLocation = () => {
    const id = uuidv4()

    setPartialPrettoSearch(previousContext => ({
      ...previousContext,
      locations: [
        ...previousContext.locations,
        {
          address: null,
          city: null,
          countryCode: null,
          id,
          zipcode: null,
        },
      ],
    }))

    return id
  }

  const deleteLocation = (id: string) => {
    setPartialPrettoSearch(previousContext => ({
      ...previousContext,
      locations: previousContext.locations.filter(location => location.id !== id),
    }))
  }

  const getValues = () => {
    return {
      area_max: prettoSearch.areaMax,
      area_min: prettoSearch.areaMin,
      email: prettoSearch.email,

      get frequency() {
        if (!prettoSearch.frequency) {
          return null
        }

        switch (prettoSearch.frequency) {
          case Frequency.Daily:
            return 'daily'

          case Frequency.Weekly:
            return 'weekly'

          case Frequency.Never:
            return 'never'

          default:
            return null
        }
      },

      localisations: prettoSearch.locations.map(location => ({
        city: location.city,
        zipcode: location.zipcode,
      })),

      get rooms() {
        if (!prettoSearch.numberOfRooms) {
          return null
        }

        switch (prettoSearch.numberOfRooms) {
          case Rooms.One:
            return 1

          case Rooms.Two:
            return 2

          case Rooms.Three:
            return 3

          case Rooms.Four:
            return 4

          case Rooms.Five:
            return 5

          default:
            return null
        }
      },
    }
  }

  const setLocation: React.Dispatch<React.SetStateAction<Location>> = state => {
    setPartialPrettoSearch(previousContext => ({
      ...previousContext,
      locations: previousContext.locations.map(location => {
        const locationState = typeof state === 'function' ? state(location) : state

        if (location.id === locationState.id) {
          return locationState
        }

        return location
      }),
    }))
  }

  const setPrettoSearch: React.Dispatch<React.SetStateAction<SetPrettoSearch>> = state => {
    setPartialPrettoSearch(previousContext => ({
      ...previousContext,
      ...(typeof state === 'function' ? state(previousContext) : state),
    }))
  }

  return (
    <PrettoSearchContext.Provider
      value={{
        addLocation,
        deleteLocation,
        getValues,
        prettoSearch,
        setLocation,
        setPrettoSearch,
        waitForOngoingOperations,
      }}
    >
      {children}
    </PrettoSearchContext.Provider>
  )
}

export const usePrettoSearch = () => useContext(PrettoSearchContext)
