import { mapOtherStatus } from '@pretto/app/src/Sentences/Occupation/Sector/lib/mappers/mapOtherStatus'
import { mapStartMonth } from '@pretto/app/src/Sentences/Occupation/Sector/lib/mappers/mapStartMonth'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { Month } from '@pretto/app/src/Sentences/types/enums'
import {
  SimulationMortgagorOccupationKind,
  SimulationMortgagorOccupationOtherStatus,
} from '@pretto/app/src/types/api/enums'

interface OthersChoicesProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const OthersChoices: React.FC<OthersChoicesProps> = ({ activeField, onSetActive }) => {
  const context = useSentences()

  const { simulation } = context

  return (
    <>
      {activeField === 1 &&
        simulation.profileMortgagors[0].occupationKind !== SimulationMortgagorOccupationKind.Medical && (
          <Choices
            choices={mapOtherStatus(
              Object.values([
                SimulationMortgagorOccupationOtherStatus.SelfEmployed,
                SimulationMortgagorOccupationOtherStatus.Retired,
                SimulationMortgagorOccupationOtherStatus.BusinessOwner,
                SimulationMortgagorOccupationOtherStatus.LiberalLegal,
                SimulationMortgagorOccupationOtherStatus.LiberalOther,
                SimulationMortgagorOccupationOtherStatus.Jobless,
                SimulationMortgagorOccupationOtherStatus.Merchant,
                SimulationMortgagorOccupationOtherStatus.Farmer,
                SimulationMortgagorOccupationOtherStatus.ParentalLeave,
                SimulationMortgagorOccupationOtherStatus.Craftsman,
                SimulationMortgagorOccupationOtherStatus.Student,
                SimulationMortgagorOccupationOtherStatus.ManagingEmployee,
                SimulationMortgagorOccupationOtherStatus.Interim,
                SimulationMortgagorOccupationOtherStatus.Entertainment,
                SimulationMortgagorOccupationOtherStatus.Remote,
                SimulationMortgagorOccupationOtherStatus.Jobseeker,
              ]),
              context
            )}
            events={{
              eventName: EventName.SimulationChoiceClicked,
              eventPayload: { choice: 'occupationOthersStatus' },
            }}
            onChoiceClick={() => {
              onSetActive(2)
            }}
          />
        )}
      {((activeField === 2 &&
        simulation.profileMortgagors[0].occupationOtherStatus !== SimulationMortgagorOccupationOtherStatus.Retired) ||
        (activeField === 1 &&
          simulation.profileMortgagors[0].occupationKind === SimulationMortgagorOccupationKind.Medical)) && (
        <Choices
          choices={mapStartMonth(Object.values(Month), context)}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'occupationOthersNotRetiredMonths' },
          }}
          onChoiceClick={() => onSetActive(3)}
        />
      )}
    </>
  )
}
