import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

export const mapBeenOwnerValues: MapToChoices<YesNo[]> = values => {
  const { simulation, setSimulation } = useSentences()
  const ptzBeenOwner = simulation.ptzBeenOwner

  return values.map(value => ({
    key: value,
    isSelected: (value === YesNo.No && ptzBeenOwner === false) || (value === YesNo.Yes && ptzBeenOwner === true),
    onClick: () => {
      setSimulation({ ptzBeenOwner: value === YesNo.Yes })
    },
    label: t(`sentences.ptz.beenOwner.values.${value}`),
  }))
}
