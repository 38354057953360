import { GetSimulation } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import {
  SimulationKind,
  SimulationMortgagorLodgingLocationKind,
  SimulationPropertyKind,
} from '@pretto/app/src/types/api/enums'

type ContributionKeys =
  | 'nonResident'
  | 'nonResidentCapacity'
  | 'capacity'
  | 'construction'
  | 'landConstruction'
  | 'purchase'

export const getI18nContributionKey = (simulation: GetSimulation, minContribution: number | null): ContributionKeys => {
  switch (true) {
    case simulation.profileMortgagors[0]?.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.World &&
      (minContribution ?? 0) > 0:
      return 'nonResident'

    case simulation.profileMortgagors[0]?.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.World &&
      minContribution === 0:
      return 'nonResidentCapacity'

    case simulation.kind === SimulationKind.Capacity:
      return 'capacity'

    case simulation.propertyKind === SimulationPropertyKind.Construction:
      return 'construction'

    case simulation.propertyKind === SimulationPropertyKind.LandConstruction:
      return 'landConstruction'

    default:
      return 'purchase'
  }
}
