import { GetSimulation } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { SimulationMortgagorIncomePeriod, SimulationMortgagorIncomeTaxes } from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'

export const calcMonthlySalaryAfterTaxes = ({ profileMortgagors }: GetSimulation) =>
  profileMortgagors.reduce((previous, { incomeSalaryAmount, incomeSalaryPeriod, incomeSalaryTaxes }) => {
    if (isNil(incomeSalaryAmount) || isNil(incomeSalaryPeriod) || isNil(incomeSalaryTaxes)) {
      return previous
    }

    const netAmount =
      incomeSalaryTaxes === SimulationMortgagorIncomeTaxes.Before ? incomeSalaryAmount * 0.8 : incomeSalaryAmount

    const monthlyAmount = incomeSalaryPeriod === SimulationMortgagorIncomePeriod.Monthly ? netAmount : netAmount / 12

    return previous + monthlyAmount
  }, 0)
