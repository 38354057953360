import { useState, useEffect } from 'react'

import * as S from './LoaderLLM.styles'

type item = {
  title: string
}

export interface LoaderLLMProps {
  title: string
  illustration: string
  listItem: item[]
  timing: number // in milliseconds
}

const LoaderLLM: React.FC<React.HTMLAttributes<HTMLDivElement> & LoaderLLMProps> = ({
  title,
  illustration,
  listItem,
  timing,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex(prevIndex => {
        const nextIndex = (prevIndex + 1) % listItem.length
        return nextIndex
      })
    }, timing || 1000)

    return () => clearInterval(timer)
  }, [listItem.length, timing])

  return (
    <S.Container>
      <S.ImageContainer>
        <S.Image path={illustration} />
      </S.ImageContainer>
      <S.ContentContainer>
        <S.Title>{title}</S.Title>
        <S.List>
          {listItem.map((item, index) => (
            <S.ListItem key={index} isActive={activeIndex === index} isPrevious={index < activeIndex}>
              <S.Content isActive={activeIndex === index} isPrevious={index < activeIndex}>
                {item.title}
              </S.Content>
            </S.ListItem>
          ))}
        </S.List>
      </S.ContentContainer>
    </S.Container>
  )
}

export default LoaderLLM
