import { breakpoints, g } from '@pretto/bricks/components/layout'

import { ButtonChoice } from '@pretto/zen/reveal/atoms/buttons/buttonChoice/ButtonChoice'

import { Calendar as CalendarComponent } from '@pretto/app/src/SharedContainers/MutualizedAgenda/components/MutualizedAgenda/steps/StepAgenda/components/Calendar/Calendar'
import { ExclamationMarkTriangleBold } from '@pretto/picto'

import styled from 'styled-components'

export const StepAgendaContainer = styled.div`
  position: relative;
  height: fit-content;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: flex;
  }
`

export const LeftSide = styled.div`
  height: fit-content;

  @media screen and (min-width: ${breakpoints.laptop}) {
    position: sticky;
    top: ${g(4)};
    width: 50%;
    padding-right: 10%;
  }
`

export const RightSide = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: block;
    width: 50%;
  }
`

export const Tag = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading6};
    display: block;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary1};
    padding: ${g(1)} ${g(1, 4)};
    border-radius: ${g(1, -4)};
    text-overflow: ellipsis;
    width: fit-content;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    flex-shrink: 0;

    margin-bottom: ${g(1)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(2)};
  }
`

export const TitleContainer = styled.h1`
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(2)};
  }
`

export const Title = styled.span`
  ${({ theme }) => theme.typos.heading5};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading4};
  }
`

export const Duration = styled.span`
  ${({ theme }) => theme.typos.heading6};
`

export const Description = styled.p`
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: unset;
  }
`

export const Calendar = styled(CalendarComponent)``

export const OtherOptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    width: 100%;
    padding-top: ${g(2)};
    border-top: 1px solid #e1dbd1;
    margin-top: ${g(5)};
  }
`

export const SectionTitle = styled.div`
  ${({ theme }) => theme.typos.heading5};
`

export const Button = styled(ButtonChoice)`
  text-align: left;
  margin-right: auto;
`

export const ErrorMessage = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.warning2};
  color: ${({ theme }) => theme.colors.warning1};
  padding: ${g(2)};
  gap: ${g(2)};
  border-radius: ${g(1)};
  width: 100%;
`

export const Picto = styled(ExclamationMarkTriangleBold)`
  height: ${g(3)};
  width: ${g(3)};
  flex-shrink: 0;
  fill: ${({ theme }) => theme.colors.warning1};
`
