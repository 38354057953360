import dayjs from 'dayjs'

export const checkSeniorityYearIsValid = (seniority: number | null | undefined): boolean => {
  const currentYear = dayjs().year()

  const maxSeniority = currentYear - 100
  const minSeniority = currentYear + 1

  if (seniority && !!seniority) {
    return seniority >= maxSeniority && seniority <= minSeniority
  }

  return false
}
