import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import DialogComponent from '@pretto/bricks/components/overlays/Dialog'

import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'
import {
  TrustpilotRate as TrustpilotRateComponent,
  TrustpilotRateProps,
} from '@pretto/zen/reveal/main/trustpilot/TrustpilotRate/TrustpilotRate'

import { AdrenaleadIframe } from '@pretto/app/src/components/AdrenaleadIframe/AdrenaleadIframe'
import { t } from '@pretto/app/src/lib/i18n'

import styled from 'styled-components'

import { Choices as ChoicesComponent, ChoicesProps } from '../../components/Choices/Choices'
import { LanguageSwitchDialog } from '../../components/LanguageSwitchDialog/LanguageSwitchDialog'
import { Reassurance as ReassuranceComponent } from '../../components/Reassurance/Reassurance'

interface IndexPageProps {
  purchaseChoices: ChoicesProps['items']
  trustpilotRateProps: TrustpilotRateProps
  isLanguageSwitchDialogOpen: boolean
  onConfirmLanguageSwitch: () => void
  onCloseLanguageSwitchDialog: () => void
}

export const IndexPage: React.FC<IndexPageProps> = ({
  purchaseChoices,
  trustpilotRateProps,
  isLanguageSwitchDialogOpen,
  onConfirmLanguageSwitch,
  onCloseLanguageSwitchDialog,
}) => {
  const trustpilotRateAlignment = useBreakpointToValue('twolines', { tablet: 'inline' })

  return (
    <View>
      <Content>
        <Header>
          <Title>{t('sentences.maturity.index.title')}</Title>
        </Header>

        <Choices items={purchaseChoices} />
      </Content>
      <Reassurance />
      <TrustpilotRate {...trustpilotRateProps} alignment={trustpilotRateAlignment} />

      <Dialog isOpen={isLanguageSwitchDialogOpen} onRequestClose={onCloseLanguageSwitchDialog}>
        <LanguageSwitchDialog onClose={onCloseLanguageSwitchDialog} onConfirm={onConfirmLanguageSwitch} />
      </Dialog>

      <AdrenaleadIframe type="simulationStart" />
    </View>
  )
}

const Choices = styled(ChoicesComponent)`
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
  }
`

const Content = styled.main`
  ${column([2, 4])};
  color: ${({ theme }) => theme.colors.neutral1};
  display: flex;
  flex-direction: column;
  gap: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
    gap: ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 8])};
  }
`

const Header = styled.header`
  ${({ theme }) => theme.typos.body4};
  display: flex;
  flex-direction: column;
  gap: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    text-align: center;
  }
`

const Reassurance = styled(ReassuranceComponent)`
  ${column([1, 6])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }
`

const TrustpilotRate = styled(TrustpilotRateComponent)`
  ${column([2, 4])};
  justify-content: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }
`

const Dialog = styled(DialogComponent)`
  max-width: 800px;
`

const Title = styled.h1`
  ${({ theme }) => theme.typos.heading4};
  padding-right: ${g(13 / 2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-right: 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => theme.typos.heading3};
  }
`

const View = styled.div`
  ${grid()};
  align-content: flex-start;
  background-color: ${({ theme }) => theme.colors.neutral4};
  min-height: 100%;
  padding: ${g(3)} 0;
  row-gap: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(2)} 0 ${g(10)};
    row-gap: ${g(6)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(5)} 0 ${g(8)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${g(5)} 0 ${g(7)};
  }
`
