import { ContributionPage } from '@pretto/app/src/Sentences/Contribution/Contribution/Containers/ContributionPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const ContributionController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route path={graph.matchRoute('/project/(buyout|capacity|purchase)/contribution')} component={ContributionPage} />

      <Redirect to="/" />
    </Switch>
  )
}
