import qs from 'qs'

import { getItem, setItem } from '../config/itemStorage'

// For a balanced experiment, use a string and it will be 50% a and 50% b
// For a customized experiment, use an object with name and variants.
// Variants can have a percentage or be splitted equally.
// Examples:
// Forced percentage:
// {
//   name: 'eventName',
//   variants: {
//     a: 0.8,
//     b: 0.2,
//   },
// }
// Equally splitted percentage:
// {
//   name: 'eventName',
//   variants: ['a', 'b'],
// }

interface Experiment {
  name: string
  variants: readonly string[] | { [key: string]: number }
}

type VariantsToObject<V extends Experiment['variants']> = V extends readonly string[] ? { [K in V[number]]: number } : V
type VariantsToOptions<V extends Experiment['variants']> = V extends readonly string[] ? V[number] : keyof V

const EXPERIMENTS = [
  { name: 'timeline_202112', variants: { short: 0, long: 1 } },
  { name: 'interstitial_niches_202210', variants: ['on', 'off'] },
  { name: 'consent_wording_202310', variants: { robust: 0, frail: 1 } },
  { name: 'pushy_or_mutualized_202310', variants: ['pushy'] },
  { name: 'contribution_wording_202310', variants: ['detailled', 'simple'] },
  { name: 'capacity_result_202311', variants: { classic: 1 } },
  { name: 'pushy_or_not_202402', variants: { on: 0.7, off: 0.3 } },
  { name: 'aiguillage_renegotiation_wording_270325', variants: { a: 0.4, b: 0.3, c: 0.3 } },
  { name: 'redesign_error_page_202501', variants: { new: 0.03, old: 0.97 } },
] as const satisfies readonly Experiment[]

export const all = <
  A extends (typeof EXPERIMENTS)[number],
  N extends A['name'],
  R extends { [K in N]: VariantsToOptions<Extract<A, { name: K }>['variants']> },
>(): R => EXPERIMENTS.reduce((previous, { name }) => ({ ...previous, [name]: getOrGenerate(name) }), {} as R)

const getOrGenerate = <
  N extends Experiment['name'],
  I extends Extract<(typeof EXPERIMENTS)[number], { name: N }>,
  V extends I['variants'],
>(
  experimentName: N
): VariantsToOptions<V> => {
  const experiment = EXPERIMENTS.find(({ name }) => name === experimentName)

  if (!experiment) {
    throw new Error('Cannot get or generate an experiment with no definition.')
  }

  const { name, variants } = experiment

  const storageName = `experiment-${name}`

  const currentVariant = getItem(storageName)

  if (currentVariant && (Array.isArray(variants) ? variants.includes(currentVariant) : currentVariant in variants)) {
    return currentVariant as VariantsToOptions<V>
  }

  const normalizedVariants = normalizeVariants(variants)
  const randomValue = Math.random()

  const { variant } = (Object.entries(normalizedVariants) as [VariantsToOptions<V>, number][]).reduce(
    (previous, [variant, value]) => {
      const min = previous.max
      const max = min + value

      if (randomValue > min && randomValue <= max) {
        return { variant, max }
      }

      return { ...previous, max }
    },
    { variant: null, max: 0 } as { variant: VariantsToOptions<V> | null; max: number }
  )

  if (!variant) {
    throw new Error(
      'Cannot generate an experiment based on provided numeric values. Please verify the experiment definition.'
    )
  }

  setItem(storageName, variant)

  return variant
}

const normalizeVariants = <V extends Experiment['variants']>(variants: V): VariantsToObject<V> => {
  if (Array.isArray(variants)) {
    return variants.reduce((previous, variant) => ({ ...previous, [variant]: 1 / variants.length }), [])
  }

  return variants as VariantsToObject<V>
}

// Set experiments flags with ?ab[experimentName]=variant
export const persistExperiments = () => {
  const flags = qs.parse(window.location.search.substring(1)).ab

  if (!flags) {
    return
  }

  Object.entries(flags).forEach(([experimentName, value]) => {
    if (typeof value !== 'string') {
      return
    }

    const experiment = EXPERIMENTS.find(({ name }) => name === experimentName)

    if (!experiment) {
      return
    }

    const { name, variants } = experiment

    if ((Array.isArray(variants) && variants.includes(value)) || value in variants) {
      setItem(`experiment-${name}`, value)
    }
  })
}

export default getOrGenerate
