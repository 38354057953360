import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorIncomePeriod } from '@pretto/app/src/types/api/enums'

export const mapBonusPeriodValues: MapToChoices<SimulationMortgagorIncomePeriod[]> = (values, context) => {
  if (!context) return []
  const { simulation, setSimulationComortgagor } = context

  return values.map(value => ({
    key: value,
    isSelected: simulation.profileMortgagors[1]?.incomeBonusPeriod === value,
    onClick: () => {
      setSimulationComortgagor({ incomeBonusPeriod: value })
    },
    label: t(`sentences.income.bonus.period.${value}`),
  }))
}
