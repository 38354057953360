import TagsField from '@pretto/bricks/components/form/TagsField'

import { searchPlaces } from '@pretto/places'

import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const DEFAULT_CITIES = [
  { label: 'Paris (75011)', value: { city: 'Paris', zipcode: '75011' } },
  { label: 'Marseille (13006)', value: { city: 'Marseille', zipcode: '13006' } },
  { label: 'Lyon (69007)', value: { city: 'Lyon', zipcode: '69007' } },
  { label: 'Toulouse', value: { city: 'Toulouse', zipcode: '31000' } },
  { label: 'Nice', value: { city: 'Nice', zipcode: '06000' } },
  { label: 'Nantes', value: { city: 'Nantes', zipcode: '44000' } },
]

const CitiesField = ({ onBlur, onChange, value = [], ...props }) => {
  const [searchValue, setSearchValue] = useState('')
  const [searchValues, setSearchValues] = useState([])

  useEffect(() => {
    if (searchValue === '') {
      setSearchValues([])
      return
    }

    ;(async () => {
      const results = await searchPlaces(searchValue, { limit: 6 })
      setSearchValues(results)
    })()
  }, [searchValue])

  const handleAfterOpen = () => setSearchValue('')
  const handleClear = () => setSearchValue('')
  const handleSearch = value => setSearchValue(value)
  const handleSelect = ({ value: selectedValue }) => {
    if (value.some(value => isEqual(value, selectedValue))) {
      onChange(value.filter(value => !isEqual(value, selectedValue)))
      return
    }

    onChange([...value, selectedValue])
  }

  const results = (searchValues.length === 0 ? DEFAULT_CITIES : searchValues).filter(({ value: searchValue }) =>
    value.every(value => !isEqual(value, searchValue))
  )

  const tags = value.map(value => ({
    children: `${value.city} (${value.zipcode})`,
    onClick: () => handleSelect({ value }),
  }))

  return (
    <TagsField
      {...props}
      onAfterOpen={handleAfterOpen}
      onBlur={onBlur}
      onClear={handleClear}
      onSearch={handleSearch}
      onSelect={handleSelect}
      results={results}
      searchValue={searchValue}
      tags={tags}
    />
  )
}

CitiesField.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.array,
}

export default CitiesField
