import * as Types from '@pretto/app/src/types/api/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SimulationAnalysisQueryVariables = Types.Exact<{
  input: Types.SimulationAnalysisInput;
}>;


export type SimulationAnalysisQuery = { __typename?: 'RootQuery', simulationAnalysis?: { __typename?: 'SimulationAnalysis', id: string, failures: Array<{ __typename?: 'SimulationAnalysisFailure', id: string, title: string, description: string }>, recommendations: Array<{ __typename?: 'SimulationAnalysisRecommendation', id: string, description: string, tag: string, title: string, action?: { __typename?: 'SimulationAnalysisRecommendationAction', id: string, title: string, path: string } | null, links?: Array<{ __typename?: 'SimulationAnalysisRecommendationLink', id: string, kind: Types.SimulationAnalysisRecommendationLinkKind, title: string, url: string, imageUrl: string }> | null }> } | null };


export const SimulationAnalysisDocument = gql`
    query SimulationAnalysis($input: SimulationAnalysisInput!) {
  simulationAnalysis(input: $input) {
    id
    failures {
      id
      title
      description
    }
    recommendations {
      id
      description
      tag
      title
      action {
        id
        title
        path
      }
      links {
        id
        kind
        title
        url
        imageUrl
      }
    }
  }
}
    `;

/**
 * __useSimulationAnalysisQuery__
 *
 * To run a query within a React component, call `useSimulationAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulationAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulationAnalysisQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimulationAnalysisQuery(baseOptions: Apollo.QueryHookOptions<SimulationAnalysisQuery, SimulationAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimulationAnalysisQuery, SimulationAnalysisQueryVariables>(SimulationAnalysisDocument, options);
      }
export function useSimulationAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimulationAnalysisQuery, SimulationAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimulationAnalysisQuery, SimulationAnalysisQueryVariables>(SimulationAnalysisDocument, options);
        }
export type SimulationAnalysisQueryHookResult = ReturnType<typeof useSimulationAnalysisQuery>;
export type SimulationAnalysisLazyQueryHookResult = ReturnType<typeof useSimulationAnalysisLazyQuery>;
export type SimulationAnalysisQueryResult = Apollo.QueryResult<SimulationAnalysisQuery, SimulationAnalysisQueryVariables>;