import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import translation from '@pretto/app/src/Sentences/config/translations/fr'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import { isNil } from 'ramda'

export const EcoPtzPage: React.FC = () => {
  const { simulation, setSimulation } = useSentences()

  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(
    simulation.propertyHasRenovationWorks ? 1 : 0,
    Boolean(nextRoute && !isNil(simulation.propertyHasRenovationWorks) && simulation.propertyHasRenovationWorks)
  )

  const mapEcoPtzValues = (values: [key: string, value: string][]) => {
    return values.map(([key, value]) => {
      return {
        key,
        value,
        isSelected:
          (key === YesNo.Yes && simulation.propertyHasRenovationWorks === true) ||
          (key === YesNo.No && simulation.propertyHasRenovationWorks === false),
        onClick: () => {
          setSimulation({ propertyHasRenovationWorks: key === YesNo.Yes })
          setActiveField(key === YesNo.Yes ? 1 : 0)
          goToNextRoute()
        },
        label: t(`sentences.property.ecoPtz.values.${key}`),
      }
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'ecoPtz' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t('sentences.property.ecoPtz.sentences.0')}
        <Prompt isFilled={!isNil(simulation.propertyHasRenovationWorks)} onClick={() => setActiveField(0)}>
          {t(
            `sentences.property.ecoPtz.values.${
              simulation.propertyHasRenovationWorks || isNil(simulation.propertyHasRenovationWorks)
                ? YesNo.Yes
                : YesNo.No
            }`
          )}
        </Prompt>
        {t('sentences.property.ecoPtz.sentences.1')}
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapEcoPtzValues(Object.entries(translation.sentences.property.ecoPtz.values))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'ecoPtz' } }}
        />
      )}
      <Message>{t('sentences.tips.ecoPtz')}</Message>
      {!isNil(simulation.propertyHasRenovationWorks) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
