import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { InfoMessage } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const ExpatriatePage: React.FC = () => {
  const { simulation, setSimulationMortgagor } = useSentences()
  const expatriate = simulation.profileMortgagors[0]?.occupationExpatriation
  const { nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && !isNil(expatriate)))

  const mapExpatriateValues = (values: YesNo[]) => {
    return values.map(value => {
      return {
        key: value,
        value,
        isSelected: (value === YesNo.Yes && expatriate) || (value === YesNo.No && !expatriate && !isNil(expatriate)),
        onClick: () => {
          setSimulationMortgagor({ occupationExpatriation: value === YesNo.Yes })
        },
        label: t(`sentences.situation.expatriate.values.${value}`),
      }
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'expatriate' } }}>
      <Title>{t('sentences.situation.title')}</Title>
      <div>
        {t('sentences.situation.expatriate.sentences.0')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!isNil(expatriate)}>
          {t(`sentences.situation.expatriate.values.${expatriate || isNil(expatriate) ? YesNo.Yes : YesNo.No}`)}
        </Prompt>
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapExpatriateValues(Object.values(YesNo))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'expatriate' } }}
        />
      )}

      {expatriate && <InfoMessage>{t('sentences.situation.expatriate.info')} </InfoMessage>}

      <ButtonNext>{t('sentences.next')}</ButtonNext>
    </TrackedView>
  )
}
