import * as S from './ListItemSolutions.styles'
import { SolutionItem, SolutionItemProps } from './components/SolutionItem/SolutionItem'

export interface ListItemSolutionsProps {
  title: string
  data: SolutionItemProps[]
  onClickSimu: () => void
}

export const ListItemSolutions: React.FC<ListItemSolutionsProps & React.HTMLAttributes<HTMLDivElement>> = ({
  title,
  data,
  onClickSimu,
}) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      {data.map((item, index) => (
        <SolutionItem {...item} key={index} />
      ))}
      <S.ButtonSimu onClick={onClickSimu}>Modifier ma simulation</S.ButtonSimu>
    </S.Container>
  )
}
