import { temporal } from '@pretto/bricks/core/utility/temporal'

import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import { calcRemainingPrincipal } from '@pretto/app/src/Sentences/lib/renegotiation'
import { SimulationKind } from '@pretto/app/src/types/api/enums'

import { mapBank } from './lib/bank'

export const mapRenegotiation: ComposableMapper = ({ project, simulation }) => {
  if (simulation.kind !== SimulationKind.Renegotiation) {
    return { project, simulation }
  }

  return {
    project: {
      ...project,

      renegotiation: {
        get bank() {
          if (!simulation.renegotiationLoanBank) {
            return null
          }

          return mapBank(simulation.renegotiationLoanBank)
        },

        get end_date() {
          if (!simulation.renegotiationLoanDuration || !simulation.renegotiationLoanStartYear) {
            return null
          }

          return temporal(
            new Date(simulation.renegotiationLoanStartYear + simulation.renegotiationLoanDuration, 0)
          ).format('YYYY-MM-DD')
        },

        remaining_principal: calcRemainingPrincipal(simulation),
        estimated_value: simulation.renegotiationPropertyEstimatedPrice,
        rate: simulation.renegotiationLoanRate,
      },
    },
    simulation,
  }
}
