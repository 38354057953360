import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import { ButtonChoice } from '@pretto/zen/reveal/atoms/buttons/buttonChoice/ButtonChoice'

import styled from 'styled-components'

export const StepSubscribeContainer = styled.div``

export const Tag = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading6};
    display: block;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary1};
    padding: ${g(1)} ${g(1, 4)};
    border-radius: ${g(1, -4)};
    text-overflow: ellipsis;
    width: fit-content;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    flex-shrink: 0;

    margin-bottom: ${g(1)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(2)};
  }
`

export const Title = styled.div`
  ${({ theme }) => theme.typos.heading5};
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading4};
    margin-bottom: ${g(3)};
  }
`

export const AppointmentDetails = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.neutral4};
  width: 100%;
  gap: ${g(2)};
  border-radius: ${g(1)};
  margin-bottom: ${g(2)};
  padding: ${g(2)};
`

export const AdvisorPicture = styled(BaseImage).attrs({
  options: {
    crop: 'fill',
    gravity: 'face',
    height: ng(6),
    width: ng(6),
  },
})`
  display: block;
  height: ${g(6)};
  width: ${g(6)};
  border-radius: 100%;
  overflow: hidden;
  flex-shrink: 0;
`

export const TextChooseVisio = styled.div`
  margin-top: ${g(2)};
`

export const OtherOptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
  width: 100%;
  margin-top: ${g(3)};
`

export const Button = styled(ButtonChoice)`
  text-align: left;
  margin-right: auto;
`

export const Back = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: ${g(1)};
  text-decoration: none;
  ${({ theme }) => theme.typos.captionUnderline};
`

export const Picto = styled.svg`
  height: ${g(2)};
  width: ${g(2)};
  flex-shrink: 0;
  margin-right: ${g(1)};
`
