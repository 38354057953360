import { temporal } from '@pretto/bricks/core/utility/temporal'

import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { toShortISO } from '@pretto/app/src/Sentences/lib/date'
import { calcPayment } from '@pretto/app/src/lib/helpers'
import { t } from '@pretto/app/src/lib/i18n'
import {
  SimulationCreditKind,
  SimulationKind,
  SimulationMortgagorLodgingSituationKind,
  SimulationPropertyUsage,
} from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'

import { CreditBoxSummary } from './CreditBoxSummary'
import { Container, Header, Title } from './Styled'

export const OwnPropertyCredit: React.FC = () => {
  const { simulation } = useSentences()

  if (
    (simulation.kind !== SimulationKind.Capacity && simulation.kind !== SimulationKind.Purchase) ||
    simulation.propertyUsage !== SimulationPropertyUsage.PrimaryResidence ||
    (!isNil(simulation.profileMortgagors[0]?.lodgingSituationKind) &&
      simulation.profileMortgagors[0]?.lodgingSituationKind !== SimulationMortgagorLodgingSituationKind.Owner) ||
    (!isNil(simulation.profileMortgagors[1]?.lodgingSituationKind) &&
      simulation.profileMortgagors[1]?.lodgingSituationKind !== SimulationMortgagorLodgingSituationKind.Owner) ||
    !simulation.ownPropertyRemainingPrincipal ||
    !simulation.ownPropertyEndDate ||
    !simulation.ownPropertyRate ||
    !simulation.ownPropertyForSale
  ) {
    return null
  }

  return (
    <Container $isEditable={false}>
      <Header>
        <Title>{t(`sentences.charge.credits.subtitle.purchase`)} 1 :</Title>
      </Header>
      <Prompt isFilled> {t(`sentences.charge.credits.values.${SimulationCreditKind.Mortgage}`)}</Prompt>
      <CreditBoxSummary
        endDate={simulation.ownPropertyEndDate}
        kind={SimulationCreditKind.Mortgage}
        payment={calcPayment(
          simulation.ownPropertyRemainingPrincipal,
          simulation.ownPropertyRate,
          toShortISO(temporal(simulation.ownPropertyEndDate))
        )}
      />
    </Container>
  )
}
