import { g } from '@pretto/bricks/components/layout'

import { ButtonChoice } from '@pretto/zen/reveal/atoms/buttons/buttonChoice/ButtonChoice'

import { Divider } from '@pretto/app/src/Sentences/components/Styled/Divider/Divider'
import { TrackedButtonChoice } from '@pretto/app/src/Sentences/containers/TrackedButtonChoice/TrackedButtonChoice'
import { TrackingProps } from '@pretto/app/src/Sentences/lib/tracking/types/event'

import styled from 'styled-components'

export interface ChoiceProps {
  key: string
  isSelected: boolean
  onClick: () => void
  label: string
}

interface ChoicesProps extends Partial<TrackingProps> {
  choices: ChoiceProps[]
  onChoiceClick?: (key: string) => void
  withoutTracking?: boolean
}

type ButtonProps = {
  $withoutTracking?: boolean
  isSelected?: boolean
}

const Buttons = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-top: ${g(3)};
  padding-bottom: ${g(1)};
  margin-bottom: ${g(2)};

  & > * + * {
    margin-top: ${g(2)};
  }
`

const Button = styled.button.attrs<ButtonProps>(({ $withoutTracking }) => ({
  as: $withoutTracking ? ButtonChoice : TrackedButtonChoice,
}))<ButtonProps>`
  &:first-letter {
    text-transform: capitalize;
  }
`

export const Choices: React.FC<ChoicesProps> = ({
  choices,
  defaultEventTrackingType,
  events,
  onChoiceClick,
  withoutTracking,
  ...props
}) => {
  return (
    <>
      <Divider />
      <Buttons {...props}>
        {choices.map(({ key, isSelected, onClick, label }, index) => {
          const handleClick = () => {
            onClick()
            if (onChoiceClick) onChoiceClick(key)
          }

          const trackingProps = withoutTracking ? {} : { defaultEventTrackingType, events }

          return (
            <Button
              key={key + index}
              isSelected={isSelected}
              onClick={handleClick}
              $withoutTracking={withoutTracking}
              {...trackingProps}
            >
              {label}
            </Button>
          )
        })}
      </Buttons>
    </>
  )
}
