import PropTypes from 'prop-types'

import * as S from './styles'

const GoogleOrEmail = ({
  onGoogleConnect,
  emailComponent,
  googleText,
  buttonText,
  information,
  isButtonDisabled,
  isButtonLoading,
  onSubmit,
  textConnect,
  ...props
}) => {
  return (
    <div {...props}>
      {window.location.hostname.split('.')[0] === 'app' && (
        <div>
          <S.GoogleConnect onClick={onGoogleConnect}>{googleText}</S.GoogleConnect>
          <S.TextConnect>{textConnect}</S.TextConnect>
        </div>
      )}
      {emailComponent}
      <S.ConnectCTA isLoading={isButtonLoading} onClick={onSubmit}>
        {buttonText}
      </S.ConnectCTA>
      <S.Info>
        <S.Icon />
        <S.Caption>{information}</S.Caption>
      </S.Info>
    </div>
  )
}

GoogleOrEmail.propTypes = {
  buttonText: PropTypes.string,
  emailComponent: PropTypes.element,
  googleText: PropTypes.string.isRequired,
  information: PropTypes.node,
  isButtonDisabled: PropTypes.bool,
  isButtonLoading: PropTypes.bool,
  onGoogleConnect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  textConnect: PropTypes.string,
}

export default GoogleOrEmail
