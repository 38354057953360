import { mapDuration } from '@pretto/app/src/Sentences/ComortgagorOccupation/Sector/lib/mappers/mapDuration'
import { mapPrivateRole } from '@pretto/app/src/Sentences/ComortgagorOccupation/Sector/lib/mappers/mapPrivateRole'
import { mapStartMonth } from '@pretto/app/src/Sentences/ComortgagorOccupation/Sector/lib/mappers/mapStartMonth'
import { mapTrialPeriod } from '@pretto/app/src/Sentences/ComortgagorOccupation/Sector/lib/mappers/mapTrialPeriod'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { Month, YesNo } from '@pretto/app/src/Sentences/types/enums'
import {
  SimulationMortgagorOccupationDuration,
  SimulationMortgagorOccupationPrivateRole,
} from '@pretto/app/src/types/api/enums'

interface PrivateChoicesProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const PrivateChoices: React.FC<PrivateChoicesProps> = ({ activeField, onSetActive }) => {
  const context = useSentences()

  const { simulation } = context

  const { goToNextRoute } = useRoutesGraph()

  const isOnTrial = simulation.profileMortgagors[1].occupationTrialPeriod === true

  const handleChoiceClick = () => {
    if (!isOnTrial) {
      goToNextRoute()
    }
  }

  return (
    <>
      {activeField === 1 && (
        <Choices
          choices={mapDuration(
            Object.values([
              SimulationMortgagorOccupationDuration.LongTerm,
              SimulationMortgagorOccupationDuration.ShortTerm,
            ]),
            context
          )}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationPrivateContractType' },
          }}
          onChoiceClick={() => onSetActive(2)}
        />
      )}
      {activeField === 2 &&
        simulation.profileMortgagors[1]?.occupationDuration === SimulationMortgagorOccupationDuration.LongTerm && (
          <Choices
            choices={mapPrivateRole(
              Object.values([
                SimulationMortgagorOccupationPrivateRole.Executive,
                SimulationMortgagorOccupationPrivateRole.NonExecutive,
              ]),
              context
            )}
            events={{
              eventName: EventName.SimulationChoiceClicked,
              eventPayload: { choice: 'coMortgagorOccupationPrivateStatus' },
            }}
            onChoiceClick={() => {
              onSetActive(3)
            }}
          />
        )}
      {activeField === 3 &&
        simulation.profileMortgagors[1]?.occupationDuration === SimulationMortgagorOccupationDuration.LongTerm && (
          <Choices
            choices={mapTrialPeriod(Object.values([YesNo.No, YesNo.Yes]), context)}
            events={{
              eventName: EventName.SimulationChoiceClicked,
              eventPayload: { choice: 'coMortgagorOccupationPrivateTrial' },
            }}
            onChoiceClick={handleChoiceClick}
          />
        )}
      {activeField === 2 &&
        simulation.profileMortgagors[1]?.occupationDuration === SimulationMortgagorOccupationDuration.ShortTerm && (
          <Choices
            choices={mapStartMonth(Object.values(Month), context)}
            events={{
              eventName: EventName.SimulationChoiceClicked,
              eventPayload: { choice: 'coMortgagorOccupationPrivateShortTerm' },
            }}
            onChoiceClick={() => {
              onSetActive(4)
            }}
          />
        )}
    </>
  )
}
