import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { usePrettoSearch } from '@pretto/app/src/Sentences/contexts/PrettoSearchContext'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { tooltipEmail } from '@pretto/app/src/Sentences/lib/email'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useTracking } from '@pretto/app/src/Sentences/lib/tracking/useTracking'
import { Frequency, Rooms } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import SubscribeConsumer from '@pretto/app/src/SharedContainers/Subscribe'
import { t } from '@pretto/app/src/lib/i18n'

import { isNil } from 'lodash'
import qs from 'qs'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce'

const Disclaimer = styled.div`
  ${({ theme }) => theme.typos.caption};
`

export const CriteriaEmailPage: React.FC = () => {
  const { getValues, prettoSearch, setPrettoSearch } = usePrettoSearch()

  const { goToNextRoute } = useRoutesGraph()

  const trackAction = useTracking()

  const getDefaultActiveField = () => {
    if (!prettoSearch.areaMin) {
      return 0
    }
    if (!prettoSearch.areaMax) {
      return 1
    }
    if (!prettoSearch.numberOfRooms) {
      return 2
    }
    return 3
  }
  const [activeField, setActiveField] = useState(getDefaultActiveField())
  const [debouncedEmail] = useDebounce(prettoSearch.email, 1200)

  const handleChangeAreaMin = (value: string) => {
    setPrettoSearch({ areaMin: toIntOrNull(value) })
    setActiveField(1)
  }

  const handleChangeAreaMax = (value: string) => {
    setPrettoSearch({ areaMax: toIntOrNull(value) })
    setActiveField(2)
  }

  const mapRoomsValues: MapToChoices<Rooms[]> = values => {
    return values.map(value => ({
      key: value,
      isSelected: prettoSearch.numberOfRooms === value,
      onClick: () => {
        setPrettoSearch({ numberOfRooms: value })
      },
      label: t(`sentences.propertySearch.criteriaEmail.values.${value}`),
    }))
  }

  return (
    <SubscribeConsumer>
      {subscribeContext => (
        <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'criteriaEmail' } }}>
          <Title>{t('sentences.propertySearch.title')}</Title>
          <div>
            {t('sentences.propertySearch.criteriaEmail.sentences.0')}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'propertySearchAreaMin' } }}
              inputProps={{ placeholder: '60', type: 'integer' }}
              onChange={handleChangeAreaMin}
              onClick={() => {
                setActiveField(0)
              }}
              value={prettoSearch.areaMin?.toString() || ''}
            />
            {t('sentences.propertySearch.criteriaEmail.sentences.1')}
            {activeField > 0 && (
              <>
                {t('sentences.propertySearch.criteriaEmail.sentences.2')}
                <TrackedResizableField
                  clearable
                  events={{
                    eventName: EventName.SimulationFieldChanged,
                    eventPayload: { field: 'propertySearchAreaMax' },
                  }}
                  inputProps={{ placeholder: '80', type: 'integer' }}
                  onChange={handleChangeAreaMax}
                  onClick={() => {
                    setActiveField(1)
                  }}
                  value={prettoSearch.areaMax?.toString() || ''}
                />
                {t('sentences.propertySearch.criteriaEmail.sentences.3')}
              </>
            )}
            {activeField > 1 && (
              <>
                {t('sentences.propertySearch.criteriaEmail.sentences.4')}
                <Prompt isFilled={!isNil(prettoSearch.numberOfRooms)} onClick={() => setActiveField(2)}>
                  {t(
                    `sentences.propertySearch.criteriaEmail.values.${
                      (prettoSearch.numberOfRooms?.toString() || Rooms.One) as Rooms
                    }`
                  )}
                </Prompt>
                {t('sentences.propertySearch.criteriaEmail.sentences.5')}
              </>
            )}
            {activeField > 2 && (
              <>
                <br />
                {t('sentences.propertySearch.criteriaEmail.sentences.6')}
                <TrackedResizableField
                  {...tooltipEmail(debouncedEmail || '')}
                  clearable
                  events={{
                    eventName: EventName.SimulationFieldChanged,
                    eventPayload: { field: 'propertySearchEmail' },
                  }}
                  inputProps={{ placeholder: 'bruce.wayne@gmail.com', spellCheck: false, type: 'email' }}
                  onChange={value => {
                    setPrettoSearch({ email: value })
                  }}
                  onClick={() => {
                    setActiveField(3)
                  }}
                  value={prettoSearch.email || ''}
                />
                {t('sentences.propertySearch.criteriaEmail.sentences.7')}
                {!!prettoSearch.email && (
                  <Disclaimer>
                    {t('sentences.propertySearch.criteriaEmail.disclaimer.0')}{' '}
                    <Link to={'https://www.pretto.fr/cgu'}>
                      {t('sentences.propertySearch.criteriaEmail.disclaimer.1')}
                    </Link>
                  </Disclaimer>
                )}
              </>
            )}
          </div>
          {activeField === 2 && (
            <Choices
              choices={mapRoomsValues(Object.values(Rooms))}
              events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'propertySearchRooms' } }}
              onChoiceClick={() => setActiveField(3)}
            />
          )}
          {prettoSearch.email && (
            <ButtonNext
              onClick={async (event: React.MouseEvent<HTMLButtonElement> & React.MouseEvent<HTMLAnchorElement>) => {
                event.preventDefault()

                const shouldSubscribe = prettoSearch.frequency && prettoSearch.frequency !== Frequency.Never
                const values = getValues()

                trackAction([
                  {
                    eventName: EventName.SimulationPropertySearchDeclared,
                    get eventPayload() {
                      if (!shouldSubscribe) {
                        return { property_search_frequency: shouldSubscribe }
                      }

                      return {
                        property_search_frequency: shouldSubscribe,
                        ...Object.entries(values).reduce(
                          (previous, [key, value]) => ({ ...previous, [`property_search_criteria_${key}`]: value }),
                          {}
                        ),
                      }
                    },
                  },
                ])

                if (shouldSubscribe) {
                  await subscribeContext.onSignUp({
                    assignment: 'project',
                    email: prettoSearch.email,
                    kind: 'property_search_alert',
                    source: 'sentences_property_search_alert',
                    subscribePropertySearchAlert: true,
                  })

                  goToNextRoute({
                    search: qs.stringify(
                      { propertySearch: { ...values } },
                      { addQueryPrefix: true, encodeValuesOnly: true }
                    ),
                  })
                }
              }}
            >
              {t('sentences.next')}
            </ButtonNext>
          )}
        </TrackedView>
      )}
    </SubscribeConsumer>
  )
}
