import { checkSeniorityYearIsValid } from '@pretto/app/src/Sentences/Occupation/Sector/lib/checkSeniorityYearIsValid'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { intToMonth } from '@pretto/app/src/Sentences/lib/intToMonth'
import { getJobStartYearPlaceholder } from '@pretto/app/src/Sentences/lib/placeholders'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { t } from '@pretto/app/src/lib/i18n'
import {
  SimulationMortgagorOccupationKind,
  SimulationMortgagorOccupationOtherStatus,
} from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'
import { useDebounce } from 'use-debounce'

interface OthersSentenceProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const OthersSentence: React.FC<OthersSentenceProps> = ({ activeField, onSetActive }) => {
  const { simulation, setSimulationComortgagor } = useSentences()

  const [debouncedStartYear] = useDebounce(simulation.profileMortgagors[1]?.occupationStartYear, 1000)

  if (!simulation.profileMortgagors[1]) {
    return null
  }

  const handleYearChange = (value: string) => {
    setSimulationComortgagor({ occupationStartYear: toIntOrNull(value) })
  }

  return (
    <>
      {simulation.profileMortgagors[1].occupationKind !== SimulationMortgagorOccupationKind.Medical && (
        <>
          {t('sentences.situation.others.sentence')}
          <Prompt
            onClick={() => {
              onSetActive(1)
            }}
            isFilled={!!simulation.profileMortgagors[1].occupationOtherStatus}
          >
            {t(`sentences.situation.others.values.${simulation.profileMortgagors[1].occupationOtherStatus}`)}
          </Prompt>
        </>
      )}
      {((activeField > 1 &&
        simulation.profileMortgagors[1].occupationOtherStatus !== SimulationMortgagorOccupationOtherStatus.Retired) ||
        (activeField === 1 &&
          simulation.profileMortgagors[1].occupationKind === SimulationMortgagorOccupationKind.Medical)) && (
        <>
          {t('sentences.situation.since')}
          <Prompt
            onClick={() => onSetActive(2)}
            isFilled={!isNil(simulation.profileMortgagors[1].occupationStartMonth)}
          >
            {t(`sentences.months.${intToMonth(simulation.profileMortgagors[1].occupationStartMonth ?? 0)}`)}
          </Prompt>
          <TrackedResizableField
            clearable
            events={{
              eventName: EventName.SimulationFieldChanged,
              eventPayload: { field: 'coMortgagorOccupationOthersSince' },
            }}
            inputProps={{ format: '0000', placeholder: getJobStartYearPlaceholder(), type: 'date' }}
            message={t('sentences.situation.error')}
            onChange={handleYearChange}
            state={checkSeniorityYearIsValid(debouncedStartYear) || !debouncedStartYear ? 'default' : 'error'}
            value={simulation.profileMortgagors[1].occupationStartYear?.toString() || ''}
          />
        </>
      )}
    </>
  )
}
