import { temporal } from '@pretto/bricks/core/utility/temporal'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { getDateStatus } from '@pretto/app/src/Sentences/Maturity/Default/lib/getDateStatus'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { usePrettoSearch } from '@pretto/app/src/Sentences/contexts/PrettoSearchContext'
import { useRateAlert } from '@pretto/app/src/Sentences/contexts/RateAlertContext'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { fromFormattedToShortISO, inputDateFormat, placeholeDateFormat } from '@pretto/app/src/Sentences/lib/date'
import { getSearchParamsFromSentencesContext } from '@pretto/app/src/Sentences/lib/prefill'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { isMaturityComplete, isNotPartner, isPartner } from '@pretto/app/src/Sentences/lib/validators/context'
import { inIframe } from '@pretto/app/src/lib/helpers'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationKind, SimulationMaturityStatus } from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'

export const DefaultPage: React.FC = () => {
  const { isLoggedIn } = useAuth()
  const { prettoSearch } = usePrettoSearch()
  const { rateAlert } = useRateAlert()

  const { simulation, setSimulation } = useSentences()
  const { goToNextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(
    simulation.maturityStatus === SimulationMaturityStatus.SaleAgreementSigned ? 1 : 0,
    Boolean(
      simulation.maturityStatus &&
        ((simulation.maturityStatus === SimulationMaturityStatus.SaleAgreementSigned &&
          simulation.maturityAgreementSignedDate) ||
          simulation.maturityStatus !== SimulationMaturityStatus.SaleAgreementSigned)
    )
  )
  const [date, setDate] = useState<string>(
    simulation.maturityAgreementSignedDate
      ? temporal(simulation.maturityAgreementSignedDate).format(inputDateFormat)
      : ''
  )

  const [debouncedDate] = useDebounce(simulation.maturityAgreementSignedDate, 1200)
  const dateStatus = getDateStatus(debouncedDate, date)

  const getContextUpdates = (maturityStatus: SimulationMaturityStatus) => {
    if (maturityStatus !== SimulationMaturityStatus.SearchStarted) {
      return {
        maturityStatus,
        kind:
          maturityStatus === SimulationMaturityStatus.UnderStudy ? SimulationKind.Capacity : SimulationKind.Purchase,
      }
    }

    return { maturityStatus }
  }

  const handleClickMaturity = (type: SimulationMaturityStatus) => {
    const contextUpdates = getContextUpdates(type)
    if (inIframe() && isPartner({ isLoggedIn, prettoSearch, rateAlert, simulation })) {
      window.open(
        `${window.location.origin}/project/maturity?${getSearchParamsFromSentencesContext(contextUpdates)}`,
        '_blank'
      )
      return
    }
    setSimulation(contextUpdates)
    if (type !== SimulationMaturityStatus.SaleAgreementSigned) {
      goToNextRoute()
      return
    }
    setActiveField(1)
  }

  const mapMaturityValues = (values: SimulationMaturityStatus[]) => {
    return values.map(value => {
      return {
        key: value,
        value,
        isSelected: simulation.maturityStatus === value,
        onClick: () => {
          handleClickMaturity(value)
        },
        label: t(`sentences.maturity.default.values.${value}`),
      }
    })
  }

  const handleDateChange = (value: string) => {
    setDate(value)

    setSimulation({
      maturityAgreementSignedDate: fromFormattedToShortISO(value),
    })
  }

  const handlePromptClick = () => {
    setActiveField(0)
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'maturityDefault' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t('sentences.maturity.type')}
        {simulation.maturityStatus === SimulationMaturityStatus.SaleAgreementSigned &&
        isNotPartner({ isLoggedIn, prettoSearch, rateAlert, simulation }) &&
        !isLoggedIn ? (
          <> {t(`sentences.maturity.default.values.${SimulationMaturityStatus.SaleAgreementSigned}`)} </>
        ) : (
          <Prompt onClick={handlePromptClick} isFilled={!isNil(simulation.maturityStatus)}>
            {t(
              `sentences.maturity.default.values.${
                isNil(simulation.maturityStatus) ? SimulationMaturityStatus.OfferAccepted : simulation.maturityStatus
              }`
            )}
          </Prompt>
        )}
        {activeField >= 1 && simulation.maturityStatus === SimulationMaturityStatus.SaleAgreementSigned && (
          <>
            {t('sentences.maturity.agreementSignedDate')}
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'introductionMaturityDate' },
              }}
              inputProps={{ autoFocus: activeField === 1, placeholder: placeholeDateFormat, type: 'date' }}
              message={dateStatus.message}
              onChange={handleDateChange}
              state={dateStatus.state}
              value={date}
            />
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapMaturityValues(
            Object.values([
              SimulationMaturityStatus.UnderStudy,
              SimulationMaturityStatus.SearchStarted,
              SimulationMaturityStatus.OfferMade,
              SimulationMaturityStatus.OfferAccepted,
              SimulationMaturityStatus.SaleAgreementSigned,
            ])
          )}
          events={[
            { eventName: EventName.MaturityDeclared },
            { eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'maturity' } },
          ]}
        />
      )}
      {simulation.kind && <Message>{t(`sentences.tips.project.${simulation.kind}`)}</Message>}
      {isMaturityComplete({ isLoggedIn, prettoSearch, rateAlert, simulation }) && (
        <ButtonNext>{t('sentences.next')}</ButtonNext>
      )}
    </TrackedView>
  )
}
