import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { ErrorMessage } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationStructureSciKind } from '@pretto/app/src/types/api/enums'

export const SciTypePage: React.FC = () => {
  const { simulation, setSimulation } = useSentences()
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && simulation.structureKind))

  const saveSciType = (value: SimulationStructureSciKind) => {
    setSimulation({ structureSciKind: value })
    if (value !== SimulationStructureSciKind.Is) return goToNextRoute()
  }

  // TODO: externalize this function
  const mapSciTypeValues = (values: SimulationStructureSciKind[]) => {
    return values.map(value => {
      return {
        key: value,
        value,
        isSelected: simulation.structureSciKind === value,
        onClick: () => saveSciType(value),
        label: t(`sentences.introduction.sci.values.${value}`),
      }
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'sciType' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t('sentences.introduction.sci.type')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!!simulation.structureSciKind}>
          {t(`sentences.introduction.sci.values.${simulation.structureSciKind || SimulationStructureSciKind.Ir}`)}
        </Prompt>
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapSciTypeValues(Object.values([SimulationStructureSciKind.Is, SimulationStructureSciKind.Ir]))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'sciType' } }}
        />
      )}
      {simulation.structureSciKind === SimulationStructureSciKind.Is && (
        <ErrorMessage>{t(`sentences.introduction.sci.error.sciType`)}</ErrorMessage>
      )}
      {simulation.structureSciKind === SimulationStructureSciKind.Ir && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
