import Card from '@pretto/bricks/components/cards/Card'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import PropTypes from 'prop-types'
import * as S from './styles'
import { SmileyFrown, SmileyNeutral, SmileyHappy } from '@pretto/picto'


const FeedbackCard = ({ onClick }) => {

    return (
      <Card format="line" variant="neutral-1-20">
        <S.Content>
          <SubHeading>
            Les explications fournies vous aident-elles à comprendre pourquoi votre projet n'est pas finançable ?
          </SubHeading>

          <S.ButtonContainer>
          <S.Button onClick={() => onClick("POSITIVE")}>
            <SmileyHappy width="32px" height="32px" fill="#0C806B"  />
          </S.Button>

          <S.Button onClick={() => onClick("NEUTRAL")}>
            <SmileyNeutral width="32px" height="32px" fill="#050505" />
          </S.Button>

          <S.Button onClick={() => onClick("WRONG")}>
            <SmileyFrown width="32px" height="32px" fill="#C70808" />
          </S.Button>
        </S.ButtonContainer>
        </S.Content>
      </Card>
    )
}

FeedbackCard.propTypes = {
  /** Function triggered button is clicked. Useful for tracking or other extra click action. */
  onClick: PropTypes.func, 
}


export default FeedbackCard