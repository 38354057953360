import { breakpoints, g } from '@pretto/bricks/components/layout'

import { SmileyFrown, SmileyHappy, SmileyNeutral } from '@pretto/picto'

import styled from 'styled-components'

export const IconHappy = styled(SmileyHappy)`
  width: ${g(4)};
  height: ${g(4)};
  fill: ${({ theme }) => theme.colors.primary2};
`

export const IconNeutral = styled(SmileyNeutral)`
  width: ${g(4)};
  height: ${g(4)};
  fill: ${({ theme }) => theme.colors.neutral1};
`

export const IconFrown = styled(SmileyFrown)`
  width: ${g(4)};
  height: ${g(4)};
  fill: ${({ theme }) => theme.colors.error1};
`

export const Container = styled.div`
  padding: 0;
  background-color: transparent;
  border-radius: ${g(1)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: ${({ theme }) => theme.colors.neutral4};
    padding: ${g(3)} ${g(4)};
  }
`
export const Heading = styled.h6`
  ${({ theme }) => theme.typos.heading6};
  text-align: center;
`
export const ButtonContainer = styled.div`
  margin-top: ${g(3)};
  display: flex;
  flex-direction: row;
`
export const Button = styled.button`
  cursor: pointer;
  display: flex;
  padding: ${g(2)};
  border-radius: ${g(1)};
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(5, 5, 5, 0.15);

  &:hover {
    box-shadow: 0px 2px 8px 0px rgba(5, 5, 5, 0.15);
  }

  &:first-of-type {
    margin-right: ${g(3)};
  }

  &:last-of-type {
    margin-left: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: #fff;
    box-shadow: unset;
  }
`
