import { g, ng, breakpoints } from '@pretto/bricks/components/layout'
import ImageComponent from '@pretto/bricks/website/shared/components/Image'

import { PrimaryButtonFix as PrimaryButtonFixComponent } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'

import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral4};
  padding: ${g(2)};
  border-radius: ${g(1)};
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(4)};
    display: flex;
    flex-direction: row;
  }
`
export const Title = styled.h5`
  ${({ theme }) => theme.typos.heading5};
  margin: 0 0 ${g(1)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading4};
    margin: 0 0 ${g(2)} 0;
  }
`
export const Text = styled.p`
  ${({ theme }) => theme.typos.body4};
  margin: 0 0 ${g(1)} 0;
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 0 ${g(2)} 0;
  }
`
export const Cta = styled(PrimaryButtonFixComponent).attrs({ scheme: 'black' })`
  width: 100%;
  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
  }
`
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Image = styled(ImageComponent).attrs({
  options: { height: ng(30), width: ng(30) },
})``
