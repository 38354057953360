import { g } from '@pretto/bricks/components/layout'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { BuyoutCredit } from '@pretto/app/src/Sentences/Charge/Credits/components/BuyoutCredit'
import { CreditBox as CreditBoxComponent } from '@pretto/app/src/Sentences/Charge/Credits/components/CreditBox'
import { MedicalAd } from '@pretto/app/src/Sentences/Charge/Credits/components/MedicalAd'
import { OwnPropertyCredit } from '@pretto/app/src/Sentences/Charge/Credits/components/OwnPropertyCredit'
import { RenegotiationCredit } from '@pretto/app/src/Sentences/Charge/Credits/components/RenegotiationCredit'
import { DividerWithMarginBottom } from '@pretto/app/src/Sentences/components/Styled/Divider/Divider'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedButtonInline } from '@pretto/app/src/Sentences/containers/TrackedButtonInline/TrackedButtonInline'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { usePrettoSearch } from '@pretto/app/src/Sentences/contexts/PrettoSearchContext'
import { useRateAlert } from '@pretto/app/src/Sentences/contexts/RateAlertContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { handleCreditDateState } from '@pretto/app/src/Sentences/lib/credits'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { hasOneMedical, isNotSolo } from '@pretto/app/src/Sentences/lib/validators/context'
import { getItem, setItem } from '@pretto/app/src/config/itemStorage'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationCreditKind, SimulationKind } from '@pretto/app/src/types/api/enums'

import { useState } from 'react'
import styled, { css } from 'styled-components'

const CreditBox = styled(CreditBoxComponent)<{ $hasMarginTop: boolean }>`
  ${({ $hasMarginTop }) =>
    $hasMarginTop &&
    css`
      margin-top: ${g(2)};
    `}
`

const INTERSTITIAL_MEDICAL = 'INTERSTITIAL_MEDICAL_VISIBILITY'

export const CreditsPage: React.FC = () => {
  const { isLoggedIn } = useAuth()
  const { prettoSearch } = usePrettoSearch()
  const { rateAlert } = useRateAlert()

  const [activeField, setActiveField] = useActiveField(0, false)

  const { addSimulationCredit, deleteSimulationCredit, setSimulationCredit, simulation } = useSentences()

  const hasBuyoutDeclaredCredit =
    simulation.kind === SimulationKind.Buyout && simulation.buyoutHasCredit && simulation.buyoutCreditRemainingPrincipal

  const hasOwnPropertyDeclaredCredit =
    simulation.ownPropertyRemainingPrincipal && simulation.ownPropertyEndDate && simulation.ownPropertyRate

  const hasRenegotiationDeclaredCredit =
    simulation.kind === SimulationKind.Renegotiation && simulation.renegotiationLoanPayment

  const indexOffset = [hasBuyoutDeclaredCredit, hasOwnPropertyDeclaredCredit, hasRenegotiationDeclaredCredit].filter(
    Boolean
  ).length

  const [editableId, setEditableId] = useState<string | null>(null)

  const eachCreditIsFilledCorrectly = simulation.chargesCredits.every(
    ({ endDate }) => !!endDate && handleCreditDateState(endDate).state === 'default'
  )

  const [isInterstitialShown, setIsInterstitialShown] = useState(
    !getItem(INTERSTITIAL_MEDICAL) && hasOneMedical({ isLoggedIn, prettoSearch, rateAlert, simulation })
  )

  if (isInterstitialShown) {
    const handleMedicalNext = () => {
      setIsInterstitialShown(false)
      setItem(INTERSTITIAL_MEDICAL, 'hidden')
    }
    return <MedicalAd onNextClick={handleMedicalNext} />
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'credits' } }}>
      <Title>{t('sentences.charge.title')}</Title>
      <div>
        <RenegotiationCredit />
        <OwnPropertyCredit />
        <BuyoutCredit />

        {simulation.chargesCredits.map(({ id, ...credit }, index) => {
          const choices = Object.values([
            SimulationCreditKind.Mortgage,
            SimulationCreditKind.Consumer,
            SimulationCreditKind.Car,
            SimulationCreditKind.Student,
            SimulationCreditKind.Loa,
            SimulationCreditKind.Other,
          ]).map(kind => {
            return {
              key: kind,
              isSelected: credit.kind === kind,
              onClick: () => {
                setSimulationCredit({ id, kind })
              },
              label: t(`sentences.charge.credits.values.${kind}`),
            }
          })

          return (
            <CreditBox
              $hasMarginTop={indexOffset > 0}
              key={id}
              activeField={activeField}
              onChangeAmount={(value: string) => {
                setSimulationCredit({ id, payment: toIntOrNull(value) })
                setActiveField(2)
              }}
              onChangeDate={(endDate: string) => {
                setSimulationCredit({ id, endDate })
              }}
              onPromptClick={() => {
                setActiveField(0)
              }}
              onChoiceClick={() => {
                setActiveField(1)
              }}
              onClick={() => {
                setEditableId(id)
              }}
              onRemove={() => {
                deleteSimulationCredit(id)
              }}
              credit={credit}
              index={index + indexOffset}
              isEditable={editableId === id}
              choices={choices}
            />
          )
        })}
      </div>
      <DividerWithMarginBottom />
      <TrackedButtonInline
        events={{ eventName: EventName.SimulationElementClicked, eventPayload: { element: 'chargeCreditsAddValue' } }}
        onClick={() => {
          setEditableId(addSimulationCredit())
          setActiveField(0)
        }}
      >
        {t('sentences.charge.credits.addValue')}
      </TrackedButtonInline>
      <Message>
        {t('sentences.tips.credits', {
          context: isNotSolo({ isLoggedIn, prettoSearch, rateAlert, simulation }) ? 'duo' : '',
        })}
      </Message>
      {eachCreditIsFilledCorrectly && (
        <ButtonNext>{t(simulation.chargesCredits.length > 0 ? 'sentences.next' : 'sentences.skip')}</ButtonNext>
      )}
    </TrackedView>
  )
}
