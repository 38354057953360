import Link from '@pretto/bricks/website/utility/Link'

import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField2'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import { isNil } from 'lodash'
import { Trans } from 'react-i18next'

export const BuyoutPage: React.FC = () => {
  const { simulation, setSimulation } = useSentences()

  const { activeField, isFilled, previousActiveField, setActiveField, refreshField } = useActiveField(
    ({ buyoutAmount, buyoutCreditRemainingPrincipal, buyoutHasCredit }) => [
      isNil(buyoutHasCredit),
      buyoutHasCredit === true && isNil(buyoutCreditRemainingPrincipal),
      isNil(buyoutAmount),
    ]
  )

  const mapHasCreditValues = (values: YesNo[]) =>
    values.map(value => ({
      key: value,
      value,
      isSelected:
        (value === YesNo.Yes && simulation.buyoutHasCredit === true) ||
        (value === YesNo.No && simulation.buyoutHasCredit === false),
      onClick: () => {
        setSimulation(({ buyoutCreditRemainingPrincipal }) => ({
          buyoutHasCredit: value === YesNo.Yes,
          buyoutCreditRemainingPrincipal: value === YesNo.No ? null : buyoutCreditRemainingPrincipal,
        }))

        setActiveField(value === YesNo.Yes ? 1 : 2)
      },
      label: t(`sentences.buyout.buyout.values.${value}`),
    }))

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'buyout' } }}>
      <Title>{t('sentences.buyout.title')}</Title>
      <div>
        {t('sentences.buyout.buyout.sentences.0')}
        <Prompt
          onClick={() => {
            setActiveField(0)
          }}
          isFilled={!isNil(simulation.buyoutHasCredit)}
        >
          {t(`sentences.buyout.buyout.values.${simulation.buyoutHasCredit === true ? YesNo.Yes : YesNo.No}`)}
        </Prompt>
        {t('sentences.buyout.buyout.sentences.1')}
      </div>
      {activeField >= 1 && simulation.buyoutHasCredit && (
        <div>
          {t('sentences.buyout.buyout.sentences.2')}
          <TrackedResizableField
            clearable
            events={{
              eventName: EventName.SimulationFieldChanged,
              eventPayload: { field: 'buyoutCreditRemainingPrincipal' },
            }}
            inputProps={{ autoFocus: true, placeholder: '200 000', type: 'integer' }}
            onChange={(value: string) => {
              const buyoutCreditRemainingPrincipal = toIntOrNull(value)
              setSimulation({ buyoutCreditRemainingPrincipal })
              refreshField({ buyoutCreditRemainingPrincipal })
            }}
            value={simulation.buyoutCreditRemainingPrincipal?.toString() || ''}
          />
          {t('sentences.buyout.buyout.sentences.3')}
        </div>
      )}
      {activeField >= 2 && (
        <div>
          {t('sentences.buyout.buyout.sentences.4')}
          <TrackedResizableField
            clearable
            events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'buyoutAmount' } }}
            inputProps={{ autoFocus: previousActiveField !== 1, placeholder: '200 000', type: 'integer' }}
            onChange={(value: string) => {
              setSimulation({ buyoutAmount: toIntOrNull(value) })
            }}
            value={simulation.buyoutAmount?.toString() || ''}
          />
          {t('sentences.buyout.buyout.sentences.5')}
        </div>
      )}
      {activeField === 0 && (
        <Choices
          choices={mapHasCreditValues(Object.values(YesNo))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'hasBuyoutCredit' } }}
        />
      )}
      {activeField >= 2 && (
        <Message>
          <Trans i18nKey="sentences.tips.buyout" components={{ Link: <Link /> }} />
        </Message>
      )}
      {isFilled && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
