import { temporal } from '@pretto/bricks/core/utility/temporal'

import { GetSimulation } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import {
  SimulationMortgagorAdditionalRevenueKind,
  SimulationMortgagorIncomePeriod,
  SimulationMortgagorIncomeTaxes,
  SimulationMortgagorLodgingSituationKind,
  SimulationMortgagorOccupationDuration,
  SimulationMortgagorOccupationKind,
  SimulationMortgagorOccupationOtherStatus,
  SimulationMortgagorOccupationPrivateRole,
  SimulationMortgagorOccupationPublicStatus,
  SimulationStructureKind,
} from '@pretto/app/src/types/api/enums'
import { SimulationMortgagorAdditionalRevenue } from '@pretto/app/src/types/api/schema'
import { Contract, Housing, IncomeKind, Recurrence, Taxes } from '@pretto/app/src/types/gateway/enums'
import { ExtraIncome, Income, Job, Mortgagor } from '@pretto/app/src/types/gateway/schema'

import isNil from 'lodash/isNil'
import { v4 as uuidv4 } from 'uuid'

import { mapLocalisation } from './lib/location'

export const mapProfile: ComposableMapper = ({ project, simulation }) => {
  const mapExtraIncomeKind = (extraIncomeKind: SimulationMortgagorAdditionalRevenueKind): IncomeKind | null => {
    switch (extraIncomeKind) {
      case SimulationMortgagorAdditionalRevenueKind.ChildSupport:
        return IncomeKind.ChildSupport

      case SimulationMortgagorAdditionalRevenueKind.ExpatriationBonus:
        return IncomeKind.ExpatriationBonus

      case SimulationMortgagorAdditionalRevenueKind.FamilyAllowances:
        return IncomeKind.FamilyAllowances

      case SimulationMortgagorAdditionalRevenueKind.ProfessionalBenefits:
        return IncomeKind.ProfessionalBenefits

      case SimulationMortgagorAdditionalRevenueKind.Rent:
        return IncomeKind.Rent

      default:
        return null
    }
  }

  const mapLodgingSituationKind = (lodgingSituationKind: SimulationMortgagorLodgingSituationKind): Housing | null => {
    switch (lodgingSituationKind) {
      case SimulationMortgagorLodgingSituationKind.Owner:
        return Housing.Owner

      case SimulationMortgagorLodgingSituationKind.Tenant:
        return Housing.Tenant

      case SimulationMortgagorLodgingSituationKind.Sheltered:
        return Housing.Sheltered

      case SimulationMortgagorLodgingSituationKind.StaffHoused:
        return Housing.StaffHoused

      default:
        return null
    }
  }

  const mapPeriod = (period: SimulationMortgagorIncomePeriod): Recurrence | null => {
    switch (period) {
      case SimulationMortgagorIncomePeriod.Monthly:
        return Recurrence.Monthly

      case SimulationMortgagorIncomePeriod.Yearly:
        return Recurrence.Yearly

      default:
        return null
    }
  }

  const mapTaxes = (period: SimulationMortgagorIncomeTaxes): Taxes | null => {
    switch (period) {
      case SimulationMortgagorIncomeTaxes.Before:
        return Taxes.Before

      case SimulationMortgagorIncomeTaxes.After:
        return Taxes.After

      default:
        return null
    }
  }

  const mapMortgagorIncomeAdditionalRevenue = ({
    kind,
    ...incomeAdditionalRevenue
  }: SimulationMortgagorAdditionalRevenue): ExtraIncome => ({
    amount: incomeAdditionalRevenue.amount,

    id: uuidv4(),

    get kind() {
      if (!kind) {
        return null
      }

      return mapExtraIncomeKind(kind)
    },
  })

  const mapMortgagors = (simulation: GetSimulation): Mortgagor[] =>
    simulation.profileMortgagors
      .reduce((previous, mortgagor, index) => {
        return [
          ...previous,
          ...[
            {
              get address() {
                if (!mortgagor.lodgingLocation) {
                  return null
                }

                return mapLocalisation(mortgagor.lodgingLocation)
              },

              age: mortgagor.age,

              get bonus() {
                if (!mortgagor.incomeHasBonus) {
                  return null
                }

                return {
                  amount: mortgagor.incomeBonusAmount,

                  get period() {
                    if (!mortgagor.incomeBonusPeriod) {
                      return null
                    }

                    return mapPeriod(mortgagor.incomeBonusPeriod)
                  },

                  get taxes() {
                    if (!mortgagor.incomeBonusTaxes) {
                      return null
                    }

                    return mapTaxes(mortgagor.incomeBonusTaxes)
                  },
                } satisfies Income
              },

              get child_support() {
                if (!mortgagor.chargesPaysChildSupport) {
                  return null
                }

                return mortgagor.chargesChildSupport
              },

              get contract() {
                switch (true) {
                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.BusinessOwner:
                    return Contract.BusinessOwner

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.Entertainment:
                    return Contract.CasualShowBusinessWorker

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Public &&
                    mortgagor.occupationPublicStatus === SimulationMortgagorOccupationPublicStatus.Contractor &&
                    mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.ShortTerm:
                    return Contract.ContractuelCdd

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Public &&
                    mortgagor.occupationPublicStatus === SimulationMortgagorOccupationPublicStatus.Contractor &&
                    mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.LongTerm:
                    return Contract.ContractuelCdi

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Public &&
                    mortgagor.occupationPublicStatus === SimulationMortgagorOccupationPublicStatus.Tenure:
                    return Contract.Tenure

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.Craftsman:
                    return Contract.Craftsman

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Medical:
                    return Contract.Doctor

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.Farmer:
                    return Contract.Farmer

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.Interim:
                    return Contract.Interim

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.Jobless:
                    return Contract.Jobless

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.Jobseeker:
                    return Contract.Jobseeker

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.LiberalLegal:
                    return Contract.Lawyer

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.LiberalOther:
                    return Contract.Liberal

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.ParentalLeave:
                    return Contract.ParentalLeave

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Private &&
                    mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.LongTerm &&
                    mortgagor.occupationPrivateRole === SimulationMortgagorOccupationPrivateRole.Executive &&
                    mortgagor.occupationTrialPeriod === false:
                    return Contract.PermanentExecutive

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Private &&
                    mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.LongTerm &&
                    mortgagor.occupationPrivateRole === SimulationMortgagorOccupationPrivateRole.Executive &&
                    mortgagor.occupationTrialPeriod === true:
                    return Contract.PermanentExecutiveTrial

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Private &&
                    mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.LongTerm &&
                    mortgagor.occupationPrivateRole === SimulationMortgagorOccupationPrivateRole.NonExecutive &&
                    mortgagor.occupationTrialPeriod === false:
                    return Contract.PermanentNonExecutive

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Private &&
                    mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.LongTerm &&
                    mortgagor.occupationPrivateRole === SimulationMortgagorOccupationPrivateRole.NonExecutive &&
                    mortgagor.occupationTrialPeriod === true:
                    return Contract.PermanentNonExecutiveTrial

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Private &&
                    mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.ShortTerm:
                    return Contract.FixedTerm

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.Remote:
                    return Contract.PortageSalarial

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.Retired:
                    return Contract.Retired

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.ManagingEmployee:
                    return Contract.SalariedDirector

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.SelfEmployed:
                    return Contract.SelfEmployed

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.Merchant:
                    return Contract.Trader

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.Student:
                    return Contract.Student

                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Public &&
                    mortgagor.occupationPublicStatus === SimulationMortgagorOccupationPublicStatus.Intern:
                    return Contract.Internship

                  default:
                    return null
                }
              },

              expatriate_status: mortgagor.occupationExpatriation ?? false,

              id: uuidv4(),

              incomes: mortgagor.incomeAdditionalRevenues.map(mapMortgagorIncomeAdditionalRevenue),

              get job() {
                if (isNil(mortgagor.occupationStartMonth) || isNil(mortgagor.occupationStartYear)) {
                  return null
                }

                switch (true) {
                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Public &&
                    mortgagor.occupationPublicStatus === SimulationMortgagorOccupationPublicStatus.Contractor &&
                    mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.ShortTerm:
                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Private &&
                    mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.ShortTerm:
                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other &&
                    mortgagor.occupationOtherStatus !== SimulationMortgagorOccupationOtherStatus.Retired:
                  case mortgagor.occupationKind === SimulationMortgagorOccupationKind.Medical:
                    return {
                      start_date: temporal(
                        new Date(mortgagor.occupationStartYear, mortgagor.occupationStartMonth)
                      ).format('YYYY-MM-DD'),
                    } satisfies Job

                  default:
                    return { start_date: null }
                }
              },

              salary: {
                amount: mortgagor.incomeSalaryAmount,

                get period() {
                  if (!mortgagor.incomeSalaryPeriod) {
                    return null
                  }

                  return mapPeriod(mortgagor.incomeSalaryPeriod)
                },

                get taxes() {
                  if (!mortgagor.incomeSalaryTaxes) {
                    return null
                  }

                  return mapTaxes(mortgagor.incomeSalaryTaxes)
                },
              },

              get housing() {
                if (index > 0 && simulation.profileLiveTogether) {
                  return previous[0].housing
                }

                if (!mortgagor.lodgingSituationKind) {
                  return null
                }

                return mapLodgingSituationKind(mortgagor.lodgingSituationKind)
              },

              get rent() {
                if (index > 0 && simulation.profileLiveTogether) {
                  return null
                }

                if (
                  isNil(mortgagor.lodgingRent) ||
                  (mortgagor.lodgingSituationKind !== SimulationMortgagorLodgingSituationKind.Tenant &&
                    mortgagor.lodgingSituationKind !== SimulationMortgagorLodgingSituationKind.StaffHoused)
                ) {
                  return null
                }

                return mortgagor.lodgingRent
              },
            } satisfies Mortgagor,
          ],
        ]
      }, [] as Mortgagor[])
      .slice(0, simulation.structureKind === SimulationStructureKind.Solo ? 1 : 2)

  return {
    project: {
      ...project,

      profile: {
        ...project.profile,

        children: simulation.profileChildren,
        live_together: simulation.profileLiveTogether,
        mortgagors: mapMortgagors(simulation),
      },
    },
    simulation,
  }
}
