import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { CoachingCard } from '@pretto/app/src/Simulation/components/CoachingCard/CoachingCard'
import { trackAction } from '@pretto/app/src/lib/tracking'
import { SimulationKind } from '@pretto/app/src/types/api/enums'

import { useMemo } from 'react'

import * as S from './NonFundableFallback.styles'

const NonFundableFallback = () => {
  const { simulation } = useSentences()

  const getCurrentDate = useMemo(() => {
    const date = new Date()
    return date.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })
  }, [])

  const handleCoachingCardClick = () => {
    const trackKind = {
      CAPACITY: 'CAPACITY_ERROR_FEE_APPOINTMENT_CLICKED',
      PURCHASE: 'SIMULATION_ERROR_FEE_APPOINTMENT_CLICKED',
    } as const

    if (simulation.kind === SimulationKind.Capacity) {
      trackAction(trackKind.CAPACITY)
    }
    if (simulation.kind === SimulationKind.Purchase) {
      trackAction(trackKind.PURCHASE)
    }
  }

  return (
    <S.MainContent>
      <S.MainContentWrapper>
        <S.SectionTop>
          <S.Heading>Votre projet ne semble pas finançable…</S.Heading>
          <S.Date>Résultats au {getCurrentDate}</S.Date>
        </S.SectionTop>

        <S.Section>
          <S.Content>
            Malheureusement votre projet ne semble pas finançable par Pretto en l’état. Plusieurs raisons peuvent
            expliquer ce résultat comme un apport insuffisant, votre situation professionnelle ou un endettement trop
            élevé.
          </S.Content>
        </S.Section>

        <S.CoachingCardContainer>
          <CoachingCard
            title={'Vous voulez échanger avec un expert de Pretto ?'}
            content={
              "<strong>Réservez une consultation</strong> avec l'un de nos experts en financement. Il répondra à vos questions et vous apportera les conseils nécessaires pour réaliser votre projet immobilier"
            }
            ctaTitle={'Ça m’intéresse !'}
            ctaHref={'https://pretto-fr.typeform.com/to/Nm2myYsn'}
            picture={'v1738856705/app/illustrations/handkey.svg'}
            onCtaClick={handleCoachingCardClick}
          />
        </S.CoachingCardContainer>

        <S.Section>
          <S.Caption>
            Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager.
          </S.Caption>
        </S.Section>
      </S.MainContentWrapper>
    </S.MainContent>
  )
}

export default NonFundableFallback
