import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import {
  SimulationKind,
  SimulationMortgagorLodgingSituationKind,
  SimulationPropertyUsage,
} from '@pretto/app/src/types/api/enums'

export const mapOwnProperty: ComposableMapper = ({ project, simulation }) => {
  if (
    (simulation.kind !== SimulationKind.Capacity && simulation.kind !== SimulationKind.Purchase) ||
    !simulation.ownPropertyForSale ||
    simulation.profileMortgagors.every(
      mortgagor => mortgagor.lodgingSituationKind !== SimulationMortgagorLodgingSituationKind.Owner
    ) ||
    simulation.propertyUsage !== SimulationPropertyUsage.PrimaryResidence
  ) {
    return { project, simulation }
  }

  return {
    project: {
      ...project,

      purchase: {
        ...project.purchase,

        bridge: {
          get end_date() {
            if (simulation.ownPropertyPaidOff) {
              return null
            }

            return simulation.ownPropertyEndDate
          },

          estimated_value: simulation.ownPropertyEstimatedPrice,
          rate: simulation.ownPropertyRate,

          get remaining_principal() {
            if (simulation.ownPropertyPaidOff) {
              return null
            }

            return simulation.ownPropertyRemainingPrincipal
          },
        },

        specific_amounts: project.purchase?.specific_amounts ?? [],
      },
    },
    simulation,
  }
}
