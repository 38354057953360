import * as v from '@pretto/app/src/Sentences/lib/validators/context'
import type { GraphContext } from '@pretto/app/src/Sentences/types/graph'
import { Edge, Graph, Route } from '@pretto/app/src/lib/graph/Graph'

import allPass from 'ramda/src/allPass'
import anyPass from 'ramda/src/anyPass'

import { buyoutGraph } from './buyoutGraph'
import { capacityGraph } from './capacityGraph'
import { purchaseGraph } from './purchaseGraph'
import { renegotiationGraph } from './renegotiationGraph'

const indexRoute = new Route<GraphContext>('index', '')

export const mainGraph = new Graph<GraphContext>('mainGraph', indexRoute)

// Maturity
const maturityDefaultRoute = new Route<GraphContext>('maturityDefault', '/project/maturity')
const maturityIndexRoute = new Route<GraphContext>('maturityIndex', '/')
const maturityPropertyPriceRoute = new Route<GraphContext>('maturityPropertyPrice', '/project/maturity/propertyPrice')
const introductionOthersRoute = new Route<GraphContext>('introductionOthers', '/project/introduction/others')

mainGraph.addRoute(maturityDefaultRoute)
mainGraph.addRoute(maturityIndexRoute)
mainGraph.addRoute(maturityPropertyPriceRoute)
mainGraph.addRoute(introductionOthersRoute)

mainGraph.addGraph(buyoutGraph)
mainGraph.addGraph(capacityGraph)
mainGraph.addGraph(purchaseGraph)
mainGraph.addGraph(renegotiationGraph)

mainGraph.addEdge(new Edge<GraphContext>(indexRoute, maturityDefaultRoute, anyPass([v.isLoggedIn, v.isPartner])))
mainGraph.addEdge(new Edge<GraphContext>(indexRoute, maturityIndexRoute, allPass([v.isProspect, v.isNotPartner])))
mainGraph.addEdge(new Edge<GraphContext>(indexRoute, introductionOthersRoute))
mainGraph.addEdge(new Edge<GraphContext>(maturityIndexRoute, maturityDefaultRoute, v.isMaturityTypeSaleAgreementSigned))

mainGraph.addEdge(
  new Edge<GraphContext>(maturityDefaultRoute, maturityPropertyPriceRoute, v.isMaturityTypeSearchStarted)
)

mainGraph.addEdge(new Edge<GraphContext>(maturityIndexRoute, maturityPropertyPriceRoute, v.isMaturityTypeSearchStarted))

// Capacity
mainGraph.addEdge(
  new Edge<GraphContext>(maturityIndexRoute, capacityGraph, allPass([v.isMaturityTypeUnderStudy, v.isCapacity]))
)

mainGraph.addEdge(
  new Edge<GraphContext>(maturityDefaultRoute, capacityGraph, allPass([v.isMaturityTypeUnderStudy, v.isCapacity]))
)

mainGraph.addEdge(
  new Edge<GraphContext>(maturityPropertyPriceRoute, capacityGraph, allPass([v.hasMaturityPropertyPrice, v.isCapacity]))
)

// Purchase
mainGraph.addEdge(
  new Edge<GraphContext>(
    maturityDefaultRoute,
    purchaseGraph,
    // this triggers an multi branch error, keeping it for further debugging and graph inspection
    // allPass([v.isMaturityComplete, v.isPurchase])
    // it can be fix by:
    allPass([
      anyPass([
        v.isMaturityTypeOfferMade,
        v.isMaturityTypeOfferAccepted,
        allPass([v.isMaturityTypeSaleAgreementSigned, v.hasAgreementSignedDate]),
      ]),
      v.isPurchase,
    ])
  )
)

mainGraph.addEdge(
  new Edge<GraphContext>(
    maturityIndexRoute,
    purchaseGraph,
    // this triggers an multi branch error, keeping it for further debugging and graph inspection
    // allPass([v.isMaturityComplete, v.isPurchase])
    // it can be fix by:
    allPass([
      anyPass([
        v.isMaturityTypeOfferMade,
        v.isMaturityTypeOfferAccepted,
        allPass([v.isMaturityTypeSaleAgreementSigned, v.hasAgreementSignedDate]),
      ]),
      v.isPurchase,
    ])
  )
)

mainGraph.addEdge(
  new Edge<GraphContext>(maturityPropertyPriceRoute, purchaseGraph, allPass([v.hasMaturityPropertyPrice, v.isPurchase]))
)

// Buyout
mainGraph.addEdge(new Edge<GraphContext>(introductionOthersRoute, buyoutGraph, v.isBuyout))

// Renegotiation
mainGraph.addEdge(new Edge<GraphContext>(introductionOthersRoute, renegotiationGraph, v.isRenegotiation))
