/* eslint-disable max-lines */
import * as v from '@pretto/app/src/Sentences/lib/validators/context'
import type { GraphContext } from '@pretto/app/src/Sentences/types/graph'
import { Edge, Graph, Route } from '@pretto/app/src/lib/graph/Graph'

import allPass from 'ramda/src/allPass'
import anyPass from 'ramda/src/anyPass'

// Introduction
const structureRoute = new Route<GraphContext>('structure', '/project/purchase/introduction/structure')
const propertyUsageRoute = new Route<GraphContext>('propertyUsage', '/project/purchase/introduction/propertyUsage')
const propertyTypeRoute = new Route<GraphContext>('propertyType', '/project/purchase/introduction/propertyType')
const sciTypeRoute = new Route<GraphContext>('sciType', '/project/purchase/introduction/sciType')
const sciAssociationRoute = new Route<GraphContext>('sciAssociation', '/project/purchase/introduction/sciAssociation')

export const purchaseGraph = new Graph<GraphContext>('purchaseGraph', structureRoute)

purchaseGraph.addRoute(propertyUsageRoute)
purchaseGraph.addRoute(propertyTypeRoute)
purchaseGraph.addRoute(sciTypeRoute)
purchaseGraph.addRoute(sciAssociationRoute)

purchaseGraph.addEdge(new Edge<GraphContext>(structureRoute, sciTypeRoute, v.isSCI))
purchaseGraph.addEdge(new Edge<GraphContext>(sciTypeRoute, sciAssociationRoute, v.isIR))
purchaseGraph.addEdge(new Edge<GraphContext>(sciAssociationRoute, propertyUsageRoute, v.isAssociationSimple))
purchaseGraph.addEdge(new Edge<GraphContext>(structureRoute, propertyUsageRoute, allPass([v.hasStructure, v.isNotSCI])))
purchaseGraph.addEdge(new Edge<GraphContext>(propertyUsageRoute, propertyTypeRoute, v.hasPropertyUsage))

// Lodging
const ageChildrenRoute = new Route<GraphContext>('ageChildren', '/project/purchase/lodging/ageChildren')
const liveTogetherRoute = new Route<GraphContext>('liveTogether', '/project/purchase/lodging/liveTogether')
const addressRoute = new Route<GraphContext>('address', '/project/purchase/lodging/address')
const situationRoute = new Route<GraphContext>('situation', '/project/purchase/lodging/situation')

purchaseGraph.addRoute(ageChildrenRoute)
purchaseGraph.addRoute(liveTogetherRoute)
purchaseGraph.addRoute(addressRoute)
purchaseGraph.addRoute(situationRoute)

purchaseGraph.addEdge(new Edge<GraphContext>(propertyTypeRoute, ageChildrenRoute, v.hasPropertyType))

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    ageChildrenRoute,
    liveTogetherRoute,
    allPass([v.isNotSolo, v.hasAge, v.hasCoMortgagorAge, v.hasChildrenNumber])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(ageChildrenRoute, addressRoute, allPass([v.hasAge, v.hasChildrenNumber, v.isSolo]))
)

purchaseGraph.addEdge(new Edge<GraphContext>(liveTogetherRoute, addressRoute, v.hasLiveTogetherCompleted))
purchaseGraph.addEdge(new Edge<GraphContext>(addressRoute, situationRoute, v.hasAddress))

// Occupation
const expatriateRoute = new Route<GraphContext>('expatriate', '/project/purchase/occupation/expatriate')
const sectorRoute = new Route<GraphContext>('sector', '/project/purchase/occupation/sector')

purchaseGraph.addRoute(expatriateRoute)
purchaseGraph.addRoute(sectorRoute)

purchaseGraph.addEdge(
  new Edge<GraphContext>(sectorRoute, expatriateRoute, allPass([v.isInternational, v.isOccupationFilled]))
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(situationRoute, sectorRoute, allPass([v.hasSituationType, v.hasRent, v.hasForSale]))
)

// Income
const salaryRoute = new Route<GraphContext>('salary', '/project/purchase/income/salary')
const bonusRoute = new Route<GraphContext>('bonus', '/project/purchase/income/bonus')
const revenueRoute = new Route<GraphContext>('revenue', '/project/purchase/income/revenue')

purchaseGraph.addRoute(salaryRoute)
purchaseGraph.addRoute(bonusRoute)
purchaseGraph.addRoute(revenueRoute)

purchaseGraph.addEdge(new Edge<GraphContext>(expatriateRoute, salaryRoute, v.isExpatriateFilled))

purchaseGraph.addEdge(
  new Edge<GraphContext>(sectorRoute, salaryRoute, allPass([v.isNotInternational, v.isOccupationFilled]))
)

purchaseGraph.addEdge(new Edge<GraphContext>(salaryRoute, bonusRoute, allPass([v.isNotOthersSector, v.isSalaryFilled])))
purchaseGraph.addEdge(new Edge<GraphContext>(salaryRoute, revenueRoute, allPass([v.isSalaryFilled, v.isOthersSector])))
purchaseGraph.addEdge(new Edge<GraphContext>(bonusRoute, revenueRoute, v.isBonusFilled))

// Comortgagor Lodging
const comortgagorAddressRoute = new Route<GraphContext>(
  'comortgagorAddress',
  '/project/purchase/lodging-comortgagor/address'
)

const comortgagorSituationRoute = new Route<GraphContext>(
  'comortgagorSituation',
  '/project/purchase/lodging-comortgagor/situation'
)

purchaseGraph.addRoute(comortgagorAddressRoute)
purchaseGraph.addRoute(comortgagorSituationRoute)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    revenueRoute,
    comortgagorAddressRoute,
    allPass([v.isRevenueFilled, v.isNotSolo, v.doesNotLiveTogether])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(comortgagorAddressRoute, comortgagorSituationRoute, v.hasComortgagorAddress)
)

// Comortgagor Occupation
const comortgagorExpatriateRoute = new Route<GraphContext>(
  'comortgagorExpatriate',
  '/project/purchase/occupation-comortgagor/expatriate'
)

const comortgagorSectorRoute = new Route<GraphContext>(
  'comortgagorSector',
  '/project/purchase/occupation-comortgagor/sector'
)

purchaseGraph.addRoute(comortgagorExpatriateRoute)
purchaseGraph.addRoute(comortgagorSectorRoute)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    comortgagorSectorRoute,
    comortgagorExpatriateRoute,
    anyPass([
      allPass([v.isInternational, v.doesLiveTogether]),
      allPass([v.doesNotLiveTogether, v.isComortgagorInternational]),
    ])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    revenueRoute,
    comortgagorSectorRoute,
    allPass([v.isRevenueFilled, v.isNotSolo, v.doesLiveTogether])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    comortgagorSituationRoute,
    comortgagorSectorRoute,
    allPass([v.hasComortgagorSituationType, v.hasComortgagorRent, v.hasComortgagorForSale])
  )
)

// Comortgagor Income
const comortgagorSalaryRoute = new Route<GraphContext>(
  'comortgagorSalary',
  '/project/purchase/income-comortgagor/salary'
)

const comortgagorBonusRoute = new Route<GraphContext>('comortgagorBonus', '/project/purchase/income-comortgagor/bonus')

const comortgagorRevenueRoute = new Route<GraphContext>(
  'comortgagorRevenue',
  '/project/purchase/income-comortgagor/revenue'
)

purchaseGraph.addRoute(comortgagorSalaryRoute)
purchaseGraph.addRoute(comortgagorBonusRoute)
purchaseGraph.addRoute(comortgagorRevenueRoute)

purchaseGraph.addEdge(
  new Edge<GraphContext>(comortgagorExpatriateRoute, comortgagorSalaryRoute, v.isCoMortgagorExpatriateFilled)
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    comortgagorSectorRoute,
    comortgagorSalaryRoute,
    allPass([
      anyPass([
        allPass([v.doesLiveTogether, v.isNotInternational]),
        allPass([v.doesNotLiveTogether, v.isComortgagorNotInternational]),
      ]),
      v.isCoMortgagorOccupationFilled,
    ])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    comortgagorSalaryRoute,
    comortgagorBonusRoute,
    allPass([v.isCoMortgagorNotOthersSector, v.isCoMortgagorSalaryFilled])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    comortgagorSalaryRoute,
    comortgagorRevenueRoute,
    allPass([v.isCoMortgagorSalaryFilled, v.isCoMortgagorOthersSector])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(comortgagorBonusRoute, comortgagorRevenueRoute, v.isCoMortgagorBonusFilled)
)

// Sale
const estimatedPriceRoute = new Route<GraphContext>('estimatedPrice', '/project/purchase/sale/estimatedPrice')
const paidOffRoute = new Route<GraphContext>('paidOff', '/project/purchase/sale/paidOff')
const remainingMortgageRoute = new Route<GraphContext>('remainingMortgage', '/project/purchase/sale/remainingMortgage')

purchaseGraph.addRoute(estimatedPriceRoute)
purchaseGraph.addRoute(paidOffRoute)
purchaseGraph.addRoute(remainingMortgageRoute)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    revenueRoute,
    estimatedPriceRoute,
    allPass([v.hasAnyForSale, v.isRevenueFilled, v.isForSale, v.isSolo])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    comortgagorRevenueRoute,
    estimatedPriceRoute,
    allPass([v.hasAnyForSale, v.isForSale, v.isCoMortgagorRevenueFilled])
  )
)

purchaseGraph.addEdge(new Edge<GraphContext>(estimatedPriceRoute, paidOffRoute, v.hasEstimatedPrice))
purchaseGraph.addEdge(new Edge<GraphContext>(paidOffRoute, remainingMortgageRoute, v.isNotPaidOff))

// Charges
const creditsRoute = new Route<GraphContext>('credits', '/project/purchase/credits/credits')
const childSupportRoute = new Route<GraphContext>('childSupport', '/project/purchase/credits/childSupport')

const comortgagorChildSupportRoute = new Route<GraphContext>(
  'comortgagorChildSupport',
  '/project/purchase/credits/comortgagorChildSupport'
)

purchaseGraph.addRoute(creditsRoute)
purchaseGraph.addRoute(childSupportRoute)
purchaseGraph.addRoute(comortgagorChildSupportRoute)

purchaseGraph.addEdge(
  new Edge<GraphContext>(revenueRoute, creditsRoute, allPass([v.isRevenueFilled, v.isSolo, v.isNotForSale]))
)

purchaseGraph.addEdge(new Edge<GraphContext>(comortgagorRevenueRoute, creditsRoute, v.isCoMortgagorRevenueFilled))
purchaseGraph.addEdge(new Edge<GraphContext>(paidOffRoute, creditsRoute, v.isPaidOff))
purchaseGraph.addEdge(new Edge<GraphContext>(remainingMortgageRoute, creditsRoute, v.hasRemainingMortgage))
purchaseGraph.addEdge(new Edge<GraphContext>(creditsRoute, childSupportRoute, v.isCreditsFilled))

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    childSupportRoute,
    comortgagorChildSupportRoute,
    allPass([v.isNotSolo, v.isChildSupportFilled])
  )
)

// Property
const goodRoute = new Route<GraphContext>('good', '/project/purchase/property/good')
const constructionRoute = new Route<GraphContext>('construction', '/project/purchase/property/construction')

const landConstructionRoute = new Route<GraphContext>('landConstruction', '/project/purchase/property/landConstruction')

const worksRoute = new Route<GraphContext>('works', '/project/purchase/property/works')
const ecoPtzRoute = new Route<GraphContext>('ecoPtz', '/project/purchase/property/ecoPtz')
const expectedRentalRoute = new Route<GraphContext>('expectedRental', '/project/purchase/property/expectedRental')
const dpeRoute = new Route<GraphContext>('dpe', '/project/purchase/property/dpe')

purchaseGraph.addRoute(goodRoute)
purchaseGraph.addRoute(constructionRoute)
purchaseGraph.addRoute(landConstructionRoute)
purchaseGraph.addRoute(dpeRoute)
purchaseGraph.addRoute(expectedRentalRoute)
purchaseGraph.addRoute(worksRoute)
purchaseGraph.addRoute(ecoPtzRoute)

purchaseGraph.addEdge(
  new Edge<GraphContext>(childSupportRoute, goodRoute, allPass([v.isOldNewOrVEFA, v.isSolo, v.isChildSupportFilled]))
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    comortgagorChildSupportRoute,
    goodRoute,
    allPass([v.isOldNewOrVEFA, v.isNotSolo, v.isCoMortgagorChildSupportFilled])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    childSupportRoute,
    constructionRoute,
    allPass([v.isPropertyConstruction, v.isSolo, v.isChildSupportFilled])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    comortgagorChildSupportRoute,
    constructionRoute,
    allPass([v.isPropertyConstruction, v.isNotSolo, v.isCoMortgagorChildSupportFilled])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    childSupportRoute,
    landConstructionRoute,
    allPass([v.isPropertyLandConstruction, v.isSolo, v.isChildSupportFilled])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    comortgagorChildSupportRoute,
    landConstructionRoute,
    allPass([v.isPropertyLandConstruction, v.isNotSolo, v.isCoMortgagorChildSupportFilled])
  )
)

purchaseGraph.addEdge(new Edge<GraphContext>(goodRoute, dpeRoute, v.hasGood))

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    constructionRoute,
    expectedRentalRoute,
    allPass([v.isPropertyConstruction, v.hasConstructionPrice, v.isRentalUsage])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(landConstructionRoute, expectedRentalRoute, allPass([v.hasLandPrice, v.isRentalUsage]))
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    dpeRoute,
    expectedRentalRoute,
    allPass([v.hasDpe, v.isOldNewOrVEFA, v.hasPropertyPrice, v.isRentalUsage])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    dpeRoute,
    worksRoute,
    allPass([v.hasDpe, v.isNotRentalUsage, v.hasPropertyPrice, v.isPropertyOld])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    expectedRentalRoute,
    worksRoute,
    allPass([v.isExpectedRentalIncomeFilled, v.isRentalUsage, v.hasGood, v.hasPropertyPrice, v.isPropertyOld])
  )
)

purchaseGraph.addEdge(new Edge<GraphContext>(worksRoute, ecoPtzRoute, allPass([v.isWorksFilled, v.isEligibleToEcoPtz])))

// PTZ
const beenOwnerRoute = new Route<GraphContext>('beenOwner', '/project/purchase/ptz/beenOwner')
const fiscalIncomeNm2Route = new Route<GraphContext>('fiscalIncomeNm2', '/project/purchase/ptz/fiscalIncomeNm2')

purchaseGraph.addRoute(beenOwnerRoute)
purchaseGraph.addRoute(fiscalIncomeNm2Route)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    constructionRoute,
    beenOwnerRoute,
    allPass([v.isPropertyConstruction, v.hasConstructionPrice, v.hasPTZ])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    landConstructionRoute,
    beenOwnerRoute,
    allPass([v.isPropertyLandConstruction, v.hasLandPrice, v.hasPTZ])
  )
)

purchaseGraph.addEdge(new Edge<GraphContext>(dpeRoute, beenOwnerRoute, allPass([v.hasDpe, v.hasPTZ])))

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    worksRoute,
    beenOwnerRoute,
    allPass([v.isPropertyOld, v.isWorksFilled, v.hasPTZ, v.isNotEligibleToEcoPtz])
  )
)

purchaseGraph.addEdge(new Edge<GraphContext>(ecoPtzRoute, beenOwnerRoute, allPass([v.isEcoPtzFilled, v.hasPTZ])))

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    expectedRentalRoute,
    beenOwnerRoute,
    allPass([v.isExpectedRentalIncomeFilled, v.isRentalUsage, v.hasRent, v.hasPTZ])
  )
)

purchaseGraph.addEdge(new Edge<GraphContext>(beenOwnerRoute, fiscalIncomeNm2Route, v.hasNotBeenOwner))

// Contribution
const contributionRoute = new Route<GraphContext>('contribution', '/project/purchase/contribution')

purchaseGraph.addRoute(contributionRoute)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    constructionRoute,
    contributionRoute,
    allPass([v.isPropertyConstruction, v.hasConstructionPrice, v.hasNotPTZ])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    landConstructionRoute,
    contributionRoute,
    allPass([v.isPropertyLandConstruction, v.hasLandPrice, v.hasNotPTZ])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(dpeRoute, contributionRoute, allPass([v.hasDpe, v.isPropertyNewOrVEFA, v.hasNotPTZ]))
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    expectedRentalRoute,
    contributionRoute,
    allPass([v.isExpectedRentalIncomeFilled, v.isRentalUsage, v.hasRent, v.hasNotPTZ])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    worksRoute,
    contributionRoute,
    allPass([v.isWorksFilled, v.hasNotPTZ, v.isNotEligibleToEcoPtz])
  )
)

purchaseGraph.addEdge(new Edge<GraphContext>(ecoPtzRoute, contributionRoute, allPass([v.isEcoPtzFilled, v.hasNotPTZ])))
purchaseGraph.addEdge(new Edge<GraphContext>(beenOwnerRoute, contributionRoute, v.hasBeenOwner))
purchaseGraph.addEdge(new Edge<GraphContext>(fiscalIncomeNm2Route, contributionRoute, v.hasFiscalIncomeNM2))

// Competition
const hasCompetitionRoute = new Route<GraphContext>('hasCompetition', '/project/purchase/competition/hasCompetition')
const offerRoute = new Route<GraphContext>('offer', '/project/purchase/competition/offer')

purchaseGraph.addRoute(hasCompetitionRoute)
purchaseGraph.addRoute(offerRoute)

purchaseGraph.addEdge(
  new Edge<GraphContext>(contributionRoute, hasCompetitionRoute, allPass([v.isContributionFilled, v.isSavingsFilled]))
)
purchaseGraph.addEdge(new Edge<GraphContext>(hasCompetitionRoute, offerRoute, v.hasCompetition))

// Rate alert
const wantsRateAlertRoute = new Route<GraphContext>('wantsRateAlert', '/project/purchase/rate-alert/wantsRateAlert')

purchaseGraph.addRoute(wantsRateAlertRoute)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    hasCompetitionRoute,
    wantsRateAlertRoute,
    allPass([v.isCompetitionFilled, v.hasNotCompetition, v.isVisibleRateAlert])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    offerRoute,
    wantsRateAlertRoute,
    allPass([v.hasCompetitionBank, v.hasCompetitionRate, v.hasCompetitionDuration, v.isVisibleRateAlert])
  )
)

// Simulation
const simulationRoute = new Route<GraphContext>('simulation', '/simulation')

purchaseGraph.addRoute(simulationRoute)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    hasCompetitionRoute,
    simulationRoute,
    allPass([v.isCompetitionFilled, v.hasNotCompetition, v.isNotVisibleRateAlert])
  )
)

purchaseGraph.addEdge(
  new Edge<GraphContext>(
    offerRoute,
    simulationRoute,
    allPass([v.hasCompetitionBank, v.hasCompetitionRate, v.hasCompetitionDuration, v.isNotVisibleRateAlert])
  )
)

purchaseGraph.addEdge(new Edge<GraphContext>(wantsRateAlertRoute, simulationRoute, v.isRateAlertFilled))
