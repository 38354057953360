import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorOccupationOtherStatus } from '@pretto/app/src/types/api/enums'

export const mapOtherStatus: MapToChoices<SimulationMortgagorOccupationOtherStatus[]> = (values, context) => {
  if (!context) return []
  const { simulation, setSimulationMortgagor } = context

  return values.map(value => ({
    key: value,
    isSelected: simulation.profileMortgagors[0]?.occupationOtherStatus === value,
    onClick: () => {
      setSimulationMortgagor({ occupationOtherStatus: value })
    },
    label: t(`sentences.situation.others.values.${value}`),
  }))
}
