import { Route } from '@pretto/app/src/Auth/Containers/Route/Route'
import CapacityPage from '@pretto/app/src/Capacity/Containers/CapacityPage'
import { ScoringProvider } from '@pretto/app/src/Scoring/contexts/ScoringContext/ScoringProvider'
import SimulationPage from '@pretto/app/src/Simulation/Containers/SimulationPage'
import Subscribe from '@pretto/app/src/Subscribe/Containers/SubscribePage'
import abTest from '@pretto/app/src/lib/abtest'

import { Switch } from 'react-router'

import SimulationNonFundable from '../../Simulation/Containers/SimulationNonFundable/SimulationNonFundable'
import SimulationResults from '../../Simulation/Containers/SimulationResults/SimulationResults'
import { SimulationResultsProvider } from '../../Simulation/context/SimulationResultsContext'

export const ResultPageController = () => {
  if (abTest('redesign_error_page_202501') === 'new') {
    return (
      <ScoringProvider>
        <SimulationResultsProvider>
          <Switch>
            <Route exact path="/(capacity|purchase|simulation)" component={SimulationResults} />
            <Route exact path="/simulation/nonfundable" component={SimulationNonFundable} />
            <Route exact scope={['prospect', 'preLead']} path="/simulation/subscribe" component={Subscribe} />
          </Switch>
        </SimulationResultsProvider>
      </ScoringProvider>
    )
  }

  return (
    <ScoringProvider>
      <Switch>
        <Route exact path="/capacity" component={CapacityPage} />
        <Route exact path="/simulation" component={SimulationPage} />
        <Route exact scope={['prospect', 'preLead']} path="/simulation/subscribe" component={Subscribe} />
      </Switch>
    </ScoringProvider>
  )
}
