import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import { Project } from '@pretto/app/src/types/gateway/schema'

import { pipe } from 'ramda'

import { mapBuyout } from './buyout'
import { mapCharges } from './charges'
import { mapCompetition } from './competition'
import { mapContribution } from './contribution'
import { mapKind } from './kind'
import { mapMaturity } from './maturity'
import { mapOwnProperty } from './ownProperty'
import { mapProfile } from './profile'
import { mapProperty } from './property'
import { mapPtz } from './ptz'
import { mapRenegotiation } from './renegotiation'
import { mapSavings } from './savings'
import { mapStructure } from './structure'

export const mapSimulationToProject: ComposableMapper<Partial<Project>> = props =>
  pipe(
    mapCharges,
    mapBuyout,
    mapCompetition,
    mapContribution,
    mapKind,
    mapMaturity,
    mapOwnProperty,
    mapProfile,
    mapProperty,
    mapPtz,
    mapRenegotiation,
    mapSavings,
    mapStructure
  )(props).project
