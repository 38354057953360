import { useStateOperations } from '@pretto/app/src/lib/state/useStateOperations'

import { createContext, PropsWithChildren, useContext } from 'react'

import { useSentences } from './SentencesContext'

export type GetRateAlert = {
  email?: string | null
  isVisible: boolean
  wantsRateAlert?: boolean | null
}

type SetRateAlert = Omit<GetRateAlert, 'isVisible'>

export interface RateAlertContextInterface {
  rateAlert: GetRateAlert
  setRateAlert: React.Dispatch<React.SetStateAction<SetRateAlert>>
  waitForOngoingOperations: () => Promise<GetRateAlert>
}

const RateAlertContext = createContext<RateAlertContextInterface>({} as RateAlertContextInterface)

export const RateAlertContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { isUpdate } = useSentences()

  const [rateAlert, setPartialRateAlert, waitForOngoingOperations] = useStateOperations<GetRateAlert>({
    isVisible: !isUpdate,
  })

  const setRateAlert: React.Dispatch<React.SetStateAction<SetRateAlert>> = state => {
    setPartialRateAlert(previousContext => ({
      ...previousContext,
      ...(typeof state === 'function' ? state(previousContext) : state),
    }))
  }

  return (
    <RateAlertContext.Provider value={{ rateAlert, setRateAlert, waitForOngoingOperations }}>
      {children}
    </RateAlertContext.Provider>
  )
}

export const useRateAlert = () => useContext(RateAlertContext)
