import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import { useFieldFormat } from '@pretto/zen/reveal/lib/useFieldFormat'
import type { FieldProps, FieldState } from '@pretto/zen/reveal/types/Field'

import { ExclamationMarkCircleBold, ExclamationMarkTriangleBold, Picto, TimesBold } from '@pretto/picto'

import { forwardRef } from 'react'
import { TransitionGroup } from 'react-transition-group'

import * as S from './TextFieldDefault.styles'

export interface TextFieldDefaultProps extends FieldProps {
  isDisabled?: boolean
  onClear?: () => void
  suffix?: React.ReactNode
}

const PICTO: Omit<Record<FieldState, Picto>, 'default'> = {
  error: ExclamationMarkCircleBold,
  warning: ExclamationMarkTriangleBold,
}

export const TextFieldDefault = forwardRef(
  (
    {
      inputProps = {},
      isDisabled,
      message,
      messageKey = 'key',
      onChange,
      onClear,
      state = 'default',
      suffix,
      value,
      ...props
    }: TextFieldDefaultProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>,
    ref
  ) => {
    const fieldFormatProps = useFieldFormat({ inputProps, onChange, value }, ref)

    const focusInput = () => {
      if (fieldFormatProps.ref && 'current' in fieldFormatProps.ref) {
        fieldFormatProps.ref.current?.focus()
      }
    }

    const handleClear = () => {
      onClear?.()

      focusInput()
    }

    const isEmptyValue = value === ''

    const isClearActive = state === 'default' && !isEmptyValue && !!onClear && !suffix
    const isMessageVisible = !!message && state !== 'default'
    const isPictoVisible = state !== 'default'
    const isSuffixVisible = !!suffix && !isPictoVisible

    return (
      <div {...props}>
        <S.TextField $isDisabled={isDisabled} $isEmptyValue={isEmptyValue} $state={state}>
          <S.Input disabled={isDisabled} {...inputProps} {...fieldFormatProps} />

          {isClearActive && (
            <S.ClearButton onClick={handleClear} type="button">
              <S.Picto $state="default" as={TimesBold} />
            </S.ClearButton>
          )}

          {isPictoVisible && <S.Picto $state={state} as={PICTO[state]} />}
          {isSuffixVisible && <S.Suffix onClick={focusInput}>{suffix}</S.Suffix>}
        </S.TextField>

        <TransitionGroup>
          {isMessageVisible && (
            <CollapsibleRow key={messageKey} animateIn childComponent={S.Footer}>
              <S.Message $state={state}>{message}</S.Message>
            </CollapsibleRow>
          )}
        </TransitionGroup>
      </div>
    )
  }
)

TextFieldDefault.displayName = 'TextFieldDefault'
