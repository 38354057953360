import { breakpoints, g } from '@pretto/bricks/components/layout'

import { CalendarSlot } from '@pretto/zen/dashboard/calendar/CalendarSlot/CalendarSlot'

import { GreenDot } from '@pretto/app/src/SharedContainers/MutualizedAgenda/components/MutualizedAgenda/steps/StepAgenda/components/Calendar/components/Footer/Footer.styles'

import styled from 'styled-components'

export const Slots = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: ${g(5)};
  column-gap: ${g(1)};
  row-gap: ${g(1)};
  padding: ${g(2)} ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Slot = styled(CalendarSlot)`
  position: relative;
`

export const SlotDot = styled(GreenDot)`
  position: absolute;
  z-index: 5;
  top: -4px;
  right: 4px;
`
