import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationCreditKind, SimulationKind } from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'

import { Container, Header, Title } from './Styled'

export const BuyoutCredit: React.FC = () => {
  const { simulation } = useSentences()

  if (
    simulation.kind !== SimulationKind.Buyout ||
    simulation.buyoutHasCredit === false ||
    isNil(simulation.buyoutCreditRemainingPrincipal)
  ) {
    return null
  }

  return (
    <Container $isEditable={false}>
      <Header>
        <Title>{t(`sentences.charge.credits.subtitle.purchase`)} 1 :</Title>
      </Header>
      <Prompt isFilled>{t(`sentences.charge.credits.values.${SimulationCreditKind.Mortgage}`)}</Prompt>
      {t(`sentences.charge.credits.sentences.3`)}
      <Prompt isFilled>{simulation.buyoutCreditRemainingPrincipal}</Prompt>
      {t(`sentences.charge.credits.sentences.4`)}
    </Container>
  )
}
