import { g } from '@pretto/bricks/components/layout'

import { RevenueBox } from '@pretto/app/src/Sentences/ComortgagorIncome/Revenue/components/RevenueBox'
import { DividerWithMarginBottom } from '@pretto/app/src/Sentences/components/Styled/Divider/Divider'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedButtonInline } from '@pretto/app/src/Sentences/containers/TrackedButtonInline/TrackedButtonInline'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorAdditionalRevenueKind } from '@pretto/app/src/types/api/enums'

import { useState } from 'react'
import styled from 'styled-components'

const Description = styled.p`
  margin-bottom: ${g(2)};
`

const Box = styled(RevenueBox)`
  & + & {
    margin-top: ${g(2)};
  }
`

export const RevenuePage: React.FC = () => {
  const {
    addSimulationComortgagorIncomeAdditionalRevenue,
    deleteSimulationComortgagorIncomeAdditionalRevenue,
    setSimulationComortgagorIncomeAdditionalRevenue,
    simulation,
  } = useSentences()
  const { nextRoute } = useRoutesGraph()

  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute))

  const [editableId, setEditableId] = useState<string | null>(null)

  const descriptionString = [
    t(`sentences.income.revenue.values.${SimulationMortgagorAdditionalRevenueKind.Rent}`),
    t(`sentences.income.revenue.values.${SimulationMortgagorAdditionalRevenueKind.ChildSupport}`),
    t(`sentences.income.revenue.values.${SimulationMortgagorAdditionalRevenueKind.ProfessionalBenefits}`),
    t(`sentences.income.revenue.values.${SimulationMortgagorAdditionalRevenueKind.FamilyAllowances}`),
    t(`sentences.income.revenue.values.${SimulationMortgagorAdditionalRevenueKind.ExpatriationBonus}`),
  ].join(', ')

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'revenue-comortgagor' } }}>
      <Title>{t('sentences.income.comortgagor.revenue.title')}</Title>
      <Description>({descriptionString}).</Description>
      {simulation.profileMortgagors[1]?.incomeAdditionalRevenues.map(({ id, ...revenue }, index) => {
        const choices = Object.values([
          SimulationMortgagorAdditionalRevenueKind.Rent,
          SimulationMortgagorAdditionalRevenueKind.ChildSupport,
          SimulationMortgagorAdditionalRevenueKind.ProfessionalBenefits,
          SimulationMortgagorAdditionalRevenueKind.FamilyAllowances,
          SimulationMortgagorAdditionalRevenueKind.ExpatriationBonus,
        ]).map(value => {
          return {
            key: value,
            isSelected: revenue.kind === value,
            onClick: () => {
              setSimulationComortgagorIncomeAdditionalRevenue({ id, kind: value })
            },
            label: t(`sentences.income.revenue.values.${value}`),
          }
        })

        const handleChangeAmount = (value: string) => {
          setSimulationComortgagorIncomeAdditionalRevenue({ id, amount: toIntOrNull(value) })
        }

        return (
          <Box
            key={id}
            activeField={activeField}
            index={index}
            isEditable={editableId === id}
            onClick={() => {
              setEditableId(id)
            }}
            onRemove={() => {
              deleteSimulationComortgagorIncomeAdditionalRevenue(id)
            }}
            onPromptClick={() => {
              setActiveField(0)
            }}
            onChoiceClick={() => {
              setActiveField(1)
            }}
            onChangeAmount={handleChangeAmount}
            revenue={revenue}
            choices={choices}
          />
        )
      })}
      <DividerWithMarginBottom />
      <TrackedButtonInline
        events={{
          eventName: EventName.SimulationElementClicked,
          eventPayload: { element: 'coMortgagorRevenueAddValue' },
        }}
        onClick={() => {
          setActiveField(0)
          setEditableId(addSimulationComortgagorIncomeAdditionalRevenue())
        }}
      >
        {t('sentences.income.revenue.addValue')}
      </TrackedButtonInline>
      <ButtonNext>
        {t(simulation.profileMortgagors[1]?.incomeAdditionalRevenues.length ? 'sentences.next' : 'sentences.skip')}
      </ButtonNext>
    </TrackedView>
  )
}
