import { mapBonusPeriodValues } from '@pretto/app/src/Sentences/ComortgagorIncome/Bonus/lib/mappers/mapBonusPeriod'
import { mapBonusTypeValues } from '@pretto/app/src/Sentences/ComortgagorIncome/Bonus/lib/mappers/mapBonusType'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationMortgagorIncomePeriod, SimulationMortgagorIncomeTaxes } from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'

export const BonusPage: React.FC = () => {
  const context = useSentences()
  const { simulation, setSimulationComortgagor } = context

  const { nextRoute, goToNextRoute } = useRoutesGraph()

  const hasBonus = simulation.profileMortgagors[1]?.incomeHasBonus

  const setHasBonus = (value: boolean) => {
    setSimulationComortgagor({ incomeHasBonus: value })
  }

  const getDefaultActiveField = () => {
    if (!hasBonus) return 0
    if (!simulation.profileMortgagors[1]?.incomeBonusPeriod) return 1
    if (!simulation.profileMortgagors[1]?.incomeBonusTaxes) return 2
    return 3
  }

  const getIsFilled = () => {
    if (!nextRoute) return false
    if (isNil(hasBonus)) return false
    if (
      hasBonus &&
      (!simulation.profileMortgagors[1]?.incomeBonusPeriod || !simulation.profileMortgagors[1]?.incomeBonusTaxes)
    )
      return false
    return true
  }

  const [activeField, setActiveField] = useActiveField(getDefaultActiveField(), getIsFilled())

  if (!simulation.profileMortgagors[1]) {
    return null
  }

  const saveAmount = (value: string) => {
    setSimulationComortgagor({ incomeBonusAmount: toIntOrNull(value) })
  }

  const mapHasBonus = (values: YesNo[]) => {
    return values.map(value => {
      return {
        key: value,
        value,
        isSelected: (value === YesNo.Yes && hasBonus === true) || (value === YesNo.No && hasBonus === false),
        onClick: () => {
          setHasBonus(value === YesNo.Yes)
          setActiveField(value === YesNo.Yes ? 1 : 0)
          if (value === YesNo.No) goToNextRoute()
        },
        label: t(`sentences.income.comortgagor.bonus.values.${value}`),
      }
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'bonus-comortgagor' } }}>
      <Title>{t('sentences.income.comortgagor.title')}</Title>
      <div>
        {t('sentences.income.comortgagor.bonus.sentence.0')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!isNil(hasBonus)}>
          {t(`sentences.income.comortgagor.bonus.values.${hasBonus || isNil(hasBonus) ? YesNo.Yes : YesNo.No}`)}
        </Prompt>
        {hasBonus && activeField > 0 && (
          <Prompt onClick={() => setActiveField(1)} isFilled={!!simulation.profileMortgagors[1].incomeBonusPeriod}>
            {t(
              `sentences.income.bonus.period.${
                simulation.profileMortgagors[1].incomeBonusPeriod || SimulationMortgagorIncomePeriod.Yearly
              }`
            )}
          </Prompt>
        )}
        {hasBonus && activeField > 1 && (
          <Prompt onClick={() => setActiveField(2)} isFilled={!!simulation.profileMortgagors[1].incomeBonusTaxes}>
            {t(
              `sentences.income.bonus.type.${
                simulation.profileMortgagors[1].incomeBonusTaxes || SimulationMortgagorIncomeTaxes.After
              }`
            )}
          </Prompt>
        )}
        {hasBonus && activeField > 2 && (
          <>
            {t('sentences.income.comortgagor.bonus.sentence.1')}
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'coMortgagorIncomeBonusAmount' },
              }}
              inputProps={{ autoFocus: activeField === 3, placeholder: '500', type: 'integer' }}
              onChange={saveAmount}
              value={simulation.profileMortgagors[1].incomeBonusAmount?.toString() || ''}
            />
            {t('sentences.income.comortgagor.bonus.sentence.2')}
          </>
        )}
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapHasBonus(Object.values(YesNo))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'coMortgagorIncomeBonus' } }}
        />
      )}
      {hasBonus && activeField === 1 && (
        <Choices
          choices={mapBonusPeriodValues(
            Object.values([SimulationMortgagorIncomePeriod.Yearly, SimulationMortgagorIncomePeriod.Monthly]),
            context
          )}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'coMortgagorIncomePeriod' } }}
          onChoiceClick={() => setActiveField(2)}
        />
      )}
      {hasBonus && activeField === 2 && (
        <Choices
          choices={mapBonusTypeValues(
            Object.values([SimulationMortgagorIncomeTaxes.After, SimulationMortgagorIncomeTaxes.Before]),
            context
          )}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'coMortgagorIncomeType' } }}
          onChoiceClick={() => setActiveField(3)}
        />
      )}
      {hasBonus && <Message>{t('sentences.tips.income.bonus.mortgagor')}</Message>}
      {!isNil(hasBonus) && ((hasBonus && activeField >= 3) || !hasBonus) && (
        <ButtonNext>{t('sentences.next')}</ButtonNext>
      )}
    </TrackedView>
  )
}
