import { g, breakpoints } from '@pretto/bricks/components/layout'

import { SecondaryButton as SecondaryButtonComponent } from '@pretto/zen/reveal/atoms/buttons/secondaryButton/SecondaryButton'

import styled from 'styled-components'

export const RecommendationCard = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h5`
  ${({ theme }) => theme.typos.heading5};
  margin: 0 0 ${g(2)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 0 ${g(1)} 0;
  }
`

export const Content = styled.p`
  ${({ theme }) => theme.typos.body4};
`

export const Cta = styled(SecondaryButtonComponent).attrs({ scheme: 'green' })`
  margin: ${g(2)} 0 0 0;
  @media screen and (min-width: ${breakpoints.tablet}) {
    align-self: center;
    margin: 0 0 0 ${g(4)};
  }
`
