import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Content = styled.div`
    margin-top: ${g(3)};
    display: flex;
    flex-direction: column;
    text-align: center;
`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: ${g(3)};
    margin-bottom: ${g(3)};

`
export const Button = styled.button`
    cursor: pointer;
    display: flex;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px #05050526;

    &:hover { background-color: #F2F2F2; }
    &:first-of-type { margin-right: ${g(3)}; }
    &:last-of-type { margin-left: ${g(3)}; }
`