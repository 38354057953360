import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import type { EventPayload } from '@pretto/app/src/Sentences/lib/tracking/types/event'
import { client } from '@pretto/app/src/api/gateway'

import { TrackEventDocument } from './trackEvent.gateway.graphql'

export const trackEventBackend = (eventName: EventName, projectId: string, payload: EventPayload) => {
  client.mutate({
    mutation: TrackEventDocument,
    variables: {
      eventName,
      projectId,
      payload: JSON.stringify(payload),
    },
  })
}
