import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'
import { heading32 } from '@pretto/bricks/core/typography'

import { Button as ButtonComponent } from '@pretto/zen/atoms/buttons/Button/Button'
import { ButtonLink as ButtonLinkComponent } from '@pretto/zen/atoms/buttons/ButtonLink/ButtonLink'
import { Banner } from '@pretto/zen/main/banners/Banner/Banner'

import type { PushyViewProps } from '@pretto/app/src/Simulation/types/Pushy'
import { ExclamationMarkCircle } from '@pretto/picto'

import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'

import { Agreement as AgreementComponent } from './components/Agreement/Agreement'
import { CGU as CGUComponent } from './components/CGU/CGU'
import { Fields } from './components/Fields/Fields'

export const Pushy: React.FC<PushyViewProps> = ({
  agreements,
  errors = [],
  fields,
  isForce,
  isMutating,
  isProspect,
  onSkip,
  onSubmit,
}) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit()
  }

  return (
    <View>
      <Title>{t('pushy.title')}</Title>

      <Form noValidate onSubmit={handleSubmit}>
        <Fields {...fields} />

        <Agreement choices={agreements} />

        <TransitionGroup>
          {errors.map(({ key, message }) => (
            <CollapsibleRow childComponent={Row} key={key}>
              <Banner picto={ExclamationMarkCircle} type="error">
                {message}
              </Banner>
            </CollapsibleRow>
          ))}
        </TransitionGroup>

        <Disclaimer>
          <Trans i18nKey="pushy.disclaimer" />
        </Disclaimer>

        <Button isArrowShown isLoading={isMutating}>
          {t('pushy.continue')}
        </Button>

        {isProspect && <CGU />}

        {!isForce && <Skip onClick={onSkip}>{t('pushy.skip')}</Skip>}
      </Form>
    </View>
  )
}

const Agreement = styled(AgreementComponent)`
  margin-top: ${g(2)};
`

const Button = styled(ButtonComponent)`
  margin-top: ${g(2)};
  width: 100%;
`

const Disclaimer = styled.p`
  margin-top: ${g(2)};
`

const Form = styled.form`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([6, 4])};
  }
`

const CGU = styled(CGUComponent)`
  margin-top: ${g(2)};
  text-align: center;
`

const Row = styled.div`
  padding-top: ${g(2)};
`

const Skip = styled(ButtonLinkComponent)`
  margin: auto;
  margin-top: ${g(4)};
`

const Title = styled.h2`
  ${column([2, 4])};
  ${heading32};
  margin-bottom: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    text-align: center;
  }
`

const View = styled.div`
  ${grid()};
  padding: ${g(3)} 0;

  @media screen and (min-width: ${breakpoints.laptop}) {
    min-height: 100%;
    place-content: center;
  }
`
