import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

export const mapTrialPeriod: MapToChoices<YesNo[]> = (values, context) => {
  if (!context) return []
  const { simulation, setSimulationComortgagor } = context

  return values.map(value => ({
    key: value,
    isSelected:
      (simulation.profileMortgagors[1].occupationTrialPeriod === true && value === YesNo.Yes) ||
      (simulation.profileMortgagors[1].occupationTrialPeriod === false && value === YesNo.No),
    onClick: () => {
      setSimulationComortgagor({ occupationTrialPeriod: value === YesNo.Yes })
    },
    label: t(`sentences.situation.private.longTerm.trial.values.${value}`),
  }))
}
