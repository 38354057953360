import { g } from '@pretto/bricks/components/layout'

import { Buttons } from '@pretto/app/src/Sentences/components/Styled/Buttons/Buttons'
import { ErrorMessage } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedButtonChoice } from '@pretto/app/src/Sentences/containers/TrackedButtonChoice/TrackedButtonChoice'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationKind, SimulationStructureKind } from '@pretto/app/src/types/api/enums'

import styled from 'styled-components'

const Choices = styled(Buttons)`
  margin-top: ${g(2)};
  display: flex;
  flex-direction: column;
  align-items: baseline;
`

export const StructurePage: React.FC = () => {
  const { deleteSimulationComortgagor, simulation, setSimulation } = useSentences()
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && simulation.structureKind))

  const handleClick = (structureKind: SimulationStructureKind) => {
    setSimulation({ structureKind })
    if (structureKind === SimulationStructureKind.Solo) {
      deleteSimulationComortgagor()
    }
    goToNextRoute()
  }

  const structureSentenceType =
    simulation.kind &&
    [SimulationKind.Buyout, SimulationKind.Purchase, SimulationKind.Renegotiation].includes(simulation.kind)
      ? simulation.kind
      : SimulationKind.Purchase

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'structure' } }}>
      <Title>{t('sentences.introduction.title')}</Title>

      <div>
        {t(`sentences.introduction.structure.sentences.${structureSentenceType}`)}
        <Prompt onClick={() => setActiveField(0)} isFilled={!!simulation.structureKind}>
          {t(`sentences.introduction.structure.values.${simulation.structureKind || SimulationStructureKind.Solo}`)}
        </Prompt>
        .
      </div>

      {activeField === 0 && (
        <Choices>
          {Object.entries(
            simulation.kind === SimulationKind.Renegotiation
              ? [SimulationStructureKind.Solo, SimulationStructureKind.Duo]
              : [SimulationStructureKind.Solo, SimulationStructureKind.Duo, SimulationStructureKind.Sci]
          ).map(([key, value]) => {
            return (
              <TrackedButtonChoice
                events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'structure', value } }}
                key={key}
                isSelected={simulation.structureKind === value}
                onClick={() => handleClick(value)}
              >
                {t(`sentences.introduction.structure.values.${value}`)}
              </TrackedButtonChoice>
            )
          })}
        </Choices>
      )}

      {simulation.kind === SimulationKind.Buyout &&
        simulation.structureKind &&
        simulation.structureKind !== SimulationStructureKind.Solo && (
          <ErrorMessage>{t(`sentences.introduction.structure.error.nonSolo`)}</ErrorMessage>
        )}

      {((simulation.kind === SimulationKind.Buyout && simulation.structureKind === SimulationStructureKind.Solo) ||
        (simulation.kind === SimulationKind.Renegotiation &&
          (simulation.structureKind === SimulationStructureKind.Solo ||
            simulation.structureKind === SimulationStructureKind.Duo)) ||
        simulation.kind === SimulationKind.Capacity ||
        simulation.kind === SimulationKind.Purchase) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
