import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationKind } from '@pretto/app/src/types/api/enums'

const otherSimulationTypes = [SimulationKind.Renegotiation, SimulationKind.Buyout] as const

export const OthersPage: React.FC = () => {
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const { simulation, setSimulation } = useSentences()

  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && simulation.kind))

  const mapOthersToValue: MapToChoices<typeof otherSimulationTypes> = values =>
    values.map(value => ({
      key: value,
      isSelected: simulation.kind === value,
      onClick() {
        setSimulation({ kind: value })
        goToNextRoute()
      },
      label: t(`sentences.introduction.others.values.${value}`),
    }))

  const value = otherSimulationTypes.find(value => value === simulation.kind)

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'introductionOthers' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t('sentences.introduction.others.sentence')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!!value}>
          {t(`sentences.introduction.others.values.${value || SimulationKind.Renegotiation}`)}
        </Prompt>
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapOthersToValue(otherSimulationTypes)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'propertyType' } }}
        />
      )}
      {value && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
