import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import { SimulationCreditKind } from '@pretto/app/src/types/api/enums'
import { CreditKind } from '@pretto/app/src/types/gateway/enums'

import { v4 as uuidv4 } from 'uuid'

export const mapCharges: ComposableMapper = ({ project, simulation }) => {
  const mapKind = (kind: SimulationCreditKind): CreditKind => {
    switch (kind) {
      case SimulationCreditKind.Car:
        return CreditKind.Car

      case SimulationCreditKind.Consumer:
        return CreditKind.Consumer

      case SimulationCreditKind.Loa:
        return CreditKind.Loa

      case SimulationCreditKind.Mortgage:
        return CreditKind.Mortgage

      case SimulationCreditKind.Student:
        return CreditKind.Student

      case SimulationCreditKind.Other:
      default:
        return CreditKind.Other
    }
  }
  return {
    project: {
      ...project,

      profile: {
        ...project.profile,

        credits: simulation.chargesCredits.map(({ kind, ...chargeCredit }) => ({
          end_date: chargeCredit.endDate,

          id: uuidv4(),

          get kind() {
            if (!kind) {
              return null
            }

            return mapKind(kind)
          },

          payment: chargeCredit.payment,
        })),
      },
    },
    simulation,
  }
}
