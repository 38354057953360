import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import { GetSimulation } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { SimulationMortgagorLodgingLocationKind, SimulationPropertyKind } from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'
import qs from 'qs'

const calcPriceBeforeFees = ({
  propertyConstructionPrice = 0,
  propertyKind,
  propertyLandPrice = 0,
  propertyPrice = 0,
  propertyWorksPrice = 0,
}: GetSimulation): number | null => {
  if (!propertyKind) {
    return null
  }

  switch (propertyKind) {
    case SimulationPropertyKind.Construction:
      if (!propertyConstructionPrice) {
        return null
      }

      return propertyConstructionPrice + (propertyWorksPrice ?? 0)

    case SimulationPropertyKind.LandConstruction:
      if (!propertyConstructionPrice || !propertyLandPrice) {
        return null
      }

      return propertyConstructionPrice + propertyLandPrice + (propertyWorksPrice ?? 0)

    case SimulationPropertyKind.New:
    case SimulationPropertyKind.Old:
    case SimulationPropertyKind.Vefa:
    default:
      if (!propertyPrice) {
        return null
      }

      return propertyPrice + (propertyWorksPrice ?? 0)
  }
}

export type MinimumContribution = {
  contribution: number
  savings?: number
}

interface GetMinimumContribution {
  (simulation: GetSimulation): Promise<MinimumContribution | null>
}

export const getMinimumContribution: GetMinimumContribution = async simulation => {
  const mortgagorNonResident =
    simulation.profileMortgagors[0]?.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.World

  const comortgagorNonResident =
    simulation.profileMortgagors[1]?.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.World

  const priceBeforeFees = calcPriceBeforeFees(simulation)

  if (priceBeforeFees === null || isNil(simulation.propertyLocation?.zipcode)) {
    return null
  }

  const queryString = qs.stringify(
    {
      property_kind: simulation.propertyKind,
      purchase_department: simulation.propertyLocation?.zipcode,
      purchase_price: priceBeforeFees,
    },
    { addQueryPrefix: true }
  )

  const response = await fetch(`https://api.pretto.fr/api/v0/public/contribution${queryString}`)

  if (!response.ok) {
    return null
  }

  const { fees } = await response.json()

  const hasConstruction =
    simulation.propertyKind === SimulationPropertyKind.Construction ||
    simulation.propertyKind === SimulationPropertyKind.LandConstruction

  const hasNonResident = mortgagorNonResident || comortgagorNonResident

  const contributionRate = hasNonResident ? 0.2 : hasConstruction ? 0.1 : 1
  const additionalAmount = hasConstruction || hasNonResident ? priceBeforeFees * contributionRate : 0

  return {
    contribution: roundNumber(fees + additionalAmount, 1000),
    savings: hasNonResident ? roundNumber(priceBeforeFees * 0.1, 1000) : undefined,
  }
}
