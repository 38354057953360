import { CheckBoldMini } from '@pretto/picto'

import * as S from './Timeline.styles'

export type TimelineProps = {
  currentStepIndex: number
  steps: string[]
}

export type StepStatus = 'done' | 'current' | 'todo'

export const Timeline = ({ currentStepIndex, steps, ...props }: TimelineProps) => (
  <S.Timeline {...props}>
    {steps.map((step, index) => {
      const status = index < currentStepIndex ? 'done' : index === currentStepIndex ? 'current' : 'todo'

      return (
        <S.Step key={index} status={status}>
          <S.PictoContainer status={status}>
            <S.Picto as={CheckBoldMini} status={status} />
          </S.PictoContainer>

          <S.StepName status={status}>{step}</S.StepName>

          {index !== 0 && <S.Separator isSolid={index <= currentStepIndex} />}
        </S.Step>
      )
    })}
  </S.Timeline>
)
