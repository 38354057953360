import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import CapacityPage from '@pretto/app/src/Capacity/Containers/CapacityPage'
import { Values } from '@pretto/app/src/SignUp/lib/useSignUpForm'
import SimulationPage from '@pretto/app/src/Simulation/Containers/SimulationPage'
import { NonFinancingSignup } from '@pretto/app/src/Simulation/Containers/nonFinancingSignup/NonFinancingSignup'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { LoaderType } from '@pretto/app/src/config/loader'

import { Redirect } from 'react-router'

import { useSimulationResults } from '../../context/SimulationResultsContext'

const SimulationResults = () => {
  const { data, loading, error, simulation } = useSimulationResults()
  const { typology, user } = useUser()
  const defaultValues: Partial<Values> = typology !== 'client' ? { email: user?.email } : {}

  if (!!error || simulation.id === undefined) {
    return <Redirect to="/" />
  }

  useLoading(loading, LoaderType.Simulation)

  const { __typename } = data?.simulationResults ?? {}

  switch (__typename) {
    case 'SimulationResultsCapacity':
      return <CapacityPage isSuggestionsHidden={true} continueHref={''} />
    case 'SimulationResultsError':
      return <Redirect to="/simulation/nonfundable" />
    case 'SimulationResultsPurchase':
    case 'SimulationResultsRenegotiation':
      return <SimulationPage />
    case 'SimulationResultsBypass':
      return <NonFinancingSignup defaultValues={defaultValues} />
    default:
      return null
  }
}

export default SimulationResults
