import useUpdateEffect from '@pretto/bricks/core/utility/useUpdateEffect'

import { ResizableField } from '@pretto/app/src/Sentences/components/ResizableField/ResizableField'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedButtonInline } from '@pretto/app/src/Sentences/containers/TrackedButtonInline/TrackedButtonInline'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { Location, usePrettoSearch } from '@pretto/app/src/Sentences/contexts/PrettoSearchContext'
import { Result as CityResult, useCityField } from '@pretto/app/src/Sentences/lib/useCityField'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import { useEffect, useState } from 'react'

interface LocationFieldProps {
  location: Location
  onDelete: () => void
  onFocus: () => void
  onResults: (results: CityResult[]) => void
}

const formatLocation = (location: Location): string => {
  return !location.city || !location.zipcode ? '' : `${location.city} (${location.zipcode})`
}

const LocationField: React.FC<LocationFieldProps> = ({ location, onDelete, onFocus, onResults }) => {
  const { fieldProps, reset, results } = useCityField()

  useEffect(() => {
    onResults(results)
  }, [results])

  const { deleteLocation, prettoSearch, setLocation } = usePrettoSearch()

  const [searchValue, setSearchValue] = useState<string>(formatLocation(location))

  useEffect(() => {
    setSearchValue(formatLocation(location))
  }, [location])

  useUpdateEffect(() => {
    fieldProps.onChange(searchValue)
  }, [searchValue])

  const handleChange = (value: string) => {
    setSearchValue(value)
  }

  const handleClear = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    if (prettoSearch.locations.length > 1) {
      deleteLocation(location.id)
      onDelete()
    }

    setLocation({
      ...location,
      city: null,
      zipcode: null,
    })
  }

  const handleFocus = () => {
    reset()
    onFocus()
  }

  return (
    <ResizableField
      clearable
      inputProps={{ autoFocus: true, placeholder: 'Paris' }}
      onChange={handleChange}
      onClear={handleClear}
      onFocus={handleFocus}
      value={searchValue}
    />
  )
}

export const LocalisationsPage: React.FC = () => {
  const { addLocation, prettoSearch, setLocation } = usePrettoSearch()

  const [results, setResults] = useState<Record<string, CityResult[]>>({})

  const [editableId, setEditableId] = useState<string | null>(null)

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'localisationsPropertySearch' } }}>
      <Title>{t('sentences.propertySearch.title')}</Title>
      <div>
        {t('sentences.propertySearch.localisations.sentence')}
        {prettoSearch.locations.map((location, index, locations) => {
          const handleDelete = () => {
            setResults(({ [location.id]: _, ...results }) => results)
          }

          const handleFocus = () => {
            setEditableId(location.id)
          }

          const handleResults = (results: CityResult[]) => {
            setResults(previousResults => ({
              ...previousResults,
              [location.id]: results,
            }))
          }

          return (
            <>
              <LocationField
                key={location.id}
                location={location}
                onDelete={handleDelete}
                onFocus={handleFocus}
                onResults={handleResults}
              />
              {index < locations.length - 1 && locations.length > 1 && ', '}
            </>
          )
        })}
        .{' '}
        {prettoSearch.locations.every(location => location.city && location.zipcode) && (
          <TrackedButtonInline
            events={{
              eventName: EventName.SimulationElementClicked,
              eventPayload: { element: 'propertySearchLocalisationsAddCity' },
            }}
            onClick={() => {
              setEditableId(addLocation())
            }}
          >
            {t('sentences.propertySearch.localisations.addCity')}
          </TrackedButtonInline>
        )}
      </div>
      {prettoSearch.locations.map(location => {
        const cityResults = results[location.id] ?? []

        if (location.id !== editableId || cityResults.length === 0) {
          return null
        }

        const mapCityValues: MapToChoices<CityResult[]> = values =>
          values.map(({ label, value }) => ({
            key: value.zipcode,
            isSelected: location.city === value.city && location.zipcode === value.zipcode,
            onClick() {
              setLocation({
                ...location,
                city: value.city,
                zipcode: value.zipcode,
              })
              setEditableId(null)
            },
            label,
          }))

        return (
          <Choices
            choices={mapCityValues(cityResults)}
            events={{
              eventName: EventName.SimulationChoiceClicked,
              eventPayload: { choice: 'localisationsPropertySearch' },
            }}
            key={location.id}
          />
        )
      })}
      <ButtonNext>{t('sentences.next')}</ButtonNext>
    </TrackedView>
  )
}
