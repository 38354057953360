import type { GetSimulation } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { calcMonthlySalaryAfterTaxes } from '@pretto/app/src/Sentences/lib/salary'
import { SimulationMortgagorIncomePeriod } from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'
import round from 'lodash/round'

type Placeholder = (simulation: GetSimulation) => string

export const getContributionPlaceholder: Placeholder = simulation => {
  const value = simulation.profileMortgagors.reduce(
    (previous, { incomeSalaryAmount, incomeSalaryPeriod, incomeSalaryTaxes }) => {
      if (isNil(incomeSalaryAmount) || isNil(incomeSalaryPeriod) || isNil(incomeSalaryTaxes)) {
        return previous
      }

      const monthlyAmount =
        incomeSalaryPeriod === SimulationMortgagorIncomePeriod.Monthly ? incomeSalaryAmount : incomeSalaryAmount / 12

      return previous + monthlyAmount
    },
    0
  )

  const placeholder = Math.round((value * 8) / 1000) * 1000

  return Math.round(placeholder).toLocaleString('fr')
}

export const getJobStartYearPlaceholder = () => (new Date().getFullYear() - 5).toString()

export const getSavingsPlaceholder: Placeholder = simulation => {
  const value = round(calcMonthlySalaryAfterTaxes(simulation) * 3, -4)

  return Math.round(value).toLocaleString('fr')
}
