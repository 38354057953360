import { mapBeenOwnerValues } from '@pretto/app/src/Sentences/Ptz/BeenOwner/lib/mappers/mapBeenOwnerValues'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const BeenOwnerPage: React.FC = () => {
  const { simulation } = useSentences()
  const ptzBeenOwner = simulation.ptzBeenOwner
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && !isNil(ptzBeenOwner)))

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'beenOwner' } }}>
      <Title>{t('sentences.ptz.title')}</Title>
      <div>
        {t('sentences.ptz.beenOwner.sentences.0')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!isNil(ptzBeenOwner)}>
          {t(`sentences.ptz.beenOwner.values.${ptzBeenOwner ? YesNo.Yes : YesNo.No}`)}
        </Prompt>
        {t('sentences.ptz.beenOwner.sentences.1')}.
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapBeenOwnerValues(Object.values(YesNo))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'ptzBeenOwner' } }}
          onChoiceClick={() => {
            goToNextRoute()
          }}
        />
      )}
      {!isNil(ptzBeenOwner) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
