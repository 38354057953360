import * as Types from '@pretto/app/src/types/api/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SimulationResultsQueryVariables = Types.Exact<{
  input: Types.SimulationResultsInput;
}>;


export type SimulationResultsQuery = { __typename?: 'RootQuery', simulationResults: { __typename?: 'SimulationResultsBypass', id: string } | { __typename?: 'SimulationResultsCapacity', id: string, offers: Array<{ __typename?: 'SimulationResultsCapacityOffer', id: string, loanAmount: number, loanApplicationFees: number, loanBrokerageFees: number, loanDuration: number, loanGuarantyFees: number, loanIndebtedness: number, loanInterests: number, loanPayment: number, loanRate: number }> } | { __typename?: 'SimulationResultsError', id: string } | { __typename?: 'SimulationResultsPurchase', id: string, offers: Array<{ __typename?: 'SimulationResultsPurchaseOffer', id: string, loanApplicationFees: number, loanBridgeRemaining?: number | null, loanBridgeAmount: number, loanGuarantyFees: number, loanBrokerageFees: number, loanNotaryFees: number, loanAmount: number, loanDuration: number, loanInterests: number, loanIndebtedness: number, loanPayment: number, loanPtzAmount?: number | null, loanRate: number, projectContribution: number, projectBuildPrice?: number | null, projectLandPrice?: number | null, projectPropertyPrice?: number | null, projectWorksPrice?: number | null, bankCriterias: Array<string>, offerCriterias: Array<string> }> } | { __typename?: 'SimulationResultsRenegotiation', id: string, offers: Array<{ __typename?: 'SimulationResultsRenegotiationOffer', id: string, loanApplicationFees: number, loanBrokerageFees: number, loanGuarantyFees: number, loanRepurchaseFees: number, loanAmount: number, loanDuration: number, loanInterests: number, loanIndebtedness: number, loanPayment: number, loanRate: number, loanInterestsSavings: number, loanTotalSavings: number }> } };


export const SimulationResultsDocument = gql`
    query SimulationResults($input: SimulationResultsInput!) {
  simulationResults(input: $input) {
    ... on SimulationResultsCapacity {
      id
      offers {
        id
        loanAmount
        loanApplicationFees
        loanBrokerageFees
        loanDuration
        loanGuarantyFees
        loanIndebtedness
        loanInterests
        loanPayment
        loanRate
      }
    }
    ... on SimulationResultsPurchase {
      id
      offers {
        id
        loanApplicationFees
        loanBridgeRemaining
        loanBridgeAmount
        loanGuarantyFees
        loanBrokerageFees
        loanNotaryFees
        loanAmount
        loanDuration
        loanInterests
        loanIndebtedness
        loanPayment
        loanPtzAmount
        loanRate
        projectContribution
        projectBuildPrice
        projectLandPrice
        projectPropertyPrice
        projectWorksPrice
        bankCriterias
        offerCriterias
      }
    }
    ... on SimulationResultsRenegotiation {
      id
      offers {
        id
        loanApplicationFees
        loanBrokerageFees
        loanGuarantyFees
        loanRepurchaseFees
        loanAmount
        loanDuration
        loanInterests
        loanIndebtedness
        loanPayment
        loanRate
        loanInterestsSavings
        loanTotalSavings
      }
    }
    ... on SimulationResultsBypass {
      id
    }
    ... on SimulationResultsError {
      id
    }
  }
}
    `;

/**
 * __useSimulationResultsQuery__
 *
 * To run a query within a React component, call `useSimulationResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulationResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulationResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimulationResultsQuery(baseOptions: Apollo.QueryHookOptions<SimulationResultsQuery, SimulationResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimulationResultsQuery, SimulationResultsQueryVariables>(SimulationResultsDocument, options);
      }
export function useSimulationResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimulationResultsQuery, SimulationResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimulationResultsQuery, SimulationResultsQueryVariables>(SimulationResultsDocument, options);
        }
export type SimulationResultsQueryHookResult = ReturnType<typeof useSimulationResultsQuery>;
export type SimulationResultsLazyQueryHookResult = ReturnType<typeof useSimulationResultsLazyQuery>;
export type SimulationResultsQueryResult = Apollo.QueryResult<SimulationResultsQuery, SimulationResultsQueryVariables>;