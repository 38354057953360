export enum Bank {
  AllianzBanque = 'ALLIANZ_BANQUE',
  Autre = 'AUTRE',
  AxaBanque = 'AXA_BANQUE',
  BankOfAmerica = 'BANK_OF_AMERICA',
  BankOfChina = 'BANK_OF_CHINA',
  BanqueCasden = 'BANQUE_CASDEN',
  BanqueChalus = 'BANQUE_CHALUS',
  BanqueCourtois = 'BANQUE_COURTOIS',
  BanqueFiducial = 'BANQUE_FIDUCIAL',
  BanqueKolb = 'BANQUE_KOLB',
  BanqueLaydernier = 'BANQUE_LAYDERNIER',
  BanqueMilleis = 'BANQUE_MILLEIS',
  BanqueNuger = 'BANQUE_NUGER',
  BanquePopulaireAlsace = 'BANQUE_POPULAIRE_ALSACE',
  BanquePopulaireAquitaine = 'BANQUE_POPULAIRE_AQUITAINE',
  BanquePopulaireAuvergne = 'BANQUE_POPULAIRE_AUVERGNE',
  BanquePopulaireBourgogne = 'BANQUE_POPULAIRE_BOURGOGNE',
  BanquePopulaireCasden = 'BANQUE_POPULAIRE_CASDEN',
  BanquePopulaireGrandOuest = 'BANQUE_POPULAIRE_GRAND_OUEST',
  BanquePopulaireMediterranee = 'BANQUE_POPULAIRE_MEDITERRANEE',
  BanquePopulaireMediterraneeNonResident = 'BANQUE_POPULAIRE_MEDITERRANEE_NON_RESIDENT',
  BanquePopulaireNord = 'BANQUE_POPULAIRE_NORD',
  BanquePopulaireOccitane = 'BANQUE_POPULAIRE_OCCITANE',
  BanquePopulaireRivesParis = 'BANQUE_POPULAIRE_RIVES_PARIS',
  BanquePopulaireSud = 'BANQUE_POPULAIRE_SUD',
  BanquePopulaireValDeFrance = 'BANQUE_POPULAIRE_VAL_DE_FRANCE',
  BanqueRhonesalpes = 'BANQUE_RHONESALPES',
  BanqueSavoie = 'BANQUE_SAVOIE',
  BanqueTarneaud = 'BANQUE_TARNEAUD',
  BanqueTransatlantique = 'BANQUE_TRANSATLANTIQUE',
  Barclays = 'BARCLAYS',
  Bcp = 'BCP',
  Bfm = 'BFM',
  Bforbank = 'BFORBANK',
  BnpParibas = 'BNP_PARIBAS',
  Boursorama = 'BOURSORAMA',
  Bred = 'BRED',
  CaisseDEpargneAquitainePoitoucharentes = 'CAISSE_D_EPARGNE_AQUITAINE_POITOUCHARENTES',
  CaisseDEpargneAuvergneLimousin = 'CAISSE_D_EPARGNE_AUVERGNE_LIMOUSIN',
  CaisseDEpargneBourgogneFranchecomte = 'CAISSE_D_EPARGNE_BOURGOGNE_FRANCHECOMTE',
  CaisseDEpargneBretagnePaysdeloire = 'CAISSE_D_EPARGNE_BRETAGNE_PAYSDELOIRE',
  CaisseDEpargneCepac = 'CAISSE_D_EPARGNE_CEPAC',
  CaisseDEpargneCotedazur = 'CAISSE_D_EPARGNE_COTEDAZUR',
  CaisseDEpargneGrandestEurope = 'CAISSE_D_EPARGNE_GRANDEST_EUROPE',
  CaisseDEpargneHautsdefrance = 'CAISSE_D_EPARGNE_HAUTSDEFRANCE',
  CaisseDEpargneIledefrance = 'CAISSE_D_EPARGNE_ILEDEFRANCE',
  CaisseDEpargneLanguedocRoussillon = 'CAISSE_D_EPARGNE_LANGUEDOC_ROUSSILLON',
  CaisseDEpargneLoirecentre = 'CAISSE_D_EPARGNE_LOIRECENTRE',
  CaisseDEpargneLoireDromeArdeche = 'CAISSE_D_EPARGNE_LOIRE_DROME_ARDECHE',
  CaisseDEpargneMidipyrenees = 'CAISSE_D_EPARGNE_MIDIPYRENEES',
  CaisseDEpargneNormandie = 'CAISSE_D_EPARGNE_NORMANDIE',
  CaisseDEpargneRhonealpes = 'CAISSE_D_EPARGNE_RHONEALPES',
  Ccf = 'CCF',
  CcfNonResident = 'CCF_NON_RESIDENT',
  Cetelem = 'CETELEM',
  Cgd = 'CGD',
  Chaabi = 'CHAABI',
  Cic = 'CIC',
  CityBank = 'CITY_BANK',
  CreditAgricoleAlpesprovence = 'CREDIT_AGRICOLE_ALPESPROVENCE',
  CreditAgricoleAlsacevosges = 'CREDIT_AGRICOLE_ALSACEVOSGES',
  CreditAgricoleAnjoumaine = 'CREDIT_AGRICOLE_ANJOUMAINE',
  CreditAgricoleAquitaine = 'CREDIT_AGRICOLE_AQUITAINE',
  CreditAgricoleAtlantiqueVendee = 'CREDIT_AGRICOLE_ATLANTIQUE_VENDEE',
  CreditAgricoleBriepicardie = 'CREDIT_AGRICOLE_BRIEPICARDIE',
  CreditAgricoleCentreest = 'CREDIT_AGRICOLE_CENTREEST',
  CreditAgricoleCentrefrance = 'CREDIT_AGRICOLE_CENTREFRANCE',
  CreditAgricoleCentreloire = 'CREDIT_AGRICOLE_CENTRELOIRE',
  CreditAgricoleCentreouest = 'CREDIT_AGRICOLE_CENTREOUEST',
  CreditAgricoleChampagneBourgogne = 'CREDIT_AGRICOLE_CHAMPAGNE_BOURGOGNE',
  CreditAgricoleCharentemaritimeDeuxsevres = 'CREDIT_AGRICOLE_CHARENTEMARITIME_DEUXSEVRES',
  CreditAgricoleCharenteperigord = 'CREDIT_AGRICOLE_CHARENTEPERIGORD',
  CreditAgricoleConsumerfinance = 'CREDIT_AGRICOLE_CONSUMERFINANCE',
  CreditAgricoleCorse = 'CREDIT_AGRICOLE_CORSE',
  CreditAgricoleCotesdarmor = 'CREDIT_AGRICOLE_COTESDARMOR',
  CreditAgricoleFinistere = 'CREDIT_AGRICOLE_FINISTERE',
  CreditAgricoleFranchecomte = 'CREDIT_AGRICOLE_FRANCHECOMTE',
  CreditAgricoleGuadeloupe = 'CREDIT_AGRICOLE_GUADELOUPE',
  CreditAgricoleIledefrance = 'CREDIT_AGRICOLE_ILEDEFRANCE',
  CreditAgricoleIleetvilaine = 'CREDIT_AGRICOLE_ILEETVILAINE',
  CreditAgricoleLanguedoc = 'CREDIT_AGRICOLE_LANGUEDOC',
  CreditAgricoleLareunion = 'CREDIT_AGRICOLE_LAREUNION',
  CreditAgricoleLoireHauteloire = 'CREDIT_AGRICOLE_LOIRE_HAUTELOIRE',
  CreditAgricoleLorraine = 'CREDIT_AGRICOLE_LORRAINE',
  CreditAgricoleMartiniqueGuyane = 'CREDIT_AGRICOLE_MARTINIQUE_GUYANE',
  CreditAgricoleMorbihan = 'CREDIT_AGRICOLE_MORBIHAN',
  CreditAgricoleNordest = 'CREDIT_AGRICOLE_NORDEST',
  CreditAgricoleNordfrance = 'CREDIT_AGRICOLE_NORDFRANCE',
  CreditAgricoleNordMidipyrenees = 'CREDIT_AGRICOLE_NORD_MIDIPYRENEES',
  CreditAgricoleNormandie = 'CREDIT_AGRICOLE_NORMANDIE',
  CreditAgricoleNormandieseine = 'CREDIT_AGRICOLE_NORMANDIESEINE',
  CreditAgricoleProvenceCotedazur = 'CREDIT_AGRICOLE_PROVENCE_COTEDAZUR',
  CreditAgricolePyreneesGascogne = 'CREDIT_AGRICOLE_PYRENEES_GASCOGNE',
  CreditAgricoleSavoie = 'CREDIT_AGRICOLE_SAVOIE',
  CreditAgricoleSudmediterranee = 'CREDIT_AGRICOLE_SUDMEDITERRANEE',
  CreditAgricoleSudrhonealpes = 'CREDIT_AGRICOLE_SUDRHONEALPES',
  CreditAgricoleToulouse31 = 'CREDIT_AGRICOLE_TOULOUSE31',
  CreditAgricoleTourainepoitou = 'CREDIT_AGRICOLE_TOURAINEPOITOU',
  CreditAgricoleValdefrance = 'CREDIT_AGRICOLE_VALDEFRANCE',
  CreditCooperatif = 'CREDIT_COOPERATIF',
  CreditDuNord = 'CREDIT_DU_NORD',
  CreditFoncier = 'CREDIT_FONCIER',
  CreditFoncierCommunalAlsaceLorraine = 'CREDIT_FONCIER_COMMUNAL_ALSACE_LORRAINE',
  CreditMunicipalToulon = 'CREDIT_MUNICIPAL_TOULON',
  CreditMutuelAnjou = 'CREDIT_MUTUEL_ANJOU',
  CreditMutuelAntillesGuyane = 'CREDIT_MUTUEL_ANTILLES_GUYANE',
  CreditMutuelBretagne = 'CREDIT_MUTUEL_BRETAGNE',
  CreditMutuelCentre = 'CREDIT_MUTUEL_CENTRE',
  CreditMutuelCentreesteurope = 'CREDIT_MUTUEL_CENTREESTEUROPE',
  CreditMutuelDauphinevivarais = 'CREDIT_MUTUEL_DAUPHINEVIVARAIS',
  CreditMutuelEnseignant = 'CREDIT_MUTUEL_ENSEIGNANT',
  CreditMutuelIledefrance = 'CREDIT_MUTUEL_ILEDEFRANCE',
  CreditMutuelLoireatlantiqueCentreouest = 'CREDIT_MUTUEL_LOIREATLANTIQUE_CENTREOUEST',
  CreditMutuelMassifcentral = 'CREDIT_MUTUEL_MASSIFCENTRAL',
  CreditMutuelMediterraneen = 'CREDIT_MUTUEL_MEDITERRANEEN',
  CreditMutuelMidiatlantique = 'CREDIT_MUTUEL_MIDIATLANTIQUE',
  CreditMutuelNordeurope = 'CREDIT_MUTUEL_NORDEUROPE',
  CreditMutuelNormandie = 'CREDIT_MUTUEL_NORMANDIE',
  CreditMutuelOcean = 'CREDIT_MUTUEL_OCEAN',
  CreditMutuelSavoiemontblanc = 'CREDIT_MUTUEL_SAVOIEMONTBLANC',
  CreditMutuelSudest = 'CREDIT_MUTUEL_SUDEST',
  CreditMutuelSudouest = 'CREDIT_MUTUEL_SUDOUEST',
  Fortuneo = 'FORTUNEO',
  Helios = 'HELIOS',
  HelloBank = 'HELLO_BANK',
  Hsbc = 'HSBC',
  IngDirect = 'ING_DIRECT',
  JpMorgan = 'JP_MORGAN',
  LaBanquePostale = 'LA_BANQUE_POSTALE',
  Lcl = 'LCL',
  LouvreBanquePrivee = 'LOUVRE_BANQUE_PRIVEE',
  Mafrenchbank = 'MAFRENCHBANK',
  MemoBank = 'MEMO_BANK',
  Monabanq = 'MONABANQ',
  Montepaschi = 'MONTEPASCHI',
  N26 = 'N26',
  Neuflize = 'NEUFLIZE',
  Nickel = 'NICKEL',
  OrangeBank = 'ORANGE_BANK',
  Palatine = 'PALATINE',
  Qonto = 'QONTO',
  Revolut = 'REVOLUT',
  Rothschild = 'ROTHSCHILD',
  Sbe = 'SBE',
  Shine = 'SHINE',
  Smc = 'SMC',
  SocieteGenerale = 'SOCIETE_GENERALE',
  Socredo = 'SOCREDO',
  Socrif = 'SOCRIF',
  Virgil = 'VIRGIL'
}

export enum LanguageIsoCode {
  En = 'EN',
  Fr = 'FR'
}

export enum ProjectKickoffStepKind {
  AskBookAppointment = 'ASK_BOOK_APPOINTMENT',
  CompleteFolderIntro = 'COMPLETE_FOLDER_INTRO',
  RequestCallBackNow = 'REQUEST_CALL_BACK_NOW',
  ShowDashboardIntro = 'SHOW_DASHBOARD_INTRO'
}

export enum SimulationAnalysisRecommendationLinkKind {
  Article = 'ARTICLE',
  Partner = 'PARTNER'
}

export enum SimulationCreditKind {
  Car = 'CAR',
  Consumer = 'CONSUMER',
  Loa = 'LOA',
  Mortgage = 'MORTGAGE',
  Other = 'OTHER',
  Student = 'STUDENT'
}

export enum SimulationKind {
  Buyout = 'BUYOUT',
  Capacity = 'CAPACITY',
  Purchase = 'PURCHASE',
  Renegotiation = 'RENEGOTIATION'
}

export enum SimulationMaturityStatus {
  OfferAccepted = 'OFFER_ACCEPTED',
  OfferMade = 'OFFER_MADE',
  SaleAgreementSigned = 'SALE_AGREEMENT_SIGNED',
  SearchStarted = 'SEARCH_STARTED',
  UnderStudy = 'UNDER_STUDY'
}

export enum SimulationMortgagorAdditionalRevenueKind {
  ChildSupport = 'CHILD_SUPPORT',
  ExpatriationBonus = 'EXPATRIATION_BONUS',
  FamilyAllowances = 'FAMILY_ALLOWANCES',
  ProfessionalBenefits = 'PROFESSIONAL_BENEFITS',
  Rent = 'RENT'
}

export enum SimulationMortgagorIncomePeriod {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export enum SimulationMortgagorIncomeTaxes {
  After = 'AFTER',
  Before = 'BEFORE'
}

export enum SimulationMortgagorLodgingLocationKind {
  FranceMetropolitan = 'FRANCE_METROPOLITAN',
  World = 'WORLD'
}

export enum SimulationMortgagorLodgingSituationKind {
  Owner = 'OWNER',
  Sheltered = 'SHELTERED',
  StaffHoused = 'STAFF_HOUSED',
  Tenant = 'TENANT'
}

export enum SimulationMortgagorOccupationDuration {
  LongTerm = 'LONG_TERM',
  ShortTerm = 'SHORT_TERM'
}

export enum SimulationMortgagorOccupationKind {
  Medical = 'MEDICAL',
  Other = 'OTHER',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum SimulationMortgagorOccupationOtherStatus {
  BusinessOwner = 'BUSINESS_OWNER',
  Craftsman = 'CRAFTSMAN',
  Entertainment = 'ENTERTAINMENT',
  Farmer = 'FARMER',
  Interim = 'INTERIM',
  Jobless = 'JOBLESS',
  Jobseeker = 'JOBSEEKER',
  LiberalLegal = 'LIBERAL_LEGAL',
  LiberalOther = 'LIBERAL_OTHER',
  ManagingEmployee = 'MANAGING_EMPLOYEE',
  Merchant = 'MERCHANT',
  ParentalLeave = 'PARENTAL_LEAVE',
  Remote = 'REMOTE',
  Retired = 'RETIRED',
  SelfEmployed = 'SELF_EMPLOYED',
  Student = 'STUDENT'
}

export enum SimulationMortgagorOccupationPrivateRole {
  Executive = 'EXECUTIVE',
  NonExecutive = 'NON_EXECUTIVE'
}

export enum SimulationMortgagorOccupationPublicStatus {
  Contractor = 'CONTRACTOR',
  Intern = 'INTERN',
  Tenure = 'TENURE'
}

export enum SimulationPropertyDpe {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  Na = 'NA'
}

export enum SimulationPropertyKind {
  Construction = 'CONSTRUCTION',
  LandConstruction = 'LAND_CONSTRUCTION',
  New = 'NEW',
  Old = 'OLD',
  Vefa = 'VEFA'
}

export enum SimulationPropertyUsage {
  MixedUseProject = 'MIXED_USE_PROJECT',
  PrimaryResidence = 'PRIMARY_RESIDENCE',
  ProfessionalCommercialProject = 'PROFESSIONAL_COMMERCIAL_PROJECT',
  RentalInvestment = 'RENTAL_INVESTMENT',
  SecondaryResidence = 'SECONDARY_RESIDENCE'
}

export enum SimulationRenegotiationLoanInsuranceKind {
  Delegate = 'DELEGATE',
  Group = 'GROUP'
}

export enum SimulationResultsErrorFailureState {
  Block = 'BLOCK',
  Warn = 'WARN'
}

export enum SimulationStructureKind {
  Duo = 'DUO',
  Sci = 'SCI',
  Solo = 'SOLO'
}

export enum SimulationStructureSciAssociation {
  Complex = 'COMPLEX',
  Simple = 'SIMPLE'
}

export enum SimulationStructureSciKind {
  Ir = 'IR',
  Is = 'IS'
}
