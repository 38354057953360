import { breakpoints, g } from '@pretto/bricks/components/layout'
import { Wrapper as W } from '@pretto/bricks/components/layout/Wrapper/styles'

import styled, { css } from 'styled-components'

export const Content = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  overflow: hidden;
`

export const ContentButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(5)};
  }
`

export const ContentParagraph = styled.div`
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
  }
`

export const ContentTitle = styled.div`
  margin-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(2)};
  }
`

export const Emoji = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${g(1)};
  margin-bottom: ${g(3)};
`

export const InformationBox = styled.div`
  margin-bottom: ${g(1)};

  &:last-child {
    margin-bottom: 0;
  }

  p,
  ul {
    font-size: 14px;
    margin-bottom: ${g(2)};

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    list-style-type: disc;
    list-style-position: inside;
  }

  a {
    font-size: 14px;
    color: ${({ theme }) => theme.legacy.colors.accent1.default};
  }
`

export const InformationBoxes = styled.div`
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(4)};
  }
`

export const MainContent = styled.div`
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
  }
`

export const Malus = styled.div`
  margin-bottom: ${g(2)};
  padding-top: ${g(3)};

  &:last-child {
    margin-bottom: 0;
  }
`

export const MarketingCards = styled.div`
  display: grid;
  grid-gap: ${g(2)};
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 0;
    grid-template-columns: repeat(2, 1fr);
  }
`

export const Page = styled.div`
  min-height: 100%;
  overflow: hidden;
  position: relative;
`

export const Results = styled.div`
  padding-bottom: ${g(5)};
`

export const ResultsTitle = styled.div`
  margin: ${g(2)} 0;
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: ${g(4)} 0 ${g(3)};
  }
`

export const Sidebar = styled.div`
  background-color: ${({ theme }) => theme.legacy.colors.white.default};
  position: relative;
  width: ${g(46)};
  z-index: 0;

  @media screen and (min-width: ${breakpoints.laptop}) {
    flex: 0 0 ${g(46)};

    &:before {
      background-color: ${({ theme }) => theme.legacy.colors.white.default};
      bottom: 0;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 50vw;
      z-index: -1;
    }
  }
`

export const SidebarTitle = styled.div`
  margin-bottom: ${g(2)};
  margin-top: ${g(1)};
`

export const Wrapper = styled(W)`
  display: flex;
`
export const RegroupCredits = styled.div`
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
  }
`
export const FeedbackCard = styled.div`
 margin-top: ${g(3)};
 margin-bottom: ${g(3)};
`
