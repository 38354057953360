import { createContext, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Graph, Route } from './Graph'

interface Options {
  ignoreOnEdge?: boolean
  search?: string
}

interface RoutesGraphContextInterface<C extends Record<string, unknown>> {
  graph: Graph<C>
  indexNextRoute?: Route<C>
  nextRoute?: Route<C>
  previousRoute?: Route<C>
  goToNextRoute: (options?: Options) => void
  goToPreviousRoute: (options?: Options) => void
  goToRoute: (path: string) => void
}

interface RoutesGraphContextProps<C extends Record<string, unknown>> {
  context: C
  wait?: () => Promise<C>
}

export const createRoutesGraphContext = <C extends Record<string, unknown>>(graph: Graph<C>) => {
  const RoutesGraphContext = createContext<RoutesGraphContextInterface<C>>({} as RoutesGraphContextInterface<C>)

  const Provider: React.FC<RoutesGraphContextProps<C>> = ({
    context,
    children,
    wait = () => Promise.resolve(context),
  }) => {
    const { location, push, replace } = useHistory()

    const currentRoute = graph.findRouteByPath(location.pathname) ?? graph.rootRoute

    useEffect(() => {
      if (currentRoute !== null && !graph.isNodeAccessible(currentRoute, context)) {
        const previousRoute = findPreviousRoute(graph, currentRoute, context)

        if (previousRoute) {
          replace(previousRoute.path)
        }
      }
    }, [currentRoute])

    const indexNextRoute = graph.rootRoute?.getNextRoute(context)
    const nextRoute = currentRoute?.getNextRoute(context)
    const previousRoute = currentRoute?.getPreviousRoute(context)

    const value = {
      graph,
      indexNextRoute,
      nextRoute,
      previousRoute,
      async goToNextRoute({ ignoreOnEdge = false, search = '' }: Options = {}) {
        const context = await wait()
        const nextRoute = currentRoute?.getNextRoute(context)

        if (nextRoute && (!ignoreOnEdge || (ignoreOnEdge && !graph.endsBy(nextRoute)))) {
          push(`${nextRoute.path}${search}`)
        }
      },
      async goToPreviousRoute({ ignoreOnEdge = false, search = '' }: Options = {}) {
        const context = await wait()
        const previousRoute = currentRoute?.getPreviousRoute(context)

        if (previousRoute && (!ignoreOnEdge || (ignoreOnEdge && graph.rootRoute !== previousRoute))) {
          push(`${previousRoute.path}${search}`)
        }
      },
      goToRoute(path: string) {
        if (graph.findRouteByPath(path)) {
          push(path)
        }
      },
    }

    return <RoutesGraphContext.Provider value={value}>{children}</RoutesGraphContext.Provider>
  }

  return { Provider, useRoutesGraph: () => useContext(RoutesGraphContext) }
}

const findPreviousRoute = <C extends Record<string, unknown>>(graph: Graph<C>, route: Route<C>, context: C) => {
  const previousRoute = route.getPreviousRoute(context)

  if (previousRoute) {
    return previousRoute
  }

  const deepRoute = graph.getDeepRoute(context)

  if (graph.endsBy(deepRoute)) {
    return deepRoute.getPreviousRoute(context)
  }

  return deepRoute
}
