import { monthToIntOrNull } from '@pretto/app/src/Sentences/lib/monthToIntOrNull'
import { Month } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

export const mapStartMonth: MapToChoices<Month[]> = (values, context) => {
  if (!context) return []
  const { simulation, setSimulationComortgagor } = context

  return values.map(value => {
    const occupationStartMonth = monthToIntOrNull(value)

    return {
      key: value,
      isSelected:
        occupationStartMonth !== null && simulation.profileMortgagors[1]?.occupationStartMonth === occupationStartMonth,
      onClick: () => {
        setSimulationComortgagor({ occupationStartMonth })
      },
      label: t(`sentences.months.${value}`),
    }
  })
}
