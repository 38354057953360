import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import styled from 'styled-components'

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${g(3)} ${g(2.5)};
  ${grid()}
`
export const MainContentWrapper = styled.div`
  ${grid()}
  ${column([1, 5])};
  gap: ${g(3)};
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tablet}) {
    gap: ${g(5)};
    ${grid(10, { isLeftAligned: true, isRightAligned: true })};
    ${column([1, 10])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
  }
`

export const SectionTop = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    flex-direction: row;
  }
`
export const Section = styled.section`
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }
`
export const Heading = styled.h3`
  ${({ theme }) => theme.typos.heading4};
  max-width: 287px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading3};
    max-width: 456px;
  }
`
export const Date = styled.p`
  color: ${({ theme }) => theme.colors.neutral2};
  ${({ theme }) => theme.typos.body4}
  display: flex;
  white-space: nowrap;
  align-self: flex-start;
  padding: ${g(1)} ${g(2)};
  background-color: ${({ theme }) => theme.colors.neutral5};
  border-radius: ${g(0.5)};
  margin: 0 0 ${g(2)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0;
  }
`
export const Content = styled.p``

export const CoachingCardContainer = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }
`

export const Caption = styled.p`
  text-align: center;
  ${({ theme }) => theme.typos.caption};
  color: ${({ theme }) => theme.colors.neutral2};
`
