import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { ReferSignUpPage } from '@pretto/app/src/Refer/views/ReferSignUpPage'
import Header from '@pretto/app/src/SharedContainers/Header'
import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { useSignUpForm } from '@pretto/app/src/SignUp/lib/useSignUpForm'
import { REFERRAL_SIGNUP } from '@pretto/app/src/apollo'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { useTrustpilotData } from '@pretto/app/src/lib/useTrustpilotData'

import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import qs from 'qs'
import { memo, useState } from 'react'

const ReferSignup = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const { isLoggedIn, signIn, signOut } = useAuth()

  const [signupFriend] = useMutation(REFERRAL_SIGNUP)

  const defaultValues = qs.parse(window.location.search.substring(1))

  const handleClickCgu = () => {
    trackAction('REFER_LEGAL_LINKS_CLICKED', { refer_legal_link: 'cgu', refer_type: 'friend' })
  }

  const handleClickPrivacy = () => {
    trackAction('REFER_LEGAL_LINKS_CLICKED', { refer_legal_link: 'privacy', refer_type: 'friend' })
  }

  const handleSubmit = async fields => {
    setIsSigningIn(true)

    if (isLoggedIn) {
      await signOut({ ignoreRedirect: true })
    }

    const response = await signupFriend({
      variables: { ...fields, idReferral: id },
    })

    const { error, token } = response.data.referral_signup

    if (error) {
      setError(error)
      setIsSigningIn(false)
    }

    if (token) {
      trackAction('REFER_SIGNUP', { refer_signup_type: 'friend' })

      await signIn({ accessToken: token })

      history.push('/refer/friend/appointment')
    }
  }

  const { formProps, setError } = useSignUpForm({
    defaultValues,
    onCguClick: handleClickCgu,
    onPrivacyClick: handleClickPrivacy,
    onSubmit: handleSubmit,
  })

  const [isSigningIn, setIsSigningIn] = useState(false)

  const trackAction = useTracking()

  const trustpilotData = useTrustpilotData()

  return (
    <ThemeProvider designSystem={'reveal'}>
      <Header backgroundColor="neutral4" isPartnerLogoDisabled navigationItemsList={[NAV_ITEMS.faq]} />

      {isSigningIn && <SpinnerLegacy overlay />}

      <ReferSignUpPage formProps={formProps} trustpilotRateProps={trustpilotData} />
    </ThemeProvider>
  )
}

ReferSignup.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default memo(ReferSignup)
