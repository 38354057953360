import { temporal } from '@pretto/bricks/core/utility/temporal'

export enum TimeUnit {
  YEAR = 'year',
  MONTH = 'month',
  DAY = 'day',
}

type IsDateInRangeFromTodayProps = {
  date: string | null | undefined
  range: number
  unit: TimeUnit
}

export const isDateInRangeFromToday = ({ date, range, unit }: IsDateInRangeFromTodayProps): boolean =>
  temporal(date).isBefore(temporal().add(range, unit)) && temporal(date).isAfter(temporal().add(range * -1, unit))
