import { gql } from '@apollo/client'

import * as fragments from './fragments'

export const TYPOLOGY = gql`
  query Typology {
    account {
      id
      typology
    }
  }
`

export const USER_CLIENT = gql`
  query UserClient {
    ...User
    account {
      id
      firstName
      lastName
      name
    }
    referrer {
      id
    }
    accounts {
      id
      email
      firstName
      id
      isActive
      isMine
      lastName
      name
      phone
      project
      rateAlert
      featureAccess
      mortgagorId
    }
    project {
      id
      profile {
        joint_banks
        mortgagors {
          id
        }
      }
    }
    deal {
      id
      agreement
      application {
        status
      }
      appointmentKind
      isLowPotential
      onboardingDone
      mandateStatus {
        signed
      }
      status
      lostReason
    }
  }

  ${fragments.USER}
`

export const USER_PRE_LEAD = gql`
  query UserPreLead {
    ...User
    account {
      email
      firstName
      id
      isActive
      lastName
      name
      phone
      project
      rateAlert
      featureAccess
    }
    referrer {
      id
    }
  }

  ${fragments.USER}
`
