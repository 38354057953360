import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { GetSimulation, SetSimulation, useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'

import { useState } from 'react'

// This is an experiment on how to make useActiveField easier
// to use in complex form, needs to be tested further before
// being propagated to others pages
export const useActiveField = (
  indexes: (simulation: GetSimulation) => boolean[]
): {
  activeField: number
  isFilled: boolean
  previousActiveField: number
  setActiveField: React.Dispatch<React.SetStateAction<number>>
  refreshField: (simulation: SetSimulation) => void
} => {
  const { nextRoute } = useRoutesGraph()

  const { simulation } = useSentences()

  const getValue = (simulation: GetSimulation) => {
    const value = indexes(simulation).findIndex(Boolean)

    return value < 0 ? Infinity : value
  }

  const isFilled = (simulation: GetSimulation) => {
    return nextRoute !== undefined && getValue(simulation) === Infinity
  }

  const [activeField, setActiveField] = useState<number>(isFilled(simulation) ? Infinity : getValue(simulation) || 0)
  const [previousActiveField, setPreviousActiveField] = useState<number>(activeField)

  const refreshField = (partials: SetSimulation) => {
    updateField(getValue({ ...simulation, ...partials }))
  }

  const updateField: React.Dispatch<React.SetStateAction<number>> = (...args) => {
    setPreviousActiveField(activeField)
    setActiveField(...args)
  }

  return {
    activeField,
    isFilled: isFilled(simulation),
    previousActiveField,
    refreshField,
    setActiveField: updateField,
  }
}
