import { mapPropertyTypeValues } from '@pretto/app/src/Sentences/Introduction/PropertyType/lib/mappers/mapPropertyTypeValues'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationPropertyKind } from '@pretto/app/src/types/api/enums'

export const PropertyTypePage: React.FC = () => {
  const { simulation } = useSentences()
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && simulation.propertyKind))

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'propertyType' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t('sentences.introduction.propertyType.sentence')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!!simulation.propertyKind}>
          {t(`sentences.introduction.propertyType.values.${simulation.propertyKind || SimulationPropertyKind.Old}`)}
        </Prompt>
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapPropertyTypeValues(
            Object.values([
              SimulationPropertyKind.Old,
              SimulationPropertyKind.Vefa,
              SimulationPropertyKind.New,
              SimulationPropertyKind.LandConstruction,
              SimulationPropertyKind.Construction,
            ])
          )}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'propertyType' } }}
          onChoiceClick={() => {
            goToNextRoute()
          }}
        />
      )}
      {simulation.propertyKind && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
