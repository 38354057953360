import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import styled, { createGlobalStyle, css } from 'styled-components'

const TRANSITION_DURATION = 300

type TransitionProps = {
  $isOpen: boolean
}

export const GlobalStyle = createGlobalStyle<{ isOpen: boolean }>`
  body {
    overflow: ${({ isOpen }) => (isOpen ? 'hidden' : '')};
  }
`

export const Overlay = styled.div<TransitionProps>`
  ${grid()};
  position: fixed;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
  background-color: rgba(5, 5, 5, 0.8);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition:
    opacity ${`${TRANSITION_DURATION}ms`} ease-in-out,
    visibility ${`${TRANSITION_DURATION}ms`} ease-in-out;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    `}
`

export const StyledModal = styled.div<TransitionProps>`
  ${column([1, 6])};
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  flex-shrink: 0;
  overflow: auto;
  transform: translateY(${g(3)});
  opacity: 0;
  transition:
    transform ${`${TRANSITION_DURATION}ms`} ease-in-out,
    opacity ${`${TRANSITION_DURATION}ms`} ease-in-out;

  ${({ $isOpen }: { $isOpen: boolean }) =>
    $isOpen &&
    css`
      transform: translateY(0);
      opacity: 1;
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    height: auto;
    margin: ${g(3)} 0px;
    border-radius: ${g(1)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    margin: ${g(9)} 0px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 10])};
  }
`
