import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationStructureKind } from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'

export const HasCompetitionPage: React.FC = () => {
  const { simulation, setSimulation } = useSentences()

  const { goToNextRoute, nextRoute } = useRoutesGraph()

  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && !isNil(simulation.competitionHasOffer)))

  const mapHasCompetitionValues: MapToChoices<YesNo[]> = values =>
    values.map(value => {
      return {
        key: value,
        value,
        isSelected:
          (value === YesNo.Yes && simulation.competitionHasOffer === true) ||
          (value === YesNo.No && simulation.competitionHasOffer === false),
        onClick: () => {
          setSimulation({ competitionHasOffer: value === YesNo.Yes })
          goToNextRoute({ ignoreOnEdge: true })
        },
        label: t(`sentences.competition.hasCompetition.values.${value}`),
      }
    })

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'hasCompetition' } }}>
      <Title>{t('sentences.competition.title')}</Title>
      <div>
        {t('sentences.competition.hasCompetition.sentences.0')}
        {simulation.structureKind === SimulationStructureKind.Duo &&
          t('sentences.competition.hasCompetition.sentences.1')}
        <Prompt
          isFilled={!isNil(simulation.competitionHasOffer)}
          onClick={() => {
            setActiveField(0)
          }}
        >
          {t(
            `sentences.competition.hasCompetition.values.${
              simulation.competitionHasOffer || isNil(simulation.competitionHasOffer) ? YesNo.Yes : YesNo.No
            }`
          )}
        </Prompt>
        {t('sentences.competition.hasCompetition.sentences.2')}
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapHasCompetitionValues(Object.values(YesNo))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'hasCompetition' } }}
        />
      )}
      {!isNil(simulation.competitionHasOffer) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
