import AutoComplete from '@pretto/bricks/components/form/AutoComplete'

import { formatValueForType } from '@pretto/app-core/components/PlaceField'

import { searchAddress, searchCountry, searchPlaces } from '@pretto/places'

import countries from 'i18n-iso-countries'
import { useEffect, useState } from 'react'

countries.registerLocale(require('i18n-iso-countries/langs/fr.json'))
const countriesApi = searchCountry.init(process.env.ALGOLIA_COUNTRIES_APP_ID, process.env.ALGOLIA_COUNTRIES_API_KEY)

const usePlaceField = (config = {}) => {
  const [value, setValue] = useState()

  const [results, setResults] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const { type } = config

  useEffect(() => {
    setValue(config.defaultValue)
  }, [config.defaultValue])

  useEffect(() => {
    if (searchValue === '') {
      setResults([])
      return
    }

    ;(async () => {
      if (type === 'city') {
        const results = await searchPlaces(searchValue, { limit: 21 })
        return setResults(results)
      }

      if (type === 'country') {
        const results = await countriesApi.get(searchValue, { limit: 10 })
        return setResults(results)
      }

      try {
        const results = await searchAddress(searchValue, { limit: 10 })
        setResults(results)
      } catch (error) {}
    })()
  }, [searchValue])

  const handleAfterOpen = value => {
    const results = type === 'country' ? [{ label: 'France', value: 'fr' }] : []

    setResults(results)
    setSearchValue(value)
  }

  const handleClear = () => setSearchValue('')
  const handleSearch = value => setSearchValue(value)
  const handleSelect = ({ value }) => setValue(value)

  const selectedValue = value && formatValueForType(type, value)

  const props = {
    ...config,
    onAfterOpen: handleAfterOpen,
    onClear: handleClear,
    onSearch: handleSearch,
    onSelect: handleSelect,
    results,
    searchValue,
    selectedValue,
  }
  const component = <AutoComplete {...props} />
  return { component, formattedValue: selectedValue, setValue, value: (value || '').trim() }
}

export default usePlaceField
