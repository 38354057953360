import { GetSimulation } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import { SimulationStructureKind, SimulationStructureSciKind } from '@pretto/app/src/types/api/enums'
import { MortgageArrangement } from '@pretto/app/src/types/gateway/enums'

export const mapStructure: ComposableMapper = ({ project, simulation }) => {
  const mapArrangement = (simulation: GetSimulation): MortgageArrangement | null => {
    if (
      simulation.structureKind === SimulationStructureKind.Sci &&
      simulation.structureSciKind === SimulationStructureSciKind.Ir
    ) {
      return MortgageArrangement.SciIr
    }

    if (
      simulation.structureKind === SimulationStructureKind.Sci &&
      simulation.structureSciKind === SimulationStructureSciKind.Is
    ) {
      return MortgageArrangement.SciIs
    }

    return null
  }

  return {
    project: {
      ...project,

      mortgagors_arrangement: mapArrangement(simulation),
    },
    simulation,
  }
}
