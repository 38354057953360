import * as v from '@pretto/app/src/Sentences/lib/validators/context'
import type { GraphContext } from '@pretto/app/src/Sentences/types/graph'
import { Edge, Graph, Route } from '@pretto/app/src/lib/graph/Graph'

import { allPass } from 'ramda'

// Introduction
const structureRoute = new Route<GraphContext>('structure', '/project/buyout/introduction/structure')
const propertyUsageRoute = new Route<GraphContext>('propertyUsage', '/project/buyout/introduction/propertyUsage')
const propertyTypeRoute = new Route<GraphContext>('propertyType', '/project/buyout/introduction/propertyType')

export const buyoutGraph = new Graph<GraphContext>('buyoutGraph', structureRoute)

buyoutGraph.addRoute(propertyUsageRoute)
buyoutGraph.addRoute(propertyTypeRoute)

buyoutGraph.addEdge(new Edge<GraphContext>(structureRoute, propertyUsageRoute, v.isSolo))
buyoutGraph.addEdge(new Edge<GraphContext>(propertyUsageRoute, propertyTypeRoute, v.hasPropertyUsage))

// Lodging
const ageChildrenRoute = new Route<GraphContext>('ageChildren', '/project/buyout/lodging/ageChildren')
const addressRoute = new Route<GraphContext>('address', '/project/buyout/lodging/address')
const situationRoute = new Route<GraphContext>('situation', '/project/buyout/lodging/situation')

buyoutGraph.addRoute(ageChildrenRoute)
buyoutGraph.addRoute(addressRoute)
buyoutGraph.addRoute(situationRoute)

buyoutGraph.addEdge(new Edge<GraphContext>(propertyTypeRoute, ageChildrenRoute, v.hasPropertyType))
buyoutGraph.addEdge(new Edge<GraphContext>(ageChildrenRoute, addressRoute, allPass([v.hasAge, v.hasChildrenNumber])))
buyoutGraph.addEdge(new Edge<GraphContext>(addressRoute, situationRoute, v.hasAddress))

// Occupation
const expatriateRoute = new Route<GraphContext>('expatriate', '/project/buyout/occupation/expatriate')
const sectorRoute = new Route<GraphContext>('sector', '/project/buyout/occupation/sector')

buyoutGraph.addRoute(expatriateRoute)
buyoutGraph.addRoute(sectorRoute)

buyoutGraph.addEdge(
  new Edge<GraphContext>(sectorRoute, expatriateRoute, allPass([v.isInternational, v.isOccupationFilled]))
)

buyoutGraph.addEdge(new Edge<GraphContext>(situationRoute, sectorRoute, allPass([v.hasSituationType, v.hasRent])))

// Income
const salaryRoute = new Route<GraphContext>('salary', '/project/buyout/income/salary')
const bonusRoute = new Route<GraphContext>('bonus', '/project/buyout/income/bonus')
const revenueRoute = new Route<GraphContext>('revenue', '/project/buyout/income/revenue')

buyoutGraph.addRoute(salaryRoute)
buyoutGraph.addRoute(bonusRoute)
buyoutGraph.addRoute(revenueRoute)

buyoutGraph.addEdge(new Edge<GraphContext>(expatriateRoute, salaryRoute, v.isExpatriateFilled))

buyoutGraph.addEdge(
  new Edge<GraphContext>(sectorRoute, salaryRoute, allPass([v.isNotInternational, v.isOccupationFilled]))
)

buyoutGraph.addEdge(new Edge<GraphContext>(salaryRoute, bonusRoute, allPass([v.isNotOthersSector, v.isSalaryFilled])))
buyoutGraph.addEdge(new Edge<GraphContext>(salaryRoute, revenueRoute, allPass([v.isSalaryFilled, v.isOthersSector])))
buyoutGraph.addEdge(new Edge<GraphContext>(bonusRoute, revenueRoute, v.isBonusFilled))

// Buyout
const buyoutRoute = new Route<GraphContext>('buyout', '/project/buyout/buyout/buyout')

buyoutGraph.addRoute(buyoutRoute)

buyoutGraph.addEdge(new Edge<GraphContext>(revenueRoute, buyoutRoute, v.isRevenueFilled))

// Charges
const creditsRoute = new Route<GraphContext>('credits', '/project/buyout/credits/credits')
const childSupportRoute = new Route<GraphContext>('childSupport', '/project/buyout/credits/childSupport')

buyoutGraph.addRoute(creditsRoute)
buyoutGraph.addRoute(childSupportRoute)

buyoutGraph.addEdge(new Edge<GraphContext>(buyoutRoute, creditsRoute, v.isBuyoutFilled))
buyoutGraph.addEdge(new Edge<GraphContext>(creditsRoute, childSupportRoute, v.isCreditsFilled))

// Contribution
const contributionRoute = new Route<GraphContext>('contribution', '/project/buyout/contribution')

buyoutGraph.addRoute(contributionRoute)

buyoutGraph.addEdge(new Edge<GraphContext>(childSupportRoute, contributionRoute, v.hasNotPTZ))

// Simulation
const simulationRoute = new Route<GraphContext>('simulation', '/simulation')

buyoutGraph.addRoute(simulationRoute)

buyoutGraph.addEdge(
  new Edge<GraphContext>(contributionRoute, simulationRoute, allPass([v.isContributionFilled, v.isSavingsFilled]))
)
