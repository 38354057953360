import ImageComponent from '@pretto/bricks/website/shared/components/Image'

import { SecondaryButton } from '@pretto/zen/reveal/atoms/buttons/secondaryButton/SecondaryButton'

import * as S from './NonFundableFailure.styles'

interface NonFundableFailureProps {
  refetch: () => void
}

const NonFundableFailure = ({ refetch }: NonFundableFailureProps) => {
  return (
    <S.Content>
      <S.ImageContainer>
        <ImageComponent alt="" path="v1739894850/app/illustrations/hands_up.svg" />
      </S.ImageContainer>

      <S.Title>Une erreur est survenue…</S.Title>

      <SecondaryButton scheme="black" onClick={refetch}>
        Réessayer
      </SecondaryButton>
    </S.Content>
  )
}

export default NonFundableFailure
