import CapacityMarketingCard from '@pretto/bricks/app/capacity/components/CapacityMarketingCard'
import FeedbackCard from '@pretto/bricks/shared/components/FeedbackCard'
import RegroupCredits from '@pretto/bricks/app/simulator/components/RegroupCredits'
import { useBreakpoint } from '@pretto/bricks/assets/utility'
import CollapsibleBox from '@pretto/bricks/components/boxes/CollapsibleBox'
import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import Heading from '@pretto/bricks/components/typography/Heading'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'
import FlagRow from '@pretto/bricks/shared/components/FlagRow'
import SubMenu from '@pretto/bricks/shared/components/SubMenu'
import Link from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'
import { Fragment } from 'react'

import * as S from './styles'

const LOAN_AMOUNT = 50000
const LOAN = formatNumber(LOAN_AMOUNT)

const getBoxesContent = (Link, onReview) => [
  {
    content: (
      <>
        <p>
          De manière générale, votre capacité d’emprunt ne doit pas excéder 33 % de vos revenus et nécessite la prise en
          compte de plusieurs facteurs :
        </p>
        <ul>
          <li>La durée de votre prêt</li>
          <li>Votre apport personnel</li>
          <li>Votre âge</li>
        </ul>
        <p>
          Enfin, les banques regarderont aussi le saut de charge. C’est la différence de niveau de vie avant et après le
          prêt immobilier.
        </p>
        <Link href="https://www.pretto.fr/capacite-demprunt/">En savoir plus</Link>
      </>
    ),
    title: 'Pourquoi ce résultat ?',
  },
  {
    content: (
      <>
        <p>
          Pour estimer votre capacité d'achat, nous n'appliquons pas une formule simpliste comme peuvent le faire
          d'autres services plus rapides. Nous tenons compte de l'<strong>activité réelle de crédit</strong> que nous
          constatons chez nos banques partenaires, ainsi que de l'<strong>ensemble des informations</strong> que vous
          nous avez indiquées :
        </p>
        <ul>
          <li>Vos revenus et ceux de votre co-emprunteur</li>
          <li>Vos charges et vos crédits en cours</li>
          <li>Le type de logement qui vous intéresse (neuf ou ancien)</li>
          <li>Le montant de votre apport et de votre épargne</li>
          <li>La durée du crédit</li>
          <li>Les taux d'intérêt, et les exigences des banques actuelles sur l'endettement</li>
          <li>Le montant de vos futures mensualités</li>
        </ul>

        <p>À savoir :</p>
        <ul>
          <li>
            Les salaires d’un emploi en CDD ne sont pas pris en compte par les banques et donc par notre simulateur.
          </li>
          <li>Les bonus ne sont pris en compte que si on peut les observer sur quelques années (3 le plus souvent).</li>
        </ul>

        <p>Les résultats de notre simulateur sont indicatifs.</p>
      </>
    ),
    title: 'Quels éléments sont pris en compte dans le calcul ?',
  },
  {
    content: (
      <>
        <p>
          Vous avez obtenu un calcul différent ailleurs ou vous êtes surpris du résultat ? Donnez-nous votre avis sur le
          simulateur Pretto, nous cherchons à l'améliorer en permanence.
        </p>
        <ButtonLegacy href="mailto:hello@pretto.fr" onClick={onReview}>
          Donner mon avis
        </ButtonLegacy>
      </>
    ),
    title: 'Donnez-nous votre avis',
  },
]

const CapacityErrorPage = ({
  actions,
  editLabel,
  malus,
  onEditClick,
  onFaqClick,
  onGuideClick,
  onRegroupCreditsClick,
  onReview,
  projectId,
  regroupCredits,
  onFeedbackClick
}) => {
  const { isMobile } = useBreakpoint()

  const buttonProps = isMobile
    ? {
        format: 'full',
        size: 'small',
      }
    : {
        icon: 'pen',
        iconPosition: 'right',
      }

  const boxes = getBoxesContent(Link, onReview)

  return (
    <S.Page>
      <Responsive min="tablet">
        <SubMenu actions={actions} format="align-right" />
      </Responsive>

      <S.Content>
        <S.Wrapper full="tablet">
          <S.Results>
            <div>
              <S.ResultsTitle>
                <Heading>Résultats</Heading>
              </S.ResultsTitle>

              <S.MainContent>
                <Card variant="white" zDepth={2} format="small-radius">
                  <Content>
                    <S.ContentTitle>
                      <S.Emoji>
                        <Emoji size="large">:/</Emoji>
                      </S.Emoji>
                      <Heading size="small">Votre capacité d’emprunt est insuffisante.</Heading>
                    </S.ContentTitle>
                    <S.ContentParagraph>
                      Pretto ne traite pas les projets immobiliers avec une capacité d’emprunt inférieure à {LOAN} €.
                    </S.ContentParagraph>
                    <S.ContentButton>
                      <ButtonLegacy {...buttonProps} onClick={onEditClick} variant="accent-1">
                        {editLabel}
                      </ButtonLegacy>
                    </S.ContentButton>
                    <SubHeading>
                      Voici les raisons qui pourraient expliquer ce résultat en fonction de votre profil, en prenant
                      l'exemple d'un projet d'achat avec {LOAN} euros d'emprunt :
                    </SubHeading>
                    {malus.map((flagProps, index) => (
                      <Fragment key={index}>
                        {index > 0 && <Divider />}
                        <S.Malus>
                          <FlagRow {...flagProps} />
                        </S.Malus>
                      </Fragment>
                    ))}
                  </Content>
                </Card>
              </S.MainContent>

              {regroupCredits && (
                <S.RegroupCredits>
                  <RegroupCredits onClick={onRegroupCreditsClick} projectId={projectId} />
                </S.RegroupCredits>
              )}

              <S.FeedbackCard>
                <FeedbackCard onClick={onFeedbackClick} />
              </S.FeedbackCard>
              
              <S.InformationBoxes>
                {boxes.map(({ content, title }, i) => (
                  <S.InformationBox key={i}>
                    <CollapsibleBox title={title}>{content}</CollapsibleBox>
                  </S.InformationBox>
                ))}
              </S.InformationBoxes>

              <S.MarketingCards>
                <CapacityMarketingCard onClick={onGuideClick} slug="guide" />
                <CapacityMarketingCard onClick={onFaqClick} slug="faq" />
              </S.MarketingCards>
            </div>
          </S.Results>
        </S.Wrapper>
      </S.Content>
    </S.Page>
  )
}

CapacityErrorPage.propTypes = {
  /** List of actions. */
  actions: PropTypes.array.isRequired,
  /** Label to be used for the edit button. */
  editLabel: PropTypes.string.isRequired,
  /** Array of [`FlagRow`](/#!/FlagRow). */
  malus: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Function triggered when Edit Button is clicked. */
  onEditClick: PropTypes.func.isRequired,
  /** Function triggered when button of FAQ card is clicked. */
  onFaqClick: PropTypes.func.isRequired,
  /** Function triggered when button of Guide card is clicked. */
  onGuideClick: PropTypes.func.isRequired,
  /** Function triggered when regroup credits button is clicked. */
  onRegroupCreditsClick: PropTypes.func,
  /** Function triggered when Review button is clicked. */
  onReview: PropTypes.func.isRequired,
  /** ID of current project to identify the regroup credits request. */
  projectId: PropTypes.string.isRequired,
  /** Whether the user can regroup his credits or not. */
  regroupCredits: PropTypes.bool,
  /** Function triggered when feedback button is clicked. */
  onFeedbackClick: PropTypes.func,
}

export default CapacityErrorPage
