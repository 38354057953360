import Theme from '@pretto/bricks/components/utility/ThemeProvider/index'

import { Spinner } from '@pretto/zen/atoms/loaders/Spinner/Spinner'

import { MaturityType } from '@pretto/app/src/Sentences/types/enums'
import { Modal } from '@pretto/app/src/SharedComponents/Modal/Modal'
import type { Day } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/day'
import { Occurrence } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/occurrence'
import type { Slot } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/slot'
import { Steps } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/steps'
import { AdvisorLabelsType } from '@pretto/app/src/config/advisor'

import { ReactNode } from 'react'

import * as S from './MutualizedAgenda.styles'

interface MutualizedAgendaProps {
  advisorLabel: AdvisorLabelsType
  alert: ReactNode
  days: Day[]
  handleCallMeBack: () => void
  handleClose: () => void
  handleConfirmAppointment: (slot?: Slot) => void
  handleGoBack: () => void
  handleSubmit: (withVisio: boolean) => void
  handleTrackNext: () => void
  isDriAllowed?: boolean
  isLoading: boolean
  isOpen: boolean
  isShown: boolean
  maturity: MaturityType
  occurrence: Occurrence
  selectedSlot?: Slot
  step: Steps
  timelineProps?: { steps: string[]; currentStepIndex: number }
}

export const MutualizedAgenda: React.FC<MutualizedAgendaProps> = ({
  advisorLabel,
  alert,
  days,
  handleCallMeBack,
  handleClose,
  handleConfirmAppointment,
  handleGoBack,
  handleSubmit,
  handleTrackNext,
  isDriAllowed,
  isLoading,
  isOpen,
  isShown,
  maturity,
  occurrence,
  selectedSlot,
  step,
  timelineProps,
}) => {
  const stepComponent =
    step === Steps.SUBSCRIBE && selectedSlot ? (
      <S.Subscribe selectedSlot={selectedSlot} handleGoBack={handleGoBack} handleSubmit={handleSubmit} />
    ) : (
      <S.Agenda
        advisorLabel={advisorLabel}
        days={days}
        handleCallMeBack={handleCallMeBack}
        handleClose={handleClose}
        handleConfirmAppointment={handleConfirmAppointment}
        handleTrackNext={handleTrackNext}
        maturity={maturity}
        occurrence={occurrence}
        isDriAllowed={isDriAllowed}
      />
    )

  return (
    <Theme designSystem="reveal">
      <Modal onClose={handleClose} isOpen={isOpen}>
        <S.Grid>
          <S.ContentContainer>
            {isLoading ? (
              <S.SpinnerContainer>
                <Spinner />
              </S.SpinnerContainer>
            ) : (
              <>
                {!!alert && <S.Alert in={isShown}>{alert}</S.Alert>}

                {timelineProps && <S.Timeline {...timelineProps} />}

                {stepComponent}
              </>
            )}
          </S.ContentContainer>
        </S.Grid>
      </Modal>
    </Theme>
  )
}
