import { GetSimulation } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import {
  SimulationKind,
  SimulationPropertyDpe,
  SimulationPropertyKind,
  SimulationPropertyUsage,
} from '@pretto/app/src/types/api/enums'
import { Dpe, Usage as PayloadUsage, PropertyKind } from '@pretto/app/src/types/gateway/enums'

import { pipe } from 'ramda'

import { mapLocalisation } from './lib/location'

const mapDpe = (simulation: GetSimulation): Dpe | null => {
  if (simulation.kind !== SimulationKind.Purchase) {
    return null
  }

  switch (simulation.propertyDpe) {
    case SimulationPropertyDpe.A:
      return Dpe.A

    case SimulationPropertyDpe.B:
      return Dpe.B

    case SimulationPropertyDpe.C:
      return Dpe.C

    case SimulationPropertyDpe.D:
      return Dpe.D

    case SimulationPropertyDpe.E:
      return Dpe.E

    case SimulationPropertyDpe.F:
      return Dpe.F

    case SimulationPropertyDpe.G:
      return Dpe.G

    case SimulationPropertyDpe.Na:
      return Dpe.Na

    default:
      return null
  }
}

const mapEcoWorks: ComposableMapper = ({ project, simulation }) => {
  if (
    simulation.kind !== SimulationKind.Purchase ||
    simulation.propertyKind !== SimulationPropertyKind.Old ||
    simulation.propertyUsage !== SimulationPropertyUsage.PrimaryResidence ||
    simulation.propertyHasWorks === false ||
    simulation.propertyHasRenovationWorks === false
  ) {
    return { project, simulation }
  }

  return {
    project: {
      ...project,

      eco_works: {
        global_renovation: false,
        heating_renewable_energy_source: false,
        hot_water_renewable_energy_source: false,
        hot_water_systems: false,
        insulation_exterior_walls: false,
        insulation_exterior_windows_and_doors: false,
        insulation_roofs: true,
        low_floor_insulation: false,
        sanitation: false,
      },
    },
    simulation,
  }
}

const mapGood: ComposableMapper = ({ project, simulation }) => ({
  project: {
    ...project,

    good: {
      ...project.good,

      dpe: mapDpe(simulation),

      get localisation() {
        if (!simulation.propertyLocation) {
          return null
        }

        return mapLocalisation(simulation.propertyLocation)
      },

      property_kind: mapPropertyKind(simulation),
      usage: mapUsage(simulation),
    },
  },
  simulation,
})

const mapPropertyKind = (simulation: GetSimulation): PropertyKind | null => {
  switch (simulation.propertyKind) {
    case SimulationPropertyKind.Construction:
      return PropertyKind.Construction

    case SimulationPropertyKind.LandConstruction:
      return PropertyKind.LandConstruction

    case SimulationPropertyKind.New:
      return PropertyKind.New

    case SimulationPropertyKind.Old:
      return PropertyKind.Old

    case SimulationPropertyKind.Vefa:
      return PropertyKind.Vefa

    default:
      return null
  }
}

const mapPurchase: ComposableMapper = ({ project, simulation }) => {
  if (
    simulation.kind !== SimulationKind.Capacity &&
    simulation.kind !== SimulationKind.Buyout &&
    simulation.kind !== SimulationKind.Purchase
  ) {
    return { project, simulation }
  }

  return {
    project: {
      ...project,

      purchase: {
        ...project.purchase,

        get build_price() {
          if (
            simulation.kind !== SimulationKind.Purchase ||
            (simulation.propertyKind !== SimulationPropertyKind.Construction &&
              simulation.propertyKind !== SimulationPropertyKind.LandConstruction)
          ) {
            return null
          }

          return simulation.propertyConstructionPrice
        },

        get expected_rental_income() {
          if (
            simulation.kind !== SimulationKind.Purchase ||
            simulation.propertyUsage !== SimulationPropertyUsage.RentalInvestment
          ) {
            return null
          }

          return simulation.propertyExpectedRent
        },

        get land_price() {
          if (
            simulation.kind !== SimulationKind.Purchase ||
            simulation.propertyKind !== SimulationPropertyKind.LandConstruction
          ) {
            return null
          }

          return simulation.propertyLandPrice
        },

        get property_price() {
          if (
            simulation.kind !== SimulationKind.Purchase ||
            (simulation.propertyKind !== SimulationPropertyKind.New &&
              simulation.propertyKind !== SimulationPropertyKind.Old &&
              simulation.propertyKind !== SimulationPropertyKind.Vefa)
          ) {
            return null
          }

          return simulation.propertyPrice
        },

        specific_amounts: project.purchase?.specific_amounts ?? [],

        get works_price() {
          if (
            simulation.kind !== SimulationKind.Purchase ||
            simulation.propertyKind !== SimulationPropertyKind.Old ||
            simulation.propertyHasWorks === false
          ) {
            return null
          }

          return simulation.propertyWorksPrice
        },
      },
    },

    simulation,
  }
}

const mapUsage = (simulation: GetSimulation): PayloadUsage | null => {
  switch (simulation.propertyUsage) {
    case SimulationPropertyUsage.PrimaryResidence:
      return PayloadUsage.PrimaryResidence

    case SimulationPropertyUsage.RentalInvestment:
      return PayloadUsage.RentalInvestment

    case SimulationPropertyUsage.SecondaryResidence:
      return PayloadUsage.SecondaryResidence

    default:
      return null
  }
}

export const mapProperty: ComposableMapper = props => {
  return pipe(mapEcoWorks, mapGood, mapPurchase)(props)
}
