import * as S from './CoachingCard.styles'

export interface CoachingCardProps {
  title: string
  content: string
  ctaTitle: string
  ctaHref: string
  onCtaClick?: () => void
  picture: string
}

export const CoachingCard: React.FC<CoachingCardProps & React.HTMLAttributes<HTMLDivElement>> = ({
  title,
  content,
  ctaTitle,
  ctaHref,
  picture,
  onCtaClick,
}) => {
  return (
    <S.Container>
      <div>
        <S.Title>{title}</S.Title>
        <S.Text dangerouslySetInnerHTML={{ __html: content }} />
        <S.Cta href={ctaHref} onClick={onCtaClick}>
          {ctaTitle}
        </S.Cta>
      </div>
      <S.ImageContainer>
        <S.Image path={picture} />
      </S.ImageContainer>
    </S.Container>
  )
}
