import ApplicationPageComponent from '@pretto/bricks/app/application/pages/ApplicationPage'
import * as layout from '@pretto/bricks/components/layout'
import Responsive from '@pretto/bricks/components/utility/Responsive'

import { getCards } from '@pretto/app-core/application/lib/getCards'

import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'
import { getIsPriceSubmitted } from '@pretto/app/src/SharedContainers/Header/lib/getIsPriceSubmitted'
import { PROJECT } from '@pretto/app/src/apollo'

import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { useAdvisor } from '../../../SharedContainers/AdvisorDialog'
import Header from '../../../SharedContainers/Header'
import { useUser } from '../../../User/Containers/UserProvider'
import { useTracking } from '../../../lib/tracking'
import * as forms from '../../config'
import cardsSchema from '../../config/cards'
import { useApplication } from '../ApplicationContext'

const SCROLL_OFFSET_MIN = 2
const SCROLL_OFFSET_MAX = 6

const getOffsetTop = anchor => {
  if (!anchor) {
    return 0
  }

  const element = document.getElementById(anchor.slice(1))

  if (!element) {
    return 0
  }

  return (
    element.offsetTop -
    (window.innerWidth >= layout.numeralBreakpoints.tablet
      ? SCROLL_OFFSET_MIN * layout.layoutBase
      : SCROLL_OFFSET_MAX * layout.layoutBase)
  )
}

export const ApplicationPageView = ({ location }) => {
  const advisorContext = useAdvisor()

  const { data, documentsProgress } = useApplication()

  const userContext = useUser()

  const [switches, setSwitches] = useState({})

  const themeContext = useTheme()

  const trackAction = useTracking()

  const projectQuery = useQuery(PROJECT)

  const isPriceSubmitted = getIsPriceSubmitted(projectQuery)

  useEffect(() => {
    trackAction('APPLICATION_PAGE_LOADED')
    window.scrollTo(0, getOffsetTop(location.hash))
  }, [])

  const handleToggle = id => {
    setSwitches(switches => ({ ...switches, [id]: !switches[id] }))
  }

  const cards = getCards(
    cardsSchema,
    forms,
    switches,
    handleToggle,
    data,
    userContext,
    advisorContext,
    themeContext,
    trackAction
  )

  return (
    <>
      <Responsive max="laptop">
        <Header
          navigationItemsList={[isPriceSubmitted && NAV_ITEMS.simulator, NAV_ITEMS.dashboard, NAV_ITEMS.folder].filter(
            Boolean
          )}
        />
      </Responsive>
      <Responsive min="laptop">
        <Header
          navigationItemsList={[
            isPriceSubmitted && NAV_ITEMS.simulator,
            NAV_ITEMS.dashboard,
            NAV_ITEMS.folder,
            NAV_ITEMS.faq,
          ].filter(Boolean)}
        />
      </Responsive>
      <ApplicationPageComponent cards={cards} documentsProgress={documentsProgress} />
    </>
  )
}

const ApplicationPage = props => {
  const { introductionUrl, isIntroductionDone } = useApplication()

  if (!isIntroductionDone) {
    return <Redirect to={introductionUrl} />
  }

  return <ApplicationPageView {...props} />
}

ApplicationPageView.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
  }).isRequired,
}

ApplicationPage.restrictionRedirect = ({ isProjectEditable }) => !isProjectEditable && '/'

export default ApplicationPage
