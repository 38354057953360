import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import Header from '@pretto/app/src/SharedContainers/Header'
import NonFundableFailure from '@pretto/app/src/Simulation/Containers/SimulationNonFundable/views/NonFundableFailure/NonFundableFailure'
import NonFundableFallback from '@pretto/app/src/Simulation/Containers/SimulationNonFundable/views/NonFundableFallback/NonFundableFallback'
import SimulationNonFundableView from '@pretto/app/src/Simulation/Containers/SimulationNonFundable/views/SimulationNonFundableView'
import LoaderLLM from '@pretto/app/src/Simulation/components/LoaderLLM/LoaderLLM'
import { client as clientAPI } from '@pretto/app/src/api/api'

import { NetworkStatus } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router'

import { useSimulationAnalysisQuery } from '../../context/simulation_analysis.api.graphql'

const LOADER_TIMING = 5000
const POLLING_INTERVAL = 4000
const POLLING_MAX_COUNT = 6

const SimulationNonFundable = () => {
  const [pollCount, setPollCount] = useState<number>(0)
  const [haveSeenFailure, setHaveSeenFailure] = useState<boolean>(false)
  const { simulation } = useSentences()

  const { loading, data, refetch, startPolling, error, stopPolling, networkStatus } = useSimulationAnalysisQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        simulationId: simulation.id as string,
      },
    },
    client: clientAPI,
    fetchPolicy: 'network-only',
    skip: simulation.id === undefined,
  })

  if (simulation.id === undefined || !!error) {
    return <Redirect to="/" />
  }

  const isPolling = networkStatus === NetworkStatus.poll

  useEffect(() => {
    if (isPolling) {
      setPollCount(prevState => prevState + 1)
    }
  }, [networkStatus])

  useEffect(() => {
    if (
      pollCount < POLLING_MAX_COUNT &&
      (data?.simulationAnalysis === undefined || data?.simulationAnalysis === null)
    ) {
      startPolling(POLLING_INTERVAL)
    } else {
      stopPolling()
    }
  }, [data?.simulationAnalysis, pollCount])

  const { failures, recommendations } = data?.simulationAnalysis ?? {}

  const handleRefetch = () => {
    setPollCount(0)
    setHaveSeenFailure(true)
    refetch()
  }

  if (
    loading ||
    (pollCount < POLLING_MAX_COUNT && (data?.simulationAnalysis === undefined || data?.simulationAnalysis === null))
  ) {
    return (
      <LoaderLLM
        title="Recherche de financement en cours"
        illustration="v1739189143/app/illustrations/handscale.svg"
        listItem={[
          { title: 'Compilation de vos informations…' },
          { title: 'Analyse des critères bancaires…' },
          { title: 'Recherche d’optimisations…' },
          { title: 'Tests de scénarios alternatifs…' },
          { title: 'Identification de solutions…' },
        ]}
        timing={LOADER_TIMING}
      />
    )
  } else {
    if (data?.simulationAnalysis !== undefined && data?.simulationAnalysis !== null) {
      return (
        <ThemeProvider designSystem="reveal">
          <Header />
          <SimulationNonFundableView recommendations={recommendations} failures={failures} />
        </ThemeProvider>
      )
    } else {
      if (!haveSeenFailure) {
        return (
          <ThemeProvider designSystem="reveal">
            <Header />
            <NonFundableFailure refetch={() => handleRefetch()} />
          </ThemeProvider>
        )
      } else {
        return (
          <ThemeProvider designSystem="reveal">
            <Header />
            <NonFundableFallback />
          </ThemeProvider>
        )
      }
    }
  }
}

export default SimulationNonFundable
