import * as S from './FeedbackCard.styles'

export enum Feedback {
  POSITIVE = 'POSITIVE',
  NEUTRAL = 'NEUTRAL',
  WRONG = 'WRONG',
}

export interface FeedbackCardProps {
  title: string
  onFeedbackClick: (feedback: Feedback) => void
}

export const FeedbackCard: React.FC<FeedbackCardProps & React.HTMLAttributes<HTMLDivElement>> = ({
  title,
  onFeedbackClick,
}) => {
  return (
    <S.Container>
      <S.Heading>{title}</S.Heading>

      <S.ButtonContainer>
        <S.Button onClick={() => onFeedbackClick(Feedback.POSITIVE)}>
          <S.IconHappy />
        </S.Button>

        <S.Button onClick={() => onFeedbackClick(Feedback.NEUTRAL)}>
          <S.IconNeutral />
        </S.Button>

        <S.Button onClick={() => onFeedbackClick(Feedback.WRONG)}>
          <S.IconFrown />
        </S.Button>
      </S.ButtonContainer>
    </S.Container>
  )
}
