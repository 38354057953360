import { mapDuration } from '@pretto/app/src/Sentences/ComortgagorOccupation/Sector/lib/mappers/mapDuration'
import { mapPublicStatus } from '@pretto/app/src/Sentences/ComortgagorOccupation/Sector/lib/mappers/mapPublicStatus'
import { mapStartMonth } from '@pretto/app/src/Sentences/ComortgagorOccupation/Sector/lib/mappers/mapStartMonth'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { Month } from '@pretto/app/src/Sentences/types/enums'
import {
  SimulationMortgagorOccupationDuration,
  SimulationMortgagorOccupationPublicStatus,
} from '@pretto/app/src/types/api/enums'

interface PublicChoicesProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const PublicChoices: React.FC<PublicChoicesProps> = ({ activeField, onSetActive }) => {
  const context = useSentences()

  const { simulation } = context

  return (
    <>
      {activeField === 1 && (
        <Choices
          choices={mapPublicStatus(
            Object.values([
              SimulationMortgagorOccupationPublicStatus.Tenure,
              SimulationMortgagorOccupationPublicStatus.Contractor,
              SimulationMortgagorOccupationPublicStatus.Intern,
            ]),
            context,
            onSetActive
          )}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationPublicStatus' },
          }}
        />
      )}
      {activeField === 2 && (
        <Choices
          choices={mapDuration(
            Object.values([
              SimulationMortgagorOccupationDuration.LongTerm,
              SimulationMortgagorOccupationDuration.ShortTerm,
            ]),
            context
          )}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationPublicContractType' },
          }}
          onChoiceClick={() => {
            onSetActive(3)
          }}
        />
      )}
      {activeField === 3 &&
        simulation.profileMortgagors[1]?.occupationDuration === SimulationMortgagorOccupationDuration.ShortTerm && (
          <Choices
            choices={mapStartMonth(Object.values(Month), context)}
            events={{
              eventName: EventName.SimulationChoiceClicked,
              eventPayload: { choice: 'coMortgagorOccupationPublicShortTerm' },
            }}
            onChoiceClick={() => {
              onSetActive(4)
            }}
          />
        )}
    </>
  )
}
