import temporal from '@pretto/bricks/core/utility/temporal'

import { GetSimulation } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { SimulationPropertyUsage, SimulationRenegotiationLoanInsuranceKind } from '@pretto/app/src/types/api/enums'

const MONTHS_IN_YEAR = 12

const matchRate = (usage: SimulationPropertyUsage, age: number): number => {
  switch (usage) {
    case SimulationPropertyUsage.PrimaryResidence:
    case SimulationPropertyUsage.SecondaryResidence: {
      if (age < 46) return 0.26
      if (age < 56) return 0.37
      if (age < 66) return 0.48

      return 0.53
    }

    case SimulationPropertyUsage.RentalInvestment: {
      if (age < 46) return 0.22
      if (age < 56) return 0.3
      if (age < 66) return 0.39

      return 0.45
    }

    default:
      return 0
  }
}

const formatRate = (rate: number) => rate / (MONTHS_IN_YEAR * 100)

const getRate = (usage: SimulationPropertyUsage, age: number): number => formatRate(matchRate(usage, age))

const getRemainingDuration = (startYear: number, duration: number): number => {
  const now = temporal()
  const day = now.date()
  const month = now.month() + 1
  const startDate = temporal(`${startYear}-${month}-${day}`)
  const endDate = startDate.add(duration, 'y')
  const remainingDuration = endDate.diff(now, 'M') + 1

  return remainingDuration
}

const calcRenegotiation = (
  simulation: GetSimulation
): {
  insurancePayment: number
  insuranceRate: number
  loanPayment: number
  payment: number
} | null => {
  const {
    renegotiationLoanDuration,
    renegotiationLoanInsuranceKind,
    renegotiationLoanInsurancePayment,
    renegotiationLoanPayment,
    renegotiationLoanRate,
    renegotiationLoanStartYear,
    propertyUsage,
  } = simulation

  if (
    !renegotiationLoanDuration ||
    !renegotiationLoanInsuranceKind ||
    !renegotiationLoanPayment ||
    (renegotiationLoanInsuranceKind === SimulationRenegotiationLoanInsuranceKind.Delegate &&
      !renegotiationLoanInsurancePayment) ||
    !renegotiationLoanRate ||
    !renegotiationLoanStartYear ||
    !propertyUsage
  ) {
    return null
  }

  const duration = renegotiationLoanDuration * MONTHS_IN_YEAR
  const rate = renegotiationLoanRate / (MONTHS_IN_YEAR * 100)

  return {
    get insurancePayment() {
      if (renegotiationLoanInsuranceKind === SimulationRenegotiationLoanInsuranceKind.Delegate) {
        return renegotiationLoanInsurancePayment ?? 0
      }

      return (
        (renegotiationLoanPayment / (this.insuranceRate + rate / (1 - (1 + rate) ** -duration))) * this.insuranceRate
      )
    },

    get insuranceRate() {
      if (renegotiationLoanInsuranceKind === SimulationRenegotiationLoanInsuranceKind.Delegate) {
        return 0
      }

      return simulation.profileMortgagors.reduce(
        (previous, { age }) => previous + getRate(propertyUsage, Number(age)),
        0
      )
    },

    get loanPayment() {
      if (renegotiationLoanInsuranceKind === SimulationRenegotiationLoanInsuranceKind.Group) {
        return renegotiationLoanPayment - this.insurancePayment
      }

      return renegotiationLoanPayment
    },

    payment: renegotiationLoanPayment,
  }
}

export const calcRemainingPrincipal = (simulation: GetSimulation): number | null => {
  const { renegotiationLoanDuration, renegotiationLoanRate, renegotiationLoanStartYear } = simulation

  if (!renegotiationLoanDuration || !renegotiationLoanRate || !renegotiationLoanStartYear) {
    return null
  }

  const renegotiation = calcRenegotiation(simulation)

  if (!renegotiation) {
    return null
  }

  const { insuranceRate, loanPayment, payment } = renegotiation

  const duration = renegotiationLoanDuration * MONTHS_IN_YEAR
  const rate = renegotiationLoanRate / (MONTHS_IN_YEAR * 100)
  const principal = payment / (insuranceRate + rate / (1 - (1 + rate) ** -duration))

  const remainingDuration = getRemainingDuration(renegotiationLoanStartYear, renegotiationLoanDuration)
  const paidDuration = duration - remainingDuration

  const remainingPrincipal =
    principal * (1 + rate) ** paidDuration - loanPayment * (((1 + rate) ** paidDuration - 1) / rate)

  return remainingPrincipal
}

export const calcPayment = (simulation: GetSimulation): number | null =>
  calcRenegotiation(simulation)?.loanPayment ?? null
