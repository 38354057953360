import { GetSimulation } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/types/mapper'
import { SimulationKind } from '@pretto/app/src/types/api/enums'
import { ProjectKind } from '@pretto/app/src/types/gateway/enums'

export const mapKind: ComposableMapper = ({ project, simulation }) => {
  const mapKind = (simulation: GetSimulation): ProjectKind | null => {
    switch (simulation.kind) {
      case SimulationKind.Buyout:
      case SimulationKind.Capacity:
      case SimulationKind.Purchase:
        return ProjectKind.Purchase

      case SimulationKind.Renegotiation:
        return ProjectKind.Renegotiation

      default:
        return null
    }
  }

  return {
    project: {
      ...project,

      project_kind: mapKind(simulation),
    },
    simulation,
  }
}
