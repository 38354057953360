import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { usePrettoSearch } from '@pretto/app/src/Sentences/contexts/PrettoSearchContext'
import { useRateAlert } from '@pretto/app/src/Sentences/contexts/RateAlertContext'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { hasDpe } from '@pretto/app/src/Sentences/lib/validators/context'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationPropertyDpe } from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'

export const dpePage: React.FC = () => {
  const { isLoggedIn } = useAuth()
  const { prettoSearch } = usePrettoSearch()
  const { rateAlert } = useRateAlert()

  const { simulation, setSimulation } = useSentences()
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && !isNil(simulation.propertyDpe)))

  const handleClickDpe = (dpe: SimulationPropertyDpe) => {
    setSimulation({ propertyDpe: dpe })
    goToNextRoute()
  }

  const mapDpeValues = (values: SimulationPropertyDpe[]) => {
    return values.map(value => {
      return {
        key: value,
        value,
        isSelected: simulation.propertyDpe === value,
        onClick: () => {
          handleClickDpe(value)
        },
        label: t(`sentences.property.dpe.values.${value}`),
      }
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'dpe' } }}>
      <Title>{t('sentences.property.title')}</Title>
      <div>
        {t('sentences.property.dpe.sentence')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!isNil(simulation.propertyDpe)}>
          {t(
            `sentences.property.dpe.values.${
              isNil(simulation.propertyDpe) ? SimulationPropertyDpe.A : simulation.propertyDpe
            }`
          )}
        </Prompt>
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapDpeValues(
            Object.values([
              SimulationPropertyDpe.A,
              SimulationPropertyDpe.B,
              SimulationPropertyDpe.C,
              SimulationPropertyDpe.D,
              SimulationPropertyDpe.E,
              SimulationPropertyDpe.F,
              SimulationPropertyDpe.G,
              SimulationPropertyDpe.Na,
            ])
          )}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'dpe' } }}
        />
      )}
      <Message>{t('sentences.tips.dpe')}</Message>
      {hasDpe({ isLoggedIn, prettoSearch, rateAlert, simulation }) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
