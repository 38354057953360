import Responsive from '@pretto/bricks/components/utility/Responsive/index'

import { MaturityType } from '@pretto/app/src/Sentences/types/enums'
import { getTexts } from '@pretto/app/src/SharedContainers/MutualizedAgenda/lib/getTexts'
import type { Day } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/day'
import type { Occurrence } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/occurrence'
import type { Slot } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/slot'
import { AdvisorLabelsType } from '@pretto/app/src/config/advisor'
import { t } from '@pretto/app/src/lib/i18n'

import { Trans } from 'react-i18next'

import * as S from './StepAgenda.styles'

interface StepAgendaProps {
  advisorLabel: AdvisorLabelsType
  days: Day[]
  handleCallMeBack: () => void
  handleClose: () => void
  handleConfirmAppointment: (slot?: Slot) => void
  handleTrackNext: () => void
  isDriAllowed?: boolean
  maturity: MaturityType
  occurrence: Occurrence
}

export const StepAgenda: React.FC<StepAgendaProps> = ({
  advisorLabel,
  days,
  handleCallMeBack,
  handleClose,
  handleConfirmAppointment,
  handleTrackNext,
  isDriAllowed,
  maturity,
  occurrence,
  ...props
}) => {
  const { title, description, buttonDRI, buttonSkip } = getTexts({ advisorLabel, maturity, occurrence })
  const calendarComponent =
    days.length > 0 ? (
      <S.Calendar onBooked={handleConfirmAppointment} days={days} onTrackNext={handleTrackNext} />
    ) : (
      <S.ErrorMessage>
        <S.Picto />
        <div>
          <Trans i18nKey="mutualizedAgenda.noSlotsErrorMessage" />
        </div>
      </S.ErrorMessage>
    )

  return (
    <S.StepAgendaContainer {...props}>
      <S.LeftSide>
        <S.Tag>{t('mutualizedAgenda.tag')}</S.Tag>

        <S.TitleContainer>
          <S.Title>{title}</S.Title> <S.Duration>{t('mutualizedAgenda.duration')}</S.Duration>
        </S.TitleContainer>

        <S.Description>{description}</S.Description>

        <Responsive max="laptop">{calendarComponent}</Responsive>

        <S.OtherOptionsSection>
          <S.SectionTitle>{t('mutualizedAgenda.appointmentSlots')}</S.SectionTitle>
          {isDriAllowed && <S.Button onClick={handleCallMeBack}>{buttonDRI}</S.Button>}
          <S.Button onClick={handleClose}>{buttonSkip}</S.Button>
        </S.OtherOptionsSection>
      </S.LeftSide>

      <Responsive min="laptop">
        <S.RightSide>{calendarComponent}</S.RightSide>
      </Responsive>
    </S.StepAgendaContainer>
  )
}
