import { Frequency, Month, Rooms, YesNo } from '@pretto/app/src/Sentences/types/enums'
import {
  SimulationCreditKind,
  SimulationKind,
  SimulationMaturityStatus,
  SimulationMortgagorAdditionalRevenueKind,
  SimulationMortgagorIncomePeriod,
  SimulationMortgagorIncomeTaxes,
  SimulationMortgagorLodgingLocationKind,
  SimulationMortgagorLodgingSituationKind,
  SimulationMortgagorOccupationDuration,
  SimulationMortgagorOccupationKind,
  SimulationMortgagorOccupationOtherStatus,
  SimulationMortgagorOccupationPrivateRole,
  SimulationMortgagorOccupationPublicStatus,
  SimulationPropertyDpe,
  SimulationPropertyKind,
  SimulationPropertyUsage,
  SimulationRenegotiationLoanInsuranceKind,
  SimulationStructureKind,
  SimulationStructureSciAssociation,
  SimulationStructureSciKind,
} from '@pretto/app/src/types/api/enums'

/* eslint-disable max-lines */
const translation = {
  sentences: {
    next: 'Suivant',
    back: 'Retour',
    nextWithoutContribution: 'Continuer sans apport',
    nextWithSmallContribution: 'Continuer avec cet apport',
    understood: 'J’ai compris !',
    skip: 'Passer cette étape',
    suspiciousDate: 'La date semble lointaine, nous vous invitons à verifier votre saisie.',
    invalidDate:
      'Format de date invalide. Veuillez remplir les dates sous le format : JJ / MM / YYYY (exemple : 14 / 09 / 1992).',
    creditTooLong: 'Vous ne pouvez pas indiquer de crédits qui se terminent dans plus de 100 ans.',
    paidCredits: 'Vous n’avez pas à indiquer vos crédits déjà remboursés.',
    invalidEmail: 'Votre adresse email n’est pas valide',
    months: {
      [Month.January]: 'Janvier',
      [Month.February]: 'Février',
      [Month.March]: 'Mars',
      [Month.April]: 'Avril',
      [Month.May]: 'Mai',
      [Month.June]: 'Juin',
      [Month.July]: 'Juillet',
      [Month.August]: 'Août',
      [Month.September]: 'Septembre',
      [Month.October]: 'Octobre',
      [Month.November]: 'Novembre',
      [Month.December]: 'Décembre',
    },
    maturity: {
      default: {
        values: {
          [SimulationMaturityStatus.UnderStudy]: 'êtes en réflexion',
          [SimulationMaturityStatus.SearchStarted]: 'êtes en recherche active de bien',
          [SimulationMaturityStatus.OfferMade]: 'avez fait une offre d’achat pour un bien',
          [SimulationMaturityStatus.OfferAccepted]: 'avez une offre d’achat acceptée',
          [SimulationMaturityStatus.SaleAgreementSigned]: 'avez signé un compromis de vente',
        },
      },
      index: {
        title: 'Bienvenue chez Pretto',
        others: {
          description: {
            a: 'Je souhaite renégocier mon crédit immobilier, faire un rachat de soulte, regrouper mes crédits etc.',
            b: 'Je veux renégocier mon taux, regrouper mes crédits ou racheter une soulte',
            c: 'Je veux renégocier mon taux, regrouper mes crédits ou racheter une soulte',
          },
          title: {
            a: 'Je suis déjà propriétaire du bien concerné',
            b: 'Je fais un rachat de crédit',
            c: 'J’optimise un crédit en cours',
          },
        },
        reassurance: {
          image: { alt: 'Équipe Pretto' },
          items: [
            { description: 'Nos courtiers vous disent tout et défendent vos intérêts.', title: 'Transparence' },
            {
              description: 'Nous traitons tous les projets (Primo-accédant, prêts relais, non-résidents, SCI...).',
              title: 'Expertise',
            },
            { description: 'Restez en contact avec votre expert (visio, sms, téléphone).', title: 'Implication' },
          ],
          title: 'Pretto vous garantit...',
        },
        values: {
          [SimulationMaturityStatus.UnderStudy]: {
            description: 'Mon projet d’achat n’est pas encore défini et je n’ai pas encore commencé les visites',
            title: 'Je suis en réflexion',
          },
          [SimulationMaturityStatus.SearchStarted]: {
            description: 'Je regarde régulièrement des annonces et j’ai démarré ou planifié des visites',
            title: 'Je suis en recherche active',
          },
          [SimulationMaturityStatus.OfferMade]: { description: '', title: 'J’ai fait une offre d’achat pour un bien' },
          [SimulationMaturityStatus.OfferAccepted]: { description: '', title: 'J’ai une offre d’achat acceptée' },
          [SimulationMaturityStatus.SaleAgreementSigned]: { description: '', title: 'J’ai signé un compromis' },
        },
      },
      propertyPrice: {
        sentence: 'Connaissez-vous le prix du bien que vous voulez acquérir ?',
        values: { [YesNo.No]: 'Pas encore', [YesNo.Yes]: 'Oui' },
      },
      type: 'Vous',
      agreementSignedDate: 'le',
    },
    // this level refers to the different steps of the sentences (as seen in timeline)
    introduction: {
      title: 'Votre projet',
      // this level is for different views/pages
      structure: {
        error: { nonSolo: 'Pretto n’accompagne pas les projets de rachat de soulte à plusieurs ou en SCI.' },
        sentences: {
          [SimulationKind.Buyout]: 'Vous souhaitez faire un rachat de soulte',
          [SimulationKind.Purchase]: 'Vous souhaitez faire un achat immobilier',
          [SimulationKind.Renegotiation]: 'Vous souhaitez renégocier un prêt immobilier',
        },
        values: {
          [SimulationStructureKind.Solo]: 'seul',
          [SimulationStructureKind.Duo]: 'à deux',
          [SimulationStructureKind.Sci]: 'en SCI',
        },
      },
      sci: {
        type: 'Il s’agit d’une SCI soumise à l’',
        error: {
          sciType:
            'Malheureusement, Pretto n’accompagne pas encore le financement des projets immobiliers pour les SCI soumises à l’impôt sur les sociétés.',
          sciAssociation:
            'Malheureusement, Pretto n’accompagne pas encore le financement des projets immobiliers pour les SCI soumises à l’impôt sur le revenu de plus de 2 associés.',
        },
        values: {
          [SimulationStructureSciKind.Is]: 'impôt sur les sociétés (IS)',
          [SimulationStructureSciKind.Ir]: 'impôt sur les revenus (IR)',
        },
      },
      sciAssociation: {
        sentence: ['Elle est constituée de', 'associés, vous inclus.'],
        values: {
          [SimulationStructureSciAssociation.Simple]: '2',
          [SimulationStructureSciAssociation.Complex]: 'plus de 2',
        },
      },
      propertyUsage: {
        sentence: {
          [SimulationKind.Buyout]: 'Le rachat de soulte porte sur',
          [SimulationKind.Purchase]: 'Vous voulez investir dans',
          [SimulationKind.Renegotiation]: 'Il s’agit de',
        },
        info: {
          [SimulationPropertyUsage.ProfessionalCommercialProject]:
            'Un projet professionnel ou commercial étant spécifique, nous vous invitons à remplir ce formulaire dédié. Un expert spécialisé dans les projets pro étudiera alors la faisabilité de votre projet et vous contactera par téléphone prochainement.',
          [SimulationPropertyUsage.MixedUseProject]:
            'Un projet mixte étant spécifique, nous vous invitons à remplir ce formulaire dédié. Un expert spécialisé dans les projets mixtes étudiera alors la faisabilité de votre projet et vous contactera par téléphone prochainement.',
        },
        values: {
          [SimulationPropertyUsage.PrimaryResidence]: 'votre résidence principale',
          [SimulationPropertyUsage.SecondaryResidence]: 'une résidence secondaire',
          [SimulationPropertyUsage.RentalInvestment]: 'un bien locatif',
          [SimulationPropertyUsage.ProfessionalCommercialProject]: 'un projet professionnel ou commercial',
          [SimulationPropertyUsage.MixedUseProject]: 'un projet mixte',
        },
        form: 'Remplir le formulaire.',
      },
      propertyLocalisation: { sentence: 'dans la commune de' },
      propertyType: {
        sentence: 'Il s’agit d’',
        values: {
          [SimulationPropertyKind.Old]: 'un logement ancien',
          [SimulationPropertyKind.New]: 'un logement neuf achevé (jamais habité)',
          [SimulationPropertyKind.Vefa]: 'une VEFA (vente sur plan)',
          [SimulationPropertyKind.Construction]: 'une construction',
          [SimulationPropertyKind.LandConstruction]: 'un terrain et une construction',
        },
      },
      others: {
        sentence: 'Vous souhaitez faire un',
        values: {
          [SimulationKind.Renegotiation]: 'rachat ou regroupement de crédit(s)',
          [SimulationKind.Buyout]: 'rachat de soulte (séparation, succession)',
        },
      },
    },
    lodging: {
      comortgagor: {
        title: 'Sa situation',
        situation: {
          sentence: 'Votre co-emprunteur est',
          values: {
            [SimulationMortgagorLodgingSituationKind.Tenant]: 'locataire de son logement',
            [SimulationMortgagorLodgingSituationKind.Owner]: 'propriétaire de son logement',
            [SimulationMortgagorLodgingSituationKind.Sheltered]: 'hébergé gratuitement',
            [SimulationMortgagorLodgingSituationKind.StaffHoused]: 'dans un logement de fonction',
          },
        },
        rent: { sentences: ['et son loyer charges comprises est de', '€ par mois'] },
        forSale: {
          sentences: ['et', 'revendre son bien pour financer votre nouvel achat'],
          values: { [YesNo.Yes]: 'souhaite', [YesNo.No]: 'ne souhaite pas' },
        },
        address: { sentence: 'Votre co-emprunteur habite' },
        age: { sentences: [', votre co-emprunteur a', 'ans'], error: 'Veuillez indiquer un âge entre 18 et 99 ans.' },
      },
      title: 'Votre situation',
      age: { sentences: ['Vous avez', 'ans'], error: 'Veuillez indiquer un âge entre 18 et 99 ans.' },
      children: {
        sentences: [' et vous', 'à charge'],
        values: [
          'n’avez aucun enfant',
          'avez un enfant',
          'avez deux enfants',
          'avez trois enfants',
          'avez quatre enfants',
          'avez cinq enfants',
          'avez six enfants',
          'avez sept enfants',
          'avez huit enfants',
          'avez neuf enfants',
          'avez dix enfants',
        ],
      },
      liveTogether: {
        sentences: ['Vous', 'ensemble'],
        values: { [YesNo.Yes]: 'habitez', [YesNo.No]: 'n’habitez pas' },
      },
      address: {
        info: 'Vous ne résidez pas en France : tous les montants que vous allez nous transmettre doivent être renseignés en euros. Pensez donc à convertir les devises étrangères en euros au taux de change actuel.',
        sentence: ['Vous habitez', 'à', 'en/au(x)'],
        values: {
          [SimulationMortgagorLodgingLocationKind.FranceMetropolitan]: 'en France',
          [SimulationMortgagorLodgingLocationKind.World]: 'à l’étranger',
        },
      },
      situation: {
        sentence: 'Vous êtes',
        values: {
          [SimulationMortgagorLodgingSituationKind.Tenant]: 'locataire de votre logement',
          [SimulationMortgagorLodgingSituationKind.Owner]: 'propriétaire de votre logement',
          [SimulationMortgagorLodgingSituationKind.Sheltered]: 'hébergé gratuitement',
          [SimulationMortgagorLodgingSituationKind.StaffHoused]: 'dans un logement de fonction',
        },
      },
      rent: { sentences: ['et votre loyer est de', '€ par mois'] },
      forSale: {
        sentences: ['et vous', 'revendre votre bien pour financer votre nouvel achat'],
        values: { [YesNo.Yes]: 'souhaitez', [YesNo.No]: 'ne souhaitez pas' },
      },
      countryPlaceholder: 'Royaume-Uni',
    },
    situation: {
      comortgagor: {
        title: 'Sa situation pro',
        sector: { sentence: 'Votre co-emprunteur est' },
        expatriate: {
          sentences: ['Votre co-emprunteur a un'],
          values: { [YesNo.Yes]: `statut d'expatrié`, [YesNo.No]: 'contrat local' },
          info: 'Vous êtes considéré comme expatrié par les banques si vous avez un contrat avec une entreprise localisée en France, qui vous a envoyé en mission dans un pays étranger pour une durée limitée dans le temps.',
        },
      },
      title: 'Votre situation pro',
      sector: {
        sentence: 'Vous êtes',
        values: {
          [SimulationMortgagorOccupationKind.Private]: 'salarié dans le privé',
          [SimulationMortgagorOccupationKind.Public]: 'salarié dans le public',
          [SimulationMortgagorOccupationKind.Other]: 'non salarié',
          [SimulationMortgagorOccupationKind.Medical]: 'professionnel de la santé',
        },
      },
      expatriate: {
        sentences: ['Vous avez un'],
        values: { [YesNo.Yes]: `statut d'expatrié`, [YesNo.No]: 'contrat local' },
        info: 'Vous êtes considéré comme expatrié par les banques si vous avez un contrat avec une entreprise localisée en France, qui vous a envoyé en mission dans un pays étranger pour une durée limitée dans le temps.',
      },
      contractType: {
        values: {
          [SimulationMortgagorOccupationDuration.LongTerm]: 'CDI',
          [SimulationMortgagorOccupationDuration.ShortTerm]: 'CDD',
        },
      },
      since: 'depuis',
      in: 'en',
      error: 'La date semble lointaine, nous vous invitons à verifier votre saisie.',
      private: {
        longTerm: {
          executive: {
            values: {
              [SimulationMortgagorOccupationPrivateRole.Executive]: 'cadre',
              [SimulationMortgagorOccupationPrivateRole.NonExecutive]: 'non-cadre',
            },
          },
          trial: { values: { [YesNo.No]: 'hors période d’essai', [YesNo.Yes]: 'en période d’essai' } },
        },
      },
      public: {
        sentence: 'avec un statut de',
        values: {
          [SimulationMortgagorOccupationPublicStatus.Tenure]: 'titulaire',
          [SimulationMortgagorOccupationPublicStatus.Contractor]: 'contractuel',
          [SimulationMortgagorOccupationPublicStatus.Intern]: 'stagiaire',
        },
      },
      others: {
        sentence: 'plus précisément',
        values: {
          [SimulationMortgagorOccupationOtherStatus.SelfEmployed]: 'travailleur indépendant ou autoentrepreneur',
          [SimulationMortgagorOccupationOtherStatus.Retired]: 'retraité',
          [SimulationMortgagorOccupationOtherStatus.BusinessOwner]: 'chef d’entreprise',
          [SimulationMortgagorOccupationOtherStatus.LiberalLegal]: 'professions libérales juridiques',
          [SimulationMortgagorOccupationOtherStatus.LiberalOther]:
            'professions libérales hors médical, paramédical et juridique',
          [SimulationMortgagorOccupationOtherStatus.Jobless]: 'sans profession',
          [SimulationMortgagorOccupationOtherStatus.Merchant]: 'commerçant',
          [SimulationMortgagorOccupationOtherStatus.Farmer]: 'agriculteur',
          [SimulationMortgagorOccupationOtherStatus.ParentalLeave]: 'congé parental',
          [SimulationMortgagorOccupationOtherStatus.Craftsman]: 'artisan',
          [SimulationMortgagorOccupationOtherStatus.Student]: 'étudiant',
          [SimulationMortgagorOccupationOtherStatus.ManagingEmployee]: 'gérant salarié',
          [SimulationMortgagorOccupationOtherStatus.Interim]: 'intérimaire',
          [SimulationMortgagorOccupationOtherStatus.Entertainment]: 'intermittent du spectacle',
          [SimulationMortgagorOccupationOtherStatus.Remote]: 'portage salarial',
          [SimulationMortgagorOccupationOtherStatus.Jobseeker]: 'recherche d’emploi',
        },
      },
    },
    income: {
      comortgagor: {
        title: 'Ses revenus',
        salary: {
          sentence: ['Votre co-emprunteur reçoit un salaire fixe', 'de'],
          info: [
            'Avec le prélèvement à la source, nous avons besoin du salaire de votre co-emprunteur ',
            'net avant impôt',
            '. Vous le trouverez sur sa fiche de paie sous la mention « net à payer avant impôt sur le revenu ».',
          ],
        },
        bonus: {
          sentence: ['Votre co-emprunteur', 'de', '€ (en moyenne sur les 3 dernières années)'],
          values: { [YesNo.Yes]: 'reçoit des primes', [YesNo.No]: 'ne reçoit pas de primes' },
        },
        otherIncome: {
          sentence: [
            'En moyenne sur les trois dernières années, le revenu',
            'de votre-coemprunteur a été de ',
            '(dividendes inclus) ',
            ' de',
          ],
        },
        revenue: { title: 'Ses revenus additionnels' },
      },
      title: 'Vos revenus',
      salary: {
        sentence: ['Vous recevez un salaire fixe', 'de'],
        info: [
          'Avec le prélèvement à la source, nous avons besoin de votre salaire ',
          'net avant impôt',
          '. Vous le trouverez sur votre fiche de paie sous la mention « net à payer avant impôt sur le revenu ».',
        ],
      },
      bonus: {
        sentence: ['Vous', 'de', '€ (en moyenne sur les 3 dernières années)'],
        values: { [YesNo.Yes]: 'avez des primes', [YesNo.No]: 'n’avez pas de primes' },
        period: {
          [SimulationMortgagorIncomePeriod.Yearly]: 'annuelles',
          [SimulationMortgagorIncomePeriod.Monthly]: 'mensuelles',
        },
        type: {
          [SimulationMortgagorIncomeTaxes.After]: 'nettes avant impôt',
          [SimulationMortgagorIncomeTaxes.Before]: 'brutes',
        },
      },
      otherIncome: {
        sentence: ['En moyenne sur les trois dernières années, votre revenu', 'a été ', '(dividendes inclus) ', ' de'],
      },
      period: {
        [SimulationMortgagorIncomePeriod.Yearly]: 'annuel',
        [SimulationMortgagorIncomePeriod.Monthly]: 'mensuel',
      },
      type: {
        [SimulationMortgagorIncomeTaxes.After]: 'net avant impôt',
        [SimulationMortgagorIncomeTaxes.Before]: 'brut',
      },
      revenue: {
        title: 'Vos revenus additionnels',
        subtitle: 'Revenu',
        addValue: 'Ajouter un revenu additionnel',
        sentences: ['pour un montant de', '€ par mois'],
        values: {
          [SimulationMortgagorAdditionalRevenueKind.Rent]: 'Revenu locatif',
          [SimulationMortgagorAdditionalRevenueKind.ChildSupport]: 'Pension alimentaire',
          [SimulationMortgagorAdditionalRevenueKind.ProfessionalBenefits]: 'Revenus autoentreprise',
          [SimulationMortgagorAdditionalRevenueKind.FamilyAllowances]: 'Allocations',
          [SimulationMortgagorAdditionalRevenueKind.ExpatriationBonus]: 'Indemnités d’expatriation',
        },
      },
    },
    sale: {
      title: 'Votre bien en vente',
      estimatedPrice: { sentences: ['Votre bien a une valeur estimée de', '€'] },
      paidOff: {
        sentences: ['Vous', 'fini de le rembourser'],
        values: { [YesNo.Yes]: 'avez', [YesNo.No]: 'n’avez pas' },
      },
      remainingMortgage: {
        sentences: ['Il vous reste', '€ à rembourser', ', à un taux de', '%', '. Votre crédit se termine le'],
      },
    },
    ptz: {
      title: 'Votre prêt à taux zéro',
      beenOwner: {
        sentences: ['Vous', 'été propriétaire de votre résidence principale au cours des deux dernières années'],
        values: { [YesNo.Yes]: 'avez', [YesNo.No]: 'n’avez pas' },
      },
      fiscalIncomeNm2: { sentences: ['Votre revenu fiscal de référence en {{year}} s’élevait à', '€'] },
    },
    charge: {
      title: 'Vos charges',
      sentences: ['Vous', 'Votre co-emprunteur', 'd’autre(s) crédit(s) en cours.'],
      credits: {
        addValue: 'Ajouter un crédit',
        sentences: [
          'avec une mensualité de',
          '€ par mois',
          ' qui se termine le',
          'du bien sujet au rachat de soulte (reste à rembourser',
          '€)',
        ],
        values: {
          [SimulationCreditKind.Mortgage]: 'Crédit immobilier',
          [SimulationCreditKind.Consumer]: 'Crédit conso',
          [SimulationCreditKind.Car]: 'Crédit voiture',
          [SimulationCreditKind.Student]: 'Crédit étudiant',
          [SimulationCreditKind.Loa]: 'Location avec Option d’Achat (LOA, ou leasing)',
          [SimulationCreditKind.Other]: 'Autre type de crédit',
        },
        subtitle: { purchase: 'Crédit', renegotiation: 'Votre crédit immobilier à racheter' },
      },
      childSupport: {
        sentences: ['Vous', 'pension alimentaire', ' qui s’élève à', '€ par mois'],
        values: { [YesNo.Yes]: 'versez une', [YesNo.No]: 'ne versez pas de' },
        comortgagor: {
          sentence: 'Votre co-emprunteur',
          values: { [YesNo.Yes]: 'verse une', [YesNo.No]: 'ne verse pas de' },
        },
      },
    },
    property: {
      title: 'Votre bien',
      dpe: {
        sentence: 'Le DPE (Diagnostique de Performance Énergétique) de votre futur bien est',
        values: {
          [SimulationPropertyDpe.A]: 'Classe A',
          [SimulationPropertyDpe.B]: 'Classe B',
          [SimulationPropertyDpe.C]: 'Classe C',
          [SimulationPropertyDpe.D]: 'Classe D',
          [SimulationPropertyDpe.E]: 'Classe E',
          [SimulationPropertyDpe.F]: 'Classe F',
          [SimulationPropertyDpe.G]: 'Classe G',
          [SimulationPropertyDpe.Na]: 'Inconnu',
        },
      },
      good: { sentences: ['Le prix du bien est de', '€ (frais d’agence compris)'] },
      construction: { sentences: ['Le coût de la construction est de', '€'] },
      landConstruction: {
        sentences: ['Le coût du terrain - frais d’agence compris - est de', ', et celui de la construction de', '€'],
      },
      works: {
        sentences: ['Vous', 'financer des travaux', ' pour un montant de', '€'],
        values: { [YesNo.Yes]: 'souhaitez', [YesNo.No]: 'ne souhaitez pas' },
      },
      ecoPtz: {
        sentences: ['Les travaux que vous envisagez', 'des travaux de rénovation énergétique.'],
        values: { [YesNo.Yes]: 'contiennent', [YesNo.No]: 'ne contiennent pas' },
      },
      expectedRental: { sentences: ['Le loyer attendu est de', '€ par mois'] },
    },
    propertyRenegotiation: {
      title: 'Votre bien',
      estimatedPrice: { sentences: ['Vous estimez que le prix actuel du bien est de', '€'] },
    },
    loan: {
      title: 'Votre prêt',
      bank: {
        sentences: ['Ce crédit a été contracté auprès de la banque', '. Votre assurance emprunteur est'],
        values: {
          [SimulationRenegotiationLoanInsuranceKind.Group]: 'l’assurance de la banque',
          [SimulationRenegotiationLoanInsuranceKind.Delegate]: 'une assurance déléguée',
        },
      },
      payment: {
        sentences: {
          [SimulationRenegotiationLoanInsuranceKind.Group]: [
            'La mensualité assurance comprise de votre crédit est de',
            '€',
          ],
          [SimulationRenegotiationLoanInsuranceKind.Delegate]: [
            'La mensualité de votre crédit est de',
            '€',
            ' et votre mensualité d’assurance emprunteur moyenne est de',
            '€',
          ],
        },
      },
      rate: { sentences: ['Le taux d’intérêt de ce crédit est de', '%'] },
      dateDuration: {
        sentences: ['Vous avez signé ce prêt en', 'pour une durée de', 'ans'],
        date: { error: 'Vous ne pouvez pas renégocier un crédit qui n’a pas encore démarré' },
        duration: { error: 'Vous ne pouvez pas renégocier un prêt qui se termine dans moins de 5 ans.' },
      },
    },
    contribution: {
      title: 'Votre apport',
      contribution: {
        sentences: ['En plus de la revente, pour ', 'Pour ', 'financer votre projet, vous avez un apport de', '€'],
      },
      savings: { sentences: ['il vous restera', '€ de côté.'] },
    },
    competition: {
      title: 'Vos démarches',
      hasCompetition: {
        sentences: ['Vous', ' et votre co-emprunteur', 'reçu une proposition écrite de la part d’une banque.'],
        values: { [YesNo.Yes]: 'avez', [YesNo.No]: 'n’avez pas' },
      },
      offer: {
        sentences: [
          'La meilleure offre que vous avez vient de',
          '. Elle vous a proposé un taux d’intérêt (hors assurance) de',
          '%',
          ' sur une durée de',
          'ans',
        ],
      },
      bankPlaceholder: 'Nom de la banque',
    },
    rateAlert: {
      title: 'Votre taux personnalisé',
      sentence:
        'Les taux changent constamment ! Nous les suivons en permanence pour chaque profil et pouvons vous prévenir lorsqu’une opportunité pour un taux plus attractif apparaît dans une de nos banques partenaire.',
      wantsRateAlert: {
        sentences: ['Et vous souhaitez', 'et recevoir vos alertes à l’adresse e-mail :'],
        values: { [YesNo.Yes]: 'être alerté', [YesNo.No]: 'ne pas être alerté' },
      },
      emailRateAlert: {
        sentence: 'Votre adresse email est',
        disclaimer: [
          'En vous inscrivant à l’alerte taux Pretto, vous acceptez les',
          'conditions générales d’utilisation',
        ],
      },
    },
    propertySearch: {
      title: 'Votre recherche de bien',
      localisations: { sentence: 'Vous surveillez les annonces à', addCity: 'Ajouter une ville' },
      frequency: {
        sentence: 'Et souhaitez',
        values: {
          [Frequency.Daily]: 'être notifié une fois par jour',
          [Frequency.Weekly]: 'être notifié une fois par semaine',
          [Frequency.Never]: 'ne pas être notifié',
        },
      },
      criteriaEmail: {
        disclaimer: ['En vous inscrivant aux alertes Pretto, vous acceptez les', 'conditions générales d’utilisation.'],
        sentences: [
          'Vous recherchez un bien d’une surface comprise entre',
          'm²',
          ' et',
          'm²',
          ', avec au moins',
          '.',
          'Vous souhaitez recevoir vos alertes à l’adresse e-mail :',
          '.',
        ],
        values: {
          [Rooms.One]: '1 pièce ou plus',
          [Rooms.Two]: '2 pièces ou plus',
          [Rooms.Three]: '3 pièces ou plus',
          [Rooms.Four]: '4 pièces ou plus',
          [Rooms.Five]: '5 pièces ou plus',
        },
      },
    },
    tips: {
      project: {
        [SimulationKind.Purchase]:
          'Prenez quelques minutes pour décrire votre projet : c’est simple, rapide et sans engagement. Plus vous serez complet dans la description de votre situation et plus notre comparaison des meilleurs taux pour vous sera précise !',
        [SimulationKind.Renegotiation]:
          'Prenez quelques minutes pour décrire votre projet : c’est simple, rapide et sans engagement. Plus vous serez complet dans la description de votre situation et plus notre comparaison des meilleurs taux pour vous sera précise !',
        [SimulationKind.Capacity]:
          'Prenez quelques minutes pour décrire votre projet : c’est simple, rapide et sans engagement. Plus vous serez complet dans la description de votre situation et plus notre estimation de votre capacité d’emprunt sera précise !',
      },
      income: {
        salary: {
          mortgagor:
            'Avec le prélèvement à la source, nous avons besoin de votre salaire <strong>net avant impôt</strong>. Vous le trouverez sur votre fiche de paie sous la mention « net à payer avant impôt sur le revenu ».',
          comortgagor:
            'Avec le prélèvement à la source, nous avons besoin du salaire de votre co-emprunteur <strong>net avant impôt</strong>. Vous le trouverez sur sa fiche de paie sous la mention « net à payer avant impôt sur le revenu ».',
        },
        bonus: {
          mortgagor:
            'La banque calcule la moyenne du variable que vous avez touché au cours des trois dernières années. Ne le surestimez pas : vous pourriez mal évaluer votre budget immobilier.',
          comortgagor:
            'La banque calcule la moyenne du variable que votre co-emprunteur a touché au cours des trois dernières années. Ne le surestimez pas : vous pourriez mal évaluer votre budget immobilier.',
        },
        businessOwner: {
          mortgagor:
            'Vous êtes chef d’entreprise, pour calculer vos revenus nous vous invitons à faire la moyenne de vos revenus déclarés sur vos avis d’imposition sur les trois dernières années, vous pouvez inclure les dividendes dans cette moyenne.',
          comortgagor:
            'Votre co-emprunteur est chef d’entreprise, pour calculer ses revenus nous vous invitons à faire la moyenne de ses revenus déclarés sur ses avis d’imposition sur les trois dernières années, vous pouvez inclure les dividendes dans cette moyenne.',
        },
      },
      contribution: {
        nonResident:
          'Vous êtes non résident : les banques demandent que l’apport couvre les frais et 20 % du montant du bien, soit <strong>{{minContribution, number}} €</strong>.',
        nonResidentCapacity:
          'Vous êtes non résident : les banques demandent que l’apport couvre les frais et 20 % du montant du bien.',
        construction:
          'Il est recommandé que l’apport soit au moins égal aux frais de votre projet et 10 % du prix de la construction, soit <strong>{{minContribution, number}} €</strong>. Vous pouvez dans certains cas apporter moins, mais gardez en tête que plus votre apport est élevé, plus facile sera votre financement.',
        landConstruction:
          'Il est recommandé que l’apport soit au moins égal aux frais de votre projet et 10 % du prix du terrain et de la construction, soit <strong>{{minContribution, number}} €</strong>. Vous pouvez dans certains cas apporter moins, mais gardez en tête que plus votre apport est élevé, plus facile sera votre financement.',
        purchase:
          'Il est recommandé que l’apport soit au moins égal aux frais de votre projet, soit <strong>{{minContribution, number}} €</strong>. Vous pouvez dans certains cas apporter moins, mais gardez en tête que plus votre apport est élevé, plus facile sera votre financement.',
        capacity:
          'Plus votre apport est élevé, plus vous pourrez emprunter et plus facile sera votre financement. Vous pourrez bien sûr modifier votre apport à tout moment.',
        withoutContribution: [
          'Êtes vous sûr(e) de continuer sans apport ?',
          'Sans apport, votre projet sera moins facilement finançable.',
        ],
        smallContribution: 'Êtes vous sûr(e) de continuer avec cet apport ?',
      },
      nonResident: {
        currency:
          'Vous ne résidez pas en France : tous les montants que vous allez nous transmettre doivent être renseignés en euros. Pensez donc à convertir les devises étrangères en euros au taux de change actuel.',
        savings:
          'Vous êtes non résident : les banques demandent une épargne résiduelle minimum de 10 % du montant emprunté, soit environ <strong>{{minimumSavings, number}} €</strong>.',
        rentalInvestment:
          'Vous ne résidez pas en France et vous effectuez un investissement locatif : vous n’aurez pas besoin de domicilier vos revenus, en revanche votre taux et l’apport minimum exigé par la banque seront plus élevés que pour un résident. Le résultat de cette simulation en tiendra compte.',
      },
      boomer:
        'Nous vous recommandons de choisir une durée pour votre crédit immobilier permettant de le rembourser avant vos 75 ans : vous accèderez à plus de banques et votre assurance sera moins coûteuse.',
      occupation: {
        trialPeriod: {
          mortgagor:
            'Vos revenus ne seront pas pris en compte en période d’essai. Vous pouvez attendre ou, si c’est possible, vous accorder avec votre employeur pour confirmer votre CDI.',
          comortgagor:
            'Les revenus de votre co-emprunteur en période d’essai ne seront pas pris en compte. Vous pouvez attendre ou, si c’est possible, vous accorder avec son employeur pour confirmer son CDI.',
        },
        shortTerm: {
          mortgagor: `Vos revenus en CDD ne seront pas pris en compte par les banques dans le calcul de votre capacité d'emprunt si la durée de votre CDD est inférieure à 3 ans.`,
          comortgagor:
            'Les revenus en CDD de votre co-emprunteur ne seront pas pris en compte par les banques dans le calcul de votre capacité d’emprunt si la durée de son CDD est inférieure à 3 ans.',
        },
        earlyBusinessOwner: {
          mortgagor:
            'Vos revenus en tant que chef d’entreprise ne seront pas pris en compte par les banques si vous avez démarré votre activité il y a moins de 3 ans.',
          comortgagor:
            'Les revenus de votre co-emprunteur en tant que chef d’entreprise ne seront pas pris en compte par les banques si il a démarré son activité il y a moins de 3 ans.',
        },
      },
      credits:
        'Nous optimisons votre taux d’endettement pour qu’il reste acceptable par les banques. Pour cela, indiquez les crédits que vous continuerez à rembourser en même temps que votre crédit immobilier.',
      credits_duo:
        'Nous optimisons votre taux d’endettement pour qu’il reste acceptable par les banques. Pour cela, indiquez les crédits que vous et votre co-emprunteur continuerez à rembourser en même temps que votre crédit immobilier.',
      hasWorks:
        'Un prêt immobilier ne peut financer que des travaux que vous ne pourrez pas emporter. Vous pouvez par exemple financer une cloison, la plomberie, un plancher...',
      forSale:
        'Les banques peuvent vous accorder un prêt relais. Il s’agit d’une avance sur la vente de votre bien sur laquelle vous ne payez que les intérêts. Cela vous permet de limiter votre endettement tout en vous laissant le temps de trouver un acheteur.',
      dpe: 'Où trouver cette information ? La classe DPE doit normalement figurer sur l’annonce immobilière de votre futur logement.',
      renegotiationLoan:
        'Si le crédit que vous renégociez comporte plusieurs lignes, indiquez ici votre mensualité totale. Votre expert Pretto affinera avec vous par la suite.',
      ecoPtz: `Les travaux de rénovation énergétique (par exemple, l’isolation thermique de votre toiture, le changement des fenêtres ou du système de chauffage) peuvent être financés par les banques à l’aide d’un éco-PTZ. Il s’agit d’un prêt à taux 0 d’un montant maximal de ${(50000).toLocaleString(
        'fr'
      )} €. Ce montant varie en fonction des travaux effectués.`,
      buyout:
        'La soulte correspond à la part que vous rachetez aux personnes avec qui vous partagez la propriété du bien. Le montant de la soulte sera précisé par votre notaire, mais vous pouvez aussi le calculer (<Link href="https://faq.pretto.fr/fr/articles/1833026#comment_connaitre_le_montant_de_la_soulte_a_racheter">en savoir plus</Link>).',
    },
    buyout: {
      title: 'Votre soulte',
      buyout: {
        sentences: [
          'Vous',
          'crédit en cours sur le bien concerné par le rachat de soulte.',
          'Il reste',
          '€ à rembourser.',
          'Le montant de la soulte est de',
          '€.',
        ],
        values: { [YesNo.Yes]: 'avez un', [YesNo.No]: 'n’avez pas de' },
      },
    },
  },
  timeline: {
    sections: {
      introduction: { title: 'Introduction', steps: { project: 'Votre projet' } },
      mortgagor: {
        title: 'Vos informations',
        steps: {
          situationPersonal: 'Votre situation',
          loan: 'Votre prêt',
          situationProfessional: 'Votre situation pro',
          incomes: 'Vos revenus',
          sale: 'Votre bien en vente',
          ptz: 'Votre prêt à taux zéro',
        },
      },
      comortgagor: {
        title: 'Votre co-emprunteur',
        steps: { situationPersonal: 'Sa situation', situationProfessional: 'Sa situation pro', incomes: 'Ses revenus' },
      },
      end: {
        steps: {
          buyout: 'Votre soulte',
          charges: 'Vos charges',
          credits: 'Vos crédits',
          good: 'Votre bien',
          contribution: 'Votre apport',
          competition: 'Vos démarches',
          propertySearch: 'Votre recherche de bien',
          rateAlert: 'Votre taux personnalisé',
        },
      },
    },
  },
} as const

export default translation
