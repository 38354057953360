import { temporal } from '@pretto/bricks/core/utility/temporal'

import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { inputDateFormat, isDateValid } from '@pretto/app/src/Sentences/lib/date'
import { t } from '@pretto/app/src/lib/i18n'
import { SimulationCredit } from '@pretto/app/src/types/api/schema'

export type Credit = {
  endDate?: SimulationCredit['endDate']
  kind: SimulationCredit['kind']
  payment: SimulationCredit['payment']
}

type CreditBoxSummaryProps = Credit

export const CreditBoxSummary: React.FC<CreditBoxSummaryProps> = ({ endDate, payment }) => {
  return (
    <>
      {payment && (
        <>
          {t('sentences.charge.credits.sentences.0')}
          <Prompt isFilled>{payment}</Prompt>
          {t('sentences.charge.credits.sentences.1')}
        </>
      )}
      {endDate && isDateValid(temporal(endDate).toDate()) && (
        <>
          {t('sentences.charge.credits.sentences.2')}
          <Prompt isFilled>{temporal(endDate).format(inputDateFormat)}</Prompt>
        </>
      )}
    </>
  )
}
