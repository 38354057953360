import { mapPaidOffValues } from '@pretto/app/src/Sentences/Sale/PaidOff/lib/mappers/mapPaidOffValues'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const PaidOffPage: React.FC = () => {
  const { simulation, setSimulation } = useSentences()
  const { goToNextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, !isNil(simulation.ownPropertyPaidOff))

  const handleChoiceClick = () => {
    if (simulation.ownPropertyPaidOff) {
      goToNextRoute()
      return
    }
    setSimulation({
      ownPropertyEndDate: null,
      ownPropertyRemainingPrincipal: null,
      ownPropertyRate: null,
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'paidOff' } }}>
      <Title>{t('sentences.sale.title')}</Title>
      <div>
        {t('sentences.sale.paidOff.sentences.0')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!isNil(simulation.ownPropertyPaidOff)}>
          {t(
            `sentences.sale.paidOff.values.${
              !isNil(simulation.ownPropertyPaidOff) && simulation.ownPropertyPaidOff ? YesNo.Yes : YesNo.No
            }`
          )}
        </Prompt>
        {t('sentences.sale.paidOff.sentences.1')}.
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapPaidOffValues(Object.values(YesNo))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'paidOff' } }}
          onChoiceClick={handleChoiceClick}
        />
      )}
      <ButtonNext>{t('sentences.next')}</ButtonNext>
    </TrackedView>
  )
}
